import React, { useContext } from "react"
import { useQuery } from "react-query"
import { Link, useNavigate } from "react-router-dom"
import axiosInstance from "../../../../api/axiosInstance"
import Loading from "../../../../components/ClientProfile/Loading/Loading"
import { AppContext } from "../../../../contexts/AppContext"
import { Firm } from "../../../../models/Firm"
import Licensee from "../../../../models/Licensee"
import ErrorMessage from "../../../../components/Error/ErrorMessage"

export type FirmStats = {
  stats: {
    advisors: {
      active: number
      archived: number
    }
    clients: {
      active: number
      archived: number
    }
  }
}

const ListFirms = () => {
  const navigate = useNavigate()
  const { setFirmId } = useContext(AppContext)
  
  const view = useQuery(["firms"], () => axiosInstance.get<(Omit<Firm, "licensee"> & FirmStats & { licensee: Licensee } )[]>(`${import.meta.env.VITE_APP_API_BASE || ""}/api/admin/firms`)
    .then(res => res.data), {
        staleTime: 0,
    })

  return (
    <div className="px-10 w-full h-full flex flex-col gap-y-4 overflow-y-hidden">
      
      {view.isLoading && <Loading />}
      {view.isError && <ErrorMessage id="list-firms" message={String(view.error)} />}
      {view.isSuccess && <>
        <div className="grid grid-cols-[2fr_4fr_1fr_2fr_2fr_2fr_6rem]">
          <div className="text-h5 font-semibold border-b border-interactive-300">Licensee</div>
          <div className="text-h5 font-semibold border-b border-interactive-300">Firm Name</div>
          <div className="text-h5 font-semibold border-b border-interactive-300 text-right">Advisors</div>
          <div className="text-h5 font-semibold border-b border-interactive-300 text-right">Active Clients</div>
          <div className="text-h5 font-semibold border-b border-interactive-300 text-right">Archived Clients</div>
          <div className="text-h5 font-semibold border-b border-interactive-300 pl-2">Billing Model</div>
          <div className="text-h5 font-semibold border-b border-interactive-300 text-right">Actions</div>
        </div>
        <div className="grid grid-cols-[2fr_4fr_1fr_2fr_2fr_2fr_6rem] overflow-y-auto no-scrollbar">
          {view.data?.map(firm => (
            <React.Fragment key={firm._id}>
              <div className="border-b border-neutral-200"><Link className="underline text-interactive-500" to={`../licensee/${firm.licensee._id}`}>{firm.licensee.name}</Link></div>
              <div className="border-b border-neutral-200"><Link className="underline text-interactive-500" to={`../firm/${firm._id}`}>{firm.name}</Link></div>
              <div className="border-b border-neutral-200 text-right">{firm.stats?.advisors.active || 0}</div>
              <div className="border-b border-neutral-200 text-right">{firm.stats?.clients.active || 0}</div>
              <div className="border-b border-neutral-200 text-right">{firm.stats?.clients.archived || 0}</div>
              <div className="border-b border-neutral-200 pl-6">
                {firm.billingCode && <span className="rounded-2 bg-surface-300 px-3 text-sec">{firm.billingCode}</span>}
              </div>
              <div className="border-b border-neutral-200 text-right">
                <button
                  className="btn btn-xs btn-text"
                  onClick={() => {
                    setFirmId(firm._id)
                    navigate("/firm/model-portfolios")
                  }}>
                  Admin
                </button>
              </div>
          </React.Fragment>
          ))}
        </div>
      </>}
    </div>
  )
}

export default ListFirms