import clsx from "clsx"
import { Cause } from "../../../config/theme"
import { useTheme } from "../../../contexts/ThemeContext"
import { Client } from "../../../models/Client"

const ValuesPersonaSummary = ({ client, topCause }: { client: Client; topCause: Cause }) => {
  const theme = useTheme()
  return (
    <div className={clsx("persona-summary w-full h-auto relative rounded-3 p-5")} style={{ backgroundColor: topCause.barColor }}>
      <div className="w-full flex justify-center">
        <div className="persona-summary-bg-image max-w-xl absolute left-2 top-4 md:left-9 md:top-9">
          <div className="w-full h-52">
            <img src={theme.pages.profile?.valuesPersona?.bgImage} className="w-full h-52" alt="" aria-hidden />
          </div>
        </div>
        <div className="values-results-page-top-card-content z-0">
          <div className="text-white flex flex-col justify-center items-center">
            <div className="mb-6 w-24">
              <img src={topCause?.returnsIcon} alt="" aria-hidden />
            </div>
            <p className="text-sec leading-3 uppercase" style={{ color: topCause.color }}>{client.firstName} is interested in</p>
            <p className="text-h2 leading-7o5 !font-bold" style={{ color: topCause.color }}>{topCause.title}</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ValuesPersonaSummary
