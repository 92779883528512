import { AxiosError } from "axios"
import clsx from "clsx"
import React, { UIEventHandler, useContext, useState } from "react"
import { useParams } from "react-router"
import { ImportFundEntry, importPortfolio } from "../../../../api/clients"
import Loading from "../../../../components/ClientProfile/Loading/Loading"
import { ClientHouseholdCacheContext } from "../../../../contexts/ClientHouseholdCacheContext"
import { useTheme } from "../../../../contexts/ThemeContext"
import { useImportModal } from "../../../../hooks/useImport"
import { AuthContext } from "../../../../views/auth/AuthContext"
import download from "../../assets/images/download.svg"
import remove from "../../assets/images/minus-circle.svg"
import selectFile from "../../assets/images/select-file.svg"
import AlertIcon from "../../../../assets/icons/AlertIcon"
import FundErrorsDisplay from "./FundErrorsDisplay"
import ErrorMessage from "../../../../components/Error/ErrorMessage"

interface Props {
  handleClose: UIEventHandler
}

const ImportPortfolio: React.FunctionComponent<Props> = ({ handleClose }) => {
  const theme = useTheme()
  const { sessionInfo } = useContext(AuthContext)
  const { replace } = useContext(ClientHouseholdCacheContext)
  const { clientId } = useParams<{ clientId: string; householdId: string }>()
  const [invalidFunds, setInvalidFunds] = useState<ImportFundEntry[]>()
  const [importError, setImportError] = useState<string>("")

  const {
    selectedFile,
    setSelectedFile,
    dragActive,
    inputRef,
    importStatus,
    setImportStatus,
    isInvalidFile,
    handleDrag,
    handleDrop,
    handleChange,
    onButtonClick
  } = useImportModal()

  const handleImport = () => {
    if (!selectedFile || isInvalidFile) {
      setImportStatus("validationError")
      if (!selectedFile) {
        setImportError("Please add file.")
      } else if (isInvalidFile) {
        setImportError("Please use correct file type. Valid file types are xlsx, xls or csv.")
      }
    } else {
      setImportStatus("importing")
      setSelectedFile(null)
      const formData = new FormData()
      formData.append("File", selectedFile as Blob)
      importPortfolio(sessionInfo!, clientId!, formData)
        .then((response) => {
          replace(response.client)
          setImportStatus(response.missingFunds && response.missingFunds.length > 0 ? "partiallySuccess" : "success")
          setInvalidFunds(response.missingFunds)
        })
        .catch((err: AxiosError<any>) => {
          console.error("error importing the data", err)
          setImportStatus("error")
          setImportError(err.response?.data?.errorCode)
          setInvalidFunds(err.response?.data?.invalidData)
        })
    }
  }

  const portfolioTemplateLink = theme.pages.importModal?.portfolioTemplateLink ?? "/Import_portfolio_template.xlsx"

  return (
    <div className="import-portfolio-modal w-[600px] h-full flex flex-col gap-6 px-2">
      {importStatus === "importing" ? (
        <div className="w-full h-80 mx-auto">
          <Loading />
        </div>
      ) : importStatus === "error" ? (
        <div className="flex flex-col gap-6 items-center text-center">
          {theme.pages.importModal?.images?.warningImage && <img className="w-20" src={theme.pages.importModal?.images?.warningImage} alt="" aria-hidden />}
          {importError === "INVALID_TOTAL_CURRENT_ALLOCATION" ? (
            <h2 className="text-h2 text-main-600 font-semibold px-10">We can’t import this file as the current allocation doesn’t total 100%</h2>
          ) : importError === "MISSING_FUNDS_ALLOCATION_MISMATCH" ? (
            <>
              <h2 className="text-h2 text-main-600 font-semibold px-10">Current portfolio not uploaded</h2>
            </>
          ) : (
            <h2 className="text-h2 text-main-600 font-semibold px-10">Current portfolio not imported</h2>
          )}
          {invalidFunds && invalidFunds.length > 0 && (
            <>
              <p className="text-left font-normal">
                {importError === "MISSING_FUNDS_ALLOCATION_MISMATCH" ? (
                  <span>
                    Sorry, we’re currently unable to identify the following holdings which total more than 30% of the portfolio. Either there’s a typo in the
                    fund identifier, or these funds aren’t currently available in our database. As a result, we can’t provide a Sustainability Match score for
                    the portfolio.
                  </span>
                ) : (
                  <span>
                    There are issues with the following rows. Either the fund identifier or current allocation is missing, or the data is not entered correctly.
                  </span>
                )}
              </p>
              <FundErrorsDisplay funds={invalidFunds} />
            </>
          )}
        </div>
      ) : importStatus === "success" ? (
        <>
          <div className="flex flex-col gap-6 items-center text-center">
            {theme.pages.importModal?.images?.successImage && <img className="w-20" src={theme.pages.importModal?.images?.successImage} alt="" aria-hidden />}
            <h2 className="text-h2 text-main-600 font-semibold px-10">Current portfolio imported</h2>
          </div>
        </>
      ) : importStatus === "partiallySuccess" ? (
        <>
          <div className="flex flex-col gap-6 items-center text-center">
            {theme.pages.importModal?.images?.successImage && <img className="w-20" src={theme.pages.importModal?.images?.successImage} alt="" aria-hidden />}
            <h2 className="text-h2 text-main-600 font-semibold px-10">Current portfolio imported</h2>
            {invalidFunds && invalidFunds.length > 0 && (
              <>
                <p className="text-left font-normal">
                  We couldn’t identify the following funds. Either there’s a typo in the fund identifier, or these funds aren’t currently available in our
                  database. As a result, these funds will show up as undefined.
                </p>
                <FundErrorsDisplay funds={invalidFunds} />
              </>
            )}
          </div>
        </>
      ) : (
        <>
          <h2 className="text-h2 text-main-600 font-semibold">Import portfolio</h2>
          <div className="flex flex-row gap-x-2">
            <img className="import-portfolio-download-icon" src={theme.pages.importModal?.icons?.downloadImage ?? download} alt="" aria-hidden />
            <a href={portfolioTemplateLink} download className="import-portfolio-download-link text-interactive-500 font-bold text-p">
              Download portfolio import template
            </a>
          </div>
          <form
            id="form-file-upload"
            className={clsx("import-portfolio-form w-full relative text-center", {
              "mb-6": !selectedFile
            })}
            onDragEnter={handleDrag}
            onSubmit={(e) => e.preventDefault()}
          >
            <input ref={inputRef} type="file" id="input-file-upload" className="hidden" multiple={true} onChange={handleChange} />
            <label
              id="label-file-upload"
              className={clsx(
                "import-portfolio-label cursor-pointer h-full min-h-[230px] flex items-center justify-center border-0.5 border-dashed border-interactive-300 bg-white p-8",
                { "bg-main-200": dragActive, "!border !border-error": (importStatus === "validationError" && !selectedFile) || isInvalidFile }
              )}
              htmlFor="input-file-upload"
            >
              <div className="flex flex-col justify-center">
                <img src={theme.pages.importModal?.icons?.uploadImage ?? selectFile} alt="" aria-hidden="true" className="import-portfolio-upload-icon h-24" />
                <button
                  className="upload-button cursor-pointer p-2 border-0 hover:underline text-main-500 max-w-[14rem] font-sans font-normal"
                  onClick={onButtonClick}
                >
                  Select a file to upload or drag and drop here
                </button>
              </div>
            </label>
            {dragActive && (
              <div
                id="drag-file-element"
                className="absolute w-full h-full rounded-3 inset-0"
                onDragEnter={handleDrag}
                onDragLeave={handleDrag}
                onDragOver={handleDrag}
                onDrop={handleDrop}
              ></div>
            )}
            <div role="alert">
              {importStatus === "validationError" && (
                <ErrorMessage id="import-portfolio" message={isInvalidFile ? "Please use correct file type. Valid file types are xlsx, xls or csv." : (importError && !isInvalidFile) ? importError : ""} />
              )}
            </div>
          </form>
          {selectedFile && (
            <div className="import-portfolio__selected-file flex flex-row items-center justify-between bg-surface-100 p-2 mb-2">
              <p>{selectedFile?.name}</p>
              <button aria-label={`Remove file ${selectedFile.name}`} onClick={() => setSelectedFile(null)}>
                <img src={theme.pages.importModal?.icons?.removeImage ?? remove} alt="" aria-hidden />
              </button>
            </div>
          )}
        </>
      )}
      {/* Buttons */}
      {importStatus !== "importing" && (
        <>
          <div className="flex justify-center gap-4">
            {importStatus === "success" ? (
              <button type="button" className="btn btn-primary btn-medium text-sec w-44" onClick={handleClose}>
                Finish
              </button>
            ) : importStatus === "partiallySuccess" ? (
              <>
                <button className="btn btn-secondary btn-medium text-sec w-44" onClick={() => setImportStatus(undefined)}>
                  Try again
                </button>
                <button type="button" className="btn btn-primary btn-medium text-sec w-44" onClick={handleClose}>
                  Finish
                </button>
              </>
            ) : (
              <>
                <button type="button" className="btn btn-secondary btn-medium text-sec w-44" onClick={handleClose}>
                  Cancel
                </button>
                {importStatus === "error" ? (
                  <button className="btn btn-primary btn-medium text-sec w-44" onClick={() => setImportStatus(undefined)}>
                    Try again
                  </button>
                ) : (
                  <button className="btn btn-primary btn-medium text-sec w-44" onClick={handleImport}>
                    Import
                  </button>
                )}
              </>
            )}
          </div>
        </>
      )}
    </div>
  )
}

export default ImportPortfolio
