import clsx from "clsx"
import { ChangeEventHandler, FocusEventHandler, ReactNode, useCallback, useEffect, useMemo, useRef, useState } from "react"
import useDecimalNumberFormatter from "../../../hooks/useDecimalNumberFormatter"
import ErrorMessage from "../../../components/Error/ErrorMessage"

type Props = {
  className?: string
  inputFieldClassName?: string
  disableAutoComplete?: boolean
  error?: string | ReactNode
  isDisabled?: boolean
  focusOnLoad?: boolean
  label?: string | ReactNode
  locale?: string
  max?: number
  min?: number
  name: string
  onBlur?: FocusEventHandler
  onChange: (val: number | undefined) => void
  prefix?: ReactNode
  suffix?: ReactNode
  rangeErrorText?: string
  value?: number | undefined
  mandatory?: boolean
  placeholder?: string
}

const DecimalInput = ({
  className,
  disableAutoComplete = false,
  focusOnLoad = false,
  error,
  isDisabled = false,
  label,
  locale,
  max,
  min,
  name,
  onBlur: onBlurProp,
  onChange: onChangeProp,
  prefix,
  suffix,
  rangeErrorText,
  value,
  mandatory,
  inputFieldClassName,
  placeholder
}: Props) => {
  const numberFormatter = useDecimalNumberFormatter(locale || "en-US")
  const [val, setVal] = useState<number | string | null | undefined>(value ? value : "")
  const [isFocused, setIsFocused] = useState(focusOnLoad)
  const [internalError, setInternalError] = useState<string | null>(null)
  const inputRef = useRef<HTMLInputElement>(null)
  const maxLength = useMemo(() => {
    if (!max) {
      return undefined
    }
    const numChars = max.toString().length
    return numChars + Math.floor(numChars / 3) // divide by 3 (000) to get number of separators
  }, [max])

  const rangeError = useMemo(() => {
    if (rangeErrorText) {
      return rangeErrorText
    }
    return `Please add a value between ${numberFormatter.format(min!)} and ${numberFormatter.format(max!)}`
  }, [numberFormatter, max, min, rangeErrorText])

  const parser = useCallback((value: number) => {
    const regExp = /([0-9]*[.|,]{0,1}[0-9]{0,2})/s
    return Number(value?.toString().match(regExp)![0])
  }, [])

  const onChange: ChangeEventHandler<HTMLInputElement> = useCallback(
    (e) => {
      const input = e.target
      const nextValue = input.value === "" ? undefined : Number(input.value)

      if (
        (nextValue !== null && nextValue !== undefined && ((min && nextValue < min) || (max && nextValue > max))) ||
        (min && mandatory && nextValue === undefined) ||
        (max && mandatory && nextValue === undefined)
      ) {
        setInternalError(rangeError)
      } else {
        setInternalError(null)
      }
      setVal(parser(nextValue!))
      onChangeProp(parser(nextValue!))
    },
    [mandatory, max, min, onChangeProp, parser, rangeError]
  )

  const onFocus = () => {
    setIsFocused(true)
  }

  const onBlur: FocusEventHandler = (evt) => {
    setIsFocused(false)

    if (onBlurProp) {
      onBlurProp(evt)
    }
  }

  useEffect(() => {
    // prevent scroll wheel from updating number
    const handleValueOnScrollWheel = (e: WheelEvent) => {
      e.preventDefault()
    }
    const inputElement = inputRef.current
    if (inputElement) {
      inputElement.addEventListener("wheel", handleValueOnScrollWheel)
    }
    return () => {
      inputElement?.removeEventListener("wheel", handleValueOnScrollWheel)
    }
  })

  useEffect(() => {
    if (value !== undefined) {
      setVal(value)
    } else {
      setVal("")
    }
  }, [value])

  const hasError = useMemo(() => error ?? internalError, [error, internalError])

  return (
    <div className={clsx("number-input w-full flex flex-col flex-shrink-0", className)}>
      {label && (
        <label
          className={clsx("text-input-label w-full flex-shrink-0 text-sec mb-[5px]", {
            "text-main-600": !isDisabled,
            "text-main-200": isDisabled
          })}
          htmlFor={name}
        >
          {label}
          {mandatory && <span className="text-negative-600">*</span>}
        </label>
      )}
      <div
        className={clsx("text-input-wrapper w-full h-full flex items-center border bg-white overflow-hidden", {
          "border-error": !!error,
          "border-main-400": !error && !isDisabled,
          "border-main-200": !error && isDisabled,
          "shadow-focus": isFocused
        })}
      >
        {prefix && <div className="text-input-prefix">{prefix}</div>}
        <input
          ref={inputRef}
          type="number"
          onBlur={onBlur}
          onFocus={onFocus}
          autoComplete={disableAutoComplete ? "off" : "on"}
          className={clsx(
            `text-input-input w-full h-full flex-grow outline-none bg-white px-2 py-3 text-p font-normal [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none ${inputFieldClassName}`,
            {
              "text-main-500": !isDisabled,
              "text-main-200": isDisabled
            },
            className
          )}
          value={val!}
          maxLength={maxLength}
          name={name}
          id={name}
          onChange={onChange}
          placeholder={placeholder}
        />
        {suffix && <div className="text-input-suffix shrink-0 mr-2.5">{suffix}</div>}
      </div>
      <div role="alert">
        {hasError && typeof hasError !== "boolean" && (
          <ErrorMessage id="decimal-input" message={hasError} />
        )}
      </div>
    </div>
  )
}

export default DecimalInput
