import clsx from "clsx"
import { useCallback, useContext, useEffect, useMemo, useState } from "react"

import AlertIcon from "../../../../assets/icons/AlertIcon"
import FundsTable, { FundData } from "./FundsTable"
import Loading from "../../../../components/ClientProfile/Loading/Loading"
import { Client, Fund } from "../../../../models/Client"
import { update } from "../../../../api/clients"
import { AuthContext } from "../../../../views/auth/AuthContext"
import { ClientHouseholdCacheContext } from "../../../../contexts/ClientHouseholdCacheContext"
import { Firm } from "../../../../models/Firm"
import { AnimatePresence, motion } from "framer-motion"
import SearchBox from "../SearchBox"
import { latest } from "../../../../lib/clients"
import ErrorMessage from "../../../../components/Error/ErrorMessage"

export const customSort = (a: FundData, b: FundData) => {
  // The default sorting for the fund list is primarily based on the statuses and then by proposed or current allocation.
  const statusOrder = ["Added", "Updated", "Removed", ""]
  const statusPosition = statusOrder.indexOf(a.status!) - statusOrder.indexOf(b.status!)
  return !a.fundName && !b.fundName
    ? 0
    : !a.fundName
    ? 1
    : !b.fundName
    ? -1
    : statusPosition !== 0
    ? statusPosition
    : a.status === "Removed" && b.status === "Removed"
    ? b.currentAllocation! - a.currentAllocation!
    : b.proposedAllocation! - a.proposedAllocation!
}

const FundsAllocation = ({
  client,
  firm,
  forReport = false,
  tabRef,
  parentRef
}: {
  client: Client
  firm: Firm
  forReport?: boolean
  tabRef: React.RefObject<HTMLDivElement>
  parentRef: React.RefObject<HTMLDivElement>
}) => {
  const [assetClassFilters, setAssetClassFilters] = useState<string[]>([])
  const [statusFilters, setStatusFilters] = useState<string[]>([])

  const [fundData, setFundData] = useState<FundData[]>([])
  const [searchText, setSearchText] = useState("")
  const [searchResults, setSearchResults] = useState<Fund[]>([])
  const [isSearchListOpen, setIsSearchListOpen] = useState(false)
  const [selectedFund, setSelectedFund] = useState<Fund>()
  const [isEditing, setIsEditing] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const { sessionInfo } = useContext(AuthContext)
  const { replace } = useContext(ClientHouseholdCacheContext)
  const game = latest(client, "esg")
  const funds: FundData[] | undefined = useMemo(() => {
    const currentFunds = client?.portfolio?.current?.fundAllocations
    const proposedFunds = client?.portfolio?.proposed?.fundAllocations
    const missingFunds = currentFunds?.filter(
      (f) => !(proposedFunds ?? game?.esg?.results?.portfolioMatch?.fundMatches)?.find((a) => a.fund?.id === f.fund?.id)
    )
    return (
      (proposedFunds ?? game?.esg?.results?.portfolioMatch?.fundMatches)?.concat(missingFunds ?? []).map((fundAllocation) => {
        const fundId = fundAllocation.fund?.id
        const match = (client?.portfolio?.proposed?.fundAllocations ?? game?.esg?.results?.portfolioMatch?.fundMatches)?.find(
          (match) => match.fund?.id === fundId
        )
        const currentFund = currentFunds?.find((f) => f.fund?.id === fundId)
        const fund = currentFund?.fund ?? firm.funds.find((f) => f.id === fundId)
        return {
          id: fundAllocation.fund?.id,
          ticker: fund?.ticker,
          fundName: fund?.name,
          assetClass: fund?.assetClass,
          sustainabilityMatchScore: match?.smScore,
          currentAllocation: currentFund?.allocation,
          proposedAllocation: match?.allocation ?? currentFund?.allocation,
          status: !fund?.name
            ? ""
            : match?.allocation === 0
            ? "Removed"
            : !currentFund
            ? "Added"
            : currentFund.allocation !== match?.allocation
            ? "Updated"
            : ""
        }
      }) ?? []
    )
  }, [game?.esg?.results?.portfolioMatch?.fundMatches, client?.portfolio, firm.funds])

  useEffect(() => {
    setFundData([...funds!].sort(customSort))
  }, [funds])

  const constraints = useMemo(() => {
    const currentPortfolioGrowthAsset = client?.portfolio?.current?.growthAllocationSum ?? 0
    const proposedPortfolioGrowthAsset = fundData.reduce((acc, val) => {
      const assetClass = firm.assetClasses.find(({ name }) => name === val.assetClass)
      return assetClass?.type === "growth" ? acc + (val.proposedAllocation ?? 0) : assetClass?.type === "mixed" ? acc + (val.proposedAllocation ?? 0) / 2 : acc
    }, 0)

    const growthAssetDiff = Math.abs(proposedPortfolioGrowthAsset - currentPortfolioGrowthAsset)
    const growthAssetRange = growthAssetDiff > 2

    const portfolioChurn = fundData.reduce((acc, f) => {
      if (f.status === "Removed") {
        acc += f.currentAllocation || 0
      } else if (f.status === "Updated" && f.proposedAllocation! < f.currentAllocation!) {
        acc += f.currentAllocation! - f.proposedAllocation! || 0
      }
      return acc
    }, 0)

    const fundAllocationRange = fundData.some(
      ({ proposedAllocation, status, fundName }) =>
        isEditing && status !== "Removed" && !!fundName && proposedAllocation !== 0 && (proposedAllocation! > 25 || proposedAllocation! < 0.25)
    )

    const totalAllocation = fundData.reduce(
      (acc, curr) => parseFloat((acc + (!curr.fundName ? curr.currentAllocation! : isNaN(curr.proposedAllocation!) ? 0 : curr.proposedAllocation!)).toFixed(2)),
      0
    )
    const totalAllocationRange = totalAllocation !== 100

    const isUpdated = fundData.every(({ proposedAllocation }, i) => i <= funds!.length - 1 && proposedAllocation === funds![i].proposedAllocation)
    const isConstraint = isEditing && (growthAssetRange || totalAllocationRange || fundAllocationRange || portfolioChurn > 50 || isUpdated)

    return { isConstraint, isUpdated, growthAssetDiff, growthAssetRange, totalAllocation, portfolioChurn, fundAllocationRange, totalAllocationRange }
  }, [client?.portfolio, firm.assetClasses, fundData, funds, isEditing])

  const saveFunds = useCallback(() => {
    setIsLoading(true)

    update(sessionInfo!, client._id, client, {
      proposedPortfolio: fundData.map((f) => {
        return {
          fundId: f.id!,
          allocation: f.proposedAllocation!
        }
      })
    }).then((res) => {
      replace(res)
      setIsLoading(false)
      setIsEditing(false)
    })
  }, [client, fundData, replace, sessionInfo])

  const cancelEditFundAllocation = useCallback(() => {
    setFundData([...funds!].sort(customSort))
    setIsEditing(false)
  }, [funds])

  const highlightWords = useCallback(
    (paragraph: string) => {
      const wordsToHighlight = searchText.toLowerCase().split(/\s+/)
      const regex = new RegExp(`(${wordsToHighlight.join("|")})`, "gi")
      return paragraph.split(regex).map((text, index) =>
        wordsToHighlight.includes(text.toLowerCase()) ? (
          <span className="font-medium" key={index}>
            {text}
          </span>
        ) : (
          text
        )
      )
    },
    [searchText]
  )

  const searchFundsHandler = useCallback(
    (searchString: string) => {
      const matchingFunds = [...firm.funds]
        .filter(({ id }) => !fundData.some((fund) => fund.id === id))
        .filter(
          ({ ticker, name, id }) =>
            id?.toLowerCase().includes(searchString.toLowerCase()) ||
            ticker?.toLowerCase().includes(searchString.toLowerCase()) ||
            name?.toLowerCase().includes(searchString.toLowerCase())
        )
      setSearchResults(matchingFunds)
      setIsSearchListOpen(true)
      setSearchText(searchString)
    },
    [firm.funds, fundData]
  )

  const addFunds = useCallback(() => {
    if (searchText && selectedFund) {
      setFundData((prev) => [
        {
          id: selectedFund.id,
          ticker: selectedFund.ticker,
          fundName: selectedFund.name,
          assetClass: selectedFund.assetClass,
          sustainabilityMatchScore: 0,
          currentAllocation: 0,
          proposedAllocation: 0,
          status: "Added"
        },
        ...prev
      ])

      if (!statusFilters?.includes("Added") && statusFilters.length !== 0) {
        setStatusFilters((prevStatuses) => [...prevStatuses, "Added"])
      }
      if (selectedFund.assetClass && !assetClassFilters?.includes(selectedFund.assetClass) && assetClassFilters.length !== 0) {
        setAssetClassFilters((prevAssetClasses) => {
          return [...prevAssetClasses, selectedFund.assetClass!]
        })
      }
      setSelectedFund(undefined)
      setSearchText("")
    }
  }, [assetClassFilters, searchText, selectedFund, statusFilters])

  const onFilter = useCallback((filter: { assetClasses?: string[]; statuses?: string[] }) => {
    setAssetClassFilters(filter.assetClasses ?? [])
    setStatusFilters(filter.statuses ?? [])
  }, [])

  return (
    <>
      <div className="mb-6">
        <h3 className="text-p font-medium">Constraints</h3>
        <ul className="flex justify-between w-full gap-x-2 text-sec leading-5 font-normal">
          <li
            className={clsx("bg-neutral-50 w-full px-3", {
              "border border-red-400": constraints.growthAssetRange
            })}
          >
            <p className="pt-2">Growth assets</p>
            <p className="flex gap-1 pb-2">
              (+/-2%)
              <span role="alert">
                {constraints.growthAssetRange && (
                  <ErrorMessage id="fund-allocation-growth-asset" message={`Proposed +${constraints.growthAssetDiff.toFixed(2)}%`} />
                )}
              </span>
            </p>
          </li>
          <li className={clsx("bg-neutral-50 w-full px-3", constraints.portfolioChurn > 50 && "border border-red-400")}>
            <p className="pt-2">Portfolio churn</p>
            <p className="flex gap-1 pb-2">
              (50%)
              <span role="alert">
                {constraints.portfolioChurn > 50 && (
                  <ErrorMessage id="fund-allocation-portfolio-churn" message={`Proposed ${constraints.portfolioChurn}%`} />
                )}
              </span>
            </p>
          </li>
          <li className={clsx("bg-neutral-50 w-full px-3", constraints.fundAllocationRange && "border border-red-400")}>
            <p className="pt-2">Fund allocation</p>
            <p className="flex gap-1 pb-2">
              (0.25% - 25%)
              <span role="alert">
                {constraints.fundAllocationRange && (
                  <ErrorMessage id="fund-allocation-range" message="Outside range" />
                )}
              </span>
            </p>
          </li>
          <li className={clsx("bg-neutral-50 w-full px-3", constraints.totalAllocationRange && "border border-red-400")}>
            <p className="pt-2">Total allocation</p>
            <p className="flex gap-1 pb-2">
              (100%)
              <span role="alert">
                {constraints.totalAllocationRange && (
                  <ErrorMessage id="fund-allocation-range" message={`Total ${constraints.totalAllocation}%`} />
                )}
              </span>
            </p>
          </li>
        </ul>
      </div>

      {isEditing && (
        <div className="grid grid-cols-8 items-center w-2/3 mb-6 lg:grid-cols-[65px_minmax(100px,_auto)_100px]">
          <label htmlFor="search-fund" className="text-sec font-medium col-start-1 col-span-1">
            Add fund
          </label>
          <div className="col-start-2 col-span-5 relative mr-2">
            <SearchBox
              id="search-fund"
              className="text-input-wrapper py-1"
              inputClassName="text-input-input"
              placeholder="Search by fund name or ISIN"
              onChange={searchFundsHandler}
              value={searchText}
              onClear={() => setSearchText("")}
              results={
                searchText &&
                isSearchListOpen && (
                  <div className="absolute w-full z-10 mt-1 overflow-y-auto no-scrollbar shadow bg-white max-h-56">
                    <ul>
                      <AnimatePresence>
                        {searchResults.map((fund) => {
                          return (
                            <motion.li layout key={fund.id} className={clsx("fund-search-list leading-5 text-sec")}>
                              <button
                                className="text-left w-full p-3"
                                onClick={() => {
                                  setSearchText(`${!fund.ticker ? "" : fund.ticker} ${fund.name!}`.trimStart())
                                  setSelectedFund(fund)
                                  setIsSearchListOpen(false)
                                }}
                              >
                                {fund.ticker && <span className="after:content-['|'] after:mx-2">{highlightWords(fund.ticker)}</span>}
                                <span>{highlightWords(fund.name!)}</span>
                              </button>
                            </motion.li>
                          )
                        })}
                      </AnimatePresence>
                    </ul>
                  </div>
                )
              }
            />
          </div>
          <button onClick={addFunds} className="btn btn-secondary w-18 py-1 text-sec text-center font-medium col-start-7 col-span-2">
            Add
          </button>
        </div>
      )}

      <FundsTable
        tabRef={tabRef}
        parentRef={parentRef}
        fundTableData={[...fundData]}
        isEditing={isEditing}
        isLoading={isLoading}
        setFundData={setFundData}
        forReport={forReport}
        assetClassFilters={assetClassFilters}
        statusFilters={statusFilters}
        onFilter={onFilter}
        showDisclaimer={true}
      />

      {!forReport && (
        <div className="z-10 bg-white absolute bottom-0 left-0 w-full shadow-modal flex justify-end items-center py-4.5 pr-10 gap-6">
          {!isEditing ? (
            <button className={clsx("btn btn-secondary px-15 py-2", isEditing && "transition ease-in-out duration-500")} onClick={() => setIsEditing(true)}>
              Edit
            </button>
          ) : (
            <div className={clsx("flex gap-x-2", isEditing && "transition ease-in-out duration-500")}>
              <button className="btn btn-secondary py-2 w-[150px]" onClick={cancelEditFundAllocation}>
                Cancel
              </button>
              <button className="btn btn-primary px-14 py-2 w-[150px]" disabled={constraints.isConstraint} onClick={saveFunds}>
                {isLoading ? <Loading color="black" /> : "Save"}
              </button>
            </div>
          )}
        </div>
      )}
    </>
  )
}
export default FundsAllocation
