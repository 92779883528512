import clsx from "clsx"
import { Fragment, useMemo } from "react"
import { useTheme } from "../../../../contexts/ThemeContext"
import { PortfolioMapping } from "../../../../models/Client"
import { AssetClass } from "../../../../models/InvestmentUniverse"
import riskComfortIcon from "../../assets/images/riskComfort.svg"

interface Props {
  assetClasses?: AssetClass[]
  comfortMatch?: PortfolioMapping
  portfolioMappings?: PortfolioMapping[]
}

const PortfolioTableDisplay = ({ assetClasses, comfortMatch, portfolioMappings }: Props) => {
  const theme = useTheme()
  const comfortMatchColIndex = portfolioMappings?.findIndex((val) => val.portfolio.id === comfortMatch?.portfolio.id)

  const assets = useMemo(() => {
    const nextAssets = assetClasses?.map(({ id, name, type, subAssetClasses }, i) => ({
      subAssetClasses: subAssetClasses?.map(({ id, name, type }, i) => ({
        divider: i === subAssetClasses.length ? "light" : undefined,
        values: portfolioMappings?.map(({ portfolio: { components } }) => components.find((component) => component.id === id)?.weight),
        title: name,
        type
      })),
      title: name,
      type,
      values:
        !subAssetClasses || subAssetClasses.length === 0
          ? portfolioMappings?.map(({ portfolio: { components } }) => components.find((component) => component.id === id)?.weight)
          : undefined
    }))
    if (nextAssets) {
      nextAssets.push({
        subAssetClasses: undefined,
        title: "Expected return",
        type: undefined,
        values: portfolioMappings?.map(({ portfolio: { r } }) => r)
      })
      nextAssets.push({
        subAssetClasses: undefined,
        title: "Expected volatility",
        type: undefined,
        values: portfolioMappings?.map(({ portfolio: { sd } }) => sd)
      })
    }

    return nextAssets
  }, [assetClasses, portfolioMappings])

  const hasGoalAchievability = false // TODO add actual goal achievability
  const hasRiskComfort = useMemo(() => portfolioMappings?.every((mapping) => mapping.riskComfort !== undefined), [portfolioMappings])

  return (
    <table className="w-full">
      <tbody>
        <tr className="h-6">
          <td></td>
          <td></td>
          {portfolioMappings?.map(({ riskComfort }, i) => (
            <td className={clsx("relative py-2 text-center", { "bg-interactive-100": riskComfort! >= 60 })} key={i}>
              {i === comfortMatchColIndex ? (
                <div className="flex flex-col gap-y-1 items-center justify-center">
                  <img src={riskComfortIcon} alt="Risk comfort icon" className="risk-comfort-img w-3" />
                  <label className="text-xs text-riskComfort-500 ml-1 uppercase">Highest Risk comfort</label>
                </div>
              ) : undefined}
            </td>
          ))}
        </tr>
        <tr>
          <th className="text-main-600 text-sec font-semibold px-3.5 pl-0 sr-only" align="left">
            Asset Class
          </th>
          <th className="text-main-600 text-sec font-semibold px-3.5">Type</th>
          {portfolioMappings?.map(({ portfolio, riskComfort }, i) => (
            <th
              key={i}
              className={clsx("text-main-600 text-sec font-semibold px-3.5 py-2", {
                "bg-interactive-100": riskComfort! >= 60
              })}
            >
              {portfolio.name}
            </th>
          ))}
        </tr>
        {hasGoalAchievability && (
          <tr
            className={clsx("border-t border-surface-300", {
              "border-b border-b-dashed": hasRiskComfort,
              "border-b border-b-solid": !hasRiskComfort
            })}
          >
            <td className="text-main-600 text-sm font-semibold px-1 py-2">Goal achievability</td>
            <td></td>
            {portfolioMappings?.map(({ riskComfort }, i) => (
              <td
                key={i}
                className={clsx("text-main-600 text-sm text-center font-semibold px-1 py-1.5", {
                  "bg-interactive-100": riskComfort! >= 60
                })}
              >
                {riskComfort}% {/* TODO add actual goal achievability here */}
              </td>
            ))}
          </tr>
        )}
        {hasRiskComfort && (
          <tr
            className={clsx("border-b border-surface-300", {
              "border-t border-t-solid": !hasGoalAchievability
            })}
          >
            <td className="text-main-600 text-sm font-semibold px-1 py-2">Risk comfort</td>
            <td></td>
            {portfolioMappings?.map(({ riskComfort }, i) => (
              <td
                key={i}
                className={clsx("text-main-600 text-sm text-center font-semibold px-1 py-1.5", {
                  "bg-interactive-100": riskComfort! >= 60
                })}
              >
                {riskComfort}%
              </td>
            ))}
          </tr>
        )}
        {assets?.map(({ subAssetClasses, title, type, values }, i) => (
          <Fragment key={i}>
            <tr
              className={clsx({
                "border-t border-solid border-surface-300 border-b border-b-dashed": title === "Expected return",
                "border-b border-solid border-surface-300": title === "Expected volatility"
              })}
            >
              <td
                className={clsx("text-main-600 text-sm font-semibold px-1 pt-2 xl-max:pt-3", {
                  "pb-2 xl-max:pb-3": values,
                  "pb-0.5 xl-max:pb-1": !values
                })}
              >
                {title}
              </td>
              <td
                className={clsx("text-main-600 text-sm font-semibold px-1 pt-2 xl-max:pt-3 text-center", {
                  "pb-2 xl-max:pb-3": values,
                  "pb-0.5 xl-max:pb-1": !values
                })}
              >
                {type ? theme.colors.assetClassConfigurations[type].label : ""}
              </td>
              {values
                ? values?.map((value, i) => (
                    <td
                      key={i}
                      className={clsx("text-main-600 text-sm text-center", {
                        "font-semibold": !subAssetClasses
                      })}
                    >
                      {value ? `${value}%` : "0%"}
                    </td>
                  ))
                : portfolioMappings?.map((_, i) => <td key={i} />)}
            </tr>
            {subAssetClasses?.map(({ title, type, values }, j) => (
              <tr key={j} className={j === subAssetClasses.length - 1 ? "border-b border-dashed border-surface-300" : undefined}>
                <td
                  className={clsx("text-main-600 text-sm px-1 pt-1 xl-max:pt-1.5", {
                    "pb-2 xl-max:pb-4": j === subAssetClasses.length - 1,
                    "pb-0.5 xl-max:pb-1.5": j < subAssetClasses.length - 1
                  })}
                >
                  {title}
                </td>
                <td
                  className={clsx("text-main-600 text-sm px-1 pt-1 xl-max:pt-1.5 text-center", {
                    "pb-2 xl-max:pb-4": j === subAssetClasses.length - 1,
                    "pb-0.5 xl-max:pb-1.5": j < subAssetClasses.length - 1
                  })}
                >
                  {type ? theme.colors.assetClassConfigurations[type].label : ""}
                </td>
                {values?.map((value, i) => (
                  <td
                    key={i}
                    className={clsx("text-main-600 text-sm text-center px-1 pt-1.5", {
                      "pb-2 xl-max:pb-4": j === subAssetClasses.length - 1,
                      "pb-0.5 xl-max:pb-1.5": j < subAssetClasses.length - 1
                    })}
                  >
                    {value ? `${value}%` : "0%"}
                  </td>
                ))}
              </tr>
            ))}
          </Fragment>
        ))}
      </tbody>
    </table>
  )
}

export default PortfolioTableDisplay
