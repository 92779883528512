import { Theme } from "../theme"
import welcome from "../../pages/advisor/tour/card-1.png"
import insights from "../../pages/advisor/tour/card-2.png"
import educate from "../../pages/advisor/tour/card-3.png"
import { messages } from "../messages/en"
import { messages as en } from "./locales/en"
import termsOfService from "./markdown/termsOfService"
import privacyPolicy from "./markdown/privacyPolicy"
import billingPolicy from "./markdown/billingPolicy"
import expandCollapseIcon from "./assets/icons/arrowhead-right.svg"
import successImage from "./assets/images/success.svg"
import riskHero from "./assets/images/risk-hero-white.svg"
import goalsHero from "./assets/images/goals-hero-white.svg"
import pfsHero from "./assets/images/pfs-hero-white.svg"
import liquidityHero from "./assets/images/liquidity-hero-white.svg"
import taxHero from "./assets/images/tax-hero-white.svg"
import esgHero from "./assets/images/esg-hero-white.svg"
import retirementHero from "./assets/images/retirement-hero-white.svg"

import riskHeroSecondary from "./assets/images/risk-hero-brown.svg"
import goalsHeroSecondary from "./assets/images/goals-hero-brown.svg"
import pfsHeroSecondary from "./assets/images/pfs-hero-brown.svg"
import liquidityHeroSecondary from "./assets/images/liquidity-hero-brown.svg"
import taxHeroSecondary from "./assets/images/tax-hero-brown.svg"
import esgHeroSecondary from "./assets/images/esg-hero-brown.svg"
import retirementHeroSecondary from "./assets/images/retirement-hero-brown.svg"

import retirementIcon from "./assets/images/goals/retirement.svg"
import homeIcon from "./assets/images/goals/home.svg"
import educationIcon from "./assets/images/goals/education.svg"
import travelIcon from "./assets/images/goals/travel.svg"
import wealthIcon from "./assets/images/goals/wealth.svg"
import ownIcon from "./assets/images/goals/own.svg"

const defaultTheme: Theme = {
  id: "default",
  gameType: "risk",
  causes: {
    S1: {
      cause0: {
        id: "sustainability",
        barColor: "#EE7002",
        title: "Multiple areas",
        isDefault: true,
        icon: "",
        secondaryIcon: "",
        persona: {
          name: "Sustainability Champion",
          title: "You are a Sustainability Champion",
          description:
            "Sustainability Champions are concerned with aligning their investments with a broad range of environmental, social, and governance (ESG) issues."
        },
        themeItems: [
          {
            title: "Combatting climate change"
          },
          {
            title: "Human rights"
          },
          {
            title: "Diversity in leadership"
          },
          {
            title: "Reduction of pollution"
          }
        ],
        items: [
          {
            title: "Combatting climate change"
          },
          {
            title: "Human rights"
          },
          {
            title: "Diversity in leadership"
          },
          {
            title: "Reduction of pollution"
          }
        ]
      },
      cause1: {
        id: "diversity",
        barColor: "#FFBE37",
        title: "Increasing diversity & inclusion",
        description: "Companies that prioritise and promote diversity and inclusion.",
        themeIndicatorTitle: "Women on boards",
        persona: {
          name: "Diversity Champion",
          title: "You are a Diversity Champion",
          description: "Diversity Champions are concerned with equal opportunities for all across education, income, and living standards."
        },
        themeItems: [
          {
            title: "Increasing gender diversity in leadership"
          },
          {
            title: "Increasing racial diversity in leadership"
          },
          {
            title: "Increasing ethnic diversity in leadership"
          },
          {
            title: "Promotion of inclusive social and economic growth"
          }
        ],
        items: [
          {
            title: "Increase gender diversity in leadership",
            description: "Achievement of gender equality and empowerment for all women and girls."
          },
          {
            title: "Increase racial diversity in leadership",
            description: "Ensure inclusive and equitable quality education and promote lifelong learning opportunities for all."
          },
          {
            title: "Increase ethnic diversity in leadership",
            description: "Promote sustained, inclusive and sustainable economic growth, full and productive employment and decent work for all."
          },
          {
            title: "Promotion of inclusive social and economic growth"
          }
        ]
      },
      cause2: {
        id: "climate",
        barColor: "#00A16D",
        title: "Addressing climate change",
        description: "Companies that contribute to a low carbon economy and deal effectively with the risks of climate change.",
        themeIndicatorTitle: "Global temperature rise",
        persona: {
          name: "Climate Champion",
          title: "You are a Climate Champion",
          description:
            "Climate Champions are concerned with issues such as global warming, changes in weather patterns, and the impact of these shifts on people and the planet."
        },
        themeItems: [
          {
            title: "Cleaner energy technologies"
          },
          {
            title: "Reduction of the carbon intensity of manufacturing"
          },
          {
            title: "Sustainable building materials"
          },
          {
            title: "Bringing about biodiversity improvements"
          },
          {
            title: "The production of green buildings"
          },
          {
            title: "Sustainable agriculture"
          }
        ]
      },
      cause3: {
        id: "people",
        barColor: "#DB5BF0",
        title: "Putting people first",
        description: "Companies that protect and empower communities by meeting their basic needs.",
        themeIndicatorTitle: "Food security in developing countries",
        persona: {
          name: "People Champion",
          title: "You are a People Champion",
          description: "People Champions are concerned with access to essential goods and services, such as food, housing, and health care."
        },
        themeItems: [
          {
            title: "Access to healthcare"
          },
          {
            title: "Affordable housing"
          },
          {
            title: "Access to quality education"
          },
          {
            title: "Provision of nutritional food and supplements"
          },
          {
            title: "Provision of clean water and sanitation"
          },
          {
            title: "Product quality and safety"
          }
        ],
        items: [
          {
            title: "Improve access to healthcare",
            description: "Ensure healthy lives and promote well-being for all at all ages."
          },
          {
            title: "Build affordable housing",
            description: "End poverty in all its forms everywhere."
          },
          {
            title: "Increase access to quality education",
            description: "End hunger, achieve food security and improved nutrition and promote sustainable agriculture."
          },
          {
            title: "Provide nutritional food and supplements",
            description: "Ensure availability and sustainable management of water and sanitation for all."
          },
          {
            title: "Provide clean water and sanitation",
            description: "Make cities and human settlements inclusive, safe, resilient and sustainable."
          },
          {
            title: "Improve product quality and safety"
          },
          {
            title: "Increase internet connectivity"
          },
          {
            title: "Provide access to finance"
          }
        ]
      },
      cause4: {
        id: "natural-resources",
        barColor: "#0092F9",
        title: "Protecting natural resources",
        description: "Companies that help to protect and conserve our precious natural resources.",
        themeIndicatorTitle: "Toxic waste emissions",
        persona: {
          name: "Natural Capital Champion",
          title: "You are a Natural Capital Champion",
          description: "Natural Capital Champions are concerned with protecting Earth's natural resources, preventing pollution, and reducing waste."
        },
        themeItems: [
          {
            title: "Protecting water resources"
          },
          {
            title: "Reduction of pollution"
          },
          {
            title: "Conservation of marine resources"
          },
          {
            title: "Reduction of toxic emissions waste"
          },
          {
            title: "Reduction of electronic waste"
          },
          {
            title: "Sustainable use of land resources"
          }
        ],
        items: [
          {
            title: "Protect water resources",
            description:
              "Protect, restore and promote sustainable use of terrestrial ecosystems, sustainably manage forests, combat desertification, and halt and reverse land degradation and halt biodiversity loss."
          },
          {
            title: "Prevent pollution",
            description: "Conserve and sustainably use the oceans, seas and marine resources for sustainable development."
          },
          {
            title: "Conserve of marine resources",
            description: "Ensure sustainable consumption and production patterns."
          },
          {
            title: "Reduce toxic emissions waste"
          },
          {
            title: "Reduce electronic waste"
          },
          {
            title: "Sustainably use land resources"
          }
        ]
      },
      cause5: {
        id: "ethical-behaviour",
        barColor: "#9660F5",
        title: "Behaving ethically",
        description: "Companies that treat their people well and conduct their business ethically.",
        themeIndicatorTitle: "Better work standards",
        persona: {
          name: "Ethical Behaviour Champion",
          title: "You are an Ethical Behaviour Champion",
          description: "Ethical Behaviour Champions are concerned with promoting fair and sustainable business practices and protecting human rights."
        },
        themeItems: [
          {
            title: "Promotion of anti-bribery and fraud"
          },
          {
            title: "Promotion of anti-money laundering"
          },
          {
            title: "Data privacy and security"
          },
          {
            title: "Improvement of working conditions"
          },
          {
            title: "Protection of shareholder rights"
          },
          {
            title: "Improvement of board practices"
          }
        ],
        items: [
          {
            title: "Promote anti-bribery and fraud",
            description:
              "Promote peaceful and inclusive societies for sustainable development, provide access to justice for all and build effective, accountable and inclusive institutions at all levels."
          },
          {
            title: "Promote anti-money laundering",
            description: "Strengthen the means of implementation and revitalize the global partnership for sustainable development."
          },
          {
            title: "Increase data privacy and security"
          },
          {
            title: "Improve working conditions"
          },
          {
            title: "Protect shareholder rights"
          },
          {
            title: "Improve board practices"
          },
          {
            title: "Protect human rights"
          }
        ]
      }
    }
  },
  games: [
    { type: "pfs", color: "#6DA2DA", hero: pfsHero, secondaryLogo: pfsHeroSecondary },
    { type: "goals", color: "#EB8F39", hero: goalsHero, secondaryLogo: goalsHeroSecondary },
    { type: "risk", color: "#008091", hero: riskHero, secondaryLogo: riskHeroSecondary },
    { type: "liquidity", color: "#C19F70", hero: liquidityHero, secondaryLogo: liquidityHeroSecondary },
    { type: "esg", color: "#85B65F", hero: esgHero, secondaryLogo: esgHeroSecondary },
    { type: "retirement", color: "#8B85C6", hero: retirementHero, secondaryLogo: retirementHeroSecondary },
    { type: "tax", color: "#28A4AC", hero: taxHero, secondaryLogo: taxHeroSecondary }
  ],
  goals: [
    { type: "retirementIncome", icon: retirementIcon, color: ["#8B85C6"] },
    { type: "buyAHome", icon: homeIcon, color: ["#D96CA1"] },
    { type: "education", icon: educationIcon, color: ["#3EA385"] },
    { type: "travel", icon: travelIcon, color: ["#E26969"] },
    { type: "wealthAccumulation", icon: wealthIcon, color: ["#2F76BF"] },
    { type: "createOwnGoal", icon:ownIcon, color: ["#C48144", "#E9C950", "#85B65F", "#8B85C6", "#D96CA1", "#6DA2DA", "#E26969", "#3EA385"] }
  ],
  colors: {
    assetClassConfigurations: {
      growth: { color: "#006171", label: "Growth" },
      income: { color: "#28A4AC", label: "Income" },
      cash: { color: "#E5C8A1", label: "Cash" }
    },
    avatarColorByStatus: false,
    avatarColors: ["#694589", "#CA5CDF"],
    esgDonutColors: {
      sustainabilityMetrics: ["#849E42"]
    },
    avatarTextColor: "white",
    riskComfortChartColors: {
      dataPoints: ["#CEF1F4", "#ACDCDF", "#69C8CD", "#28A4AC", "#008091", "#006171", "#0D4355", "#1C3236"],
      line: "#33B0E5"
    },
    scoreChartColor: "#7882D1"
  },
  fingerprint: {
    openGameLinksInline: true
  },
  navbar: {
    links: {
      clients: true,
      team: true,
      import: false,
      archive: true
    },
    moreDropdown: {
      show: false,
      links: {
        import: true,
        archive: true
      }
    },
    hamburgerMenu: {
      show: true,
      links: {
        personalDetails: true,
        twoFactorAuth: JSON.parse(import.meta.env.VITE_APP_2FA_ENABLE ?? "false"),
        changePassword: true,
        contactSupport: true,
        knowledgeHub: true,
        billing: true,
        logout: true
      }
    }
  },
  onboarding: {
    screens: {
      1: {
        title: "Welcome to Capital Preferences' Risk Profiling Suite",
        image: welcome,
        description: "Quickly and easily reveal your clients' preferences through our gamified experiences"
      },
      2: {
        title: "Get better insights and provide defendable advice",
        image: insights,
        description: "Provide deeper client insights and automatically generate client-friendly reports for compliance and tracking."
      },
      3: {
        title: "Engage and educate your clients",
        image: educate,
        description: "Show your clients how they can balance their personal Risk Comfort alongside their financial goals."
      }
    }
  },
  pages: {
    clientsList: {
      clientActions: {
        show: true,
        links: {
          archive: true,
          view: true
        }
      },
      columns: {
        primaryAdviser: true
      }
    },
    createClient: {
      showClientTypeSelection: true,
      individual: {
        formFields: {
          showExistingClientSwitch: true,
          showDOB: true
        }
      }
    },
    profile: {
      multiActivityView: "cards",
      sections: {
        activityAndNotes: {
          show: true,
          showBottomBorder: true
        },
        altruismScore: {
          show: false,
          showBottomBorder: false
        },
        clientStatus: {
          show: true,
          showBottomBorder: true,
          showButtons: {
            presentResults: true,
            previewReport: true
          }
        },
        clientWarning: {
          show: true,
          showBottomBorder: true
        },
        fundsMatch: {
          show: false,
          showBottomBorder: false
        },
        personaSummary: {
          show: false,
          showBottomBorder: false
        },
        portfolioCompare: {
          show: false,
          showBottomBorder: false
        },
        regulatoryReporting: {
          show: true,
          showBottomBorder: false
        },
        reportHistory: {
          show: true,
          showBottomBorder: true
        },
        scoreTrends: {
          show: true,
          showBottomBorder: true
        },
        selectedPortfolio: {
          show: true,
          showBottomBorder: true
        }
      },
      sidebar: {
        isCollapsible: true,
        expandCollapseIcon,
        personalDetails: {
          age: true,
          investmentAmount: true
        },
        menu: {
          clientProfile: true,
          goalProjector: true,
          contact: true,
          createHousehold: true,
          editClientDetails: {
            showLink: true,
            modal: {
              fields: {
                dateOfBirth: true
              }
            }
          },
          portfolioAnalyser: false,
          results: true
        }
      },
      resultsTabs: {
        risk: ["viewReport", "comfortZone", "attitudeToRisk", "sensitivityToLoss", "decisionPattern"]
      }
    },
    importModal: {
      images: {
        successImage
      }
    }
  },
  charts: {
    comfortMatch: {
      highlightComfortZone: true,
      useAvatarIcons: true,
      showLegend: true
    }
  },
  reports: {
    coverPage: {
      disclaimer: {
        show: true
      },
      logosBar: {
        position: "top"
      },
      reportFlag: {
        show: false,
        position: "bottom",
        showDate: true
      },
      showDate: true
    },
    riskChartsPage: {
      lineChart: {
        showLegend: true,
        showIcon: true,
        showTitle: true
      },
      showPreferencesHeader: true
    },
    comfortMatchPage: {
      showDescription: true,
      showLegend: true,
      listView: {
        showInfo: true
      }
    },
    pages: {
      risk: ["cover", "riskProfile", "riskDimensions", "riskCharts", "comfortMatch", "projectorChart", "projectorTable"],
      pfs: ["pfsCover", "pfsNetWorth", "pfsIncomeExpenses"]
    },
    footer: {
      showDisclaimer: false
    }
  },
  statuses: {
    "New client": {
      classNames: "bg-tag-purple/10 text-tag-purple/100"
    },
    "New household": {
      classNames: "bg-tag-purple/10 text-tag-purple/100"
    },
    "Activity pending": {
      classNames: "bg-tag-orange/10 text-tag-orange/100"
    },
    "New results available": {
      classNames: "bg-tag-green/10 text-tag-green/100"
    },
    "Up to date": {
      classNames: "bg-tag-purple/10 text-tag-purple/100"
    },
    "Up for review": {
      classNames: "bg-tag-orange/10 text-tag-orange/100"
    },
    "Archived": {
      classNames: "bg-tag-purple/10 text-tag-purple/100"
    }
  },
  messages: {
    en: {
      ...messages,
      ...en
    }
    // en_AU: en,
    // en_GB: en
  },
  tours: {
    createClients: {
      show: true
    },
    gameComplete: {
      show: true
    },
    newClient: {
      show: true
    }
  },
  policies: {
    billing: {
      link: "https://capitalpreferences.com.au/billing-policy",
      markdown: {
        version: "2024-08-14",
        content: billingPolicy
      },
      enabled: true
    },
    tos: {
      markdown: {
        version: "2024-08-20",
        content: termsOfService
      },
      enabled: true
    },
    privacy: {
      markdown: {
        version: "2024-08-20",
        content: privacyPolicy
      },
      enabled: true
    }
  }
}

export default defaultTheme as Theme
