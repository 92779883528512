import { useCallback, useContext, useEffect, useMemo, useState } from "react"
import ErrorMessage from "../../../../components/Error/ErrorMessage"
import { ClientHouseholdCacheContext } from "../../../../contexts/ClientHouseholdCacheContext"
import { useTheme } from "../../../../contexts/ThemeContext"
import { customDateFormat } from "../../../../lib/date"
import { Client, GoalDetail, GoalType } from "../../../../models/Client"
import { Household } from "../../../../models/Household"
import goal from "../../../advisor/assets/images/goal.svg"
import SelectedInvestment from "../../../clients/components/Profile/components/SelectedInvestment/SelectedInvestment"
import RetirementIncome from "../../../rmjourney/goalExplorer/retirementIncome/RetirementIncome"
import WealthAccumulation from "../../../rmjourney/goalExplorer/wealthAccumulation/WealthAccumulation"
import CommentsSection from "../components/Comments/CommentsSection"
import GoalCard from "./GoalCard"
import GoalsAdd, { SelectedGoal } from "./GoalsAdd"
import AddGoal from "./AddGoal"
import Loading from "../../../../components/ClientProfile/Loading/Loading"

export const GoalPrioritiesOrder = ["essential", "important", "aspirational"]

const GoalsTab = ({ client, household }: { client?: Client; household?: Household }) => {
  const theme = useTheme()
  const clientOrHousehold = (client ?? household)!
  const { updateClient, updateHousehold } = useContext(ClientHouseholdCacheContext)
  const lastUpdatedDate = clientOrHousehold.goals?.updatedAt
  const [goalDetails, setGoalDetails] = useState<GoalDetail[]>(clientOrHousehold?.goals?.goalDetails ?? [])
  const [selectedGoal, setSelectedGoal] = useState<GoalDetail>()

  const [currentScreen, setCurrentScreen] = useState<"summary" | "add">("summary")
  const [errorMessage, setErrorMessage] = useState<string>()
  const [selectedGoals, setSelectedGoals] = useState<SelectedGoal[]>([])
  const [isAddInProgress, setIsAddInProgress] = useState<boolean>(false)

  const sortedGoalDetails = useMemo(
    () =>
      [...goalDetails].sort((a, b) => {
        const priorityA = a.priority ? GoalPrioritiesOrder.indexOf(a.priority) : Infinity
        const priorityB = b.priority ? GoalPrioritiesOrder.indexOf(b.priority) : Infinity
        return priorityA - priorityB
      }),
    [goalDetails]
  )

  useEffect(() => {
    setGoalDetails(clientOrHousehold?.goals?.goalDetails ?? [])
  }, [clientOrHousehold?.goals?.goalDetails])

  useEffect(() => {
    if (!selectedGoal) {
      setSelectedGoal((sortedGoalDetails ?? [])[0])
    }
  }, [selectedGoal, sortedGoalDetails])

  const selectedGoalDetail = useMemo(
    () => (sortedGoalDetails ?? []).find((goal) => goal.type === selectedGoal?.type && goal.id === selectedGoal?.id),
    [selectedGoal?.id, selectedGoal?.type, sortedGoalDetails]
  )
  const handleAddGoals = useCallback(
    (selectedGoals: SelectedGoal[]) => {
      setIsAddInProgress(true)
      setErrorMessage(undefined)
      const sorted = [...selectedGoals].sort((a, b) => {
        const indexA = theme.goals?.findIndex((goal) => goal.type === a.goalType)
        const indexB = theme.goals?.findIndex((goal) => goal.type === b.goalType)
        return indexA - indexB
      })
      const goalDetails = sorted.flatMap(({ goalType, quantity }) => {
        if (goalType === "education" || goalType === "createOwnGoal") {
          return Array.from({ length: quantity }, (_, index) => ({
            id: (index + 1).toString(),
            type: goalType,
            selected: true,
            completed: false
          }))
        } else {
          return {
            id: "",
            type: goalType as GoalType,
            selected: true,
            completed: false
          }
        }
      })
      ;(household ? updateHousehold(household!._id!, [], { goals: { goalDetails } }) : updateClient(client!._id, { goals: { goalDetails } }))
        .then(() => {
          setIsAddInProgress(false)
          setCurrentScreen("summary")
        })
        .catch((err) => {
          console.error("Error saving goals details", err)
          setErrorMessage("Error saving the goals details. Please try again")
        })
    },
    [client, household, theme.goals, updateClient, updateHousehold]
  )

  return (
    <>
      <div>
        <h1 className="sr-only">Goals</h1>
        {currentScreen === "summary" && (
          <>
            {!lastUpdatedDate || goalDetails.length === 0 ? (
              <section className="flex flex-col items-center gap-6 py-18 px-4 my-10 mx-16 bg-surface-100">
                <div className="flex flex-col items-center text-center gap-3">
                  <img className="w-25" src={goal} alt="" aria-hidden />
                  <p className="font-semibold">
                    {household
                      ? household.members.map((m) => m.client.firstName).join(" and ") + " have no goals yet, start by adding one."
                      : `${client?.firstName} has no goals yet, start by adding one.`}
                  </p>
                </div>
                <button className="btn btn-secondary btn-medium w-44" onClick={() => setCurrentScreen("add")}>
                  Add goals
                </button>
              </section>
            ) : (
              <div className="px-16 py-10 flex flex-col gap-10">
                <p>Goals last updated on {customDateFormat(lastUpdatedDate)}</p>
                <div className="flex flex-col gap-6">
                  <div className="flex justify-between gap-2">
                    <h2 className="text-h3 font-semibold">
                      {household ? household.members.map((m) => m.client.firstName).join(" and ") + "'s goals" : `${client?.firstName}'s goals`}
                    </h2>
                    <AddGoal client={client} household={household} goalDetails={goalDetails} onUpdate={setSelectedGoal} />
                  </div>
                  <fieldset className="flex flex-wrap gap-5">
                    <legend className="sr-only">Select a goal to view the goal projector for that goal</legend>
                    {sortedGoalDetails.map((goal, i) => (
                      <div key={i}>
                        <GoalCard
                          goal={goal}
                          isSelected={goal.type === selectedGoal?.type && goal.id === selectedGoal.id}
                          client={client}
                          household={household}
                          onChange={setSelectedGoal}
                          onDelete={() => setSelectedGoal(undefined)}
                        />
                      </div>
                    ))}
                  </fieldset>
                </div>
                {selectedGoalDetail && (
                  <>
                    <div>
                      <h2 className="text-h3 font-semibold">Selected investment risk level</h2>
                      <SelectedInvestment client={client} household={household} context="goals" goal={selectedGoalDetail} />
                    </div>
                    <div className="flex flex-col gap-6">
                      {selectedGoalDetail.type === "retirementIncome" ? (
                        <div className="relative w-full min-h-[636px]">
                          <RetirementIncome client={client} household={household} outsideIM={true} />
                        </div>
                      ) : (
                        <div className="relative w-full min-h-[600px]">
                          <WealthAccumulation client={client} household={household} outsideIM={true} goalType={selectedGoal?.type} goalId={selectedGoal?.id} />
                        </div>
                      )}
                    </div>
                  </>
                )}
              </div>
            )}
            <hr className="border-surface-300" />
            <CommentsSection gameType="goals" client={client} household={household} />
          </>
        )}
        {currentScreen === "add" && (
          <>
            <div className="py-10 px-16">
              <h2 className="w-full text-h2 font-semibold mb-10">Which goals would you like to add?</h2>
              <GoalsAdd onChange={setSelectedGoals} selectedGoals={selectedGoals} />
            </div>
            <div className="flex justify-center gap-5">
              <button className="btn btn-secondary btn-medium w-44" onClick={() => setCurrentScreen("summary")}>
                Back
              </button>
              <button className="btn btn-primary btn-medium w-44" onClick={() => handleAddGoals(selectedGoals)} disabled={isAddInProgress}>
                {isAddInProgress ? <Loading type="dots" /> : <>Add goals</>}
              </button>
            </div>
            <div role="alert" className="sr-only">
              {errorMessage && <ErrorMessage id="goals-tab-add-goals" message={errorMessage} />}
            </div>
          </>
        )}
      </div>
    </>
  )
}

export default GoalsTab
