import clsx from "clsx"
import { AnimatePresence } from "framer-motion"
import React, { useCallback, useContext, useEffect, useMemo, useState } from "react"
import { Link } from "react-router-dom"
import { update } from "../../api/firms"
import AlertIcon from "../../assets/icons/AlertIcon"
import Loading from "../../components/ClientProfile/Loading/Loading"
import Modal from "../../components/Modal/Modal"
import TextInput from "../../components/TextInput/TextInput"
import { FirmContext } from "../../contexts/FirmContext"
import { useTheme } from "../../contexts/ThemeContext"
import { AssetClass, SubAssetClass } from "../../models/InvestmentUniverse"
import { ModelPortfolio, WeightedComponent } from "../../models/PortfolioModel"
import { AuthContext } from "../../views/auth/AuthContext"
import { assetTypeWeights } from "../clients/reports/ComfortMatchPage"
import { DoughnutChart } from "../rmjourney/components/DoughnutChart/DoughnutChart"
import DecimalInput from "./components/DecimalInput"
import DeleteModelPortfolioModal from "./components/DeleteModelPortfolioModal"
import EditModelPortfolioModal from "./components/EditModelPortfolioModal"
import InflationRate from "./components/InflationRate"
import ModelPortfolioMenuPopup from "./components/ModelPortfolioMenuPopup"
import ErrorMessage from "../../components/Error/ErrorMessage"

export const assetClassColors = ["#1C3236", "#31585E", "#427880", "#5DA1AC", "#90CBCE", "#D0EDEF", "#E8F6F6", "#EAD9C3"]

export const AssetClassDot = ({ color }: { color?: number }) => (
  <svg width="7" height="6" viewBox="0 0 7 6" fill="none" xmlns="http://www.w3.org/2000/svg">
    <ellipse
      cx="3.09155"
      cy="3"
      rx="3.03442"
      ry="3"
      fill={color !== undefined && color >= 0 && color < assetClassColors.length ? assetClassColors[color] : "white"}
    />
  </svg>
)

const ModelPortfoliosPage = () => {
  const theme = useTheme()
  const { firm, reloadFirm } = useContext(FirmContext)
  const { sessionInfo } = useContext(AuthContext)
  const [pfs, setPfs] = useState<ModelPortfolio[]>(firm!.modelPortfolios || [])
  const [newPf, setNewPf] = useState<Omit<ModelPortfolio, "id">>()
  const [error, setError] = useState<string>()
  const [inflationRateModal, setInflationRateModal] = useState<boolean>(false)
  const [deleteModal, setDeleteModal] = useState<boolean>(false)
  const [editModal, setEditModal] = useState<boolean>(false)
  const [selectedPortfolio, setSelectedPortfolio] = useState<ModelPortfolio>()
  const [isSaveInProgress, setIsSaveInProgress] = useState<boolean>(false)

  // const [ weights, setWeights ] = useState<{[key: string]: number}[]>([])

  const investableAssets = useMemo(() => firm?.assetClasses?.flatMap((ac) => [ac, ...(ac.subAssetClasses || [])]) ?? [], [firm])
  // const investableAssetsById = useMemo(() => investableAssets.reduce((acc, ass) => ({...acc, [ass.id!]: ass}), {}), [investableAssets])
  // useEffect(() => {
  //   setWeights(pfs.map(pf => investableAssets!.reduce((acc, ia) => ({ ...acc, [ia.id!]: pf.components.find(c => c.id === ia.id)?.weight || 0}), {})))
  // }, [pfs, investableAssetsById, investableAssets])

  const weights: { [key: string]: number }[] = useMemo(
    () => pfs.map((pf) => investableAssets!.reduce((acc, ia) => ({ ...acc, [ia.id!]: pf.components.find((c) => c.id === ia.id)?.weight || 0 }), {})),
    [pfs, investableAssets]
  )

  const completion = useMemo(() => weights.map((pf) => Math.round(Object.values(pf).reduce((acc, wc) => acc + wc, 0) * 100) / 100), [weights])

  // const addAnotherPortfolio = useCallback(() => setPfs(prev => [...prev, { id: "", name: "", components: [] }]), [firm])
  const canAddAnother = useMemo(() => pfs.length < 8 && investableAssets.length > 0, [pfs, investableAssets.length])

  const isValidReturnAndVolatility = useCallback(() => {
    // Check expected returns and expected volatility for each portfolio is in ascending order
    const expectedReturnArray: number[] = pfs.filter((pf) => typeof pf.r === "number").map((pf) => pf.r as number)
    const expectedVolatilityArray: number[] = pfs.filter((pf) => typeof pf.sd === "number").map((pf) => pf.sd as number)
    const areValuesAscending = (arr: number[]) => arr.slice(1).every((x, i) => x >= arr[i])
    return areValuesAscending(expectedReturnArray) && areValuesAscending(expectedVolatilityArray)
  }, [pfs])

  useEffect(() => {
    setPfs(firm!.modelPortfolios || []) // doing it here to support reloadFirm which will trigger a refresh with backend data
  }, [firm])

  const updateWeight = useCallback(
    (idx: number, acId: string, weight: number) => {
      setPfs((prev) =>
        prev.map((prevpf, ii) =>
          idx === ii
            ? {
                ...prevpf,
                components: ([{ id: acId, weight }, ...prevpf.components.filter((ea) => ea.id !== acId)] as WeightedComponent[])
                  .filter((c) => c.weight > 0)
                  .sort((a, b) => b.weight - a.weight)
              }
            : prevpf
        )
      )
    },
    [setPfs]
  )

  const pfCols = pfs.length
  const addCol = canAddAnother ? 1 : 0
  const emptyCols = [...Array(Math.abs(11 - 3 - pfCols - addCol))].map((_, i) => i)

  const deletePortfolio = useCallback(() => {
    const newPortfolio = pfs.filter((portfolio) => portfolio !== selectedPortfolio)
    setDeleteModal(false)
    setPfs(newPortfolio)
    setSelectedPortfolio(undefined)
  }, [pfs, selectedPortfolio])

  const editPortfolio = useCallback(
    (name: string) => {
      if (name) {
        const newPfs = pfs.map((portfolio) => (portfolio === selectedPortfolio ? { ...portfolio, name } : portfolio))
        setEditModal(false)
        setPfs(newPfs)
        setSelectedPortfolio(undefined)
      }
    },
    [pfs, selectedPortfolio]
  )

  const allAssetClasses = useMemo(() => {
    return firm?.assetClasses
      ?.flatMap((ac) => [ac, ...(ac.subAssetClasses || []).map((sac) => ({ ...sac, type: sac.type ?? ac.type }))] as (AssetClass | SubAssetClass)[])
      .reduce(
        (acc, cur) => ({
          ...acc,
          [cur.id!]: cur
        }),
        {} as { [key: string]: AssetClass | SubAssetClass }
      )
  }, [firm?.assetClasses])

  if (!allAssetClasses || Object.keys(allAssetClasses).find(k => !allAssetClasses[k].id)) {
    return (
      <div className="pg-ctr pg-ctr-py pt-6 w-full h-full text-main-600 px-10 flex flex-col items-stretch">
        <div className="bg-surface-100 w-full h-full flex flex-col items-center justify-center p-10">
          <div className="flex flex-col w-1/2 text-center">
            {/* <img alt="" src={target} aria-hidden /> */}
            <h2 className="p-5">To create portfolios you will first need to create asset classes to be used in those portfolios.</h2>
            <Link to="/firm/asset-classes" className="btn btn-text">
              Manage asset classes
            </Link>
          </div>
        </div>
      </div>
    )
  }

  return (
    <>
      <div className="pg-ctr pg-ctr-py pt-6 w-full h-full text-main-600 px-10 flex flex-col items-stretch">
        <div className="flex justify-between items-start flex-0">
          <h1 className="text-h3 pt-4">Model portfolios</h1>
          <div className="space-x-10">
            <button onClick={() => setInflationRateModal(true)}>
              {firm?.inflationRate !== undefined ? "Expected inflation rate " + firm!.inflationRate + "%" : "Set inflation rate"}
            </button>
          </div>
        </div>
        <div className="flex items-stretch w-full h-full overflow-y-auto no-scrollbar flex-1">
          <div className="grid h-full w-full grid-cols-11 auto-rows-max gap-x-8">
            <div className="col-span-3" />
            {pfs.map((pf, i) => {
              const assetWeights = assetTypeWeights(pf.components, allAssetClasses!)
              return (
                <div key={`${pf.id || pf.name}`} className="group flex flex-col justify-end items-center mb-4 ">
                  <div className="w-4 h-4 md:w-13 md:h-13">
                    {completion[i] === 100 ? (
                      <DoughnutChart
                        data={assetWeights.map(({ total }) => total)}
                        colors={assetWeights.map(({ assetClass }) => theme.colors.assetClassConfigurations[assetClass].color)}
                      />
                    ) : (
                      <div className={clsx("text-sec full-flex-content-center", { "text-red-600": completion?.[i] !== 100 })}>
                        {completion?.[i] !== 100 && <>{completion?.[i]} %</>}
                      </div>
                    )}
                  </div>
                  <div className="text-sec h-full text-center justify-between overflow-ellipsis mt-2">
                    {pf.name}
                    <div className="hidden group-hover:block fixed w-full">
                      <ModelPortfolioMenuPopup
                        onEdit={() => {
                          setSelectedPortfolio(pf)
                          setEditModal(true)
                        }}
                        onDelete={() => {
                          setSelectedPortfolio(pf)
                          setDeleteModal(true)
                        }}
                      />
                    </div>
                  </div>
                </div>
              )
            })}
            {canAddAnother && (
              <button
                className="btn btn-secondary btn-small border-0 bg-surface-100 flex flex-col gap-1 items-center justify-center"
                onClick={() => setNewPf({ name: "", components: [] })}
              >
                <span>+</span>
                Add
              </button>
            )}
            {emptyCols.map((_, i) => (
              <div key={i} />
            ))}

            {/* Asset classes */}
            {firm?.assetClasses?.map((ac, idx) => (
              <React.Fragment key={ac.id || `${idx}-${ac.name}`}>
                <div className="flex items-center gap-x-2 col-span-3">
                  <div className="flex-full-content-center w-4">
                    <AssetClassDot color={ac.color} />
                  </div>
                  <span className="font-semibold text-sm py-2">{ac.name}</span>
                </div>
                {weights.map((pfWeights, i) => {
                  const weight = pfWeights[ac.id!]
                  const pf = pfs[i]
                  return (
                    <div key={`${pf.id || pf.name}:${ac.id || ac.name}`} className={clsx("w-full h-full my-0.5")}>
                      {(!ac.subAssetClasses?.length || !!weight) && (
                        <DecimalInput
                          inputFieldClassName={clsx("!py-0 w-full font-normal text-sec", { "text-red-600": completion && completion[i] !== 100 })}
                          name={`${pf.id || pf.name}:${ac.id || ac.name}`}
                          value={weight}
                          suffix="%"
                          onChange={(s) => updateWeight(i, ac.id!, Number(s ?? 0))}
                        />
                      )}
                    </div>
                  )
                })}
                {canAddAnother && <div className="w-full h-full bg-surface-100" />}
                {emptyCols.map((_, i) => (
                  <div key={i} />
                ))}

                {/* Sub asset classes */}
                {ac.subAssetClasses?.map((sac, sidx) => (
                  <React.Fragment key={sac.id || `${sidx}-${sac.name}`}>
                    <div className="font-normal text-sm px-6 py-2 col-span-3">{sac.name}</div>
                    {pfs.map((pf, ii) => {
                      const weight = weights[ii][sac.id!]
                      return (
                        <div key={`${pf.id || pf.name}:${sac.id || sac.name}`} className="w-full h-full my-1">
                          <DecimalInput
                            inputFieldClassName={clsx("!py-0 w-full font-normal text-sec", { "text-red-600": completion && completion[ii] !== 100 })}
                            name={`${pf.id || pf.name}:${sac.id || sac.name}`}
                            value={weight}
                            suffix="%"
                            onChange={(s) => updateWeight(ii, sac.id!, Number(s ?? 0))}
                          />
                        </div>
                      )
                    })}
                    {canAddAnother && <div className="w-full h-full bg-surface-100" />}
                    {emptyCols.map((_, i) => (
                      <div key={i} />
                    ))}
                  </React.Fragment>
                ))}
                <div
                  className={clsx("col-span-10 border-b-1", {
                    "border-b-surface-300": idx + 1 < firm!.assetClasses.length,
                    "border-b-surface-500": idx + 1 === firm!.assetClasses.length
                  })}
                />
              </React.Fragment>
            ))}
            <div className="font-normal text-sm px-6 py-2 col-span-3">Expected return</div>
            {pfs.map((pf, i) => {
              return (
                <div key={`${pf.id || pf.name}:r`} className={clsx("w-full h-full my-0.5")}>
                  <DecimalInput
                    inputFieldClassName={clsx("!py-0 w-full font-normal text-sec", { "text-red-600": completion && completion[i] !== 100 })}
                    name={`${pf.id || pf.name}:r`}
                    value={pf.r}
                    suffix="%"
                    onChange={(s) => setPfs((prevs) => prevs.map((prev) => (prev === pf ? { ...prev, r: Number(s ?? 0) } : prev)))}
                  />
                </div>
              )
            })}
            {canAddAnother && <div className="w-full h-full bg-surface-100" />}
            {emptyCols.map((_, i) => (
              <div key={i} />
            ))}
            <div className="col-span-10 border-b-1 border-b-surface-300" />
            <div className="font-normal text-sm px-6 py-2 col-span-3">Expected volatility</div>
            {pfs.map((pf, i) => {
              return (
                <div key={`${pf.id || pf.name}:sd`} className={clsx("w-full h-full my-0.5")}>
                  <DecimalInput
                    inputFieldClassName={clsx("!py-0 w-full font-normal text-sec", { "text-red-600": completion && completion[i] !== 100 })}
                    name={`${pf.id || pf.name}:sd`}
                    value={pf.sd}
                    suffix="%"
                    onChange={(s) => setPfs((prevs) => prevs.map((prev) => (prev === pf ? { ...prev, sd: Number(s ?? 0) } : prev)))}
                  />
                </div>
              )
            })}
            {canAddAnother && <div className="w-full h-full bg-surface-100" />}
            {emptyCols.map((_, i) => (
              <div key={i} />
            ))}
            <div className="col-span-10 border-b-1 border-b-surface-500" />
          </div>
          <AnimatePresence>
            {newPf && (
              <Modal
                handleClose={() => setNewPf(undefined)}
                handleConfirm={() => {
                  setPfs((prev) => [...prev, { ...newPf, id: "" }])
                  setNewPf(undefined)
                }}
              >
                <div className="w-[50vw] px-6 py-3 flex flex-col gap-y-10">
                  <h3 className="text-h2">Create model portfolio</h3>
                  <TextInput label="Name of portfolio" name="newpf" value={newPf.name} onChange={(evt) => setNewPf({ ...newPf, name: evt })} focusOnLoad />
                  <div className="flex justify-center gap-x-2">
                    <button className="btn btn-medium" onClick={() => setNewPf(undefined)}>
                      Cancel
                    </button>
                    <button
                      className="btn btn-medium btn-primary"
                      onClick={
                        newPf.name
                          ? () => {
                              setPfs((prev) => [...prev, { ...newPf, id: "" }])
                              setNewPf(undefined)
                            }
                          : () => {}
                      }
                    >
                      Create
                    </button>
                  </div>
                </div>
              </Modal>
            )}
            {inflationRateModal && (
              <Modal handleClose={() => setInflationRateModal(false)}>
                <InflationRate handleClose={() => setInflationRateModal(false)} disableSave={isSaveInProgress} />
              </Modal>
            )}
            {deleteModal && (
              <Modal handleClose={() => setDeleteModal(false)}>
                <DeleteModelPortfolioModal handleClose={() => setDeleteModal(false)} onDelete={deletePortfolio} />
              </Modal>
            )}
            {editModal && (
              <Modal handleClose={() => setEditModal(false)}>
                <EditModelPortfolioModal handleClose={() => setEditModal(false)} onEdit={editPortfolio} name={selectedPortfolio!.name} />
              </Modal>
            )}
          </AnimatePresence>
        </div>
        <div className="w-full flex bg-white flex-0 mt-5">
          <div className="flex-1">
            <div className="flex gap-2 max-w-[400px] bg-white border border-interactive-200 shadow-sm p-1 items-center text-sm mb-2">
              <AlertIcon />
              <p className="flex gap-1">
                Can't see the asset class you need? You'll need to
                <Link aria-label="Create new asset class" className="text-link-600 underline" to="/firm/asset-classes">
                  create it here.
                </Link>
              </p>
            </div>
          </div>
          <div className="flex gap-4 items-center ml-auto">
            <div role="alert">
              {error && (
                <ErrorMessage id="model-portfolios" message={error} />
              )}
            </div>
            <button
              className="btn btn-primary btn-medium px-16"
              disabled={isSaveInProgress || completion.some((c) => c !== 100) || pfs.some((x) => x.r === 0 || x.sd === null || x.sd === undefined)}
              onClick={() => {
                setIsSaveInProgress(true)
                setError(undefined)
                if (isValidReturnAndVolatility()) {
                  update(sessionInfo!, firm!._id, {
                    modelPortfolios: pfs
                  })
                    .then(() => {
                      setIsSaveInProgress(false)
                      reloadFirm()
                    })
                    .catch((err) => {
                      setIsSaveInProgress(false)
                      setError(err.response?.data || err.message || err)
                    })
                } else {
                  setIsSaveInProgress(false)
                  setError("Expected returns and volatilities should be in ascending order")
                }
              }}
            >
              {isSaveInProgress ? <Loading type="dots" /> : "Save"}
            </button>
          </div>
        </div>
      </div>
    </>
  )
}

export default ModelPortfoliosPage
