import { useMemo } from "react"
import LineChart from "../../../../rmjourney/components/LineChart/LineChart"
import { useTheme } from "../../../../../contexts/ThemeContext"
import { Client } from "../../../../../models/Client"
import { scoreAxisValues } from "./assets/scoreAttributes"

interface Props {
  client?: Client
  score: number
  title: string
}

const ScoreChart: React.FC<Props> = ({ client, score, title }) => {
  const theme = useTheme()
  const scoreChart = useMemo(() => {
    const axisValues = scoreAxisValues(theme, score, client)
    if (axisValues) {
      return {
        ...axisValues,
        highlightDataPoints: axisValues.highlightDataPoints.map((dataPoint) => {
          return {
            ...dataPoint,
            text: theme.charts?.attitudeToRisk?.showScoreAsDataPoint ? dataPoint.x : dataPoint.text,
            textColor: theme.charts?.attitudeToRisk?.showScoreAsDataPoint ? theme.colors.avatarColors[0] : dataPoint.textColor
          }
        })
      }
    }
    return axisValues
  }, [client, score, theme])

  return scoreChart ? (
    <LineChart
      enableAnimations={false}
      xAxis={scoreChart.xAxis}
      yAxis={scoreChart.yAxis}
      gameType={"risk"}
      series={[
        {
          id: "1",
          title: client?.firstName,
          values: scoreChart.attitudeToRiskScore,
          color: theme.colors.scoreChartColor
        }
      ]}
      type={"risk"}
      highlightDataPoints={scoreChart.highlightDataPoints}
      title={title}
    />
  ) : undefined
}

export default ScoreChart
