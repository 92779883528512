import clsx from "clsx"
import Dropdown from "../../../../components/Dropdown/Dropdown"
import { useEffect, useRef, useState } from "react"
import { useTheme } from "../../../../contexts/ThemeContext"
import { Client, GoalDetail } from "../../../../models/Client"
import chevronIcon from "../../../../assets/icons/chevron-down.svg"
import checkmark from "../../../../assets/icons/checkmark-circle-fill-disabled.svg"
import Modal from "../../../../components/Modal/Modal"
import EditDetails from "./EditDetails"
import { Household } from "../../../../models/Household"
import { tt } from "../../../../lib/translations"

const AddGoal = ({
  client,
  household,
  goalDetails,
  onUpdate,
  className
}: {
  client?: Client
  household?: Household
  goalDetails?: GoalDetail[]
  onUpdate?: (goal: GoalDetail) => void
  className?: string
}) => {
  const [isAddMenuOpen, setIsAddMenuOpen] = useState<boolean>(false)
  const menuBtn = useRef<HTMLButtonElement>(null)
  const theme = useTheme()
  const [isAddDisabled, setIsAddDisabled] = useState<boolean>()
  const maxGoals = 10
  const [showEditModal, setShowEditModal] = useState<boolean>(false)
  const [currentGoal, setCurrentGoal] = useState<GoalDetail>()

  useEffect(() => {
    if (goalDetails) {
      setIsAddDisabled(goalDetails.length >= maxGoals)
    }
  }, [goalDetails])

  return (
    <>
      <div className="relative">
        <button
          className={clsx(
            "btn btn-text text-sec flex gap-1 items-center font-bold leading-4 hover:bg-interactive-200 active:bg-interactive-200 w-max",
            className,
            {
              "bg-interactive-200": isAddMenuOpen,
              "opacity-50": isAddDisabled
            }
          )}
          disabled={isAddDisabled}
          ref={menuBtn}
          aria-expanded={isAddMenuOpen}
          onClick={() => setIsAddMenuOpen(!isAddMenuOpen)}
        >
          Add goal
          <img className="p-1" src={chevronIcon} alt="" aria-hidden />
        </button>
        {isAddMenuOpen && (
          <Dropdown overlayClassName="w-dropdown z-50" align="left" alignVertical="bottom" handleClose={() => setIsAddMenuOpen(false)} trigger={menuBtn}>
            <ul className="list-none py-2" role="menu">
              {theme.goals.map((goal, i) => {
                const hasMaxGoalsOfType = !!(goal.type !== "education" && goal.type !== "createOwnGoal" && goalDetails?.find((g) => g.type === goal.type)) // Education and create own goal can have more than one
                return (
                  <li key={i} role="menuitem">
                    <button
                      className={clsx("btn text-sec leading-4 flex gap-2 justify-between py-3.75 px-4 border-0 w-full text-left font-normal", {
                        "text-main-200": hasMaxGoalsOfType,
                        "hover:bg-interactive-100 active:bg-interactive-200": !hasMaxGoalsOfType
                      })}
                      disabled={hasMaxGoalsOfType}
                      onClick={() => {
                        setIsAddMenuOpen(false)
                        setCurrentGoal({ type: goal.type, completed: false })
                        setShowEditModal(true)
                      }}
                    >
                      {goal.type === "createOwnGoal" ? "Add own goal" : tt({ id: `profile-page-status-card-goals-goal-${goal.type}-title` })}
                      {hasMaxGoalsOfType && <img src={checkmark} alt="Checkmark" />}
                    </button>
                  </li>
                )
              })}
            </ul>
          </Dropdown>
        )}
      </div>
      {showEditModal && currentGoal && (
        <Modal className="w-modal" handleClose={() => setShowEditModal(false)}>
          <EditDetails client={client} household={household} currentGoal={currentGoal} handleClose={() => setShowEditModal(false)} onUpdate={onUpdate} />
        </Modal>
      )}
    </>
  )
}

export default AddGoal
