import { clsx } from "clsx"
import { AnimatePresence } from "framer-motion"
import { ReactNode, useContext, useEffect, useRef, useState } from "react"
import menuIcon from "../../../../assets/icons/more-vertical.svg"
import trashIcon from "../../../../assets/icons/trash.svg"
import Loading from "../../../../components/ClientProfile/Loading/Loading"
import Dropdown from "../../../../components/Dropdown/Dropdown"
import ErrorMessage from "../../../../components/Error/ErrorMessage"
import Modal from "../../../../components/Modal/Modal"
import { ClientHouseholdCacheContext } from "../../../../contexts/ClientHouseholdCacheContext"
import { Client, GoalDetail, GoalType } from "../../../../models/Client"
import { Household } from "../../../../models/Household"
import EditDetails from "./EditDetails"
import warningIcon from "./assets/warning.svg"
import CardTitle, { getGoalTitle } from "./components/CardTitle"

interface Props {
  alignVertical?: "bottom" | "top"
  client?: Client
  household?: Household
  goal: GoalDetail
  onDelete: () => void
  icon?: ReactNode
  btnClassName?: string
  dropdownClassName?: string
}

const GoalCardMenu = ({ alignVertical, client, household, goal, onDelete, icon, btnClassName, dropdownClassName }: Props) => {
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false)
  const menuBtn = useRef<HTMLButtonElement>(null)
  const clientOrHousehold = household ?? client
  const { updateClient, updateHousehold } = useContext(ClientHouseholdCacheContext)
  const [goalDetails, setGoalDetails] = useState<GoalDetail[]>(clientOrHousehold?.goals?.goalDetails ?? [])

  const [errorMessage, setErrorMessage] = useState<string>()
  const [showEditModal, setShowEditModal] = useState<boolean>(false)
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false)
  const [isDeleting, setIsDeleting] = useState(false)

  const reassignIds = (items: GoalDetail[], type: GoalType): GoalDetail[] => {
    let counter = 1
    return items.map((item) => (item.type === type ? { ...item, id: String(counter++) } : item))
  }

  const onConfirmDelete = () => {
    setIsDeleting(true)
    setErrorMessage(undefined)
    const filtered = goalDetails.filter((goalDetail) => !(goalDetail.type === goal.type && goalDetail.id === goal.id))
    const goals = {
      ...clientOrHousehold?.goals,
      goalDetails: reassignIds(filtered, goal.type)
    }
    ;(household ? updateHousehold(household!._id!, [], { goals }) : updateClient(client!._id, { goals }))
      .then(() => {
        setIsDeleting(false)
        setShowDeleteModal(false)
        onDelete()
      })
      .catch((err) => {
        console.error("error deleting the goal", err)
        setErrorMessage("Error deleting the goal. Please try again.")
      })
  }

  useEffect(() => {
    setGoalDetails(clientOrHousehold?.goals?.goalDetails ?? [])
  }, [clientOrHousehold?.goals?.goalDetails, goalDetails.length])

  return (
    <>
      <button
        className={clsx("p-1 hover:bg-interactive-200 active:bg-interactive-200", btnClassName)}
        ref={menuBtn}
        aria-label={`Open options menu for ${getGoalTitle({ client, household, goalDetails, goal})}`}
        aria-expanded={isMenuOpen}
        onClick={() => setIsMenuOpen(!isMenuOpen)}
      >
        {icon ?? <img src={menuIcon} alt="" aria-hidden />}
      </button>
      {isMenuOpen && (
        <Dropdown
          overlayClassName={clsx("w-dropdown -mr-0.5", dropdownClassName)}
          align="left"
          alignVertical={alignVertical}
          handleClose={() => setIsMenuOpen(false)}
          trigger={menuBtn}
        >
          <ul className="list-none py-2" role="menu">
            <li role="menuitem">
              <button
                className="btn text-sec leading-4 py-3.75 px-4 hover:bg-interactive-100 active:bg-interactive-200 border-0 block w-full text-left font-normal"
                onClick={() => {
                  setIsMenuOpen(false)
                  setShowEditModal(true)
                }}
              >
                Edit details
              </button>
            </li>
            <li role="menuitem">
              <button
                className="btn text-sec leading-4 py-3.75 px-4 hover:bg-interactive-100 active:bg-interactive-200 border-0 flex justify-between items-center w-full text-left font-normal"
                onClick={() => {
                  setIsMenuOpen(false)
                  setShowDeleteModal(true)
                }}
              >
                Delete goal
                <img className="w-4" src={trashIcon} alt="" aria-hidden />
              </button>
            </li>
          </ul>
        </Dropdown>
      )}
      <AnimatePresence>
        {showDeleteModal && (
          <Modal
            className="w-modal"
            handleClose={() => {
              setShowDeleteModal(false)
            }}
          >
            <div className="w-full flex flex-col items-center gap-6">
              <img src={warningIcon} alt="" aria-hidden />
              <h1 className="text-h2 font-semibold">Are you sure you want to delete this goal?</h1>
              <p>All details in the goal projector will be removed.</p>
              <div className="flex gap-5">
                <button className="btn btn-secondary btn-medium w-44" onClick={() => setShowDeleteModal(false)}>
                  Cancel
                </button>
                <button className="btn btn-primary btn-medium w-44" onClick={onConfirmDelete} disabled={isDeleting}>
                  {isDeleting ? <Loading type="dots" /> : "Delete"}
                </button>
              </div>
              <div role="alert" className="sr-only">
                {errorMessage && <ErrorMessage id="goals-tab-delete-goal" message={errorMessage} />}
              </div>
            </div>
          </Modal>
        )}
        {showEditModal && goal && (
          <Modal className="w-modal" handleClose={() => setShowEditModal(false)}>
            <EditDetails client={client} household={household} currentGoal={goal} handleClose={() => setShowEditModal(false)} />
          </Modal>
        )}
      </AnimatePresence>
    </>
  )
}

export default GoalCardMenu
