import { Trans, t } from "@lingui/macro"
import clsx from "clsx"
import { AnimatePresence } from "framer-motion"
import React, { ChangeEvent, useCallback, useContext, useEffect, useMemo, useRef, useState } from "react"
import { Helmet } from "react-helmet-async"
import { Link, useNavigate } from "react-router-dom"
import { investmentMeetingDone } from "../../api/advisors"
import Checkbox from "../../components/Checkbox/Checkbox"
import Dropdown from "../../components/Dropdown/Dropdown"
import StatusBadge from "../../components/StatusBadge/StatusBadge"
import { AppContext } from "../../contexts/AppContext"
import { ClientHouseholdCacheContext } from "../../contexts/ClientHouseholdCacheContext"
import { useTheme } from "../../contexts/ThemeContext"
import useAdvisorSearch from "../../hooks/useAdvisorSearch"
import useClientHouseholdSearch from "../../hooks/useClientHouseholdSearch"
import { useClientListSortColumn } from "../../hooks/useClientListSortColumn"
import useTrackViewEvent from "../../hooks/useTrackViewEvent"
import { customDateFormat } from "../../lib/date"
import { Client, ClientStatus } from "../../models/Client"
import { Household } from "../../models/Household"
import filterIcon from "./assets/images/chevron-down.svg"
import emptyListIcon from "./assets/images/fancy-people.svg"
import sortIcon from "./assets/images/sort-neutral.svg"
import sortDescIcon from "./assets/images/sort-up.svg"
import ArchiveConfirmationModal from "./components/ArchiveConfirmationModal/ArchiveConfirmationModal"
import ClientItemMenu from "./components/ClientItemMenu"
import SearchBox from "./components/SearchBox"
import StatusCounters from "./components/StatusCounters"
import Popover from "./components/Popover/Popover"
import { TourControllerContext } from "../../contexts/TourController"
import { AxiosError } from "axios"
import { UserProfile } from "../../models/UserProfile"
import ErrorMessage from "../../components/Error/ErrorMessage"

const PAGE_SIZE = 25

const ClientsListPage = () => {
  const theme = useTheme()
  const trackViewEvent = useTrackViewEvent()
  const navigate = useNavigate()
  const { replace } = useContext(ClientHouseholdCacheContext)
  const { userProfile } = useContext(AppContext)
  const { tourSteps, step, progress, completeStep, isLastStep } = useContext(TourControllerContext)

  const [showStatusFilterMenu, setShowStatusFilterMenu] = useState<boolean>(false)
  const statusFilterBtn = useRef<HTMLButtonElement>(null)
  const [showAdvisorFilterMenu, setShowAdvisorFilterMenu] = useState<boolean>(false)
  const advisorFilterBtn = useRef<HTMLButtonElement>(null)
  const [clientOrHouseholdToArchive, setClientOrHouseholdToArchive] = useState<Client | Household>()
  const { isLoading: isMyAdvisorsLoading, isSuccess, data: myAdvisors } = useAdvisorSearch()

  const {
    clients,
    error,
    isPreviousData,
    isLoading,
    isFetching,
    sort,
    setSort,
    search,
    setSearch,
    page,
    setPage,
    advisorFilters,
    statusFilters,
    setAdvisorFilters,
    setStatusFilters,
    refetch
  } = useClientHouseholdSearch(false, (userProfile as UserProfile & { clientsToShow?: number }).clientsToShow ?? PAGE_SIZE)

  const statuses = theme.pages?.clientsList?.filters ?? ["Activity pending", "New client", "New results available", "Up for review", "Up to date"]
  const [currentCountFilter, setCurrentCountFilter] = useState<ClientStatus>()
  const [selectedStatuses, setSelectedStatuses] = useState<string[]>(statusFilters ?? [])
  const [selectedAdvisors, setSelectedAdvisors] = useState<string[]>(advisorFilters ?? [])

  const { setSortCol, sortCol, sortDirection } = useClientListSortColumn({ sort, setSort })

  const onFilter = useCallback(
    (filter: { advisors?: string[]; statuses?: string[] }) => {
      setSelectedAdvisors(filter.advisors ?? [])
      setSelectedStatuses(filter.statuses ?? [])
      setAdvisorFilters(filter.advisors ?? [])
      setStatusFilters(filter.statuses ?? [])
    },
    [setAdvisorFilters, setStatusFilters]
  )

  const onStatusChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const { value, checked } = e.target
      if (checked) {
        onFilter?.({ statuses: [...(selectedStatuses ?? []), value], advisors: selectedAdvisors })
      } else {
        onFilter?.({ statuses: [...(selectedStatuses ?? []).filter((a) => !(a === value))], advisors: selectedAdvisors })
      }
    },
    [onFilter, selectedStatuses, selectedAdvisors]
  )

  const onAdvisorChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const { value, checked } = e.target
      if (checked) {
        onFilter?.({ advisors: [...(selectedAdvisors ?? []), value], statuses: selectedStatuses })
      } else {
        onFilter?.({ advisors: [...(selectedAdvisors ?? []).filter((a) => !(a === value))], statuses: selectedStatuses })
      }
    },
    [onFilter, selectedStatuses, selectedAdvisors]
  )

  const onRowClick = useCallback(
    (isHousehold: boolean, searchResult: Client | Household) => {
      trackViewEvent({ action: "click", category: "client_selection", label: searchResult._id })
      if (isHousehold) {
        navigate(`/households/${searchResult._id}`)
      } else {
        if (theme.pages.profile.autoUpToDate && searchResult?.status === "New results available") {
          investmentMeetingDone(searchResult.advisorId, "client", searchResult._id)
            .then((updatedClient) => {
              replace(updatedClient)
            })
            .catch((error) => {
              console.error("error marking client as investment meeting done", error)
              throw error
            })
        }
        navigate(`/clients/${searchResult._id}`)
      }
    },
    [navigate, replace, theme.pages.profile.autoUpToDate, trackViewEvent]
  )

  const showPrimaryAdviserColumn = theme.pages.clientsList?.columns.primaryAdviser && isSuccess && myAdvisors && myAdvisors.length > 1

  const advisorNamePulseWidths = useMemo(() => clients?.slice(0, PAGE_SIZE).map(() => ["w-1/4", "w-1/3", "w-1/5", "w-1/2"][Math.floor(Math.random() * 4)]), [clients])

  const handleCountFilterClick = useCallback(
    (status: ClientStatus) => {
      setCurrentCountFilter(currentCountFilter === status ? undefined : status)
      setStatusFilters(currentCountFilter === status ? [] : [status])
      setSelectedStatuses(currentCountFilter === status ? [] : [status])
      setPage(1)
    },
    [currentCountFilter, setPage, setStatusFilters]
  )

  useEffect(() => {
    if (selectedStatuses.length > 1 && currentCountFilter) {
      setCurrentCountFilter(undefined)
    }
  }, [currentCountFilter, selectedStatuses.length])

  return (
    <>
      <Helmet>
        <title>{import.meta.env.VITE_APP_TITLE ?? "Adviser Portal"}</title>
      </Helmet>
      <div className="clients-list-page pg-ctr w-full h-full text-main-600 p-10 flex flex-col gap-10 items-stretch overflow-y-auto no-scrollbar absolute">
        <h1 className="clients-list-page__header font-semibold text-h2">Welcome, {userProfile?.firstName}</h1>
        {import.meta.env.VITE_APP_FEATURE_CLIENT_COUNT_FILTERS !== "false" && (
          <StatusCounters currentCountFilter={currentCountFilter} handleCountFilterClick={handleCountFilterClick} />
        )}
        <div className="flex items-center gap-5 justify-between">
          <div className="w-1/2 h-11">
            <SearchBox
              className="clients-list-page__searchbox"
              placeholder={t({ id: "clients-list-search-placeholder", message: "Search for clients" })}
              value={search}
              onChange={(val) => {
                setSearch(val)
                setPage(1)
              }}
              onClear={() => {
                setSearch("")
                setPage(1)
              }}
            />
          </div>
          <div className="flex gap-5 items-center">
            <Popover
              active={theme.tours.createClients.show && step.createClients === "Create clients"}
              position="left"
              content={
                <>
                  <p className="font-semibold text-main-500 text-sec text-left">
                    <Trans id="clients-list-tour-create-clients">Create new clients directly in the Risk Profiling suite.</Trans>
                  </p>
                  <div className="flex flex-row justify-between items-center mt-5">
                    <p className="tour-steps-count text-main-400 text-sm">
                      {tourSteps.createClients.length > 1 && (
                        <span>
                          {progress.createClients + 1} of {tourSteps.createClients.length}
                        </span>
                      )}
                    </p>
                    <button className="tour-btn btn btn-small border border-interactive-600 text-interactive-600 w-19" onClick={() => completeStep({ createClients: "Create clients" })}>
                      {isLastStep("createClients") ? "Got it" : "Next"}
                    </button>
                  </div>
                </>
              }
              onClose={() => completeStep({ createClients: "Create clients" })}
            >
              <Link
                className="btn btn-secondary btn-medium py-3.5 w-44 text-center px-2"
                to="/create-client"
                onClick={() => {
                  trackViewEvent({ action: "click", category: "client_creation", label: "create_new_client_btn" })
                }}
              >
                <Trans id="clients-list-create-new-client">Create new client</Trans>
              </Link>
            </Popover>
            <Popover
              active={theme.tours.createClients.show && step.createClients === "Import clients"}
              position="left"
              content={
                <>
                  <p className="font-semibold text-main-500 text-sec text-left">
                    <Trans id="clients-list-tour-import-clients">Import your existing clients into the suite.</Trans>
                  </p>
                  <div className="flex flex-row justify-between items-center mt-5">
                    <p className="tour-steps-count text-main-400 text-sm">
                      {tourSteps.createClients.length > 1 && (
                        <span>
                          {progress.createClients + 1} of {tourSteps.createClients.length}
                        </span>
                      )}
                    </p>
                    <button className="tour-btn btn btn-small border border-interactive-600 text-interactive-600 w-19" onClick={() => completeStep({ createClients: "Import clients" })}>
                      {isLastStep("createClients") ? "Got it" : "Next"}
                    </button>
                  </div>
                </>
              }
              onClose={() => completeStep({ createClients: "Import clients" })}
            >
              <Link to="/import-clients" className="btn btn-primary btn-medium py-3.5 w-44 text-center">
                <Trans id="clients-list-import-clients">Import clients</Trans>
              </Link>
            </Popover>
          </div>
        </div>

        {!isLoading &&
        isSuccess &&
        !isFetching &&
        clients &&
        clients.length === 0 &&
        ((!selectedStatuses && !selectedAdvisors) || (selectedStatuses.length === 0 && selectedAdvisors.length === 0)) &&
        !search ? (
          <div className="flex flex-col justify-center items-center h-full mt-10 bg-surface-100">
            <img src={theme.pages.clientsList?.icons?.emptyList ?? emptyListIcon} alt="" aria-hidden />
            <h2 className="font-semibold text-center text-main-500">You have no clients yet, start by adding one.</h2>
            <Link className="btn btn-medium btn-secondary mt-4" to="/create-client">
              <Trans id="clients-list-add-your-first-client">Add your first client</Trans>
            </Link>
          </div>
        ) : (
          <div role="table" aria-label="clients list" className="w-full flex-1 flex flex-col items-stretch">
            <div role="row" className="grid grid-cols-12 justify-items-stretch pb-5">
              <div
                role="columnheader"
                className={clsx("bg-white pl-1", {
                  "col-span-3": showPrimaryAdviserColumn,
                  "col-span-4": !showPrimaryAdviserColumn
                })}
              >
                <button aria-label="Sort by client name" onClick={() => setSortCol("name")} className="w-full font-semibold flex gap-1 items-center">
                  Client
                  <img
                    src={sortCol === "name" ? sortDescIcon : sortIcon}
                    alt=""
                    className={sortCol === "name" && sortDirection === "ASC" ? "rotate-180" : ""}
                  />
                </button>
              </div>
              <div
                role="columnheader"
                className={clsx("relative bg-white", {
                  "col-span-3": showPrimaryAdviserColumn,
                  "col-span-4": !showPrimaryAdviserColumn
                })}
              >
                <button
                  aria-label="Filter by client name"
                  className="w-full font-semibold flex gap-1 items-center"
                  onClick={() => setShowStatusFilterMenu(!showStatusFilterMenu)}
                  ref={statusFilterBtn}
                >
                  Status
                  {selectedStatuses && selectedStatuses.length > 0 && (
                    <span className="relative w-3.5 h-3.5 px-1.5 text-center rounded-full bg-highlight-600 items-center justify-center text-white">
                      <span className="absolute top-0 left-1 text-xxs font-bold pt-px">{selectedStatuses.length}</span>
                    </span>
                  )}
                  <img src={filterIcon} alt="" aria-hidden />
                </button>
                {showStatusFilterMenu && (
                  <Dropdown className="m-4" trigger={statusFilterBtn} overlayClassName="w-64" handleClose={() => setShowStatusFilterMenu(false)}>
                    <div className="my-2">
                      <ul className="list-none">
                        {statuses.map((status) => (
                          <li className="w-full hover:bg-interactive-100 cursor-pointer px-4 py-1.5" key={status}>
                            <Checkbox
                              name={status}
                              label={status}
                              onChange={onStatusChange}
                              className="filter-box__checkbox w-full py-1.5 text-sec"
                              value={status}
                              checked={selectedStatuses && selectedStatuses.includes(status)}
                            />
                          </li>
                        ))}
                      </ul>
                      <button
                        className="btn btn-text w-full h-auto px-4 py-3.5 text-left text-sec"
                        onClick={() => {
                          onFilter?.({ advisors: selectedAdvisors, statuses: [] })
                          setCurrentCountFilter(undefined)
                          setShowStatusFilterMenu(false)
                        }}
                      >
                        Clear filters
                      </button>
                    </div>
                  </Dropdown>
                )}
              </div>
              <div
                role="columnheader"
                className={clsx("bg-white flex gap-1 items-center", {
                  "col-span-2": showPrimaryAdviserColumn,
                  "col-span-3": !showPrimaryAdviserColumn
                })}
              >
                <button aria-label="Sort by created date" onClick={() => setSortCol("createdAt")} className="w-full font-semibold flex gap-1 items-center">
                  Created
                  <img
                    src={sortCol === "createdAt" ? sortDescIcon : sortIcon}
                    alt=""
                    className={sortCol === "createdAt" && sortDirection === "ASC" ? "rotate-180" : ""}
                  />
                </button>
              </div>
              {showPrimaryAdviserColumn && (
                <div role="columnheader" className="relative col-span-3 bg-white">
                  <button
                    aria-label="Filter by primary adviser"
                    onClick={() => setShowAdvisorFilterMenu(!showAdvisorFilterMenu)}
                    ref={advisorFilterBtn}
                    className="w-full flex gap-1 items-center font-semibold"
                  >
                    <Trans id="clients-list-page-column-header-primary-adviser">Primary adviser</Trans>
                    {selectedAdvisors && selectedAdvisors.length > 0 && (
                      <span className="relative w-3.5 h-3.5 px-1.5 text-center rounded-full bg-highlight-600 items-center justify-center text-white">
                        <span className="absolute top-0 left-1 text-[8px] font-bold pt-[1px]">{selectedAdvisors.length}</span>
                      </span>
                    )}
                    <img src={filterIcon} alt="" aria-hidden />
                  </button>
                  {showAdvisorFilterMenu && (
                    <Dropdown className="m-4" trigger={advisorFilterBtn} overlayClassName="w-64" handleClose={() => setShowAdvisorFilterMenu(false)}>
                      <div className="my-2 max-h-72 overflow-y-scroll">
                        <ul className="list-none">
                          {myAdvisors!.map((advisor) => (
                            <li className="w-full hover:bg-interactive-100 cursor-pointer px-4 py-1.5" key={advisor._id}>
                              <Checkbox
                                name={advisor._id}
                                label={`${advisor.firstName} ${advisor.lastName}`}
                                onChange={onAdvisorChange}
                                className="filter-box__checkbox w-full py-1.5 text-sec"
                                value={advisor._id}
                                checked={selectedAdvisors && selectedAdvisors.includes(advisor._id)}
                              />
                            </li>
                          ))}
                        </ul>
                        <button
                          className="btn btn-text w-full h-auto px-4 py-3.5 text-left text-sec"
                          onClick={() => {
                            onFilter?.({ advisors: [], statuses: selectedStatuses })
                            setShowAdvisorFilterMenu(false)
                          }}
                        >
                          Clear filters
                        </button>
                      </div>
                    </Dropdown>
                  )}
                </div>
              )}
            </div>
            <div className="px-4 bg-white flex items-center justify-center"></div>
            {error ? (
              <div className="col-span-full h-full flex flex-col items-center justify-center">
                <ErrorMessage id="clients-list-page" message={`An error has occurred: ${(error as AxiosError).response?.data?.toString() ?? error.message}`} />
              </div>
            ) : isLoading || !clients ? (
              <div className="col-span-full full-flex-content-center">Loading clients...</div>
            ) : clients?.length === 0 && (selectedAdvisors.length > 0 || selectedStatuses.length > 0 || search) ? (
              <div className="flex flex-col justify-center items-center h-full">
                <h2 className="font-semibold text-main-500">No clients found, please try adjusting your filters.</h2>
              </div>
            ) : (
              <>
                <div aria-label={`clients list, sorted by ${sortCol}`} role="rowgroup" className="flex-1 mt-1.5 overflow-y-auto">
                  {clients?.slice(0, PAGE_SIZE).map((searchResult, i) => {
                    const isHousehold = searchResult.hasOwnProperty("members")
                    const primaryAdviser = myAdvisors?.find((adv) => adv._id === searchResult.advisorId)
                    return (
                      <div
                        key={i}
                        className="grid grid-cols-12 justify-items-stretch cursor-pointer bg-white transition ease-in-out duration-300 hover:bg-interactive-100"
                      >
                        <div
                          key={i}
                          className="grid grid-cols-11 col-span-11"
                          onClick={() => {
                            onRowClick(isHousehold, searchResult)
                          }}
                          onKeyDown={(evt: React.KeyboardEvent<HTMLInputElement>) => {
                            if (evt.key === "Enter") {
                              onRowClick(isHousehold, searchResult)
                            }
                          }}
                          role="row button"
                          tabIndex={0}
                        >
                          <div
                            role="cell"
                            className={clsx("border-b-0.5 border-surface-400 py-4 flex items-center gap-2 pl-1", {
                              "col-span-3": showPrimaryAdviserColumn,
                              "col-span-4": !showPrimaryAdviserColumn
                            })}
                          >
                            {isHousehold ? (searchResult as Household).name : `${(searchResult as Client).firstName} ${(searchResult as Client).lastName}`}
                            {isHousehold && (
                              <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path
                                  fillRule="evenodd"
                                  clipRule="evenodd"
                                  d="M14.6668 12.6667C14.6668 13.0347 14.3688 13.3333 14.0002 13.3333C13.6315 13.3333 13.3335 13.0347 13.3335 12.6667C13.3335 11.564 12.4362 10.6667 11.3335 10.6667C10.8782 10.6667 10.4455 10.8227 10.0962 11.0993C10.4588 11.7633 10.6668 12.5247 10.6668 13.3333C10.6668 13.7013 10.3688 14 10.0002 14C9.6315 14 9.3335 13.7013 9.3335 13.3333C9.3335 11.4953 7.83816 10 6.00016 10C4.16216 10 2.66683 11.4953 2.66683 13.3333C2.66683 13.7013 2.36883 14 2.00016 14C1.6315 14 1.3335 13.7013 1.3335 13.3333C1.3335 10.76 3.4275 8.66667 6.00016 8.66667C7.28483 8.66667 8.44883 9.18867 9.2935 10.0307C9.87283 9.58267 10.5862 9.33333 11.3335 9.33333C13.1715 9.33333 14.6668 10.8287 14.6668 12.6667ZM11.3335 6C11.7015 6 12.0002 6.29933 12.0002 6.66667C12.0002 7.034 11.7015 7.33333 11.3335 7.33333C10.9655 7.33333 10.6668 7.034 10.6668 6.66667C10.6668 6.29933 10.9655 6 11.3335 6ZM11.3335 8.66667C12.4362 8.66667 13.3335 7.76933 13.3335 6.66667C13.3335 5.564 12.4362 4.66667 11.3335 4.66667C10.2308 4.66667 9.3335 5.564 9.3335 6.66667C9.3335 7.76933 10.2308 8.66667 11.3335 8.66667ZM6.00016 3.33333C6.7355 3.33333 7.3335 3.93133 7.3335 4.66667C7.3335 5.402 6.7355 6 6.00016 6C5.26483 6 4.66683 5.402 4.66683 4.66667C4.66683 3.93133 5.26483 3.33333 6.00016 3.33333ZM6.00016 7.33333C7.47083 7.33333 8.66683 6.13733 8.66683 4.66667C8.66683 3.196 7.47083 2 6.00016 2C4.5295 2 3.3335 3.196 3.3335 4.66667C3.3335 6.13733 4.5295 7.33333 6.00016 7.33333Z"
                                  fill="#31366B"
                                />
                              </svg>
                            )}
                          </div>
                          <div
                            role="cell"
                            className={clsx("border-b-0.5 border-surface-400 flex items-center", {
                              "col-span-3": showPrimaryAdviserColumn,
                              "col-span-4": !showPrimaryAdviserColumn
                            })}
                          >
                            {searchResult.status ? <StatusBadge status={searchResult.status}></StatusBadge> : "Created"}
                          </div>
                          <p
                            role="cell"
                            className={clsx("border-b-0.5 border-surface-400 flex items-center", {
                              "col-span-2": showPrimaryAdviserColumn,
                              "col-span-3": !showPrimaryAdviserColumn
                            })}
                          >
                            {searchResult.createdAt && customDateFormat(searchResult.createdAt)}
                          </p>
                          {showPrimaryAdviserColumn && (
                            <p role="cell" className="col-span-3 border-b-0.5 border-surface-400 flex items-center">
                              {isMyAdvisorsLoading ? (
                                <span className={clsx("h-4 bg-surface-300 rounded animate-pulse", advisorNamePulseWidths![i])} />
                              ) : (
                                <>
                                  {primaryAdviser?.firstName} {primaryAdviser?.lastName}
                                </>
                              )}
                            </p>
                          )}
                        </div>
                        <div role="cell" className="px-4 border-b-0.5 border-surface-400 flex items-center justify-center">
                          {theme.pages.clientsList?.clientActions.show && (
                            <ClientItemMenu
                              alignVertical={i > 3 ? "top" : "bottom"}
                              isHousehold={isHousehold}
                              isExternal={!!searchResult.externalId}
                              onArchiveClick={() => setClientOrHouseholdToArchive(searchResult)}
                              onViewProfileClick={() => onRowClick(isHousehold, searchResult)}
                            />
                          )}
                        </div>
                      </div>
                    )
                  })}
                </div>
                {(page > 1 || clients?.length > PAGE_SIZE) && (
                  <div className="pagination flex justify-center gap-5 p-6 lg-fluid-max:my-8">
                    <button
                      className="p-4 rounded-full transition ease-in-out duration-300 hover:bg-interactive-100"
                      onClick={() => {
                        setPage((old) => Math.max(old - 1, 1))
                      }}
                      disabled={page <= 1}
                    >
                      <svg width="14" height="12" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg" aria-label="previous">
                        <path
                          d="M12.8325 5.16643H2.94502L5.97252 1.53309C6.26752 1.17976 6.21919 0.653928 5.86585 0.359761C5.51169 0.0647614 4.98669 0.113095 4.69252 0.466428L0.525853 5.46643C0.493353 5.50559 0.476686 5.55143 0.45252 5.59476C0.43252 5.62976 0.408353 5.65976 0.393353 5.69809C0.355853 5.79393 0.333353 5.89476 0.333353 5.99643C0.333353 5.99726 0.33252 5.99893 0.33252 5.99976C0.33252 6.00059 0.333353 6.00226 0.333353 6.00309C0.333353 6.10476 0.355853 6.20559 0.393353 6.30143C0.408353 6.33976 0.43252 6.36976 0.45252 6.40476C0.476686 6.44809 0.493353 6.49393 0.525853 6.53309L4.69252 11.5331C4.85752 11.7306 5.09419 11.8331 5.33252 11.8331C5.52085 11.8331 5.71002 11.7698 5.86585 11.6398C6.21919 11.3456 6.26752 10.8198 5.97252 10.4664L2.94502 6.83309H12.8325C13.2925 6.83309 13.6659 6.45976 13.6659 5.99976C13.6659 5.53976 13.2925 5.16643 12.8325 5.16643Z"
                          fill={page <= 1 ? "#C1C5E4" : "#31366B"}
                        />
                      </svg>
                    </button>
                    <button
                      className="p-4 rounded-full transition ease-in-out duration-300 hover:bg-interactive-100"
                      onClick={() => {
                        if (!isPreviousData && (clients?.length || 0) > PAGE_SIZE) {
                          setPage((old) => old + 1)
                        }
                      }}
                      disabled={isPreviousData || (clients?.length || 0) <= PAGE_SIZE}
                    >
                      <svg width="14" height="12" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg" aria-label="next">
                        <path
                          d="M1.16748 6.83357L11.055 6.83357L8.02748 10.4669C7.73248 10.8202 7.78081 11.3461 8.13415 11.6402C8.48831 11.9352 9.01331 11.8869 9.30748 11.5336L13.4741 6.53357C13.5066 6.49441 13.5233 6.44857 13.5475 6.40524C13.5675 6.37024 13.5916 6.34024 13.6066 6.30191C13.6441 6.20607 13.6666 6.10524 13.6666 6.00357C13.6666 6.00274 13.6675 6.00107 13.6675 6.00024C13.6675 5.99941 13.6666 5.99774 13.6666 5.99691C13.6666 5.89524 13.6441 5.79441 13.6066 5.69857C13.5916 5.66024 13.5675 5.63024 13.5475 5.59524C13.5233 5.55191 13.5066 5.50607 13.4741 5.46691L9.30748 0.466909C9.14248 0.269409 8.90581 0.166908 8.66748 0.166908C8.47915 0.166908 8.28998 0.230242 8.13415 0.360242C7.78081 0.654408 7.73248 1.18024 8.02748 1.53357L11.055 5.16691L1.16748 5.16691C0.707482 5.16691 0.334149 5.54024 0.334149 6.00024C0.334149 6.46024 0.707482 6.83357 1.16748 6.83357Z"
                          fill={clients.length > 6 ? "#31366B" : "#C1C5E4"}
                        />
                      </svg>
                    </button>
                  </div>
                )}
              </>
            )}
          </div>
        )}
        <AnimatePresence>
          {clientOrHouseholdToArchive && (
            <ArchiveConfirmationModal
              clientOrHousehold={clientOrHouseholdToArchive}
              onClose={() => setClientOrHouseholdToArchive(undefined)}
              refetch={() => {
                // we're the last client of the page so go to the previous page
                if (page > 1 && clients?.length === 1) {
                  setPage(page - 1)
                  return Promise.resolve(true)
                } else {
                  return refetch()
                }
              }}
            />
          )}
        </AnimatePresence>
      </div>
    </>
  )
}

export default ClientsListPage
