const GoalIcon = ({ backgroundColor }: { backgroundColor?: string }) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="24" height="24" rx="12" fill={backgroundColor} />
    <path fillRule="evenodd" clipRule="evenodd" d="M12 17.7C15.148 17.7 17.7 15.148 17.7 12C17.7 8.85198 15.148 6.3 12 6.3C8.85198 6.3 6.3 8.85198 6.3 12C6.3 15.148 8.85198 17.7 12 17.7ZM12 18C15.3137 18 18 15.3137 18 12C18 8.68629 15.3137 6 12 6C8.68629 6 6 8.68629 6 12C6 15.3137 8.68629 18 12 18Z" fill="#7D694E"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M11.925 13.2C12.5877 13.2 13.125 12.6627 13.125 12C13.125 11.3373 12.5877 10.8 11.925 10.8C11.2623 10.8 10.725 11.3373 10.725 12C10.725 12.6627 11.2623 13.2 11.925 13.2ZM11.925 13.5C12.7534 13.5 13.425 12.8284 13.425 12C13.425 11.1716 12.7534 10.5 11.925 10.5C11.0966 10.5 10.425 11.1716 10.425 12C10.425 12.8284 11.0966 13.5 11.925 13.5Z" fill="#7D694E"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M11.925 15.45C13.8304 15.45 15.375 13.9054 15.375 12C15.375 10.0946 13.8304 8.55 11.925 8.55C10.0196 8.55 8.475 10.0946 8.475 12C8.475 13.9054 10.0196 15.45 11.925 15.45ZM11.925 15.75C13.9961 15.75 15.675 14.0711 15.675 12C15.675 9.92893 13.9961 8.25 11.925 8.25C9.85393 8.25 8.175 9.92893 8.175 12C8.175 14.0711 9.85393 15.75 11.925 15.75Z" fill="#7D694E"/>
    <path d="M12.1875 12C12.1875 12.145 12.07 12.2625 11.925 12.2625C11.78 12.2625 11.6625 12.145 11.6625 12C11.6625 11.855 11.78 11.7375 11.925 11.7375C12.07 11.7375 12.1875 11.855 12.1875 12Z" fill="#7D694E"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M17.1223 16.3138C17.0698 16.3779 16.9753 16.3873 16.9112 16.3348L11.7485 12.0785C11.6844 12.026 11.675 11.9315 11.7275 11.8674C11.78 11.8034 11.8745 11.794 11.9386 11.8465L17.1013 16.1027C17.1654 16.1552 17.1748 16.2497 17.1223 16.3138Z" fill="#7D694E"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M16.9125 16.297C16.9125 16.2141 16.9797 16.147 17.0625 16.147H17.8313C17.9141 16.147 17.9813 16.2141 17.9813 16.297C17.9813 16.3798 17.9141 16.447 17.8313 16.447H17.0625C16.9797 16.447 16.9125 16.3798 16.9125 16.297Z" fill="#7D694E"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M17.0625 16.1376C17.1453 16.1376 17.2125 16.2048 17.2125 16.2876V17.0563C17.2125 17.1392 17.1453 17.2063 17.0625 17.2063C16.9797 17.2063 16.9125 17.1392 16.9125 17.0563V16.2876C16.9125 16.2048 16.9797 16.1376 17.0625 16.1376Z" fill="#7D694E"/>
  </svg>
)

export default GoalIcon
