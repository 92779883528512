import clsx from "clsx"
import { add, format, intervalToDuration } from "date-fns"
import { AnimatePresence } from "framer-motion"
import { useState } from "react"
import AmountFrequencyInput from "../../../../components/AmountFrequencyInput/AmountFrequencyInput"
import Loading from "../../../../components/ClientProfile/Loading/Loading"
import DatePicker from "../../../../components/DatePicker/DatePicker"
import DollarIcon from "../../../../components/DolarIcon/DollarIcon"
import ErrorMessage from "../../../../components/Error/ErrorMessage"
import NumberInput from "../../../../components/NumberInput/NumberInput"
import { FEATURE_ALREADY_RETIRED, FEATURE_TOOLTIP_WEALTH_AND_INCOME_FORMS } from "../../../../config/features"
import { Client, GoalDetail, InvestmentGoal } from "../../../../models/Client"
import { Household } from "../../../../models/Household"
import infoIcon from "../../assets/images/info-icon.svg"
import editIcon from "../assets/editIcon.svg"
import RMJTextTooltip from "../components/RMJTextTooltip/RMJTextTooltip"
import ToggleButton from "../components/ToggleButton/ToggleButton"
import { GoalExplorerStatuses } from "../utils/validation"
import EditPlanningHorizonModal from "./EditPlanningHorizonModal"
import { RetirementIncomeFormErrors, RetirementIncomeFormValues } from "./RetirementIncomeUtils"
import GoalCardMenu from "../../../advisor/Results/Goals/GoalCardMenu"
import chevronDown from "../../../../assets/icons/chevron-down.svg"
interface Props {
  client?: Client
  household?: Household
  errors: RetirementIncomeFormErrors
  outsideIM?: boolean
  onChange: (values: Partial<RetirementIncomeFormValues>) => void
  onSubmit: () => void
  setStatus: (status: GoalExplorerStatuses) => void
  status?: GoalExplorerStatuses
  values: RetirementIncomeFormValues
  goalType?: InvestmentGoal
  calculateResults?: (goalType: InvestmentGoal) => void
  goal?: GoalDetail
  onDelete?: () => void
}

const RetirementIncomeForm = ({
  client,
  household,
  errors,
  onChange,
  onSubmit,
  status,
  values,
  goalType,
  calculateResults,
  outsideIM,
  goal,
  onDelete
}: Props) => {
  const [isPlanningHorizonOpen, setIsPlanningHorizonOpen] = useState(false)
  const [showInfoTooltip, setShowInfoTooltip] = useState(false)
  const clientOrHousehold = client ?? household

  return (
    <div className="flex flex-col grow overflow-hidden">
      <div
        className={clsx("grow overflow-auto no-scrollbar", {
          "pointer-events-none": status === "updating"
        })}
      >
        <div className="bg-surface-300 px-5 pt-4 pb-3 mb-4 flex flex-col gap-y-1">
          <div className="flex justify-center gap-x-1 relative">
            <label className="block text-p text-main-600 text-center font-semibold mb-1" htmlFor="wealthGoal">
              {values.alreadyRetired ? "Retirement drawdown target" : "Retirement income target"}
            </label>
            {clientOrHousehold?.goals?.goalDetails && goal && !outsideIM && (
              <GoalCardMenu
                client={client}
                household={household}
                goal={goal}
                onDelete={() => onDelete?.()}
                icon={<img src={chevronDown} className="flex-shrink-0" alt="" aria-hidden />}
              />
            )}
          </div>
            <AmountFrequencyInput
              id="retirementTarget"
              inputFieldClassName="!py-2 text-sm"
              selectClassName="w-[107px] text-sm"
              amountValue={values.targetAmount}
              onAmountChange={(value) => onChange({ targetAmount: value })}
              frequencyValue={values.targetFrequency ?? "annually"}
              onFrequencyChange={(value) => onChange({ targetFrequency: value })}
              error={errors.targetAmount}
            />
          {FEATURE_ALREADY_RETIRED && (
            <ToggleButton
              id="alreadyRetired"
              toggleState={!!values.alreadyRetired}
              onClick={() => {
                const retiredStatus = !values.alreadyRetired
                onChange({ alreadyRetired: retiredStatus })
                if (calculateResults) {
                  calculateResults(retiredStatus ? "retirementDrawdown" : "retirementIncome")
                }
              }}
              label={<span className="font-semibold">Already in retirement</span>}
              className="flex-row-reverse !w-full justify-between"
            />
          )}
        </div>
        <div className="flex flex-col px-5 gap-y-4">
          <NumberInput
            inputClassName="!py-2 text-sm"
            label="Investment amount"
            disableAutoComplete
            name="investmentAmount"
            value={values.investmentAmount}
            prefix={
              <div className="pl-3">
                <DollarIcon />
              </div>
            }
            onChange={(value) => onChange({ investmentAmount: value })}
            error={errors.investmentAmount}
          />
          {goalType === "retirementIncome" && (
            <AmountFrequencyInput
              id="investmentContribution"
              inputFieldClassName="!py-2 text-sm"
              selectClassName="w-[107px] text-sm"
              label="Investment contribution"
              amountValue={values.investmentContribution}
              onAmountChange={(value) => onChange({ investmentContribution: value })}
              frequencyValue={values.investmentContributionFrequency ?? "annually"}
              onFrequencyChange={(value) => onChange({ investmentContributionFrequency: value })}
              error={errors.investmentContribution}
            />
          )}
          <div>
            <AmountFrequencyInput
              id="otherIncomeInRetirement"
              inputFieldClassName="!py-2 text-sm"
              selectClassName="w-[107px] text-sm"
              label="Other income in retirement"
              amountValue={values.otherIncome}
              onAmountChange={(value) => onChange({ otherIncome: value })}
              frequencyValue={values.otherIncomeFrequency ?? "annually"}
              onFrequencyChange={(value) => onChange({ otherIncomeFrequency: value })}
              error={errors.otherIncome}
            />
            <small className="block text-sm text-main-600 mt-1">e.g. property income, other investments</small>
          </div>
          <ToggleButton
            id="includeAgePension"
            toggleState={!!values.includeAgePension}
            onClick={() => {
              const includeAgePension = !values.includeAgePension
              onChange({ includeAgePension })
            }}
            label={<span className="font-semibold">Include Age Pension</span>}
            className="flex-row-reverse !w-full justify-between"
          />
          {goalType === "retirementDrawdown" && (
            <div>
              <NumberInput
                inputClassName="!py-2"
                className="text-sm"
                label="Legacy goal"
                disableAutoComplete
                name="estateGoal"
                value={values.estateGoal}
                prefix={
                  <div className="pl-3">
                    <DollarIcon />
                  </div>
                }
                onChange={(value) => onChange({ estateGoal: value })}
                error={errors.estateGoal}
              />
              <small className="block text-sm text-main-600 mt-1">e.g. money left for children or charities</small>
            </div>
          )}
          <fieldset>
            <legend className="text-sec text-main-600 font-semibold mb-1">{client ? `${client.firstName}'s date of birth` : "Date of birth"}</legend>
            <div className="flex gap-x-3">
              {values.members.map(({ id, dob, retirementAge, retirementAgeMonths }) => {
                const member = household ? household?.members.find((m) => m.id === id)?.client : client
                return (
                  <div className="flex-1 flex-col w-full" key={member?._id}>
                    <DatePicker
                      id={`${member?._id}-dob`}
                      label={household ? <span className="text-sm font-semibold">{member?.firstName}</span> : null}
                      inputClassName="!py-2 text-sm"
                      value={dob ? new Date(dob) : null}
                      onChange={(value: Date | null, rawDate?: string) => {
                        onChange({
                          members: values.members.map((member) => {
                            return member.id === id
                              ? {
                                  ...member,
                                  dob: value,
                                  dobRaw: rawDate,
                                  retirementDate:
                                    value && retirementAge ? add(new Date(value), { years: retirementAge ?? 0, months: retirementAgeMonths ?? 0 }) : null
                                }
                              : member
                          })
                        })
                      }}
                      onChangeRaw={(value: string) => {
                        onChange({
                          members: values.members.map((member) => {
                            return member.id === id
                              ? {
                                  ...member,
                                  dobRaw: value
                                }
                              : member
                          })
                        })
                      }}
                      yearsInAdvance={0}
                      yearsInPast={125}
                      error={errors.members?.find((m) => m.id === id)?.dob}
                    />
                  </div>
                )
              })}
            </div>
          </fieldset>
          {goalType === "retirementIncome" && (
            <>
              <fieldset>
                <legend className="text-sec text-main-600 font-semibold">{client ? `${client.firstName}'s retirement age` : "Retirement age"}</legend>
                <div className="mt-1 flex flex-row flex-wrap gap-x-2 w-full">
                  {values.members.map(({ id, dob, retirementAge, retirementAgeMonths, retirementDate }, i) => {
                    const member = household ? household?.members[i].client : client
                    const retirementAgeError = errors.members?.find((m) => m.id === id)?.retirementAge
                    return (
                      <div className="flex-1 w-full" key={member?._id}>
                        {values.members.length > 1 && household?.members[i] && (
                          <label className="text-sm text-main-600 font-semibold mb-1" htmlFor={household ? `retirementAge${i}` : "retirementAge"}>
                            {member?.firstName}
                          </label>
                        )}
                        <div className="flex w-full">
                          <NumberInput
                            inputClassName="!py-2 pr-1 text-sm"
                            className="text-sm !flex-shrink"
                            wrapperClassName="border-r-0"
                            disableAutoComplete
                            name={household ? `retirementAge${i}` : "retirementAge"}
                            value={retirementAge}
                            suffix={<span className="text-sm text-main-400">yrs</span>}
                            aria-label={`${member?.firstName}'s retirement age (years)`}
                            onChange={(value) => {
                              onChange({
                                members: values.members.map((member) => {
                                  return member.id === id
                                    ? {
                                        ...member,
                                        retirementAge: value,
                                        retirementDate: dob ? add(new Date(dob), { years: value ?? 0, months: retirementAgeMonths ?? 0 }) : null
                                      }
                                    : member
                                })
                              })
                            }}
                          />
                          <NumberInput
                            inputClassName="!py-2 pr-1 text-sm"
                            className="text-sm !flex-shrink"
                            disableAutoComplete
                            name={household ? `retirementAgeMonths${i}` : "retirementAgeMonths"}
                            value={retirementAgeMonths}
                            suffix={<span className="text-sm text-main-400">mos</span>}
                            aria-label={`${member?.firstName}'s retirement age (months)`}
                            onChange={(value) => {
                              onChange({
                                members: values.members.map((member) => {
                                  return member.id === id
                                    ? {
                                        ...member,
                                        retirementAgeMonths: value,
                                        retirementDate: dob ? add(new Date(dob!), { years: retirementAge ?? 0, months: value ?? 0 }) : null
                                      }
                                    : member
                                })
                              })
                            }}
                          />
                        </div>
                        <div role="alert">
                          {retirementAgeError && <ErrorMessage id="retirement-age-error" message={errors.members?.find((m) => m.id === id)?.retirementAge} />}
                        </div>
                        <DatePicker
                          id="retirementDate"
                          yearsInPast={0}
                          yearsInAdvance={125}
                          isInline={true}
                          inputClassName="!bg-transparent"
                          customInput={
                            <span className="text-sm text-link-600 hover:text-link-500 underline ml-1">
                              {retirementDate ? `(${format(retirementDate, "dd MMM yyyy")})` : "Choose"}
                            </span>
                          }
                          aria-label="Retirement date"
                          onChange={(value: Date | null) =>
                            onChange({
                              members: values.members.map((member, idx) => {
                                const duration = dob && value ? intervalToDuration({ start: dob, end: value}) : undefined
                                if (idx === i) {
                                  return {
                                    ...member,
                                    retirementDate: value,
                                    retirementAge: duration ? duration.years : undefined,
                                    retirementAgeMonths: duration ? duration.months : undefined
                                  }
                                }
                                return member
                              })
                            })
                          }
                          value={retirementDate || null}
                          popperPlacement="bottom-start"
                        />
                      </div>
                    )
                  })}
                </div>
              </fieldset>
              <div onClick={() => setIsPlanningHorizonOpen(true)} className="flex gap-1 cursor-pointer w-max">
                <img aria-hidden src={editIcon} alt="" />
                <button className="text-xs text-interactive-500">Edit planning horizon</button>
              </div>
            </>
          )}
          <div role="alert">{errors.planningHorizon && <ErrorMessage id="retirement-income-form" message={errors.planningHorizon} />}</div>
        </div>
      </div>
      <div className="px-5 py-4 flex gap-x-4 items-center shadow-sm">
        <button
          className={clsx("btn btn-secondary btn-medium w-full h-12 flex items-center justify-center p-0", {
            "pointer-events-none": status === "updating"
          })}
          onClick={onSubmit}
        >
          {status === "updating" ? <Loading className="h-8 w-full" /> : "Update"}
        </button>
        {FEATURE_TOOLTIP_WEALTH_AND_INCOME_FORMS && (
          <div className="w-5 h-5" onMouseOver={() => setShowInfoTooltip(true)} onMouseOut={() => setShowInfoTooltip(false)}>
            <img className="cursor-pointer" src={infoIcon} alt="information icon" />
          </div>
        )}
        {showInfoTooltip && (
          <div className="absolute bottom-0 w-75 left-70 z-10">
            <RMJTextTooltip>
              <p className="text-sm leading-3">
                This projection assumes that you will stay invested in your current investment for at least the next 10 years, and then you will switch to our
                most conservative investment option
              </p>
            </RMJTextTooltip>
          </div>
        )}
      </div>
      <AnimatePresence>
        {isPlanningHorizonOpen && (
          <EditPlanningHorizonModal
            onClose={() => setIsPlanningHorizonOpen(false)}
            value={values.planningHorizon || 100}
            client={client}
            household={household}
          />
        )}
      </AnimatePresence>
    </div>
  )
}

export default RetirementIncomeForm
