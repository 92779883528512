import clsx from "clsx"
import { useMemo, useState } from "react"
import Loading from "../../../../components/ClientProfile/Loading/Loading"
import DatePicker from "../../../../components/DatePicker/DatePicker"
import DollarIcon from "../../../../components/DolarIcon/DollarIcon"
import NumberInput from "../../../../components/NumberInput/NumberInput"
import { FEATURE_TOOLTIP_WEALTH_AND_INCOME_FORMS } from "../../../../config/features"
import { tt } from "../../../../lib/translations"
import { Client, GoalDetail } from "../../../../models/Client"
import { Household } from "../../../../models/Household"
import { getGoalTitle } from "../../../advisor/Results/Goals/components/CardTitle"
import infoIcon from "../../assets/images/info-icon.svg"
import RMJTextTooltip from "../components/RMJTextTooltip/RMJTextTooltip"
import { GoalExplorerStatuses } from "../utils/validation"
import { GoalFormErrors, GoalFormValues } from "./WealthAccumulationUtils"
import chevronDown from "../../../../assets/icons/chevron-down.svg"
import GoalCardMenu from "../../../advisor/Results/Goals/GoalCardMenu"
import AmountFrequencyInput from "../../../../components/AmountFrequencyInput/AmountFrequencyInput"

interface Props {
  client?: Client
  household?: Household
  errors: GoalFormErrors
  onChange: (values: Partial<GoalFormValues>) => void
  // onChangeYear: (value: string) => void
  onSubmit: () => void
  status?: GoalExplorerStatuses
  values: GoalFormValues
  goal?: GoalDetail
  outsideIM?: boolean
  onDelete?: () => void
}

const WealthAccumulationForm = ({ errors, onChange, onSubmit, status, values, client, household, goal, outsideIM, onDelete }: Props) => {
  const [showInfoTooltip, setShowInfoTooltip] = useState(false)
  const clientOrHousehold = client ?? household
  const title = useMemo(() => {
    if (goal && clientOrHousehold) {
      const title = getGoalTitle({ client, household, goalDetails: clientOrHousehold.goals!.goalDetails!, goal })
      return title ? `${title} target` : tt({ id: `goal-projector-${goal.type}-form-title` })
    } else {
      return "Wealth accumulation goal"
    }
  }, [client, clientOrHousehold, goal, household])

  return (
    <div className="flex flex-col grow overflow-hidden">
      <div
        className={clsx("grow overflow-auto no-scrollbar", {
          "pointer-events-none": status === "updating"
        })}
      >
        <div className="bg-surface-300 px-5 pt-4 pb-3 mb-4 gap-y-1">
          <div className="flex justify-center gap-x-1 mb-1 relative">
            <label className="block text-p text-main-600 font-semibold" htmlFor="wealthGoal">
              {title}
            </label>
            {clientOrHousehold?.goals?.goalDetails && goal && !outsideIM && (
              <GoalCardMenu
                client={client}
                household={household}
                goal={goal}
                onDelete={() => onDelete?.()}
                icon={<img src={chevronDown} className="flex-shrink-0" alt="" aria-hidden />}
              />
            )}
          </div>
          <NumberInput
            inputClassName="!py-2 text-sm"
            disableAutoComplete
            name="wealthGoal"
            value={values.targetAmount}
            prefix={
              <div className="pl-3">
                <DollarIcon />
              </div>
            }
            onChange={(value) => onChange({ targetAmount: value })}
            error={errors.targetAmount}
          />
        </div>

        <div className="flex flex-col px-5 gap-y-4">
          <NumberInput
            className="text-sec"
            inputClassName="!py-2 text-sm"
            label="Investment amount"
            disableAutoComplete
            name="investmentAmount"
            value={values.investmentAmount}
            prefix={
              <div className="pl-3">
                <DollarIcon />
              </div>
            }
            onChange={(value) => onChange({ investmentAmount: value })}
            error={errors.investmentAmount}
          />
          <AmountFrequencyInput
            id="investmentContribution"
            inputFieldClassName="!py-2 text-sm"
            selectClassName="w-[107px] text-sm"
            label="Investment contribution"
            amountValue={values.investmentContribution}
            onAmountChange={(value) => onChange({ investmentContribution: value })}
            frequencyValue={values.investmentContributionFrequency ?? "annually"}
            onFrequencyChange={(value) => onChange({ investmentContributionFrequency: value })}
            error={errors.investmentContribution}
          />
          <DatePicker
            id="target-date"
            inputClassName="!py-2 text-sm"
            label={
              <span className="font-semibold">
                {goal ? tt({ id: `goal-projector-form-${goal?.type}-target-date-label` }) : "Expected withdrawal year"}
              </span>
            }
            value={values.targetDate || null}
            onChange={(value) => {
              onChange({ targetDate: value })
            }}
            onChangeRaw={(value) => {
              onChange({ targetDateRaw: value })
            }}
            yearsInAdvance={75}
            error={errors.targetDate}
          />
        </div>
      </div>
      <div className="px-5 py-4 flex gap-x-4 items-center shadow-sm">
        <button
          className={clsx("btn btn-secondary btn-medium w-full h-10 flex items-center justify-center p-0", {
            "pointer-events-none": status === "updating"
          })}
          onClick={onSubmit}
        >
          {status === "updating" ? <Loading className="h-8 w-full" /> : "Update"}
        </button>
        {FEATURE_TOOLTIP_WEALTH_AND_INCOME_FORMS && (
          <div className="w-5 h-5" onMouseOver={() => setShowInfoTooltip(true)} onMouseOut={() => setShowInfoTooltip(false)}>
            <img className="cursor-pointer" src={infoIcon} alt="information icon" />
          </div>
        )}
        {showInfoTooltip && (
          <div className="absolute bottom-0 w-75 left-70 z-10">
            <RMJTextTooltip>
              <p className="text-sm leading-3">
                This projection assumes that you will stay invested in your current investment for at least the next 10 years, and then you will switch to our
                most conservative investment option
              </p>
            </RMJTextTooltip>
          </div>
        )}
      </div>
    </div>
  )
}

export default WealthAccumulationForm
