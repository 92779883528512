import { intervalToDuration } from "date-fns"
import differenceInYears from "date-fns/differenceInYears"
import parse from "date-fns/parse"
import { ClientUpdateRequest } from "../../../../api/clients"
import { HouseholdUpdateRequest } from "../../../../api/households"
import { validateDob } from "../../../../lib/clients"
import { DATE_FORMAT } from "../../../../lib/date"
import { Client, FrequencyOption, GoalDetail, InvestmentGoal } from "../../../../models/Client"
import { Household } from "../../../../models/Household"
import { validateAnnualContribution, validateInvestmentAmount } from "../utils/validation"

export interface RetirementIncomeFormValues {
  investmentContribution?: number
  investmentContributionFrequency?: FrequencyOption
  investmentAmount?: number
  alreadyRetired?: boolean
  includeAgePension?: boolean
  targetAmount?: number
  targetFrequency?: FrequencyOption
  otherIncome?: number
  otherIncomeFrequency?: FrequencyOption
  members: {
    id?: string
    dob?: Date | null
    dobRaw?: string
    retirementAge?: number
    retirementAgeMonths?: number
    retirementDate?: Date | null
  }[]
  pensionAmount?: number
  pensionAmountFrequency?: FrequencyOption
  planningHorizon?: number
  estateGoal?: number
}

export interface RetirementIncomeFormErrors {
  investmentContribution?: string
  investmentAmount?: string
  targetAmount?: string
  otherIncome?: string
  members?: {
    id?: string
    dob?: string
    retirementAge?: string
    retirementAgeMonths?: string
  }[]
  pensionAmount?: string
  planningHorizon?: string
  estateGoal?: string
}

export const validateRetirementIncomeForm = ({ values }: { values: RetirementIncomeFormValues }): { errors: RetirementIncomeFormErrors; isValid: boolean } => {
  const { investmentContribution, investmentAmount, targetAmount, otherIncome, members, planningHorizon, estateGoal } = values
  const errors: Partial<RetirementIncomeFormErrors> = {}

  errors.investmentAmount = validateInvestmentAmount(investmentAmount)
  errors.investmentContribution = validateAnnualContribution(investmentContribution)

  if (targetAmount) {
    if (targetAmount < 0 || targetAmount > 1000000000000) {
      errors.targetAmount = "Value must be less than $1T"
    }
  } else {
    errors.targetAmount = "Retirement income target is required"
  }

  if (otherIncome !== null && otherIncome !== undefined) {
    if (otherIncome < 0 || otherIncome > 1000000000000) {
      errors.otherIncome = "Value must be less than $1T"
    }
  }

  if (planningHorizon) {
    const retirementAge = Math.max(...members.map(({ retirementAge }) => retirementAge!))
    if (retirementAge > planningHorizon) {
      errors.planningHorizon = "Retirement age must be less than planning horizon"
    }
  }
  if (estateGoal) {
    if (estateGoal > investmentAmount!) {
      errors.estateGoal = "Legacy goal must be less than investment amount"
    }
  }
  const memberErrors = members.map(({ id, dob, dobRaw, retirementAge, retirementAgeMonths }) => {
    const dobError = validateRequiredDob(dob, dobRaw)

    return {
      id,
      dob: dobError,
      retirementAge: validateRetirementAge({ retirementAge, retirementAgeMonths, dobError, dob })
    }
  })
  const anyMemberError = memberErrors.find((memberError) => memberError.dob || memberError.retirementAge)
  const isValid = Object.values(errors).every((x) => x === null || x === undefined || x === "") && !anyMemberError

  return {
    errors: {
      ...errors,
      members: memberErrors
    },
    isValid
  }
}
export const validateRetirementDrawdownForm = ({ values }: { values: RetirementIncomeFormValues }): { errors: RetirementIncomeFormErrors; isValid: boolean } => {
  let isValid = true
  const { targetAmount, investmentAmount, otherIncome, estateGoal } = values
  const errors: Partial<RetirementIncomeFormErrors> = {}
  errors.investmentAmount = validateInvestmentAmount(investmentAmount)
  if (targetAmount) {
    if (targetAmount < 0 || targetAmount > 1000000000000) {
      errors.targetAmount = "Value must be less than $1T"
    }
  } else {
    errors.targetAmount = "Retirement income target is required"
  }
  if (otherIncome !== null && otherIncome !== undefined) {
    if (otherIncome < 0 || otherIncome > 1000000000000) {
      errors.otherIncome = "Value must be less than $1T"
    }
  }

  if (estateGoal) {
    if (estateGoal > investmentAmount!) {
      errors.estateGoal = "Legacy goal must be less than investment amount"
    }
  }
  isValid = Object.values(errors).every((x) => x === null || x === undefined || x === "")
  return {
    errors,
    isValid
  }
}

const validateRetirementAge = ({
  retirementAge,
  retirementAgeMonths,
  dobError,
  dob,
  dobRaw
}: { retirementAge?: number; retirementAgeMonths?: number; dobError?: string; dob?: Date | null; dobRaw?: string } = {}) => {
  if (!dobError && (dob || dobRaw)) {
    const currentAge = getAge(dob, dobRaw)
    if (currentAge) {
      if (retirementAge === null || retirementAge === undefined) {
        return "Required"
      } else if (retirementAge < currentAge + 1) {
        return `At least ${currentAge + 1} yrs`
      } else if (retirementAge < 30 || retirementAge > 90) {
        return "Age must be between 30-90 yrs"
      } else if (retirementAgeMonths && retirementAgeMonths > 11) {
        return "Please add a month between 0-11"
      }
    }
  }
}

const getAge = (dob?: Date | null, dobRaw?: string) => {
  const parsed = parseDob(dob, dobRaw)
  return parsed ? differenceInYears(new Date(), parsed) : null
}

const parseDob = (dob?: Date | null, dobRaw?: string) => {
  return dobRaw ? parse(dobRaw, DATE_FORMAT, new Date()) : !dobRaw && dob ? new Date(dob) : null
}

const validateRequiredDob = (dob?: Date | null, dobRaw?: string) => {
  if (dobRaw || dob) {
    const parsed = parseDob(dob, dobRaw)
    if (parsed) {
      return validateDob(parsed)
    }
  } else {
    return "Date of birth is required"
  }
}

export const createFormValues = ({ client, household, goal }: { client?: Client; household?: Household; goal?: GoalDetail }): RetirementIncomeFormValues => {
  const clientOrHousehold = client ?? household
  const memberData = (client: Client) => {
    const duration = client.dob && client.retirementDate ? intervalToDuration({ start: new Date(client.dob), end: new Date(client.retirementDate) }) : undefined
    return {
      id: client._id,
      dob: client.dob ? new Date(client.dob) : undefined,
      dobRaw: undefined,
      retirementAge: duration ? duration.years : client.retirementAge,
      retirementAgeMonths: duration ? duration.months : client.retirementAge,
      retirementDate: client.retirementDate ? new Date(client.retirementDate) : null
    }
  }
  return {
    investmentContribution: goal ? goal.contributions?.value : clientOrHousehold?.annualInvestmentContribution,
    investmentContributionFrequency: goal ? goal?.contributions?.frequency : "annually",
    investmentAmount: goal ? goal?.investmentAmount : clientOrHousehold?.investmentAmount,

    alreadyRetired: clientOrHousehold?.alreadyRetired,

    targetAmount: goal ? goal?.targetAmount?.value : clientOrHousehold?.retirementIncomeGoal,
    targetFrequency: goal ? goal?.targetAmount?.frequency : "annually",

    otherIncome: goal ? goal.otherIncome?.value : clientOrHousehold?.otherSourcesRetirementIncome,
    otherIncomeFrequency: goal ? goal.otherIncome?.frequency : "annually",

    members: household ? household.members.map(({ client }) => memberData(client)) : client ? [memberData(client)] : [],
    pensionAmount: goal ? goal.pensionInRetirement?.value : clientOrHousehold?.pensionInRetirement,
    pensionAmountFrequency: goal ? goal.pensionInRetirement?.frequency : "annually",

    planningHorizon: goal ? goal.planningHorizon : clientOrHousehold?.planningHorizon,
    estateGoal: goal ? goal.estateGoal : clientOrHousehold?.estateGoal,
    includeAgePension: goal?.includeAgePension
  }
}

export const createRetirementIncomeRequest = ({
  client,
  values,
  goalType,
  household,
  goal
}: {
  client?: Client
  values: RetirementIncomeFormValues
  goalType: InvestmentGoal
  household?: Household
  goal?: GoalDetail
}) => {
  const {
    investmentContribution,
    investmentContributionFrequency,
    investmentAmount,
    alreadyRetired,
    targetAmount,
    targetFrequency,
    otherIncome,
    otherIncomeFrequency,
    members,
    pensionAmount,
    pensionAmountFrequency,
    planningHorizon,
    estateGoal,
    includeAgePension
  } = values
  const member = members[0]

  const updateRequest: ClientUpdateRequest | HouseholdUpdateRequest = {}
  const clientOrHousehold: Client | Household | undefined = client || household

  if (goal) {
    const goals = {
      ...clientOrHousehold?.goals,
      goalDetails: clientOrHousehold?.goals?.goalDetails?.map((goalDetail) => {
        if (goalDetail.type === goal.type && goalDetail.id === goal.id) {
          return {
            ...goalDetail,
            targetAmount: {
              value: targetAmount,
              frequency: targetFrequency ?? "annually"
            },
            investmentAmount,
            contributions: {
              value: investmentContribution,
              frequency: investmentContributionFrequency ?? "annually"
            },
            otherIncome: {
              value: otherIncome,
              frequency: otherIncomeFrequency ?? "annually"
            },
            planningHorizon,
            estateGoal,
            includeAgePension
          }
        } else {
          return goalDetail
        }
      })
    }
    updateRequest.goals = goals
  } else if (clientOrHousehold) {
    if (investmentContribution !== clientOrHousehold.annualInvestmentContribution) {
      updateRequest.annualInvestmentContribution = investmentContribution
    }

    if (investmentAmount !== clientOrHousehold.investmentAmount) {
      updateRequest.investmentAmount = investmentAmount
    }

    if (otherIncome !== clientOrHousehold.otherSourcesRetirementIncome) {
      updateRequest.otherSourcesRetirementIncome = otherIncome
    }

    if (member.retirementAge !== clientOrHousehold.retirementAge) {
      updateRequest.retirementAge = member.retirementAge
    }

    if (targetAmount !== clientOrHousehold.retirementIncomeGoal) {
      updateRequest.retirementIncomeGoal = targetAmount
    }

    if (alreadyRetired !== clientOrHousehold.alreadyRetired) {
      updateRequest.alreadyRetired = alreadyRetired
    }

    if (pensionAmount !== clientOrHousehold.pensionInRetirement) {
      updateRequest.pensionInRetirement = pensionAmount
    }

    if (estateGoal !== clientOrHousehold.estateGoal) {
      updateRequest.estateGoal = estateGoal
    }

    if (goalType !== clientOrHousehold.primaryInvestmentGoal) {
      updateRequest.primaryInvestmentGoal = goalType
    }
  }

  return updateRequest
}
