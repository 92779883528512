import clsx from "clsx"
import { useQuery } from "react-query"
import { NavLink, Outlet, Route, Routes, useParams } from "react-router-dom"
import axiosInstance from "../../../../api/axiosInstance"
import Loading from "../../../../components/ClientProfile/Loading/Loading"
import NotImplemented from "../NotImplemented"
import UpdateLicensee from "./UpdateLicensee"
import ViewLicensee from "./ViewLicensee"
import ErrorMessage from "../../../../components/Error/ErrorMessage"

const ManageLicensee = () => {
  const { licenseeId } = useParams()
  const licensee = useQuery(["licensee", licenseeId], () => axiosInstance.get<{_id: string, name: string}[]>(`${import.meta.env.VITE_APP_API_BASE || ""}/api/admin/licensees`)
    .then(res => res.data.find(l => l._id === licenseeId)))

  const linkStyle = ({ isActive }: { isActive: boolean }) => clsx("px-4 border-b-2 transition-all hover:bg-interactive-100 py-2", isActive ? "border-interactive-600" : "border-white hover:border-interactive-200")
  
  return (<div className="pg-ctr pg-ctr-py-lg w-full h-full flex flex-col gap-y-4" >
    <h1 className="px-10 text-h1">{licensee.isSuccess ? licensee.data?.name : licenseeId}</h1>
    <nav className="w-full -ml-4 px-10 flex text-h5 text-interactive-600">
      <NavLink to="" end className={linkStyle}>Firms & Stats</NavLink>
      <NavLink to="edit" className={linkStyle}>Update</NavLink>
      <NavLink to="archive" className={_ => clsx(linkStyle(_), "ml-auto text-alt-critical")}>Archive</NavLink>
    </nav>
    <div className="w-full h-full overflow-y-hidden">
      <Outlet />
      {licensee.isLoading && <Loading />}
      {licensee.isError && <ErrorMessage id="manage-licensee" message={String(licensee.error)} />}
      {licensee.isSuccess &&
        <Routes>
          <Route path="edit" element={<UpdateLicensee licensee={licensee} />}/>
          <Route path="archive" element={<NotImplemented />}/>
          <Route index element={<ViewLicensee licensee={licensee.data!} />}/>
        </Routes>
      }
    </div>
  </div>
  )
}

export default ManageLicensee