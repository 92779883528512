import { AnimatePresence } from "framer-motion"
import React, { useCallback, useContext, useMemo, useState } from "react"
import { addNote } from "../../../../api/notes"
import ClientActivityRow from "../../../../components/ClientProfile/ClientStatusRow/ClientGameActivityRow"
import ClientStatusRow from "../../../../components/ClientProfile/ClientStatusRow/ClientGameStatusRow"
import HouseholdEFPSection from "../../../../components/ClientProfile/HouseholdEFPSection"
import Modal from "../../../../components/Modal/Modal"
import ReportsList, { ReportsListItem } from "../../../../components/ReportsList/ReportsList"
import { ClientHouseholdCacheContext } from "../../../../contexts/ClientHouseholdCacheContext"
import { FirmContext } from "../../../../contexts/FirmContext"
import { useTheme } from "../../../../contexts/ThemeContext"
import useTrackViewEvent from "../../../../hooks/useTrackViewEvent"
import { areDecisionsIncoherent, latest, latestGamesByType, supportedGames } from "../../../../lib/clients"
import { tt } from "../../../../lib/translations"
import { Client, Game } from "../../../../models/Client"
import { Household } from "../../../../models/Household"
import { AuthContext } from "../../../../views/auth/AuthContext"
import ReprofileConfirmationModal from "../../../advisor/components/ReprofileConfirmationModal/ReprofileConfirmationModal"
import Section from "../../../clients/components/Profile/components/Section"
import HouseholdNotes from "./components/HouseholdNotes/HouseholdNotes"
import HouseholdReporting from "./components/HouseholdReporting/HouseholdReporting"
import HouseholdScoreTrends from "./components/HouseholdScoreTrends/HouseholdScoreTrends"
import HouseholdGameStatusRow from "./components/HouseholdStatusRow/HouseholdGameStatusRow"
import ActivitySettingsModal from "../../../clients/components/ActivitySettingsModal"
import settingsIcon from "../../../../assets/icons/settings.svg"
import SelectedInvestment from "../../../clients/components/Profile/components/SelectedInvestment/SelectedInvestment"

const HouseholdProfile = ({ household }: { household: Household }) => {
  const status = household.status
  const [isNoteSaving, setIsNoteSaving] = useState<boolean>(false)
  const { firm } = useContext(FirmContext)
  const { sessionInfo } = useContext(AuthContext)
  const { replace } = useContext(ClientHouseholdCacheContext)
  const [showActivitySettingsModal, setShowActivitySettingsModal] = useState(false)
  const [showReprofileModalClient, setShowReprofileModalClient] = useState<{ client: Client; game: Game }>()
  const trackViewEvent = useTrackViewEvent()
  const theme = useTheme()

  const createReportItem = useCallback(
    ({ date, title, url }: { date: string; title: string; url: string }) => ({
      date,
      id: url ?? date, // use as id as it's unique
      link: (
        <a
          className="text-riskComfort-500 grow underline"
          onClick={() => trackViewEvent({ action: "click", category: "check_results", label: household._id })}
          href={url}
          rel="noreferrer"
          target="_blank"
        >
          View report
        </a>
      ),
      title
    }),
    [household._id, trackViewEvent]
  )

  const reports = useMemo(() => {
    const nextReports: ReportsListItem[] = []
    const householdCreatedDate = new Date(household.createdAt)

    household.members?.forEach(({ client }) => {
      client.games
        ?.filter(({ played }) => !!played && new Date(played) <= householdCreatedDate) // only include games played before household was created
        .forEach((game) => {
          if (game.gameReportUrl) {
            nextReports.push(
              createReportItem({ date: game.played, title: `Risk Preferences Report (${client.firstName} ${client.lastName})`, url: game.gameReportUrl })
            )
          }
          if (game.summaryReportUrl) {
            nextReports.push(
              createReportItem({
                date: game.summaryDate,
                title: `Investment Meeting Report (${client.firstName} ${client.lastName})`,
                url: game.summaryReportUrl
              })
            )
          }
        })
    })

    household.games?.forEach((game) => {
      if (game.gameReportUrl) {
        nextReports.push(createReportItem({ date: game.played, title: `Risk Preferences Report (${household.name} household)`, url: game.gameReportUrl }))
      }
      if (game.summaryReportUrl) {
        nextReports.push(
          createReportItem({ date: game.summaryDate, title: `Investment Meeting Report (${household.name} household)`, url: game.summaryReportUrl })
        )
      }
    })

    return nextReports
  }, [createReportItem, household.createdAt, household.games, household.members, household.name])

  const onAddNote = useCallback(
    (note: string) => {
      setIsNoteSaving(true)
      addNote(sessionInfo!, "household", household._id, note).then((res) => {
        replace(res)
        setIsNoteSaving(false)
      })
    },
    [sessionInfo, household._id, replace]
  )

  const availableGames = supportedGames(household, firm)

  return (
    <div className="w-full">
      {!firm?.config?.supportedGames || firm?.config?.supportedGames?.length === 0 || theme.pages.profile.multiActivityView === "list" ? (
        <>
          <h1 className="text-h2 text-main-600 font-semibold col-start-1 col-span-12 px-19">{`${household.name} household`}</h1>
          {availableGames.map((availableGame) => {
            const allMembersPlayed = household.members.every(({ client }) => {
              const game = latest(client, availableGame)
              return game?.played
            })
            return (
              <React.Fragment key={availableGame}>
                <Section
                  title={availableGames.length > 1 ? tt({ id: `profile-page-status-${availableGame}-title` }) : undefined}
                  titleElement={availableGames.length > 1 ? "h3" : undefined}
                  className="px-19 py-5"
                  showBottomBorder={true}
                >
                  {allMembersPlayed && <HouseholdGameStatusRow household={household} game={latest(household, availableGame)!} />}
                  {!["Archived", "Up to date"].includes(status!) &&
                    household.members.map((member, index) => {
                      const gameByType = latestGamesByType(member.client, firm)
                      return gameByType[availableGame] && areDecisionsIncoherent(gameByType[availableGame]) && member.client.status !== "Up for review" ? (
                        <ClientActivityRow
                          key={index}
                          client={member.client}
                          game={gameByType[availableGame]!}
                          household={household}
                          index={index}
                          setShowReprofileModal={setShowReprofileModalClient}
                        />
                      ) : (
                        <ClientStatusRow
                          key={index}
                          client={member.client}
                          gameType={availableGame}
                          game={gameByType[availableGame]}
                          household={household}
                          index={index}
                          setShowReprofileModal={setShowReprofileModalClient}
                        />
                      )
                    })}
                </Section>
              </React.Fragment>
            )
          })}
        </>
      ) : (
        <HouseholdEFPSection household={household} />
      )}

      {(firm?.config?.supportedGames?.length ?? 0) >= 1 && (
        <Section
          className="py-6 px-19"
          showBottomBorder
          button={
            <button
              className="activity-settings-btn btn btn-text btn-text-md flex w-auto items-center align-middle gap-x-2"
              onClick={() => setShowActivitySettingsModal(true)}
            >
              <img className="activity-settings-btn__img" src={settingsIcon} alt="" aria-hidden />
              Activity settings
            </button>
          }
        />
      )}

      <Section title="Selected investment risk level" titleElement="h3" className="px-19 py-9">
        <SelectedInvestment household={household} />
      </Section>

      <Section title="Score trends" titleElement="h3" className="px-19 py-9">
        <HouseholdScoreTrends household={household} />
      </Section>

      <Section title="Report history" titleElement="h3" className="px-19 py-9">
        <ReportsList reports={reports} />
      </Section>

      <Section title="Activity tracking + notes" titleElement="h3" className="px-19 py-9">
        <HouseholdNotes household={household} isDisabled={household.status === "Archived"} onAddNote={onAddNote} isNoteSaving={isNoteSaving} />
      </Section>

      <Section showBottomBorder={false} title="Regulatory reporting" titleElement="h3" className="px-19 pt-9">
        <HouseholdReporting household={household} />
      </Section>

      <AnimatePresence>
        {showActivitySettingsModal && (
          <Modal className="max-w-screen-md" contentClassName="!px-10 w-full w-modal" handleClose={() => setShowActivitySettingsModal(false)}>
            <ActivitySettingsModal onClose={() => setShowActivitySettingsModal(false)} household={household} />
          </Modal>
        )}
        {showReprofileModalClient && (
          <ReprofileConfirmationModal
            client={showReprofileModalClient.client}
            game={showReprofileModalClient.game}
            onClose={() => setShowReprofileModalClient(undefined)}
          />
        )}
      </AnimatePresence>
    </div>
  )
}

export default HouseholdProfile
