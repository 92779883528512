import { useMemo } from "react"
import { useTheme } from "../../../../contexts/ThemeContext"
import { Client, Game } from "../../../../models/Client"
import { allAssetClasses, assetTypeWeights } from "../../../clients/reports/ComfortMatchPage"
import { DoughnutChart } from "../../../rmjourney/components/DoughnutChart/DoughnutChart"
import { comfortMatch } from "../../../../lib/clients"

const RiskPersonaSummary = ({ client, game }: { client: Client; game: Game }) => {
  const theme = useTheme()
  const match = comfortMatch(game)

  const assetWeights = useMemo(() => {
    if (game?.assetClasses && match) {
      const assetClasses = allAssetClasses(game?.assetClasses ?? []) || {}
      return assetTypeWeights(match.portfolio?.components, assetClasses)
    } else {
      return []
    }
  }, [game, match])

  return (
    <div className="persona-summary w-full h-auto relative rounded-3 bg-interactive-500 p-5">
      <div className="w-full flex justify-center">
        <div className="persona-summary-bg-image max-w-xl absolute left-2 top-4 md:left-9 md:top-9">
          <div className="w-full h-52">
            <img src={theme.pages.profile?.riskPersona?.bgImage} className="w-full h-52" alt="" aria-hidden />
          </div>
        </div>
        <div className="risk-results-page-top-card-content z-0">
          <div className="text-white flex flex-col justify-center items-center">
            <div className="mb-6 w-24">
              <DoughnutChart
                data={assetWeights.map(({ total }) => total)}
                colors={assetWeights.map(({ assetClass }) => theme.colors.assetClassConfigurations[assetClass].color)}
              />
            </div>
            <p className="text-sec leading-3 uppercase">{client.firstName} behaved like a</p>
            <p className="text-h2 leading-7o5 !font-bold">{match?.portfolio?.name} investor</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default RiskPersonaSummary
