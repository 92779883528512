import React, { UIEventHandler, useEffect, useState } from 'react';
import { useMutation, useQuery } from "react-query";
import axiosInstance from "../../../api/axiosInstance";
import Loading from "../../../components/ClientProfile/Loading/Loading";
import { IntegrationConfig } from "../../../models/Firm";
import warning from "./assets/warning.svg";
import ErrorMessage from '../../../components/Error/ErrorMessage';

type ImportResult = {
  status: 'pending' | 'processing' | 'success' | 'failed',
  stats?: { created: number, unchanged: number, merged: number },
  error?: string,
  resumeToken?: string,
} & Progress

type Progress = {
  currentStep?: string | number;
  totalSteps?: number;
  stepDescription?: string;
}

const ImportFromIntellifloFlow: React.FunctionComponent<{ integration: IntegrationConfig, handleClose: UIEventHandler }> = ({ integration, handleClose }) => {

  const [resumeToken, setResumeToken] = useState<string | null>(null); // Track the resume token
  const [progress, setProgress] = useState<Progress>({})
  const [done, setDone] = useState(false);

  const triggerImportJob = useMutation((integration: string) => 
    axiosInstance.post<{ jobId: string }>(`${import.meta.env.VITE_APP_API_BASE}/api/crm/${integration}/import-clients`)
      .then(res => res.data.jobId)
  );

  // const usePollJobStatus = (jobId: string | undefined, poll: boolean, resumeToken?: string | null) => 

  const { mutate: triggerJob, data: jobId, isLoading: isTriggering } = triggerImportJob;

  const { data: importResult, isLoading: isPolling, isError, error: importError } = useQuery(['importStatus', jobId, resumeToken], () => {
    const url = resumeToken 
      ? `${import.meta.env.VITE_APP_API_BASE}/api/crm/import-clients/status/${jobId}?resumeToken=${resumeToken}` 
      : `${import.meta.env.VITE_APP_API_BASE}/api/crm/import-clients/status/${jobId}`;
    
    return axiosInstance.get<ImportResult>(url).then(res => res.data);
  }, {
    enabled: !!jobId && !done,
    staleTime: 0,
    // refetchInterval: 3000,
  });

  // Store the new resumeToken on every response
  useEffect(() => {
    if (importResult?.resumeToken) {
      setResumeToken(importResult.resumeToken); // Set the latest resumeToken
    }
    if (importResult) {
      setProgress(importResult)
    }
    setDone(importResult?.status === "success" || importResult?.status === "failed");
  }, [importResult]);

  const handleImport = () => {
    setResumeToken(null); // Reset the resume token on new import job start
    triggerJob(integration.id);
  };
  
  return (
    <div className="w-modal text-main-500">
      {triggerImportJob.isIdle && (
        <div className="flex flex-col items-center justify-stretch gap-y-6 min-h-[420px]">
          <img src={warning} />
          <h2 className="text-h2 text-main-600">Import your clients from {integration.id}</h2>
          <div className="flex flex-col gap-y-3">
            <p>
              This will pull all your clients from intelliflo into the Risk Profiling Suite.
              <br/>
              The following information will be pulled through:
            </p>
            <ol className="w-full list-decimal list-inside pl-1">
              <li>Name</li>
              <li>Date of Birth</li>
              <li>Email address</li>
              <li>Investment amount</li>
            </ol>
            <p>Do you want to import clients?</p>
          </div>
          <div className="flex items-center justify-around gap-x-5 pb-1">
            <button className="w-[174px] btn btn-secondary btn-large text-sec" onClick={handleClose}>Cancel</button>
            <button className="w-[174px] btn btn-primary btn-large text-sec" onClick={handleImport}>Import</button>
          </div>
        </div>
      )}
      {(isTriggering || isPolling || importResult?.status === "pending" || importResult?.status === "processing") && (
        <div className="flex flex-col items-center justify-center gap-y-6  min-h-[420px]">
          <Loading type="spinner-var" className="w-18 h-18 m-2"/>
          <div className="text-h3">
            Importing clients...
          </div>
          <div className="text-sec text-main-300 -mt-3 w-full flex gap-x-6 justify-center">
            <span>{progress?.stepDescription}</span>
            {progress?.currentStep && progress?.totalSteps && <span>{progress?.currentStep} /  {progress?.totalSteps}</span>}
          </div>
        </div>
      )}
      {importResult?.status === "success" && (
        <div className="w-full flex flex-col items-start justify-stretch gap-y-6">
          <h2 className="text-h2 text-main-600">Import successful</h2>
          {importResult.stats?.created || importResult.stats?.merged
            ? (
              <>
                <p>The following clients were successfully imported from intelliflo:</p>
                <ul className="w-full flex flex-col items-start justify-stetch gap-y-2">
                  <li className="w-full px-4 py-2.5 bg-surface-100">{importResult.stats?.created} client{importResult.stats?.created !== 1 && "s"} created</li>
                  <li className="w-full px-4 py-2.5 bg-surface-100">{importResult.stats?.merged} client{importResult.stats?.merged !== 1 && "s"} updated</li>
                </ul>
              </>              
            )
            : <p>All client data is already up to date.</p>
          }
          <div className="w-full flex items-center justify-around gap-x-5 pb-1">
            <button className="w-[174px] btn btn-primary btn-large text-sec" onClick={handleClose}>Finish</button>
          </div>
        </div>
      )}
      {importResult?.status === "failed" && (
        <div className="w-full flex flex-col items-start justify-stretch gap-y-6">
          <h2 className="text-h2 text-main-600">Import failed</h2>
          <ErrorMessage id="import-intelliflo" message={importResult.error} />
          <div className="w-full flex items-center justify-around gap-x-5 pb-1">
            <button className="w-[174px] btn btn-primary btn-large text-sec" onClick={handleClose}>Finish</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ImportFromIntellifloFlow;
