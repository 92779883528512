export const getPositions = ({
    value,
    minValue = 0,
    maxValue = 0,
    startPos,
    endPos,
}: any) => {
    // (endPos - startPos) * ((value - minValue) / (maxValue - minValue))
    // e.g: 50 / 100 * 702 = 351
    const result = (value - minValue) * (endPos - startPos) / (maxValue - minValue) + startPos;
    return isNaN(result) ? 0 : result;
};

export const rounding = (num: number, step: number) => Math.round(num / step) * step; 
export const getMinValueByScale = (num: number, step: number) => Math.floor(num / step) * step; 
export const getMaxValueByScale = (num: number, step: number) => Math.ceil(num / step) * step;

// export const getRangeValues = (start: number, end: number, scale: number) => {
//     // get rounding value of base
//     const baseValue = rounding(start, scale);
//     // get number of scale
//     const length = Math.floor((end - start) / scale);
//     // get all scale array
//     return Array.from({length}, (val: any, index: number) => index * scale + baseValue); 
// };
export const getRangeValues = (start: number, end: number, scale: number) => {
    // get rounding value of base
    const baseValue = rounding(start, scale);
    // get number of scale
    const length = Math.floor((end - start) / scale) + 1;
    // get all scale array
    return Array.from({length}, (val: any, index: number) => index * scale + baseValue); 
};

export const map = (
    value: number,
    minValue: number,
    maxValue: number,
    startPos: number,
    endPos: number,
) => getPositions({value, minValue, maxValue, startPos, endPos}); 
  
export const range = getRangeValues; 

export const isEmpty = (value: { trim?: any; } | null | undefined) => {
    return  value === undefined ||
            value === null ||
            (typeof value === "object" && Object.keys(value).length === 0) ||
            (typeof value === "string" && value.trim().length === 0)
}

export const deepEqual = (obj1: any, obj2: any): boolean => {
  if (obj1 === obj2) return true
  
  if (obj1 instanceof Date && obj2 instanceof Date) {
    return obj1.getTime() === obj2.getTime()
  }
  if (typeof obj1 !== "object" || typeof obj2 !== "object" || obj1 === null || obj2 === null) return false

  const keys1 = Object.keys(obj1)
  const keys2 = Object.keys(obj2)

  if (keys1.length !== keys2.length) return false

  for (const key of keys1) {
    if (!keys2.includes(key) || !deepEqual(obj1[key], obj2[key])) {
      return false
    }
  }

  return true
}

export const legacyCopyToClipboard = async (value: string) => {
  console.log("permission denied, using the legacy exec copy command")
  const textarea = document.createElement("textarea")
  textarea.value = value
  document.body.appendChild(textarea)
  textarea.select()
  try {
    document.execCommand("copy")
  } catch (err) {
    console.error("Failed to copy text:", err)
    throw err
  } finally {
    document.body.removeChild(textarea)
  }
}