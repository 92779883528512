import { clsx } from "clsx"
import { AnimatePresence } from "framer-motion"
import { useCallback, useState } from "react"
import Modal from "../../../../../components/Modal/Modal"
import NumberInput from "../../../../../components/NumberInput/NumberInput"
import TextInput from "../../../../../components/TextInput/TextInput"
import useClientFinances from "../../../../../hooks/useClientFinances"
import { latest } from "../../../../../lib/clients"
import { Asset, AssetType, Client, MAX_AMOUNT, PersonalFinancialDetails } from "../../../../../models/Client"
import { Household } from "../../../../../models/Household"
import plus from "../../../../advisor/assets/images/plus-circle.svg"
import AssetRow from "./AssetRow"
import { tt } from "../../../../../lib/translations"

const AssetsList = ({
  client,
  household,
  pfsData,
  isEditing,
  onChange
}: {
  client?: Client
  household?: Household
  pfsData?: PersonalFinancialDetails
  isEditing: boolean
  onChange: (updated: PersonalFinancialDetails) => void
}) => {
  const clientOrHousehold = (client ?? household)!
  const game = latest(clientOrHousehold, "pfs")
  const lastUpdatedDate = (clientOrHousehold.pfs || game?.pfs) && (clientOrHousehold.pfs?.updatedAt ?? game?.played)
  const finances = useClientFinances(clientOrHousehold)

  const [shouldValidate, setShouldValidate] = useState(false)
  const [isAddModalOpen, setIsAddModalOpen] = useState<boolean>(false)
  const [customItemAddition, setCustomItemAddition] = useState<Asset>({ type: "custom" })

  const standardAssets: AssetType[] = ["property", "vehicles", "retirementAccounts", "investments", "checkingAccounts", "savingsAccounts"]
  const customAssets = pfsData?.assets?.filter((asset) => asset.type === "custom") || []

  const handleAddOpen = useCallback(() => {
    setCustomItemAddition({
      type: "custom",
      name: "",
      amount: undefined
    })
    setIsAddModalOpen(true)
    setShouldValidate(false)
  }, [])

  const handleRemoveCustomAsset = (id?: string) => {
    onChange({
      ...pfsData,
      assets: pfsData?.assets?.filter((a) => a.id !== id)
    })
  }

  return (
    <>
      <div className="w-1/2">
        <h3 className="text-sec font-semibold">Assets</h3>
        <div>
          {standardAssets
            .filter((type) => !lastUpdatedDate || isEditing || (pfsData?.assets?.find((asset) => asset.type === type)?.amount ?? 0) > 0)
            .map((assetType) => (
              <AssetRow
                key={`standard-${assetType}`}
                asset={{ type: assetType }}
                isEditing={isEditing}
                lastUpdatedDate={lastUpdatedDate}
                pfsData={pfsData}
                onChange={onChange}
              />
            ))}

          {customAssets.map((asset) => (
            <AssetRow
              key={`custom-${asset.id}`}
              asset={asset}
              isEditing={isEditing}
              lastUpdatedDate={lastUpdatedDate}
              pfsData={pfsData}
              onChange={onChange}
              onRemove={handleRemoveCustomAsset}
            />
          ))}

          {isEditing && (
            <button className="btn btn-text flex items-center gap-x-1 my-2 text-sec" onClick={handleAddOpen}>
              <img src={plus} alt="" aria-hidden />
              Add another asset
            </button>
          )}
        </div>
        <div className="flex justify-between items-center bg-surface-100 py-4 px-3 text-sec font-semibold">
          <h4>Total assets</h4>
          <span className={clsx(!lastUpdatedDate ? "text-main-300" : "text-data-blue-600")}>{!lastUpdatedDate ? "$" : finances.formatted.assets}</span>
        </div>
      </div>

      <AnimatePresence>
        {isAddModalOpen && (
          <Modal className="w-modal max-w-md" contentClassName="!px-10 w-full" handleClose={() => setIsAddModalOpen(false)}>
            <div className="w-full flex flex-col gap-6 text-left items-center">
              <div className="flex flex-col gap-y-6 w-full">
                <h2 className="w-full text-h3 font-semibold text-grey-600">Add another asset</h2>
                <TextInput
                  label="Asset name"
                  name="add-custom-asset-name"
                  note="Maximum 30 chars"
                  onChange={(value) => setCustomItemAddition((prev) => ({ ...prev, name: value }))}
                  placeholder="Asset name"
                  value={customItemAddition?.name}
                  focusOnLoad
                  maxLength={30}
                  error={shouldValidate && !customItemAddition.name ? "Please enter a name" : undefined}
                />
                <NumberInput
                  label="Amount"
                  name="add-custom-asset-amt"
                  max={MAX_AMOUNT}
                  onChange={(value) => setCustomItemAddition((prev) => ({ ...prev, amount: value }))}
                  prefix={<div className="text-h4 ml-4 leading-none">$</div>}
                  value={customItemAddition?.amount}
                  placeholder="0"
                  error={
                    shouldValidate && (!customItemAddition.amount || customItemAddition.amount > MAX_AMOUNT)
                      ? tt({ id: "pfs-results-page-input-amount-range-error-text" })
                      : undefined
                  }
                />
              </div>
              <div className="flex flex-col gap-y-3 w-full">
                <button
                  className="btn btn-primary btn-medium"
                  onClick={() => {
                    setShouldValidate(true)
                    if (customItemAddition.name && customItemAddition.amount !== undefined) {
                      onChange({
                        ...pfsData,
                        assets: [
                          ...(pfsData?.assets ?? []),
                          {
                            type: "custom",
                            name: customItemAddition.name,
                            amount: customItemAddition.amount,
                            id: `custom_asset_` + ((pfsData?.assets?.filter((a) => a.type === "custom").length ?? 0) + 1)
                          }
                        ]
                      })
                      setIsAddModalOpen(false)
                    }
                  }}
                >
                  Add
                </button>
                <button className="btn btn-secondary btn-medium" onClick={() => setIsAddModalOpen(false)}>
                  Cancel
                </button>
              </div>
            </div>
          </Modal>
        )}
      </AnimatePresence>
    </>
  )
}

export default AssetsList
