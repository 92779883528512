import { ChangeEvent, useEffect, useMemo, useState } from "react"
import { ActionItem, ActionItemKey, ActionItemLabels } from "../../../../models/ActionItem"
import { Client } from "../../../../models/Client"
import { Household } from "../../../../models/Household"
import checkmarkImage from "./assets/checkmark.svg"
import deleteImage from "./assets/delete.svg"
import plusImage from "./assets/plus.svg"
import clsx from "clsx"
import { latest } from "../../../../lib/clients"
import ErrorMessage from "../../../../components/Error/ErrorMessage"

interface Props {
  client?: Client
  household?: Household
  title: string
  contentClassName?: string
  onAddRemoveNote: (items: ActionItem[]) => void
}

const RMJActionItemNotes = ({ client, household, title, contentClassName, onAddRemoveNote }: Props) => {
  const [status, setStatus] = useState<"pending" | "generating" | "complete" | "error">("pending")
  const [isAddStepOpen, setIsAddStepOpen] = useState(false)
  const [currentStepText, setCurrentStepText] = useState<string>("")
  const [actionItems, setActionItems] = useState<ActionItem[]>([])
  const clientOrHousehold = client ?? household
  const game = latest(clientOrHousehold!, "risk")

  useEffect(() => {
    setActionItems([
      game?.actionItems?.find((item) => item.key === "comfortable-with-investment-risk") || {
        key: "comfortable-with-investment-risk",
        value: false
      },
      game?.actionItems?.find((item) => item.key === "increase-goal-achieving-likelihood") || {
        key: "increase-goal-achieving-likelihood",
        value: false
      },
      game?.actionItems?.find((item) => item.key === "increase-goal-achieving-likelihood-of-investment-drawdown") || {
        key: "increase-goal-achieving-likelihood-of-investment-drawdown",
        value: false
      },
      ...(game?.actionItems || []).filter(
        (item) =>
          item.key === "freetext" ||
          (ActionItemLabels[item.key] && item.value && item.key !== "comfortable-with-investment-risk" && item.key !== "increase-goal-achieving-likelihood")
      )
    ])
  }, [game?.actionItems])

  const onStepChange = (evt: ChangeEvent<HTMLInputElement>) => {
    setCurrentStepText(evt.target.value)
  }

  const onAddStep = () => {
    const items = [
      ...actionItems,
      {
        key: "freetext" as ActionItemKey,
        value: currentStepText
      }
    ]
    setActionItems(items)
    setCurrentStepText("")
    setIsAddStepOpen(false)
    onAddRemoveNote(items)
  }

  const onRemoveStep = (item: ActionItem) => {
    const items = actionItems.filter((x) => (x.key === "freetext" ? x.value !== item.value : x.key !== item.key))
    setActionItems(items)
    onAddRemoveNote(items)
  }

  const relevantItems = useMemo(
    () =>
      actionItems.filter((item) => item.value && item.key !== "comfortable-with-investment-risk" && item.key !== "increase-goal-achieving-likelihood").length,
    [actionItems]
  )

  return (
    <div className="border-b border-surface-300 mb-6 pb-6">
      <h3 className="text-p text-main-600 font-bold mb-3">{title}</h3>
      <ul className={clsx("mb-2 -mx-1", contentClassName)}>
        {actionItems
          .filter(
            (item) =>
              item.value &&
              item.key !== "comfortable-with-investment-risk" &&
              item.key !== "increase-goal-achieving-likelihood" &&
              item.key !== "increase-goal-achieving-likelihood-of-investment-drawdown"
          )
          .map((item, i) => (
            <li className="flex items-center justify-between text-main-500 text-p hover:bg-interactive-100 p-1" key={`${item.key}-${i}`}>
              <div className="flex">
                <img alt="" className="mr-1" src={checkmarkImage} />
                {item.key === "freetext" ? item.value : ActionItemLabels[item.key]}
              </div>
              <button
                onClick={() => onRemoveStep(item)}
                aria-label={`Remove step "${item.key === "freetext" ? item.value : ActionItemLabels[item.key]}"`}
                title="Remove step"
              >
                <img alt="" aria-hidden="true" className="mr-1" src={deleteImage} />
              </button>
            </li>
          ))}
      </ul>

      {isAddStepOpen ? (
        <div className="w-full flex flex-col">
          <div className="w-2/3 flex flex-col gap-1 items-end">
            <input
              className="w-full text-p text-main-500 placeholder:text-main-300 border border-main-400 outline-none focus:shadow-input px-3 py-2"
              maxLength={35}
              onChange={onStepChange}
              placeholder="Start typing..."
              type="text"
              value={currentStepText}
              aria-label="Add step"
              autoFocus
              onKeyDown={(evt: React.KeyboardEvent<HTMLInputElement>) => {
                if (evt.key === "Enter") {
                  onAddStep()
                }
              }}
            />
            <div className="w-full flex gap-2">
              <div className="flex grow gap-2 mt-1">
                <button className="btn btn-secondary btn-small" disabled={currentStepText === ""} onClick={onAddStep} aria-label="Add step">
                  Add
                </button>
                <button className="btn btn-text btn-small" onClick={() => setIsAddStepOpen(false)}>
                  Cancel
                </button>
              </div>
              <span className="text-sm text-main-500">35 characters</span>
            </div>
          </div>
        </div>
      ) : relevantItems < 7 ? (
        <button className="btn btn-text btn-small flex items-center" onClick={() => setIsAddStepOpen(true)}>
          <img alt="" className="mr-1" src={plusImage} aria-hidden />
          <span>Add</span>
        </button>
      ) : (
        <ErrorMessage id="rmj-action-notes" message="Only seven items can be added" />
      )}
    </div>
  )
}

export default RMJActionItemNotes
