import { Theme } from "../../../config/theme"
import { latest } from "../../../lib/clients"
import { tt } from "../../../lib/translations"
import { Client } from "../../../models/Client"
import { Household } from "../../../models/Household"
import { LegendData } from "../components/LineChart/LineChart"
import { createClientDataPoints } from "../utils/charts"
import { ATTITUDE_TO_RISK_SCORES } from "./assets/attitudeToRiskData"

export type RiskAttitudeDetails =
  | {
      title: string
      graphTitle: string
      description?: string
      note?: string
    }
  | undefined

export const riskAttitudeDetails = (client?: Client, household?: Household, translationContext: "advisor" | "client" = "client"): RiskAttitudeDetails => {
  const getRiskDetails = (client: Client) => {
    const riskTolerance = latest(client, "risk")?.risk?.results?.R?.riskTolerance
    const graphTitle = tt({
      id: `risk-attitude-graph-title-individual-${translationContext}`,
      message: "Your Attitude to Risk compared to other investors",
      values: { firstName: client.firstName }
    })

    if (riskTolerance === undefined || riskTolerance === null) {
      return undefined
    } else if (riskTolerance <= 24) {
      return {
        title: tt({
          id: `risk-attitude-title-low-individual-${translationContext}`,
          message: "You prefer lower risk investments",
          values: { firstName: client.firstName }
        }),
        description: tt({
          id: `risk-attitude-description-low-individual-${translationContext}`,
          message: "Your decisions showed us you likely prefer a prudent approach to investment risk that delivers lower levels of risk and opportunity.",
          values: { firstName: client.firstName }
        }),
        note: tt({
          id: `risk-attitude-note-low-individual-${translationContext}`,
          message: "Among investors with wealth levels similar to yours, approximately 30% also prefer low amounts of risk.",
          values: { firstName: client.firstName }
        }),
        graphTitle
      }
    } else if (riskTolerance > 24 && riskTolerance <= 59) {
      return {
        title: tt({
          id: `risk-attitude-title-medium-individual-${translationContext}`,
          message: "You're okay with some investment risk",
          values: { firstName: client.firstName }
        }),
        description: tt({
          id: `risk-attitude-description-medium-individual-${translationContext}`,
          message: "Your decisions showed us that you're still reasonably comfortable with taking risk when seeking investment opportunities.",
          values: { firstName: client.firstName }
        }),
        note: tt({
          id: `risk-attitude-note-medium-individual-${translationContext}`,
          message: "Among investors with wealth levels similar to yours, approximately 40% also take mid-level amounts of risk in pursuit of higher returns.",
          values: { firstName: client.firstName }
        }),
        graphTitle
      }
    } else if (riskTolerance > 59) {
      return {
        title: tt({
          id: `risk-attitude-title-high-individual-${translationContext}`,
          message: "You're okay with investment risk",
          values: { firstName: client.firstName }
        }),
        description: tt({
          id: `risk-attitude-description-high-individual-${translationContext}`,
          message: "Your decisions showed us that you're still comfortable with taking risk when seeking investment opportunities.",
          values: { firstName: client.firstName }
        }),
        note: tt({
          id: `risk-attitude-note-high-individual-${translationContext}`,
          message:
            "Among investors with wealth levels similar to yours, approximately 30% are willing to take high amounts of risk in pursuit of higher returns.",
          values: { firstName: client.firstName }
        }),
        graphTitle
      }
    }
  }
  if (client) {
    return getRiskDetails(client)
  } else if (household) {
    const completedMembers = household?.members?.filter((member) => member.client.games?.find((game) => game.gameType === "risk")?.played)
    if (completedMembers.length === 1) {
      return getRiskDetails(completedMembers[0].client)
    } else if (completedMembers.length > 1) {
      const tolerances = completedMembers.map((member) => {
        return latest(member.client, "risk")?.risk.results?.R.riskTolerance
      })
      if (tolerances.every((tolerance) => tolerance !== undefined && tolerance !== null)) {
        const graphTitle = tt({
          id: `risk-attitude-graph-title-household-${translationContext}`,
          message: "Your Attitude to Risk compared to other investors",
          values: { firstName: completedMembers[0].client.firstName, secondName: completedMembers[1].client.firstName }
        })
        if (Math.abs(Math.max(...tolerances) - Math.min(...tolerances)) > 10) {
          return {
            title: tt({
              id: `risk-attitude-title-household-different-${translationContext}`,
              message: "Each of you is willing to tolerate different amounts of risk",
              values: { firstName: completedMembers[0].client.firstName, secondName: completedMembers[1].client.firstName }
            }),
            graphTitle
          }
        } else {
          return {
            title: tt({
              id: `risk-attitude-title-household-similar-${translationContext}`,
              message: "You are both willing to tolerate similar amounts of risk",
              values: { firstName: completedMembers[0].client.firstName, secondName: completedMembers[1].client.firstName }
            }),
            graphTitle
          }
        }
      }
    }
  }
  return undefined
}

export const riskAttitudeAxisValues = (theme: Theme, client?: Client, household?: Household) => {
  if (client || household) {
    const scores = client
      ? [latest(client, "risk")?.risk?.results?.R?.riskTolerance ?? 0]
      : household?.members
          .filter((member) => member.client.games?.find((game) => game.gameType === "risk")?.played)
          .map(({ client }) => latest(client, "risk")?.risk?.results?.R?.riskTolerance ?? 0)
    const riskAttitudeScoreXYaxisValue = scores!.map((s) => ATTITUDE_TO_RISK_SCORES.find((_, i) => i >= s)!)
    const xAxis = {
      labels: [
        { id: "1", text: "0" },
        { id: "2", text: "10" },
        { id: "3", text: "20" },
        { id: "4", text: "30" },
        { id: "5", text: "40" },
        { id: "6", text: "50" },
        { id: "7", text: "60" },
        { id: "8", text: "70" },
        { id: "9", text: "80" },
        { id: "10", text: "90" },
        { id: "11", text: "100" }
      ],
      title: tt({ id: "risk-attitude-x-axis-title", message: "Attitude to Risk score" })
    }
    const yAxis = {
      labels: [
        { id: "1", text: "0%" },
        { id: "2", text: "5%" },
        { id: "3", text: "10%" },
        { id: "4", text: "15%" },
        { id: "5", text: "20%" },
        { id: "6", text: "25%" }
      ],
      title: tt({ id: "risk-attitude-y-axis-title", message: "Percentage of investors" })
    }
    const highlightDataPoints = client
      ? createClientDataPoints({ theme: theme, clients: [client], values: riskAttitudeScoreXYaxisValue })
      : createClientDataPoints({
          theme: theme,
          clients: household?.members.filter((member) => member.client.games?.find((game) => game.gameType === "risk")?.played).map(({ client }) => client),
          values: riskAttitudeScoreXYaxisValue
        })

    const highlightAreas = [
      {
        endXPercent: 24,
        id: "low",
        label: "Low",
        startXPercent: 0
      },
      {
        endXPercent: 59,
        id: "mid",
        label: "Mid",
        startXPercent: 24
      },
      {
        endXPercent: 100,
        id: "high",
        label: "High",
        startXPercent: 59
      }
    ]

    const legend: LegendData[] | undefined = client
      ? [
          {
            color: highlightDataPoints[0].color,
            id: `data-point-${client?.firstName}`,
            text: client?.firstName
          }
        ]
      : household?.members
          .filter((member) => member.client.games?.find((game) => game.gameType === "risk")?.played)
          .map(({ client }, i) => {
            return {
              color: highlightDataPoints[i].color,
              id: `data-point-${client?.firstName}`,
              text: client?.firstName
            }
          })

    return {
      attitudeToRiskScore: ATTITUDE_TO_RISK_SCORES,
      riskAttitudeScoreXYaxisValue,
      highlightAreas,
      highlightDataPoints,
      legend: legend ?? undefined,
      xAxis,
      yAxis
    }
  }
}
