import { Trans } from "@lingui/macro"
import { useMemo } from "react"
import { useParams } from "react-router"
import Loading from "../../../components/ClientProfile/Loading/Loading"
import LoadingError from "../../../components/ClientProfile/LoadingError/LoadingError"
import { useTheme } from "../../../contexts/ThemeContext"
import useClient from "../../../hooks/useClient"
import useHousehold from "../../../hooks/useHousehold"
import { latest } from "../../../lib/clients"
import PatternsModalLink from "../../clients/reports/RiskChartsPage/components/PatternsModalLink"
import peopleIcon from "../assets/images/icon-people.svg"
import LineChart from "../components/LineChart/LineChart"
import { lossSensitivityChartData, lossSensitivityDetails } from "./lossSensitivityAttributes"
import RMJLossSensitivitySpectrum from "./RMJLossSensitivitySpectrum"

const RMJLossSensitivity = ({ translationContext = "client" }: { translationContext?: "advisor" | "client" }) => {
  const { clientId, householdId } = useParams<{ clientId: string; householdId: string }>()
  const { client, isClientLoading, clientLoadError } = useClient(clientId)
  const { household, isHouseholdLoading, householdLoadError } = useHousehold(householdId)
  const theme = useTheme()
  const lossSensitivityMessages = lossSensitivityDetails(client, household, translationContext)
  const chartData = useMemo(() => {
    return lossSensitivityChartData(theme, client, household)
  }, [theme, client, household])

  const showNote = theme.charts?.sensitivityToLoss?.note?.show && lossSensitivityMessages?.rmjNote

  return (
    <div className="rmj-sensitivity-loss w-full h-full overflow-auto no-scrollbar px-4">
      <div className="w-full h-full flex flex-col">
        <div className="flex justify-between">
          <div>
            <p className="text-sec text-main-600 uppercase">
              <Trans id="rmj-loss-sensitivity-title">Sensitivity to Loss</Trans>
            </p>
            {lossSensitivityMessages?.title && <h4 className="text-h3 font-semibold text-main-600 pb-5">{lossSensitivityMessages.title}</h4>}
            {theme.charts?.sensitivityToLoss?.description?.show && lossSensitivityMessages?.description && <p className="mb-6">{lossSensitivityMessages?.description}</p>}
          </div>
          <div className="score flex gap-8">
            {client ? (
              <h1 className="text-h2 text-right text-interactive-400 font-semibold flex flex-col">
                {latest(client, "risk")?.risk.results?.R.lossAversion}
                <span className="text-sm text-interactive-400">Sensitivity score</span>
              </h1>
            ) : (
              household?.members
                .filter((member) => member.client.games?.find((game) => game.gameType === "risk")?.played)
                .map(({ client }, i) => (
                  <h1 className="text-h2 text-right text-interactive-400 font-semibold flex flex-col items-end" key={client._id}>
                    <span className="flex items-center gap-2">
                      {chartData?.legend && <div className="w-2 h-2 rounded-full" style={{ backgroundColor: chartData.legend[i].color }} />}
                      {latest(client, "risk")?.risk.results?.R.lossAversion}
                    </span>
                    <span className="text-sm text-interactive-400">Sensitivity score</span>
                  </h1>
                ))
            )}
          </div>
        </div>
        {(isClientLoading || isHouseholdLoading) && (
          <div className="absolute top-0 bottom-0 left-0 right-0 flex justify-center items-center">
            <Loading />
          </div>
        )}
        <div className="rmj-sensitivity-loss-line-chart h-full w-full grow">
          {showNote && (
            <div className="loss-sensitivity-note flex gap-x-1 bg-surface-200 p-3 pr-5 items-center text-sec mb-12 w-full">
            <img className="h-4 w-4" alt="" src={theme.reports?.riskChartsPage?.attitudeToRisk?.note?.icon} aria-hidden />
            <p>{lossSensitivityMessages.rmjNote}</p>
          </div>
          )}
          {(client || household) &&
            chartData &&
            (theme.charts?.sensitivityToLoss?.showAsSpectrum && client ? (
              <RMJLossSensitivitySpectrum client={client} />
            ) : (
              <>
                <LineChart
                  className="min-h-[360px] mt-12"
                  xAxis={chartData.xAxis}
                  yAxis={chartData.yAxis}
                  gameType={theme.gameType}
                  icon={peopleIcon}
                  type={"loss"}
                  legend={chartData.legend}
                  series={[
                    {
                      id: "1",
                      title: client?.firstName,
                      values: chartData.sensitivityToLossData,
                      color: theme.colors.scoreChartColor ?? "#7882D1"
                    }
                  ]}
                  highlightDataPoints={chartData.highlightDataPoints}
                  highlightAreas={[
                    {
                      endXPercent: 19.5,
                      id: "low",
                      label: "Composed",
                      startXPercent: 0
                    },
                    {
                      endXPercent: 49.5,
                      id: "mid",
                      label: "Somewhat unsettled",
                      startXPercent: 19.5
                    },
                    {
                      endXPercent: 100,
                      id: "high",
                      label: "Unsettled",
                      startXPercent: 49.5
                    }
                  ]}
                  title={lossSensitivityMessages?.graphTitle}
                  showScoreAsDataPoint={theme.charts?.sensitivityToLoss?.showScoreAsDataPoint}
                />
                <PatternsModalLink type="loss" />
              </>
            ))}
          {clientLoadError && <LoadingError message={"Could not load client"} />}
          {householdLoadError && <LoadingError message={"Could not load household"} />}
        </div>
      </div>
    </div>
  )
}

export default RMJLossSensitivity
