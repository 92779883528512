import clsx from "clsx"
import React from "react"
interface Props {
  isDark?: boolean
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
  label?: React.ReactNode | string
  checked: boolean
  className?: string
  id: string
  error?: boolean
  name: string
  value?: string
  invertColors?: boolean
}

const RadioButton = ({ isDark = false, onChange, label, checked, className, id, error, name, value, invertColors = false }: Props) => {
  return (
    <div className={clsx("flex flex-wrap items-start w-auto", className)}>
      <div>
        <input
          name={name}
          readOnly
          id={id}
          className={clsx(
            "radio-input appearance-none relative flex justify-center border items-center rounded-full w-6 h-6 cursor-pointer",
            "after:content-[''] after:rounded-full after:w-3 after:h-3 after:ease-out after:duration-300",
            {
              "dark": isDark,
              "text-alt-critical bg-interactive-500 border-alt-critical checked:after:bg-alt-critical shadow-alt-critical": isDark && error,
              "text-red-500 border-red-500 checked:after:bg-interactive-500 shadow-err": !isDark && error,
              "border-alt-interactive-400 bg-interactive-500 checked:after:bg-alt-interactive-400": isDark && !error
            },
            `${!isDark && !error ? (invertColors ? "border-white checked:after:bg-white" : "border-interactive-500 checked:after:bg-interactive-500") : ""}`
          )}
          type="radio"
          onChange={onChange}
          checked={checked}
          value={value}
        />
      </div>

      {label && (
        <label
          htmlFor={id}
          className={clsx("pl-3 flex-1 text-p cursor-pointer", {
            "text-white": isDark && !error,
            "text-main-600": !isDark && !error
          })}
        >
          {label}
        </label>
      )}
    </div>
  )
}

export default RadioButton
