const Checkmark = () => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.5795 7.00458L8.77284 12.0046C8.61617 12.2104 8.37367 12.3321 8.11534 12.3337H8.10951C7.85367 12.3337 7.61201 12.2154 7.45367 12.0138L5.42701 9.42458C5.14367 9.06292 5.20701 8.53875 5.56951 8.25542C5.93117 7.97125 6.45617 8.03458 6.73951 8.39792L8.10034 10.1363L11.2537 5.99542C11.532 5.62958 12.0545 5.55792 12.422 5.83708C12.7878 6.11625 12.8587 6.63875 12.5795 7.00458ZM9.00034 0.667084C4.39784 0.667084 0.667007 4.39792 0.667007 9.00042C0.667007 13.6021 4.39784 17.3337 9.00034 17.3337C13.6028 17.3337 17.3337 13.6021 17.3337 9.00042C17.3337 4.39792 13.6028 0.667084 9.00034 0.667084Z"
        fill={`var(--color-highlight-500, #5462D6)`}
      />
    </svg>
  )
}

export default Checkmark
