import { Outlet, Route, Routes, useLocation } from "react-router"
import RMJRiskAttitude from "./riskAttitude/RMJRiskAttitude"
import RMJYourDecisions from "./yourDecisions/RMJYourDecisions"
import RMJPortfolioComfort from "./portfolioComfort/RMJPortfolioComfort"
import RMJOverviewPage from "./overview/RMJOverviewPage"
import RMJAdviceOverviewPage from "./adviceOverview/RMJAdviceOverviewPage"
import RMJGoalExplorerPage from "./goalExplorer/RMJGoalExplorerPage"
import RMJLossSensitivity from "./lossSensitivity/RMJLossSensitivity"
import RMJSummaryPage from "./summary/RMJSummaryPage"
import { clsx } from "clsx"

const RMJourney = () => {
  const location = useLocation()
  const isGoalExplorer = location.pathname.includes("goal-explorer")

  return (
    <div
      className={clsx("relative pg-ctr w-full h-full flex flex-col overflow-auto no-scrollbar", {
        "p-10": !isGoalExplorer
      })}
    >
      <Outlet />
      <Routes>
        <Route path="overview" element={<RMJOverviewPage />} />
        <Route path="advice-overview" element={<RMJAdviceOverviewPage />} />
        <Route path="your-decisions" element={<RMJYourDecisions />} />
        <Route path="risk-attitude" element={<RMJRiskAttitude />} />
        <Route path="loss-sensitivity" element={<RMJLossSensitivity />} />
        <Route path="portfolio-comfort" element={<RMJPortfolioComfort />} />
        <Route path="goal-explorer" element={<RMJGoalExplorerPage />} />
        <Route path="summary" element={<RMJSummaryPage />} />
      </Routes>
    </div>
  )
}

export default RMJourney
