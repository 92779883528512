import { clsx } from "clsx"
import { AnimatePresence } from "framer-motion"
import { useCallback, useState } from "react"
import AmountFrequencyInput from "../../../../../components/AmountFrequencyInput/AmountFrequencyInput"
import Modal from "../../../../../components/Modal/Modal"
import TextInput from "../../../../../components/TextInput/TextInput"
import useClientFinances from "../../../../../hooks/useClientFinances"
import { latest } from "../../../../../lib/clients"
import { Client, IncomeSource, IncomeSourceType, MAX_AMOUNT, PersonalFinancialDetails } from "../../../../../models/Client"
import { Household } from "../../../../../models/Household"
import plus from "../../../../advisor/assets/images/plus-circle.svg"
import IncomeRow from "./IncomeRow"

const IncomeList = ({
  client,
  household,
  pfsData,
  isEditing,
  onChange
}: {
  client?: Client
  household?: Household
  pfsData?: PersonalFinancialDetails
  isEditing: boolean
  onChange: (updated: PersonalFinancialDetails) => void
}) => {
  const clientOrHousehold = (client ?? household)!
  const game = latest(clientOrHousehold, "pfs")
  const lastUpdatedDate = (clientOrHousehold.pfs || game?.pfs) && (clientOrHousehold.pfs?.updatedAt ?? game?.played)
  const finances = useClientFinances(clientOrHousehold)

  const [shouldValidate, setShouldValidate] = useState(false)
  const [isAddModalOpen, setIsAddModalOpen] = useState<boolean>(false)
  const [customItemAddition, setCustomItemAddition] = useState<IncomeSource>({ type: "custom" })

  const standardIncomeSources: IncomeSourceType[] = ["salary", "retirementIncome", "businessIncome", "investmentIncome", "rentalIncome"]
  const customIncomeSources = pfsData?.income?.filter((data) => data.type === "custom") || []

  const handleAddOpen = useCallback(() => {
    setCustomItemAddition({
      type: "custom",
      name: "",
      amount: undefined
    })
    setIsAddModalOpen(true)
    setShouldValidate(false)
  }, [])

  const handleRemove = (id?: string) => {
    onChange({
      ...pfsData,
      income: pfsData?.income?.filter((a) => a.id !== id)
    })
  }

  return (
    <>
      <div className="w-1/2">
        <h3 className="text-sec font-semibold">Income</h3>
        <div>
          {standardIncomeSources
            .filter((type) => !lastUpdatedDate || isEditing || (pfsData?.income?.find((income) => income.type === type)?.amount ?? 0) > 0)
            .map((type) => (
              <IncomeRow
                key={`standard-${type}`}
                income={{ type: type }}
                isEditing={isEditing}
                lastUpdatedDate={lastUpdatedDate}
                pfsData={pfsData}
                onChange={onChange}
              />
            ))}

          {customIncomeSources.map((data) => (
            <IncomeRow
              key={`custom-${data.id}`}
              income={data}
              isEditing={isEditing}
              lastUpdatedDate={lastUpdatedDate}
              pfsData={pfsData}
              onChange={onChange}
              onRemove={handleRemove}
            />
          ))}

          {isEditing && (
            <button className="btn btn-text flex items-center gap-x-1 my-2 text-sec" onClick={handleAddOpen}>
              <img src={plus} alt="" aria-hidden />
              Add another income
            </button>
          )}
        </div>
        <div className="flex justify-between items-center bg-surface-100 py-4 px-3 text-sec font-semibold">
          <h4>Total income</h4>
          <span className={clsx(!lastUpdatedDate ? "text-main-300" : "text-positive-600")}>{!lastUpdatedDate ? "$" : finances.formatted.incomeSources}</span>
        </div>
      </div>

      <AnimatePresence>
        {isAddModalOpen && (
          <Modal className="w-modal max-w-md" contentClassName="!px-10 w-full" handleClose={() => setIsAddModalOpen(false)}>
            <div className="w-full flex flex-col gap-6 text-left items-center">
              <div className="flex flex-col gap-y-6 w-full">
                <h2 className="w-full text-h3 font-semibold text-grey-600">Add another income</h2>
                <TextInput
                  label="Income name"
                  name="add-custom-income-name"
                  note="Maximum 30 chars"
                  onChange={(value) => setCustomItemAddition((prev) => ({ ...prev, name: value }))}
                  placeholder="Income name"
                  value={customItemAddition?.name}
                  focusOnLoad
                  maxLength={30}
                  error={shouldValidate && !customItemAddition.name ? "Please enter a name" : undefined}
                />
                <AmountFrequencyInput
                  id="add-custom-asset-amt"
                  min={0}
                  max={MAX_AMOUNT}
                  placeholder="0"
                  label="Amount"
                  selectContainerClassName="w-40"
                  isError={shouldValidate && (!customItemAddition.amount || customItemAddition.amount > MAX_AMOUNT) ? true : false}
                  frequencyValue={customItemAddition?.frequency ?? "monthly"}
                  onFrequencyChange={(frequency) => setCustomItemAddition((prev) => ({ ...prev, frequency }))}
                  amountValue={customItemAddition?.amount}
                  onAmountChange={(value) => setCustomItemAddition((prev) => ({ ...prev, amount: value }))}
                />
              </div>
              <div className="flex flex-col gap-y-3 w-full">
                <button
                  className="btn btn-primary btn-medium"
                  onClick={() => {
                    setShouldValidate(true)
                    if (customItemAddition.name && customItemAddition.amount !== undefined) {
                      onChange({
                        ...pfsData,
                        income: [
                          ...(pfsData?.income ?? []),
                          {
                            type: "custom",
                            name: customItemAddition.name,
                            amount: customItemAddition.amount,
                            frequency: customItemAddition.frequency ?? "monthly",
                            id: `custom_` + ((pfsData?.income?.filter((a) => a.type === "custom").length ?? 0) + 1)
                          }
                        ]
                      })
                      setIsAddModalOpen(false)
                    }
                  }}
                >
                  Add
                </button>
                <button className="btn btn-secondary btn-medium" onClick={() => setIsAddModalOpen(false)}>
                  Cancel
                </button>
              </div>
            </div>
          </Modal>
        )}
      </AnimatePresence>
    </>
  )
}

export default IncomeList
