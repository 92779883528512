import clsx from "clsx"
import { addMonths, formatISO } from "date-fns"
import { useContext, useEffect, useState } from "react"
import { useParams } from "react-router"
import { investmentMeetingDone } from "../../../../api/advisors"
import { ClientUpdateRequest } from "../../../../api/clients"
import { HouseholdUpdateRequest } from "../../../../api/households"
import Loading from "../../../../components/ClientProfile/Loading/Loading"
import Modal from "../../../../components/Modal/Modal"
import { ClientHouseholdCacheContext } from "../../../../contexts/ClientHouseholdCacheContext"
import useClient from "../../../../hooks/useClient"
import useHousehold from "../../../../hooks/useHousehold"
import { latest } from "../../../../lib/clients"
import { ActionItem, ActionItemLabels } from "../../../../models/ActionItem"
import { InvestmentGoal } from "../../../../models/Client"
import RMJActionItemNotes from "../RMJActionItemNotes/RMJActionItemNotes"
import RMJNextMeetingSelection from "../RMJNextMeetingSelection/RMJNextMeetingSelection"
import generatedImage from "./assets/report-generated-icon.svg"
import successImage from "./assets/success.svg"
import ErrorMessage from "../../../../components/Error/ErrorMessage"

interface Props {
  infoAlert?: string
  onClose: () => void
  portfolio?: string | null
  goalExplorerCompleted?: boolean
  goalType?: InvestmentGoal
}

const RMJFinishModal = ({ onClose, portfolio, goalExplorerCompleted, goalType }: Props) => {
  const { clientId, householdId } = useParams<{ clientId: string; householdId: string }>()
  const { client } = useClient(clientId)
  const { household } = useHousehold(householdId)
  const { updateClient, updateHousehold } = useContext(ClientHouseholdCacheContext)
  const [status, setStatus] = useState<"pending" | "generating" | "complete" | "error">("pending")
  const [nextMeetingDate, setNextMeetingDate] = useState<number>(12)
  const [actionItems, setActionItems] = useState<ActionItem[]>([])
  const clientOrHousehold = client ?? household
  const game = latest(clientOrHousehold!, "risk")

  useEffect(() => {
    setActionItems([
      game?.actionItems?.find((item) => item.key === "comfortable-with-investment-risk") || {
        key: "comfortable-with-investment-risk",
        value: false
      },
      game?.actionItems?.find((item) => item.key === "increase-goal-achieving-likelihood") || {
        key: "increase-goal-achieving-likelihood",
        value: false
      },
      game?.actionItems?.find((item) => item.key === "increase-goal-achieving-likelihood-of-investment-drawdown") || {
        key: "increase-goal-achieving-likelihood-of-investment-drawdown",
        value: false
      },
      ...(game?.actionItems || []).filter(
        (item) =>
          item.key === "freetext" ||
          (ActionItemLabels[item.key] && item.value && item.key !== "comfortable-with-investment-risk" && item.key !== "increase-goal-achieving-likelihood")
      )
    ])
  }, [game?.actionItems])

  const onGenerateReport = () => {
    setStatus("generating")
    const nextActivityDue = formatISO(new Date(addMonths(new Date(), nextMeetingDate)).setHours(0, 0, 0, 0))
    const req: ClientUpdateRequest | HouseholdUpdateRequest = {
      actionItems,
      nextActivityDue
    }

    ;(client ? updateClient(client._id, req) : updateHousehold(household!._id, [], req))
      .then(() => {
        investmentMeetingDone((client ?? household)!.advisorId!, client ? "client" : "household", client ? client._id : household!._id, {
          goalExplorerCompleted,
          nextMeetingMonths: nextMeetingDate,
          generateReport: true
        })
          .then(() => {
            setStatus("complete")
          })
          .catch(() => {
            setStatus("error")
            console.error("error generating the pdf report")
          })
      })
      .catch(() => {
        setStatus("error")
        console.error("error updating action items")
      })
  }

  return (
    <Modal className="max-w-modal" contentClassName="no-scrollbar" handleClose={status === "pending" ? onClose : undefined}>
      <div className="flex flex-col">
        {(status === "pending" || status === "error") && (
          <>
            <h2 className="text-h2 text-main-600 font-semibold text-center mb-6">Thanks</h2>
            <p className="text-main-600 mb-6">
              {clientOrHousehold?.existingClient ? (
                <span>It was great to check-in and get an updated view of your comfort with investment risk.</span>
              ) : (
                <span>Congratulations on taking an important step in your financial journey. Thanks for the opportunity to get to know you better.</span>
              )}
            </p>
            <hr className="mb-6 border-surface-300" />
            <RMJActionItemNotes
              client={client}
              household={household}
              title="Next steps"
              onAddRemoveNote={(items: ActionItem[]) => {
                setActionItems(items)
              }}
            />
            <div className="pb-6">
              <h3 className="text-p text-main-600 font-bold mb-3">Next meeting</h3>
              <fieldset>
                <legend className="text-sec text-main-600 font-semibold mb-1">Select date</legend>
                <RMJNextMeetingSelection
                  onSelect={(selected: number) => {
                    setNextMeetingDate(selected)
                  }}
                />
              </fieldset>
            </div>
            {status === "error" && <ErrorMessage id="rmj-finish-modal" message="Error, please try again." />}
            <div className="flex justify-center gap-4">
              <button className="w-48 btn btn-secondary btn-medium" onClick={onClose}>
                Cancel
              </button>
              <button className="w-48 btn btn-primary btn-medium" onClick={onGenerateReport}>
                Generate report
              </button>
            </div>
          </>
        )}
        {(status === "generating" || status === "complete") && (
          <div className="w-[600px] h-[480px] flex flex-col items-center justify-center">
            <div
              className={clsx({
                hidden: status !== "generating",
                flex: status === "generating"
              })}
            >
              <Loading />
            </div>
            <div
              className={clsx("flex-col items-center justify-center", {
                hidden: status !== "complete",
                flex: status === "complete"
              })}
            >
              <h2 className="flex items-center gap-1 text-sm text-main-500 bg-white border border-interactive-200 shadow-sm p-1 pr-3 mb-10">
                <img alt="" src={generatedImage} />
                <span>Your report has been generated</span>
              </h2>
              <img alt="" className="mb-6" src={successImage} />
              <h3 className="text-h2 text-main-600 font-semibold">Session complete</h3>
            </div>
          </div>
        )}
      </div>
    </Modal>
  )
}

export default RMJFinishModal
