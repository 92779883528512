import clsx from "clsx"
import { useTheme } from "../../../contexts/ThemeContext"
import { Client, PortfolioMapping } from "../../../models/Client"
import { Household } from "../../../models/Household"
import { AssetClass, SubAssetClass } from "../../../models/InvestmentUniverse"
import riskComfortIcon from "../assets/images/riskComfort.svg"
import { DoughnutChart } from "../components/DoughnutChart/DoughnutChart"
import ClientRiskComfortIcon from "./RMJPortfolioComfortChart/components/ClientRiskComfortIcon"
import { CLIENT_NAME_COLOUR_CLASSES } from "./RMJPortfolioComfortMatchListView"
import { AVATAR_ICON_FILL_CLASSES } from "./RMJPortfolioComfort"
import pieChartFillIcon from "./assets/pie-chart-fill.svg"
import { portfolioComfortMatch } from "./comfortMatch"
import { Trans } from "@lingui/react"
import { assetTypeWeights } from "../../clients/reports/ComfortMatchPage"
import AvatarBadges from "../../../components/AvatarBadges/AvatarBadges"

const ComfortMatchListViewPortfolioDetail = ({
  allAssetClasses,
  comfortMatch,
  client,
  household,
  forReport,
  postMeeting,
  portfolioMapping
}: {
  allAssetClasses: {
    [key: string]: AssetClass | SubAssetClass
  }
  comfortMatch: PortfolioMapping
  portfolioMappings: PortfolioMapping[]
  client?: Client
  household?: Household
  forReport: boolean
  postMeeting?: boolean
  portfolioMapping: PortfolioMapping
}) => {
  const theme = useTheme()
  const { portfolio, riskComfort } = portfolioMapping
  const assetWeights = assetTypeWeights(portfolio.components, allAssetClasses)
  const isCurrentPortfolio = portfolio.id === (client ?? household)?.currentPortfolio
  const isComfortMatch = portfolio.id === comfortMatch.portfolio.id
  const { showRiskComfortAsBadge } = theme.charts?.comfortMatch ?? {}

  return (
    <div
      className={clsx("risk-comfort-match-list h-auto w-full grid grid-cols-auto items-center", {
        "border-b border-b-surface-300": true,
        "p-2": forReport,
        "p-3": !forReport
      })}
      key={portfolio.id || portfolio.name}
    >
      <div className={clsx("mr-4", forReport ? "w-10 h-10" : "w-8")}>
        <DoughnutChart
          forReport={forReport}
          data={assetWeights.map(({ total }) => total)}
          colors={assetWeights.map(({ assetClass }) => theme.colors.assetClassConfigurations[assetClass].color)}
        />
      </div>
      <div className="w-full col-start-2 col-span-10">
        <div className="grid grid-cols-10 items-center">
          <div className="col-start-1 col-span-5">
            <p className="text-sec text-main-500 font-semibold pb-0.5 portfolio-name">{portfolio.name}</p>
            <div className="w-full flex">
              {assetWeights.map(({ assetClass, total }, kk) => (
                <div className="flex flex-row justify-start" key={kk}>
                  <div
                    className="rounded-full shrink-0 h-1 w-1 my-auto mr-1"
                    style={{ backgroundColor: theme.colors.assetClassConfigurations[assetClass].color }}
                  />
                  <p key={kk} className="text-sm text-main-500 mr-4">
                    {total}% {theme.colors.assetClassConfigurations[assetClass].label}
                  </p>
                </div>
              ))}
            </div>
          </div>
          <div className="col-start-6 col-span-3 flex flex-col justify-center items-end gap-x-2">
            {isCurrentPortfolio && (
              <div className="flex items-center group relative">
                <img src={pieChartFillIcon} alt="current portfolio icon" className="cursor-pointer w-3" />
                <p className="text-xs text-avatar-0-600 pl-1 whitespace-nowrap uppercase cursor-pointer">
                  {forReport ? "Selected investment" : "Current investment"}
                </p>
                <div className="bg-white border border-interactive-200 z-10 shadow p-3 pointer-events-none hidden absolute top-5 -translate-x-1/4 group-hover:block w-80">
                  <p className="text-sm leading-3">
                    Your current investment was selected with the aim of achieving your financial goals, without taking too much risk
                  </p>
                </div>
              </div>
            )}
            {!postMeeting && isComfortMatch && (
              <div className="flex items-center group relative">
                {showRiskComfortAsBadge && client ? (
                  <AvatarBadges clients={[client]} size="small" />
                ) : (
                  <img src={riskComfortIcon} alt="Risk comfort icon" className="risk-comfort-img w-3 cursor-pointer" />
                )}
                <p className="text-xs text-riskComfort-600 font-semibold pl-1 whitespace-nowrap uppercase cursor-pointer">
                  <Trans id="risk-comfort-highest-risk-comfort">Highest Risk comfort</Trans>
                </p>
                <div className="bg-white border border-interactive-200 z-10 shadow p-3 pointer-events-none hidden absolute top-5 -translate-x-1/4 group-hover:block w-80">
                  <p className="text-sm leading-3">
                    {client && "Your highest Risk Comfort investment aligns most closely with your preferred level of risk and return."}
                    {household && "Your highest Risk Comfort investment aligns most closely with your combined preferences for risk and return."}
                  </p>
                </div>
              </div>
            )}
            <div className="flex gap-1">
              {(!postMeeting || theme.charts?.comfortMatch?.useAvatarIcons) &&
                household?.members.map(
                  ({ client }, i) =>
                    portfolio.id === portfolioComfortMatch(client)?.portfolio.id && (
                      <div key={client._id} className="flex items-center group relative cursor-pointer">
                        {theme.charts?.comfortMatch?.useAvatarIcons ? (
                          <AvatarBadges clients={[client]} size="small" colorStartIndex={i} />
                        ) : (
                          <>
                            <ClientRiskComfortIcon className={AVATAR_ICON_FILL_CLASSES[i]} />
                            <p className={clsx("text-xs pl-1 whitespace-nowrap uppercase cursor-pointer", CLIENT_NAME_COLOUR_CLASSES[i])}>{client.firstName}</p>
                          </>
                        )}

                        <div className="bg-white border border-interactive-200 z-10 shadow p-3 pointer-events-none hidden absolute top-5 -translate-x-1/4 group-hover:block w-80">
                          <p className="text-sm leading-3">
                            {client.firstName}'s highest Risk Comfort investment aligns most closely with {client.firstName}'s preferred level of risk and
                            return.
                          </p>
                        </div>
                      </div>
                    )
                )}
            </div>
          </div>
          <div className="flex items-center w-full col-start-9 col-span-1">
            <div className="w-86 pl-4 flex justify-center">
              <p className={clsx("text-sec text-main-600", { "risk-comfort-highlight": portfolio.id === comfortMatch.portfolio.id })}>{riskComfort}%</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ComfortMatchListViewPortfolioDetail
