import { clsx } from "clsx"
import { useContext, useMemo } from "react"
import { useTheme } from "../../../contexts/ThemeContext"
import { latest } from "../../../lib/clients"
import { customDateFormat } from "../../../lib/date"
import { tt } from "../../../lib/translations"
import { Client } from "../../../models/Client"
import { Household } from "../../../models/Household"
import downloadIcon from "../../advisor/assets/images/download.svg"
import { causeScoresForProfile, clientTopCauseByGame } from "../../clients/components/Profile/causeScores"
import CauseScores from "../../clients/components/Profile/components/PersonaSummary/CauseScores"
import ValuesPersonaSummary from "./ValuesPersonaSummary"
import Section from "./Section"
import { AuthContext } from "../../../views/auth/AuthContext"
import { useSearchParams } from "react-router-dom"

const InvestmentValuesTab = ({ client, household }: { client?: Client; household?: Household }) => {
  const theme = useTheme()
  const { sessionInfo } = useContext(AuthContext)
  const [queryParams] = useSearchParams()
  const isEmbedded = !!queryParams.get("facet")

  const game = latest(client!, "esg")
  const latestHouseholdGames = useMemo(() => {
    return household?.members.map(({ client }) => latest(client!, "esg")) ?? []
  }, [household?.members])

  const completedMembers = household && household.members.filter((_, index) => latestHouseholdGames[index]?.played)
  const incompleteMembers = household && household.members.filter((_, index) => !latestHouseholdGames[index]?.played)

  const householdStatus = useMemo(() => {
    if (!household) return null
    if (completedMembers!.length === household.members.length) {
      const lastPlayedGame = latestHouseholdGames
        .filter((game) => game!.played)
        .sort((a, b) => new Date(b!.played).getTime() - new Date(a!.played).getTime())[0]
      return `Household profile completed on ${customDateFormat(lastPlayedGame!.played, "d MMM yyyy")}`
    } else {
      const completedText = completedMembers! // If we're on this tab, at least one member has completed the values game
        .map((member) => {
          const playedDate = latestHouseholdGames.find((game) => game?.played)?.played
          return `${member.client.firstName} completed the investment values activity on ${customDateFormat(playedDate!, "d MMM yyyy")}`
        })
        .join(". ")
      const pendingText = incompleteMembers!.map((member) => `${member.client.firstName}'s investment values activity is still pending.`).join(" ") // If completed members are less than 2, then at least one is incomplete
      return `${completedText}.${incompleteMembers!.length > 0 ? ` ${pendingText}` : ""}`
    }
  }, [completedMembers, household, incompleteMembers, latestHouseholdGames])

  const scores = useMemo(() => (game?.esg?.results ? causeScoresForProfile(client!, theme) : []), [client, game?.esg?.results, theme])
  const topCause = clientTopCauseByGame(game!, theme)
  const hasSdgItemsIcon = useMemo(() => topCause?.learn?.devGoals?.items.find((item) => !!item.icon), [topCause?.learn?.devGoals?.items])
  const gameReportUrl = `${household && completedMembers?.length === household.members.length ? latest(household, "esg")?.gameReportUrl : game?.gameReportUrl}${
    isEmbedded ? `&_ssoToken=${sessionInfo?.idToken}&_ssoAccessToken=${sessionInfo?.accessToken}&_ssoRefreshToken=${sessionInfo?.refreshToken}` : ""
  }`

  return (
    <div className="esg-tab">
      <h1 className="sr-only">Investment Values</h1>
      <Section className="px-16 pb-0 flex justify-between items-center" showSeparator={false}>
        <p>
          {client && `${client?.firstName}'s profile completed on ${customDateFormat(game!.played)}`}
          {household && householdStatus}
        </p>
        <a
          href={gameReportUrl}
          target="_blank"
          rel="noreferrer"
          aria-disabled={household && (completedMembers?.length ?? 0) < household.members.length}
          className={clsx(
            "btn btn-text btn-text-medium flex gap-2 items-center text-sec font-bold",
            household && (completedMembers?.length ?? 0) < household.members.length && "opacity-50 hover:bg-transparent"
          )}
        >
          <span>View report</span>
          <img src={downloadIcon} alt="" aria-hidden />
        </a>
      </Section>
      {theme.pages.profile.resultsTabs?.esg?.map((section, i) => {
        if (section === "valuesPersonaSummary") {
          return (
            <Section key={section} showSeparator={theme.pages.profile.resultsTabs?.esg?.length !== i + 1}>
              <ValuesPersonaSummary client={client!} topCause={topCause!} />
            </Section>
          )
        } else if (section === "causeScores" && game) {
          return (
            <Section key={section} showSeparator={theme.pages.profile.resultsTabs?.esg?.length !== i + 1} className="pb-7">
              <h2 className="cause-scores-header text-main-600 font-semibold tracking-wide text-sec uppercase mb-1">Priorities</h2>
              <h3 className="text-h3 mb-3">{client!.firstName}'s areas of interest</h3>
              <p>{tt({ id: `values-results-page-scores-${topCause?.id}-description`, values: { name: client!.firstName } })}</p>
              <CauseScores scores={scores} />
            </Section>
          )
        } else if (section === "interests") {
          return (
            <Section key={section} showSeparator={theme.pages.profile.resultsTabs?.esg?.length !== i + 1}>
              <h2 className="interests-header text-main-600 font-semibold tracking-wide text-sec uppercase mb-1">Learning Opportunities</h2>
              <h3 className="text-h3 mb-6">{client!.firstName} told us they're interested in learning more about...</h3>
              <ul className="list-disc pl-6">
                {client?.interests?.map((interest) => (
                  <li key={interest} className="mb-2">
                    {tt({ id: `selected-interests-${interest}` })}
                  </li>
                ))}
              </ul>
            </Section>
          )
        } else if (section === "investmentThemes") {
          return (
            <Section key={section} showSeparator={theme.pages.profile.resultsTabs?.esg?.length !== i + 1}>
              <h2 className="investment-themes-header text-main-600 font-semibold tracking-wide text-sec uppercase mb-1">Investing in Energy Transition</h2>
              <h3 className="text-h3 mb-6">Here are some themes {client!.firstName} might look for in their investments</h3>
              <ul className="list-disc leading-6 ml-5 mb-6">
                {topCause?.items?.map((item, i) => (
                  <li key={`${topCause.id}-item-${i}`} className="mb-1">
                    {item.title}
                  </li>
                ))}
              </ul>
              {(topCause?.learn?.devGoals?.items ?? [])?.length > 0 && (
                <div className="flex flex-col gap-y-6">
                  <p>
                    {tt({
                      id: `report-sustainability-values-${topCause?.id}-sdg-title`,
                      message: "This area of interest aligns with the following United Nations Sustainable Development Goals (UN SDG):"
                    })}
                  </p>
                  <ul className={clsx("leading-6", hasSdgItemsIcon ? "list-none flex flex-col gap-y-2" : "list-disc ml-5")}>
                    {topCause?.learn?.devGoals?.items.map((item, i) => (
                      <li className={item.icon && "flex p-0"} key={i}>
                        {item.icon && <img alt="" src={item.icon} className="h-5 w-5 mr-2" aria-hidden />}
                        {item.description}
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </Section>
          )
        }
      })}
    </div>
  )
}

export default InvestmentValuesTab
