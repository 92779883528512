import clsx from "clsx"
import { useContext, useEffect, useMemo, useState } from "react"
import { AnimatePresence } from "framer-motion"
import useTeamMembersSearch, { TeamMember } from "../../hooks/useTeamMemberSearch"
import { AuthContext } from "../../views/auth/AuthContext"
import Modal from "../../components/Modal/Modal"
import InviteTeamMemberModal from "./components/InviteTeamMemberModal"
import TeamMenuItemList from "./components/TeamMenuItemList"
import AdminBadge from "../../components/AdminBadge/AdminBadge"
import SearchBox from "../advisor/components/SearchBox"
import chevUp from "../advisor/assets/images/chevron-up.svg"
import { format, parseISO } from "date-fns"
import { Trans } from "@lingui/macro"
import ErrorMessage from "../../components/Error/ErrorMessage"

export type AdvisorResponse = {
  _id: string
  firmId: string
  firstName?: string
  lastName?: string
  email?: string
  name: string
  userCreatedAt?: string
  userStatus?: string
  noOfClients?: number
  deletedAt?: Date
  deletedBy?: string
}

type SorterFunction = (members: TeamMember[]) => TeamMember[]

type Sorters = {
  [key: string]: SorterFunction
}

const TeamMembersPage = () => {
  const [sort, setSort] = useState<string>()
  const [sortDirection, setSortDirection] = useState<"DESC" | "ASC">("DESC")
  const [inviteTeamMemberModal, setInviteTeamMemberModal] = useState<boolean>(false)
  const { authStatus, sessionInfo } = useContext(AuthContext)
  const { members, isLoading, error, search, setSearch } = useTeamMembersSearch(sessionInfo, authStatus!)
  const [teamMembers, setTeamMembers] = useState<TeamMember[] | undefined>(members)

  useEffect(() => setTeamMembers(members), [members])

  const sortingFunction = useMemo(() => {
    const sorter: Sorters = {
      name: (members: TeamMember[]) =>
        [...(members ?? [])].sort((a, b) =>
          sortDirection === "DESC" ? (a.firstName ?? "").localeCompare(b.firstName ?? "") : (b.firstName ?? "").localeCompare(a.firstName ?? "")
        ),
      type: (members: TeamMember[]) =>
        [...(members ?? [])].sort((a, b) =>
          sortDirection === "DESC"
            ? a.isAssociate.toString().localeCompare(b.isAssociate.toString())
            : b.isAssociate.toString().localeCompare(a.isAssociate.toString())
        ),
      createdAt: (members: TeamMember[]) =>
        [...(members ?? [])].sort((a, b) =>
          sortDirection === "DESC" ? (a.createdAt ?? "").localeCompare(b.createdAt ?? "") : (b.createdAt ?? "").localeCompare(a.createdAt ?? "")
        ),
      noOfClients: (members: TeamMember[]) =>
        [...(members ?? [])].sort((a, b) =>
          sortDirection === "DESC" ? (a.noOfClients ?? 0) - (b.noOfClients ?? 0) : (b.noOfClients ?? 0) - (a.noOfClients ?? 0)
        )
    }
    return sorter[sort!] || ((members: TeamMember[]) => members)
  }, [sort, sortDirection])

  useEffect(() => {
    setTeamMembers((prevTeamMembers) => {
      const sortedMembers = sortingFunction(prevTeamMembers || [])
      return sortedMembers
    })
  }, [sortingFunction])

  function getType(member: TeamMember): string {
    return member.isAssociate ? "Associate" : "Adviser"
  }

  return (
    <div className="pg-ctr pg-ctr-py w-full h-full text-main-600 px-10 flex flex-col items-stretch overflow-y-auto no-scrollbar absolute pb-12">
      <div className="flex justify-between pt-4">
        <h1 className="text-h1">Team</h1>
        <div className="flex-0 flex items-center justify-end">
          <button className="btn btn-primary btn-medium" onClick={() => setInviteTeamMemberModal(true)}>
            Invite team member
          </button>
        </div>
      </div>
      <div className="mt-2.5 w-full grid grid-cols-12 gap-y-4 md:gap-y-0">
        <SearchBox
          className="col-span-12 md:col-span-6 lg:col-span-5"
          value={search}
          placeholder="Search for team members"
          onChange={(val) => setSearch(val)}
          onClear={() => setSearch("")}
        />
      </div>
      {isLoading && !members ? (
        <div className="col-span-full full-flex-content-center">Loading team members...</div>
      ) : (!members || members.length === 0) ? (
        <div className="flex flex-col justify-center items-center h-full gap-y-6">
          <h1 className="font-semibold text-h1 text-main-500">
            You have no team members yet, <br /> start by adding one.
          </h1>
          <button className="btn btn-primary btn-medium" onClick={() => setInviteTeamMemberModal(true)}>
            Add your team member
          </button>
        </div>
      ) : error ? (
        <div className="col-span-full h-full flex flex-col items-center justify-center">
          <ErrorMessage id="team-members" message={`An error has occurred: ${error.message}`} />
        </div>
      ) : (
        <div role="grid" aria-label="team members list" className="mt-8 lg-fluid-max:mt-14 w-full flex-1 flex flex-col items-stretch">
          <div role="row" className="grid grid-cols-12 justify-items-stretch pb-5">
            <div role="columnheader" className="col-span-3 bg-white flex items-center pl-1">
              <button
                onClick={() => {
                  setSort("name")
                  setSortDirection(sortDirection === "ASC" ? "DESC" : "ASC")
                }}
                className="font-semibold"
                aria-label="Sort by Name"
              >
                Name
              </button>
              {sort === "name" && <img src={chevUp} alt="" aria-hidden="true" className={clsx("inline", { "rotate-180": sortDirection === "DESC" })} />}
            </div>
            <div role="columnheader" className="col-span-3 bg-white flex items-center">
              <button
                onClick={() => {
                  setSort("type")
                  setSortDirection(sortDirection === "ASC" ? "DESC" : "ASC")
                }}
                className="font-semibold"
                aria-label="Sort by type"
              >
                Type
              </button>
              {sort === "type" && <img src={chevUp} alt="" aria-hidden="true" className={clsx("inline", { "rotate-180": sortDirection === "DESC" })} />}
            </div>
            <div role="columnheader" className="col-span-3 bg-white flex items-center">
              <button
                onClick={() => {
                  setSort("noOfClients")
                  setSortDirection(sortDirection === "ASC" ? "DESC" : "ASC")
                }}
                className="font-semibold"
                aria-label="Sort by non-archived clients"
              >
                <Trans id="team-members-list-clients-label">Non-archived clients</Trans>
              </button>
              {sort === "noOfClients" && <img src={chevUp} alt="" aria-hidden="true" className={clsx("inline", { "rotate-180": sortDirection === "DESC" })} />}
            </div>
            <div role="columnheader" className="col-span-2 bg-white flex items-center">
              <button
                onClick={() => {
                  setSort("createdAt")
                  setSortDirection(sortDirection === "ASC" ? "DESC" : "ASC")
                }}
                className="font-semibold"
                aria-label="Sort by date created"
              >
                Created
              </button>
              {sort === "createdAt" && <img src={chevUp} alt="" aria-hidden="true" className={clsx("inline", { "rotate-180": sortDirection === "DESC" })} />}
            </div>
          </div>
          <div role="rowgroup" aria-label={`team members list`} className="flex-1 mt-1.5 overflow-y-auto">
            {teamMembers?.map((member, i) => (
              <div key={i} className="grid grid-cols-12 justify-items-stretch bg-white">
                <div key={i} className="grid grid-cols-11 col-span-11">
                  <div role="cell" className="col-span-3 border-b-0.5 border-surface-400 py-4 flex items-center gap-2 pl-1">
                    <p>{member.firstName + " " + member.lastName}</p>
                    {member.isFirmAdmin && <AdminBadge />}
                  </div>
                  <div role="cell" className="col-span-3 border-b-0.5 border-surface-400 py-4 flex items-center gap-2 pl-1">
                    <p>{getType(member)}</p>
                  </div>
                  <div role="cell" className="col-span-3 border-b-0.5 border-surface-400 py-4 flex text-center gap-2 pl-8">
                    <p className="w-1/4">{member.noOfClients ? member.noOfClients : "-"}</p>
                  </div>
                  <div role="cell" className="col-span-2 border-b-0.5 border-surface-400 py-4 flex items-center gap-2 pl-1">
                    <p>{member.createdAt && format(parseISO(member.createdAt!), "d MMM yyyy")}</p>
                  </div>
                </div>
                <div role="cell" className="px-4 border-b-0.5 border-surface-400 flex items-center justify-center">
                  <TeamMenuItemList member={member} noOfClients={member.noOfClients} alignVertical={"bottom"} />
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
      <AnimatePresence>
        {inviteTeamMemberModal && (
          <Modal handleClose={() => setInviteTeamMemberModal(false)}>
            <InviteTeamMemberModal handleClose={() => setInviteTeamMemberModal(false)} />
          </Modal>
        )}
      </AnimatePresence>
    </div>
  )
}

export default TeamMembersPage
