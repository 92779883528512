import { Trans } from "@lingui/macro"
import React, { useEffect, useMemo, useRef, useState } from "react"
import { useTheme } from "../../../../../contexts/ThemeContext"
import { Client, Game } from "../../../../../models/Client"
import { comfortZoneText } from "../RiskComfortZone/RiskComfortZone"

const PortfolioSpectrumGraph = ({ client, game }: { client: Client; game: Game }) => {
  const [graphWidth, setGraphWidth] = useState(0)
  const [dataPointRef, setDataPointRef] = useState<React.RefObject<HTMLDivElement>[]>([])
  const [highlighterPos, setHighlighterPos] = useState<number>(0)
  const [highlighterWidth, setHighlighterWidth] = useState<number>(0)
  const theme = useTheme()
  const { portfolioMappings } = game

  const highlighterOffset = 45
  const graphRef = useRef<HTMLDivElement>(null)

  const comfortMatches = useMemo(() => {
    return [...(portfolioMappings || [])].sort((a, b) => {
      const d = b.riskComfort! - a.riskComfort!
      return d ? d : (a.portfolio!.sd || 0) - (b.portfolio!.sd || 0)
    })
  }, [portfolioMappings])

  const portfolioModel = comfortMatches?.[0]
  const highestPortfolioModelName = portfolioModel?.portfolio.name

  const spectrumGraphlabels = useMemo(() => portfolioMappings?.map(({ portfolio }) => portfolio).sort((a, b) => a.sd! - b.sd!), [portfolioMappings])

  useEffect(() => {
    const localRef = graphRef.current
    const divResizeObserver = new ResizeObserver((e) => {
      setGraphWidth(e[0].contentRect.width)
    })

    divResizeObserver.observe(localRef!)
    return () => {
      divResizeObserver.unobserve(localRef!)
    }
  }, [])

  useEffect(() => {
    setDataPointRef(portfolioMappings?.map(() => React.createRef<HTMLDivElement>()))
  }, [portfolioMappings])

  const personasInComfortZone = portfolioMappings?.filter(({ riskComfort }) => riskComfort! >= 60)
  const personasPosition = personasInComfortZone?.map((data, i) => spectrumGraphlabels?.findIndex((portfolio) => portfolio.name === data.portfolio.name)).sort()

  useEffect(() => {
    const pointsPos = dataPointRef
      .filter((ref, i) => ref.current && personasPosition?.includes(i))
      .map((dataPoints) => dataPoints.current!.getBoundingClientRect().left - dataPointRef[0].current!.getBoundingClientRect().left)

    const maxVal = Math.max(...pointsPos)
    const minVal = Math.min(...pointsPos)
    const calcHighlighterWidth = maxVal - minVal === 0 ? 45 : maxVal - minVal + highlighterOffset

    setHighlighterWidth((calcHighlighterWidth / graphWidth) * 100)
    setHighlighterPos((minVal / graphWidth) * 100)
  }, [dataPointRef, graphWidth, highlighterPos, personasInComfortZone, personasPosition, spectrumGraphlabels])

  const comfortZoneAlignText = game ? comfortZoneText(game!) : null

  return (
    <div className="risk-results-portfolio-spectrum-graph flex flex-col items-center w-full">
      <div className="flex flex-col w-full">
        {theme?.reports?.portfolioSpectrumGraph?.heroImage && (
          <img className="mb-4" src={theme?.reports?.portfolioSpectrumGraph?.heroImage} alt="" aria-hidden />
        )}
        <h3 className="risk-results-portfolio-spectrum-header text-h3 mb-6">
          {client.firstName}’s {comfortZoneAlignText}
        </h3>
        {theme.charts?.comfortMatch?.showDescription && (
          <p className="risk-results-portfolio-spectrum-description">
            The comfort zone reflects where {client.firstName}’s currently most comfortable with investment risk. Based on their decisions, {client.firstName}’s{" "}
            {comfortZoneAlignText}
          </p>
        )}
        <div className="flex justify-center">
          <div className="my-6 w-full h-32 flex flex-col md:max-w-2xl text-center">
            <div className="h-full relative">
              <div
                className="portfolio-spectrum-highlighter w-20 h-full absolute text-center"
                style={{ width: `${highlighterWidth}%`, left: `${highlighterPos}%` }}
              >
                <p className="text-sec md:text-sec pt-3 text-interactive-500">
                  <Trans id="spectrum-graph-comfort-zone-area-title">{client.firstName}'s Comfort zone</Trans>
                </p>
              </div>
            </div>
            <div ref={graphRef} className="w-full relative h-1/2">
              <div className="border border-dashed border-highlight-500 mx-6" />
              <div className="flex text-sec md:text-sec font-normal justify-between mx-6">
                {spectrumGraphlabels?.map(({ name }, i) => (
                  <div className="flex flex-col items-center" key={i}>
                    {name === highestPortfolioModelName && (
                      <div className="absolute top-0 h-12 flex flex-col items-center -translate-y-full">
                        <img className="w-6 h-6" src={theme.reports?.portfolioSpectrumGraph?.icon} alt="" aria-hidden />
                        <hr className="h-full border-0 border-l border-l-interactive-500" />
                      </div>
                    )}
                    <div className="absolute -top-1 flex flex-col items-center">
                      <div
                        ref={dataPointRef[i]}
                        className="w-2.5 h-2.5 rounded-full"
                        style={{ backgroundColor: theme?.reports?.portfolioSpectrumGraph?.pointColors[i] ?? "" }}
                      />
                      <div className="risk-results-graph-portfolio-names p-1 mt-1 w-min">{name}</div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PortfolioSpectrumGraph
