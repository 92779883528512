import { useMemo } from "react"
import { clsx } from "clsx"
import { latest } from "../../../../lib/clients"
import { customDateFormat } from "../../../../lib/date"
import { Client } from "../../../../models/Client"
import { Household } from "../../../../models/Household"
import downloadIcon from "../../../advisor/assets/images/download.svg"

import graphTmp from "./assets/liquidity-updated-graph.svg"
import altGraphTmp from "./assets/alternatives-graph.svg"
import heartIcon from "./assets/heart-fill.svg"
import arrowIcon from "./assets/arrow-right.svg"

import currentPortfolio from "./assets/current-portfolio.svg"
import proposedPortfolio from "./assets/proposed-portfolio.svg"

const LiquidityTab = ({ client, household }: { client?: Client; household?: Household }) => {
  const clientOrHousehold = (client ?? household)!
  const game = latest(clientOrHousehold, "liquidity")

  const latestHouseholdGames = useMemo(() => {
    return household?.members.map((member) => latest(member.client!, "liquidity")) ?? []
  }, [household?.members])

  const firstCompletedGame = household
    ? latestHouseholdGames.find((game) => game?.played) // Find the first completed game in household
    : null

  const lastUpdatedDate = household ? firstCompletedGame?.played : game?.played
  const completedMembers = household && household.members.filter((_, index) => latestHouseholdGames[index]?.played)

  const currentData = useMemo(() => {
    //dummy data
    return [
      {
        id: "Growth",
        color: "#006171",
        percent: 50
      },
      {
        id: "Income",
        color: "#28A4AC",
        percent: 50
      }
    ]
  }, [])
  const proposedData = useMemo(() => {
    //dummy data
    return [
      {
        id: "Growth",
        color: "#006171",
        percent: 40
      },
      {
        id: "Income",
        color: "#28A4AC",
        percent: 40
      },
      {
        id: "Alternatives (5 yr lock-in)",
        color: "#E5C8A1",
        percent: 20
      }
    ]
  }, [])
  // TODO replace with real data
  const currentComfort = 0
  const proposedComfort = 93

  return (
    <>
      <div className="w-full flex flex-col items-start gap-10 py-10">
        <h1 className="sr-only">Liquidity</h1>
        <section className="flex justify-between items-center gap-2 w-full text-left px-16">
          <p>
            {household ? completedMembers?.[0].client.firstName : client?.firstName}'s profile completed on {customDateFormat(lastUpdatedDate!)}
          </p>
          <a
            href="#"
            target="_blank"
            rel="noreferrer"
            aria-disabled={!lastUpdatedDate}
            className={clsx("btn btn-text btn-text-medium flex gap-2 items-center text-sec font-bold", !lastUpdatedDate && "opacity-50 hover:bg-transparent")}
          >
            <span>View report</span>
            <img src={downloadIcon} alt="" aria-hidden />
          </a>
        </section>
        <section className="w-full flex flex-col gap-6 px-16">
          <div className="flex flex-col gap-1">
            <p className="text-emph uppercase">Liquidity preferences</p>
            <h2 className="text-h3 font-semibold">{household ? completedMembers?.[0].client.firstName : client?.firstName} should consider allocating 20% of their portfolio to alternatives</h2>
          </div>
          <p>
          {household ? completedMembers?.[0].client.firstName : client?.firstName}'s willingness to lock away money suggests we should consider allocating 20% of their portfolio to alternative investments to achieve a higher
            liquidity-return fit.
          </p>
        </section>
        <hr className="w-full border-surface-300" />
        <section className="flex flex-col gap-6 px-16">
          <h2 className="text-h3 font-semibold">Liquidity-Return comfort zone</h2>
          <img src={graphTmp} alt="" aria-hidden />
        </section>
        <hr className="w-full border-surface-300" />
        <section className="flex flex-col gap-6 px-16">
          <h2 className="text-h3 font-semibold">Alternative asset recommendation</h2>
          <p>
            To better align {household ? completedMembers?.[0].client.firstName : client?.firstName}'s portfolio with their preferences, we recommend re-allocating 10% of their current equity and 10% of fixed income assets
            into alternatives with a 5 year lock-in period.
          </p>
          <div className="w-full flex gap-1 items-center">
            <div className="w-full h-42 bg-white border-t-2 border-t-avatar-0-300 py-4 px-3 shadow-100">
              <h3 className="text-sec text-grey-600 mb-3">Your current portfolio</h3>
              <div className="flex gap-3 items-center border-b border-b-surface-300 border-dashed pb-3">
                <img src={currentPortfolio} alt="" aria-hidden />
                <div className="flex flex-col text-sm">
                  {currentData.map((assetClass) => (
                    <div key={assetClass.id} className="flex items-center gap-2">
                      <div className="rounded-full w-1.5 h-1.5" style={{ backgroundColor: assetClass.color }} />
                      <span>
                        {assetClass.percent}% {assetClass.id}
                      </span>
                    </div>
                  ))}
                </div>
              </div>
              <div className="pt-3">
                <div className="flex flex-col gap-1 text-sec">
                  <div className="flex justify-between">
                    <span>Private market-portfolio fit</span>
                    <span className="flex gap-1 items-center">
                      <img src={heartIcon} aria-hidden alt="" />
                      <span className="font-semibold">{currentComfort}%</span>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <img src={arrowIcon} aria-hidden alt="" />
            <div className="w-full h-42 bg-white border-t-2 border-t-highlight-600 py-4 px-3 shadow-100">
              <h3 className="text-sec text-grey-600 mb-3">Proposed portfolio with alternatives</h3>
              <div className="flex gap-3 items-center border-b border-b-surface-300 border-dashed pb-3">
                <img src={proposedPortfolio} alt="" aria-hidden />
                <div className="flex flex-col text-sm">
                  {proposedData.map((assetClass) => (
                    <div key={assetClass.id} className="flex items-center gap-2">
                      <div className="rounded-full w-1.5 h-1.5" style={{ backgroundColor: assetClass.color }} />
                      <span>
                        {assetClass.percent}% {assetClass.id}
                      </span>
                    </div>
                  ))}
                </div>
              </div>
              <div className="pt-3">
                <div className="flex flex-col gap-1 text-sec">
                  <div className="flex justify-between">
                    <span>Private market-portfolio fit</span>
                    <span className="flex gap-1 items-center">
                      <img src={heartIcon} aria-hidden alt="" />
                      <span className="font-semibold">{proposedComfort}%</span>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="px-16">
          <img src={altGraphTmp} alt="" aria-hidden />
        </section>
      </div>
    </>
  )
}

export default LiquidityTab
