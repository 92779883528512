import React, { useContext, useEffect, useMemo, useRef, useState } from "react"
import { VictoryArea, VictoryAxis, VictoryChart, VictoryContainer, VictoryGroup, VictoryLabel, VictoryLine, VictoryScatter, VictoryTooltip } from "victory"
import { shortRound } from "../../../../../lib/numbers"
import { Client } from "../../../../../models/Client"
import { Household } from "../../../../../models/Household"
import { pickPortfolioColours } from "../../../utils/portfolioColour"
import { Options } from "../SelectPortfolio/SelectPortfolio"
import Tooltip from "./component/Tooltip"
import LineTooltip from "./component/LineTooltip"
import clsx from "clsx"
import ChartDataPoints from "./component/ChartDataPoints"
import { FirmContext } from "../../../../../contexts/FirmContext"
import { latest } from "../../../../../lib/clients"
interface ChartHoverState {
  colour: string
  index: number | undefined
  hoverState: boolean
}

const RetirementDrawdownChart = ({
  options,
  client,
  household,
  forReport
}: {
  client: Client
  household: Household
  options: Options
  forReport?: boolean
}) => {
  const chartContainerRef = useRef<HTMLDivElement>(null)
  const [chartSize, setChartSize] = useState<{ width: number; height: number } | null>(null)
  const [tooltipInfo, setTooltipInfo] = useState<{ x: number; y: number; planningHorizonYear: number }>({ x: 0, y: 0, planningHorizonYear: 0 })
  const [showTooltip, setShowTooltip] = useState<boolean>(false)
  const [showChartTooltip, setShowChartTooltip] = useState<boolean>(false)
  const [chartHoverState, setChartHoverState] = useState<ChartHoverState>({
    colour: "",
    index: undefined,
    hoverState: false
  })
  const { firm } = useContext(FirmContext)
  const clientOrHousehold = client ?? household
  const game = latest(clientOrHousehold, "risk")
  const maxXaxisLabel = 7
  const portfolioLineColours = pickPortfolioColours((game?.portfolioMappings ?? firm?.modelPortfolios)!.length)
  const currentYear = new Date().getFullYear()

  const goal = useMemo(() => clientOrHousehold?.goals?.goalDetails?.find((goal) => goal.type === "retirementIncome"), [clientOrHousehold?.goals?.goalDetails])
  const { estateGoal, planningHorizon } = goal || {}

  const chartData = useMemo(() => {
    const wealthData = options.drawdownChartOptions
    const chartOptions = wealthData!.map((data) => {
      const negativeIndex = data.expectedWealth.findIndex((num) => num < 0)
      const negativeIndexLow = data.expectedWealthLow.findIndex((num) => num < 0)

      const expectedWealth = data.expectedWealth
        .map((val, i) =>
          i === negativeIndex && negativeIndex > 0
            ? {
                x:
                  currentYear +
                  (negativeIndex - 1) +
                  data.expectedWealth[negativeIndex - 1] / (data.expectedWealth[negativeIndex - 1] + Math.abs(data.expectedWealth[negativeIndex])),
                y: 0
              }
            : { x: currentYear + i, y: val }
        )
        .filter((data) => data.y >= 0)

      const expectedWealthHigh = data.expectedWealthHigh.map((val, i) => ({ x: currentYear + i, y: val }))
      const expectedWealthLow = data.expectedWealthLow.map((val, i) => ({ x: currentYear + i, y: val, y0: data.expectedWealthHigh[i] }))

      const tooltipReferencePoints = [...expectedWealthLow]
        .map((val, index) =>
          index === negativeIndexLow
            ? {
                ...val,
                x: currentYear + (index - 1) + expectedWealthLow[index - 1].y / (expectedWealthLow[index - 1].y + Math.abs(expectedWealthLow[index].y)),
                y: 0
              }
            : val
        )
        .filter((val, index) => val.y === 0 || expectedWealthLow.length - 1 === index)
      return { expectedWealth, expectedWealthHigh, expectedWealthLow, tooltipReferencePoints }
    })
    const legacyGoalLine = [
      { x: currentYear, y: estateGoal || 0 },
      { x: currentYear + (planningHorizon! || 100), y: estateGoal || 0 }
    ]
    const maxYAxis = [...wealthData!]
      .map((data) => {
        return [...data.expectedWealth ?? []].reduce((acc, val) => (acc > val ? acc : val), -1)
      })
      .reduce((acc, val) => (acc > val ? acc : val))

    const maxXAxis = [...chartOptions].map((data) => currentYear + data.expectedWealth.length - 1).reduce((acc, val) => (acc > val ? acc : val))

    return { chartOptions, maxYAxis, maxXAxis, legacyGoalLine }
  }, [estateGoal, planningHorizon, currentYear, options.drawdownChartOptions])

  const xAxisLabels: number[] = useMemo(() => {
    const diff = chartData.maxXAxis - currentYear
    return Array(maxXaxisLabel)
      .fill(0)
      .map((_, i) => {
        const incrementNumber = Math.ceil(diff / maxXaxisLabel) * i
        return currentYear + incrementNumber
      })
  }, [chartData.maxXAxis, currentYear])

  const isComfortMatch = useMemo(() => {
    return game?.portfolioMappings?.map(({ portfolio }) => portfolio.id === options.comfortMatch?.id)
  }, [game?.portfolioMappings, options.comfortMatch?.id])

  useEffect(() => {
    const onResize = () => {
      if (chartContainerRef.current) {
        setChartSize({
          width: chartContainerRef.current?.offsetWidth,
          height: chartContainerRef.current?.offsetHeight
        })
      }
    }
    window.addEventListener("resize", onResize)
    onResize()
    return () => {
      window.removeEventListener("resize", onResize)
    }
  }, [])
  return (
    <div className="w-full h-full absolute overflow-hidden">
      <div tabIndex={0} className="h-full w-full overflow-hidden" ref={chartContainerRef}>
        {chartSize && (
          <VictoryChart
            padding={forReport ? { top: 30, bottom: 180, left: 100, right: 20 } : { top: 50, bottom: 95, left: 80, right: 40 }}
            containerComponent={<VictoryContainer responsive={false} />}
            height={forReport ? 500 : chartSize?.height}
            width={chartSize?.width}
            domain={{ x: [currentYear, chartData.maxXAxis], y: [0, chartData.maxYAxis] }}
            domainPadding={{ x: [0, 25] }}
          >
            <VictoryAxis
              axisLabelComponent={<VictoryLabel dy={forReport ? 65 : 25} />}
              crossAxis={false}
              offsetX={100}
              style={{
                axis: { stroke: "#E4E2DE", strokeDasharray: "2 2", strokeWidth: 1 },
                axisLabel: { fontSize: "14px", fontFamily: "Mulish", fontWeight: 600 },
                tickLabels: {
                  fill: "#1A1C2F",
                  fontSize: 12,
                  fontFamily: "Mulish",
                  fontWeight: 400,
                  cursor: "pointer"
                }
              }}
              fixLabelOverlap
              tickValues={xAxisLabels}
              tickFormat={(xAxislabel: any) => {
                //removes formatting if there is any default formatting applied e.g. 2,023 -> 2023
                return xAxislabel
              }}
              events={[
                {
                  target: "tickLabels",
                  eventHandlers: {
                    onMouseEnter: () => {
                      setShowTooltip(true)
                      return {
                        mutation: ({ x, y, text: xAxisLabel }: { x: number; y: number; text: number }) => {
                          setTooltipInfo({ x: x, y: y, planningHorizonYear: xAxisLabel })
                          return { backgroundStyle: { fill: "#EDEFFC" } }
                        }
                      }
                    },
                    onMouseLeave: () => {
                      setShowTooltip(false)
                      return {
                        /* No mutations should occur onMouseLeave so return an empty mutation function to inherit the default white background style 
                          applied to the VictoryLabel component below. */
                        mutation: () => {}
                      }
                    }
                  }
                }
              ]}
              label="Year"
              tickLabelComponent={
                <VictoryLabel
                  dx={12}
                  labelPlacement="vertical"
                  backgroundPadding={{ left: 5, right: 5, top: 5, bottom: 5 }}
                  backgroundStyle={{ fill: "white" }}
                />
              }
            />
            <VictoryAxis
              scale="time"
              axisLabelComponent={<VictoryLabel dy={forReport ? -60 : -35} />}
              crossAxis={false}
              dependentAxis
              style={{
                axis: { stroke: "transparent" },
                axisLabel: { fontSize: "14px", fontFamily: "Mulish", fontWeight: 600 },
                grid: { stroke: "#E4E2DE", strokeDasharray: "2 2", strokeWidth: 1 },
                tickLabels: {
                  fill: "#1A1C2F",
                  fontSize: 12,
                  fontFamily: "Mulish"
                }
              }}
              tickCount={7}
              tickFormat={(amount: number) => {
                const { value, unit } = shortRound(amount)
                return `$${value}${unit}`
              }}
              label="Expected wealth (today's dollars)"
            />
            {chartData.chartOptions.map((data, i) => (
              <VictoryGroup key={i}>
                {/* conditionally showing each component instead of showing all of them at once  */}
                {chartHoverState.hoverState && chartHoverState.index === i && (
                  <VictoryArea
                    standalone={false}
                    key={i}
                    data={data.expectedWealthLow}
                    padding={{ right: 25 }}
                    interpolation="basis"
                    style={{ data: { stroke: "#98A1E6", strokeWidth: 2, strokeDasharray: 5, fill: "#CCD0F3", fillOpacity: "20%" } }}
                  />
                )}

                {chartHoverState.hoverState && chartHoverState.index === i && (
                  <VictoryLine
                    standalone={false}
                    key={i}
                    data={data.expectedWealthHigh}
                    padding={{ right: 25 }}
                    interpolation="basis"
                    style={{ data: { stroke: "#98A1E6", strokeWidth: 2, strokeDasharray: 5 } }}
                  />
                )}

                {chartHoverState.hoverState && chartHoverState.index === i && (
                  <VictoryScatter
                    size={3}
                    name="points"
                    data={[data.tooltipReferencePoints[0]]}
                    style={{ data: { fill: "none" } }}
                    labels={({ y }: { y: number }) => `${y}`}
                    labelComponent={
                      <VictoryTooltip
                        text=""
                        constrainToVisibleArea
                        active={chartHoverState.index === i && showChartTooltip}
                        flyoutComponent={
                          <LineTooltip
                            index={i}
                            chartWidth={chartSize.width}
                            portfolioComponents={
                              game?.portfolioMappings
                                ? game.portfolioMappings[i].portfolio.components
                                : firm?.modelPortfolios?.[i].components
                            }
                            assetClasses={(game?.assetClasses ?? firm?.assetClasses)!}
                            options={options}
                          />
                        }
                      />
                    }
                  />
                )}
              </VictoryGroup>
            ))}

            {chartData.chartOptions.map((data, i) => (
              <VictoryGroup
                key={i}
                events={[
                  {
                    childName: "all",
                    target: "data",
                    eventHandlers: {
                      onMouseOver: () => {
                        setChartHoverState({ colour: "#6071FF", index: i, hoverState: true })
                        setShowChartTooltip(true)
                      },
                      onMouseOut: () => {
                        setShowChartTooltip(false)
                        setChartHoverState({ colour: "", index: undefined, hoverState: false })
                      }
                    }
                  }
                ]}
              >
                <VictoryLine
                  key={i}
                  name="wealthLine"
                  data={data.expectedWealth}
                  padding={{ right: 25 }}
                  interpolation="basis"
                  style={{
                    data: {
                      stroke: chartHoverState.index === i ? chartHoverState.colour : portfolioLineColours[i],
                      strokeWidth: 2,
                      cursor: "pointer"
                    }
                  }}
                />

                <VictoryScatter
                  name="points"
                  data={data.expectedWealth.filter((val) => val.y === 0)}
                  dataComponent={<ChartDataPoints isComfortMatch={isComfortMatch?.[i] ?? false} />}
                  style={{
                    data: { fill: chartHoverState.index === i ? chartHoverState.colour : portfolioLineColours[i], cursor: "pointer" }
                  }}
                />
              </VictoryGroup>
            ))}

            {estateGoal && (
              <VictoryLine
                data={chartData.legacyGoalLine}
                padding={{ right: 25 }}
                style={{ data: { stroke: "#1A1C2F", strokeWidth: 2 } }}
                labels={["Legacy Goal"]}
                labelComponent={<VictoryLabel style={{ fill: "#1A1C2F", fontFamily: "Mulish", fontSize: 14, fontStyle: "italic" }} textAnchor="start" />}
              />
            )}

            {/* use scatter points to place the x-axis tooltips */}
            {!forReport &&
              xAxisLabels.map((xVal, i) => (
                <VictoryScatter
                  events={[
                    {
                      target: "data",
                      eventHandlers: {
                        onMouseOver: () => {
                          setShowTooltip(false) // dont show tooltip if hovered over scatter point
                        }
                      }
                    }
                  ]}
                  key={xVal}
                  data={[{ x: i === xAxisLabels.length - 1 ? xVal - 3 : xVal + 0.2, y: 0 }]}
                  style={{
                    data: { fill: "none" }
                  }}
                  labels={({ x }: { x: number }) => x}
                  labelComponent={
                    <VictoryTooltip
                      text=""
                      active={showTooltip && xVal === tooltipInfo.planningHorizonYear}
                      flyoutComponent={
                        <Tooltip
                          x={tooltipInfo.x}
                          planningHorizonYear={tooltipInfo.planningHorizonYear}
                          client={client}
                          household={household}
                          chartWidth={chartSize.width}
                        />
                      }
                    />
                  }
                />
              ))}
          </VictoryChart>
        )}

        {/* use VictoryAxis to place the client's age in the PDF report */}
        {forReport && chartSize && (
          <div className="absolute bottom-6 pl-1.5">
            <VictoryChart
              padding={{ top: 0, bottom: 70, left: 100, right: 20 }}
              height={100}
              width={chartSize?.width}
              domain={{ x: [currentYear, chartData.maxXAxis] }}
              containerComponent={<VictoryContainer responsive={false} />}
              domainPadding={{ x: [0, 25] }}
            >
              {client && (
                <VictoryAxis
                  axisLabelComponent={<VictoryLabel dy={15} />}
                  style={{
                    axis: { strokeWidth: 0 },
                    axisLabel: { fontSize: "14px", fontFamily: "Mulish", fontWeight: 600 },
                    tickLabels: {
                      fill: "#1A1C2F",
                      fontSize: 12,
                      fontFamily: "Mulish",
                      fontWeight: 400,
                      cursor: "pointer"
                    }
                  }}
                  tickValues={xAxisLabels}
                  tickFormat={(xAxislabel: number) => {
                    const age = currentYear - new Date(client.dob).getFullYear()
                    //removes formatting if there is any default formatting applied e.g. 2,023 -> 2023
                    return xAxislabel === currentYear ? age : age + xAxislabel - currentYear
                  }}
                  tickLabelComponent={<VictoryLabel dx={7} labelPlacement="vertical" />}
                />
              )}
              {household &&
                household.members?.map(({ client }, i) => {
                  const age = currentYear - new Date(client.dob!).getFullYear()
                  return (
                    <VictoryAxis
                      offsetY={i === 0 ? 50 : 70}
                      standalone={false}
                      key={i}
                      style={{
                        axis: { strokeWidth: 0 },
                        axisLabel: { fontSize: "14px", fontFamily: "Mulish", fontWeight: 600 },
                        tickLabels: {
                          fill: "#1A1C2F",
                          fontSize: 12,
                          fontFamily: "Mulish",
                          fontWeight: 400,
                          cursor: "pointer"
                        }
                      }}
                      fixLabelOverlap
                      tickValues={xAxisLabels}
                      tickFormat={(xAxislabel: number) => {
                        return xAxislabel === currentYear ? age : age + xAxislabel - currentYear
                      }}
                      tickLabelComponent={<VictoryLabel dx={7} labelPlacement="vertical" />}
                    />
                  )
                })}
            </VictoryChart>
          </div>
        )}

        {forReport && (
          <div
            className={clsx("absolute flex flex-col gap-y-1 text-sm w-full", {
              "bottom-12": household,
              "bottom-[70px]": client
            })}
          >
            {client && <p className=" w-24">{client?.firstName}'s age </p>}
            {household &&
              household.members
                .map(({ client }, i) => (
                  <p key={i} className="w-24">
                    {client.firstName}'s age
                  </p>
                ))
                .reverse()}
          </div>
        )}
      </div>
    </div>
  )
}

export default RetirementDrawdownChart
