import { ReactNode } from "react"
import AlertIcon from "../../assets/icons/AlertIcon"


const ErrorMessage: React.FC<{ id: string, message: ReactNode | string }> = ({ id, message }) => {
  return (
    <div className={`error-message ${id}-error flex items-center mt-1 gap-1`}>
      <AlertIcon alertType="error" sizeProp="sm" />
      <p className={`${id}-error-text text-sm text-red-600 font-normal`}>{message}</p>
    </div>
  )
}

export default ErrorMessage
