import { useRef, useState } from "react"
import { Link } from "react-router-dom"
import Dropdown from "../components/Dropdown/Dropdown"
import arrow from "../layout/images/chevron-down.svg"
import clsx from "clsx"
import { useTheme } from "../contexts/ThemeContext"

const NavItemDropDown = ({ alignVertical, onImportClick }: { alignVertical?: "bottom" | "top", onImportClick: () => void }) => {
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false)
  const menuBtn = useRef<HTMLButtonElement>(null)
  const theme = useTheme()

  return (
    <div className="relative flex items-center">
      <button
        className="text-interactive-500 flex py-4 px-4 transition ease-in-out duration-300 hover:bg-interactive-100"
        onClick={() => (isMenuOpen ? setIsMenuOpen(false) : setIsMenuOpen(true))}
        ref={menuBtn}
        aria-expanded={isMenuOpen}
      >
        More
        <img
          src={arrow}
          alt=""
          aria-hidden
          className={clsx("inline", {
            "rotate-180 pr-1": isMenuOpen,
            "pl-1": !isMenuOpen
          })}
        />
      </button>
      {isMenuOpen && (
        <Dropdown overlayClassName="w-dropdown" align="right" alignVertical={alignVertical} handleClose={() => setIsMenuOpen(false)} trigger={menuBtn}>
          <ul className="list-none p-0">
            {theme.navbar.moreDropdown.links.archive &&
              <li>
                <Link
                  className="btn p-4 hover:bg-interactive-100 cursor-pointer border-0 block w-full text-left font-normal"
                  onClick={() => {
                    setIsMenuOpen(false)
                  }}
                  to="/archived"
                >
                  Archived clients
                </Link>
              </li>
            }
            {theme.navbar.moreDropdown.links.import &&
              <li>
                <button
                  className="btn p-4 hover:bg-interactive-100 cursor-pointer border-0 block w-full text-left font-normal"
                  onClick={() => {
                    onImportClick()
                    setIsMenuOpen(false)
                  }}
                >
                  Import Clients
                </button>
              </li>
            }
          </ul>
        </Dropdown>
      )}
    </div>
  )
}

export default NavItemDropDown
