import React, { useContext, useState } from "react"
import InputCode from "../../components/InputCode/InputCode"
import { AuthContext } from "./AuthContext"
import ErrorMessage from "../../components/Error/ErrorMessage"

type Props = {
  onCodeComplete: (code: string) => void
  onResendCode: () => void
  verifyCodeError?: string
}

const RespondToMFA: React.FC<Props> = () => {
  const { challenge/* , respondToMFAChallenge */ } = useContext(AuthContext) as any
  const respondToMFAChallenge = async (s: string) => s+s  // TODO: re-implement
  const [error, setError] = useState("")
  return (
    <div className="flex flex-col justify-center items-center mx-auto my-auto">
      <div className="modal-container">
        <h1 className="modal-title">Let’s verify you</h1>
        <p className="modal-description">A verification code has been sent to {challenge?.params.CODE_DELIVERY_DESTINATION}. Please enter the six-digit code below to sign in to your account.</p>
        <div className="pb-10 pt-6 input-wrapper">
          <InputCode length={6} onComplete={(code) => respondToMFAChallenge!(code).catch(err => setError(err.message))} />
          <div className="text-center" role="alert">
            {error &&
              <ErrorMessage id="respond-mfa" message={error} />
            }
          </div>
        </div>
        <p className="text-main-500 text-center">
          It may take a minute to receive your code.
        </p>
      </div>
    </div>
  )
}

export default RespondToMFA
