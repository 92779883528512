import { useMemo } from "react"
import { Game } from "../models/Client"

const useRetirementRiskMindset = (game?: Game) => {
  return useMemo(() => {
    if (game?.gameType === "retirement" && (game?.retirement?.decisions?.GI?.length ?? 0) > 0) {
      const averageChoiceX = Math.round(
        (game.retirement.decisions?.GI?.reduce((sum, item) => sum + item.choiceX!, 0) ?? 0) / (game.retirement.decisions?.GI?.length || 1)
      )
      const score = 100 - averageChoiceX
      const rating = score <= 50 ? "high" : "low"
      return { score, rating }
    }
    return undefined
  }, [game?.gameType, game?.retirement?.decisions?.GI])
}

export default useRetirementRiskMindset
