import clsx from "clsx"
import format from "date-fns/format"
import { useCallback, useContext, useEffect, useRef, useState } from "react"
import { useNavigate } from "react-router"
import { NewHouseholdRequest, createHousehold } from "../../api/households"
import CreateClientForm from "../../components/CreateClient/CreateClientForm"
import DollarIcon from "../../components/DolarIcon/DollarIcon"
import NumberInput from "../../components/NumberInput/NumberInput"
import RadioButton from "../../components/RadioButton/RadioButton"
import SelectAdvisor from "../../components/SelectAdvisor/SelectAdvisor"
import Switch from "../../components/Switch/Switch"
import TextInput from "../../components/TextInput/TextInput"
import { FirmContext } from "../../contexts/FirmContext"
import useMyAdvisor from "../../hooks/useMyAdvisor"
import useTrackViewEvent from "../../hooks/useTrackViewEvent"
import { validate, validateHousehold } from "../../lib/clients"
import { Advisor } from "../../models/Advisor"
import { ClientErrors, NewClient } from "./CreateIndividualClientPage"
import ErrorMessage from "../../components/Error/ErrorMessage"

export type NewHousehold = {
  name?: string
  investmentAmount?: number
  retirementIncomeGoal?: number
  annualInvestmentContribution?: number
  otherSourcesRetirementIncome?: number
  retirementAge?: number
  members: NewClient[]
  existingClient?: boolean
  currentPortfolio?: string
}

export type HouseholdErrors = {
  name?: string
  investmentAmount?: string
  retirementIncomeGoal?: string
  annualInvestmentContribution?: string
  otherSourcesRetirementIncome?: string
  retirementAge?: string
  members: ClientErrors[]
  advisor?: string
}
const CreateHouseholdPage = ({ onCreate, myAdvisors }: { onCreate: () => void, myAdvisors?: Advisor[] }) => {
  const { firm } = useContext(FirmContext)
  const [createError, setCreateError] = useState<boolean>(false)
  const [shouldValidate, setShouldValidate] = useState<boolean>(false)
  const [currentViewClient, setCurrentViewClient] = useState<number>()
  const [createClientInProgress, setCreateClientInProgress] = useState<boolean>(false)
  const [newHousehold, setNewHousehold] = useState<NewHousehold>({ members: [{}, {}] })
  const [householdErrors, setHouseholdErrors] = useState<HouseholdErrors>({ members: [{}, {}] })
  const currentPortfolioRef = useRef<HTMLDivElement>(null)
  const { advisor, selectAdvisor } = useMyAdvisor(myAdvisors)
  const primaryAdviserRef = useRef<HTMLDivElement>(null)
  const navigate = useNavigate()
  const trackViewEvent = useTrackViewEvent()

  const handleCreateHousehold = () => {
    trackViewEvent({ action: "click", category: "client_creation", label: "create_client_btn" })
    setShouldValidate(true)
    const errs = validateHouseholdForm()
    if (
      !errs ||
      Object.keys(errs).length === 0 ||
      !errs.members ||
      errs.members.length === 0 ||
      errs.members.every((element) => Object.keys(element).length === 0)
    ) {
      setCreateError(false)
      onCreate()
      const household: NewHouseholdRequest = {
        ...newHousehold,
        members: newHousehold.members?.map((member) => ({
          ...member,
          dobRaw: undefined,
          dob: member.dob ? format(member.dob, "yyyy-MM-dd") : undefined
        }))
      }
      createHousehold(advisor!._id, household)
        .then((household) => {
          navigate(`/households/${household._id}`, { replace: true })
        })
        .catch((err) => {
          console.log("error creating household", err)
          setCreateClientInProgress(false)
          setCreateError(true)
        })
    }
  }

  const validateHouseholdForm = useCallback(() => {
    const householdErrs: HouseholdErrors = validateHousehold(newHousehold, firm!, advisor?._id)
    if (currentViewClient !== undefined) {
      const memberErrors = validate(newHousehold.members[currentViewClient], firm!, advisor?._id)
      householdErrs.members[currentViewClient] = memberErrors
    }
    return householdErrs
  }, [advisor?._id, currentViewClient, firm, newHousehold])

  useEffect(() => {
    if (shouldValidate) {
      if (currentViewClient === undefined) {
        const nextErrors = validateHouseholdForm()
        setHouseholdErrors(nextErrors)
      } else {
        const nextErrors = validate(newHousehold.members[currentViewClient], firm!, advisor?._id)
        setHouseholdErrors((old) => ({
          ...old,
          members: old.members.map((mem, idx) => {
            return currentViewClient === idx ? nextErrors : { ...mem }
          })
        }))
      }
    }
  }, [advisor?._id, currentViewClient, firm, newHousehold.members, shouldValidate, validateHouseholdForm])

  useEffect(() => {
    if (newHousehold?.existingClient) {
      currentPortfolioRef?.current?.scrollIntoView({
        behavior: "smooth"
      })
    }
  }, [newHousehold?.existingClient])

  useEffect(() => {
    if (householdErrors && Object.keys(householdErrors).length === 1 && householdErrors.advisor) {
      primaryAdviserRef?.current?.scrollIntoView({
        behavior: "smooth"
      })
    }
  }, [householdErrors])

  return (
    <>
      <div className="grid grid-cols-12 gap-x-4 gap-y-6 grow justify-center m-auto auto-rows-max overflow-y-auto w-full mb-4">
        {currentViewClient === undefined && (
          <div className="col-start-4 col-span-3">
            <h4 className="text-h4">Household details</h4>
          </div>
        )}
        {currentViewClient === undefined && (
          <>
            <div className="col-start-4 col-span-6">
              <TextInput
                label={
                  <span>
                    Household name<span className="text-negative-600">*</span>
                  </span>
                }
                type="text"
                name="householdName"
                value={newHousehold?.name}
                onChange={(value) => {
                  setNewHousehold((old) => ({ ...old, name: value }))
                }}
                error={householdErrors?.name}
              />
            </div>
            <NumberInput
              className="col-start-4 col-span-6"
              label="Investment amount"
              name="investmentAmount"
              value={newHousehold?.investmentAmount}
              onChange={(valueAsNumber) => {
                setNewHousehold((old) => ({ ...old, investmentAmount: valueAsNumber }))
              }}
              prefix={
                <div className="pl-3">
                  <DollarIcon />
                </div>
              }
              error={householdErrors?.investmentAmount}
            />
            {myAdvisors && myAdvisors.length > 1 && (
              <div className="col-start-4 col-span-6" ref={primaryAdviserRef}>
                <SelectAdvisor
                  error={householdErrors?.advisor}
                  label={<span>Select primary adviser to manage this client</span>}
                  advisors={myAdvisors}
                  onSelect={(value: Advisor) => {
                    selectAdvisor(value)
                  }}
                  selectedAdvisor={advisor}
                  verticalAlign="top"
                />
              </div>
            )}
            {firm?.uiConfig?.RETIREMENT_GOAL_ATTRIBUTES && (
              <>
                <NumberInput
                  className="col-start-4 col-span-6"
                  label="Retirement income goal"
                  name="retirementIncomeGoal"
                  value={newHousehold?.retirementIncomeGoal}
                  onChange={(valueAsNumber) => {
                    setNewHousehold((old) => ({ ...old, retirementIncomeGoal: valueAsNumber }))
                  }}
                  prefix={
                    <div className="pl-3">
                      <DollarIcon />
                    </div>
                  }
                  suffix="p/y"
                  error={householdErrors?.retirementIncomeGoal}
                />
                <NumberInput
                  className="col-start-4 col-span-6"
                  label="Annual investment contribution"
                  name="annualInvestmentContribution"
                  value={newHousehold?.annualInvestmentContribution}
                  onChange={(valueAsNumber) => {
                    setNewHousehold((old) => ({ ...old, annualInvestmentContribution: valueAsNumber }))
                  }}
                  prefix={
                    <div className="pl-3">
                      <DollarIcon />
                    </div>
                  }
                  suffix="p/y"
                  error={householdErrors?.annualInvestmentContribution}
                />
                <div className="col-start-4 col-span-6">
                  <NumberInput
                    label="Annual retirement income from other sources"
                    name="otherSourcesRetirementIncome"
                    value={newHousehold?.otherSourcesRetirementIncome}
                    onChange={(valueAsNumber) => {
                      setNewHousehold((old) => ({ ...old, otherSourcesRetirementIncome: valueAsNumber }))
                    }}
                    prefix={
                      <div className="pl-3">
                        <DollarIcon />
                      </div>
                    }
                    suffix="p/y"
                    error={householdErrors?.otherSourcesRetirementIncome}
                  />
                  <div className="text-sm text-main-500">Rental property, pension, other investments</div>
                </div>
              </>
            )}
            <div className="col-start-4 col-span-full">
              <Switch
                checked={newHousehold.existingClient}
                id={"existing-client"}
                label="This is an existing household"
                onChange={() => {
                  setNewHousehold((old) => ({ ...old, existingClient: !old.existingClient, currentPortfolio: undefined }))
                }}
              />
            </div>
            {newHousehold.existingClient && (
              <div className="col-start-4 col-span-6" ref={currentPortfolioRef}>
                <fieldset>
                  <legend className="mb-2">Add your household's selected investment</legend>
                  <RadioButton
                    checked={!newHousehold.currentPortfolio}
                    id="no-portfolio"
                    label="No selected investment"
                    name="currentPortfolio"
                    onChange={() => {
                      setNewHousehold((old) => ({ ...old, currentPortfolio: undefined }))
                    }}
                    className="mb-2"
                  />
                  {firm?.modelPortfolios?.map((modelPortfolio, idx) => (
                    <RadioButton
                      key={modelPortfolio.id ?? idx}
                      checked={newHousehold.currentPortfolio === modelPortfolio.id}
                      id={modelPortfolio.id}
                      label={modelPortfolio.name}
                      name="currentPortfolio"
                      onChange={() => {
                        setNewHousehold((old) => ({ ...old, currentPortfolio: modelPortfolio.id }))
                      }}
                      className="mb-2"
                    />
                  ))}
                </fieldset>
              </div>
            )}
          </>
        )}
        {newHousehold.members.map((member, i) => {
          return (
            <div className={clsx("grid grid-cols-12 gap-5 col-start-1 col-span-12 auto-rows-max", i === currentViewClient ? "grid" : "hidden")} key={i}>
              <div className="col-start-4 col-span-3">
                <h4 className="text-h4">{`${i === 0 ? "First" : "Second"} household member`}</h4>
              </div>
              <CreateClientForm
                client={member}
                errors={householdErrors?.members ? householdErrors?.members[i] : undefined}
                onFirstNameChange={(value) => {
                  setNewHousehold((old) => ({
                    ...old,
                    members: old.members?.map((mem, idx) => {
                      return i === idx ? { ...mem, firstName: value } : { ...mem }
                    })
                  }))
                }}
                onLastNameChange={(value) => {
                  setNewHousehold((old) => ({
                    ...old,
                    members: old.members?.map((mem, idx) => {
                      return i === idx ? { ...mem, lastName: value } : { ...mem }
                    })
                  }))
                }}
                onEmailChange={(value) => {
                  setNewHousehold((old) => ({
                    ...old,
                    members: old.members?.map((mem, idx) => {
                      return i === idx ? { ...mem, email: value } : { ...mem }
                    })
                  }))
                }}
                onDobChange={(value: Date | null) => {
                  setNewHousehold((old) => ({
                    ...old,
                    members: old.members?.map((mem, idx) => {
                      return i === idx ? { ...mem, dob: value ? value : undefined } : { ...mem }
                    })
                  }))
                }}
                onDobRawChange={(value: string) => {
                  setNewHousehold((old) => ({
                    ...old,
                    members: old.members?.map((mem, idx) => {
                      return i === idx ? { ...mem, dobRaw: value } : { ...mem }
                    })
                  }))
                }}
              >
                {firm?.uiConfig?.RETIREMENT_GOAL_ATTRIBUTES && (
                  <>
                    <NumberInput
                      className="col-start-4 col-span-6"
                      label="Current annual income (before tax)"
                      name="currentAnnualIncome"
                      value={member.currentAnnualIncome}
                      onChange={(valueAsNumber) => {
                        setNewHousehold((old) => ({
                          ...old,
                          members: old.members?.map((mem, idx) => {
                            return i === idx ? { ...mem, currentAnnualIncome: valueAsNumber } : { ...mem }
                          })
                        }))
                      }}
                      prefix={
                        <div className="pl-3">
                          <DollarIcon />
                        </div>
                      }
                      suffix="p/y"
                      error={householdErrors?.members[i].currentAnnualIncome}
                    />
                    <NumberInput
                      className="col-start-4 col-span-3"
                      label="Expected retirement age"
                      name="retirementAge"
                      value={member.retirementAge}
                      onChange={(valueAsNumber) => {
                        setNewHousehold((old) => ({
                          ...old,
                          members: old.members?.map((mem, idx) => {
                            return i === idx ? { ...mem, retirementAge: valueAsNumber } : { ...mem }
                          })
                        }))
                      }}
                      prefix={
                        <div className="pl-3">
                          <DollarIcon />
                        </div>
                      }
                      suffix="years"
                      error={householdErrors?.members[i].retirementAge}
                    />
                  </>
                )}
              </CreateClientForm>
            </div>
          )
        })}
      </div>
      <div className="footer shadow-md pb-12 grid grid-cols-12 gap-x-4 col-start-1 col-span-full">
        {currentViewClient !== undefined ? (
          <button
            className="btn btn-medium btn-secondary col-start-5 col-span-2 mt-4"
            type="button"
            onClick={(evt) => {
              evt.preventDefault()
              setCurrentViewClient(currentViewClient === 0 ? undefined : currentViewClient - 1)
            }}
          >
            Back
          </button>
        ) : (
          <button type="button" className="btn btn-medium col-start-5 col-span-2 mt-4" onClick={() => navigate(-1)}>
            Cancel
          </button>
        )}
        {currentViewClient !== 1 ? (
          <button
            className="btn btn-primary btn-medium col-start-7 col-span-2 mt-4"
            type="button"
            disabled={createClientInProgress}
            onClick={(evt) => {
              evt.preventDefault()
              setShouldValidate(true)
              const errs = validateHouseholdForm()
              if (
                !errs.name &&
                !errs.investmentAmount &&
                !errs.annualInvestmentContribution &&
                !errs.otherSourcesRetirementIncome &&
                !errs.retirementIncomeGoal &&
                !errs.advisor &&
                errs.members.every((element) => Object.keys(element).length === 0)
              ) {
                setShouldValidate(false)
                setCurrentViewClient(currentViewClient === undefined ? 0 : 1)
              } else {
                console.log("there are errrrrrrs that prevent us from going to the Next", errs)
              }
            }}
          >
            Next
          </button>
        ) : (
          <button className="btn btn-primary btn-medium col-start-7 col-span-2 mt-4" onClick={handleCreateHousehold} disabled={createClientInProgress}>
            Create household
          </button>
        )}
        <div role="alert">
          {createError &&
            <div className="col-span-12 text-center">
              <ErrorMessage id="create-household-page" message="Error creating household, please try again." />
            </div>
          }
        </div>
      </div>
    </>
  )
}

export default CreateHouseholdPage
