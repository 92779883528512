import { NavLink, Outlet, Route, Routes, useParams } from "react-router-dom"
import ViewFirm from "./ViewFirm"
// import UpdateLicensee from "./UpdateLicensee"
import clsx from "clsx"
import { useQuery } from "react-query"
import axiosInstance from "../../../../api/axiosInstance"
import Loading from "../../../../components/ClientProfile/Loading/Loading"
import { Firm } from "../../../../models/Firm"
import ArchiveFirm from "./ArchiveFirm"
import UpdateFirm from "./UpdateFirm"
import CopyPortfolios from "./CopyPortfolios"
import ActivitySettings from "./ActivitySetttings"
import ErrorMessage from "../../../../components/Error/ErrorMessage"



const ManageFirm = () => {
  const { firmId } = useParams()
  const firm = useQuery(["admin/firm", firmId], () => axiosInstance.get<Firm>(`${import.meta.env.VITE_APP_API_BASE || ""}/api/admin/firm/${firmId}`)
    .then(res => res.data)
  )
    
  const linkStyle = ({ isActive }: { isActive: boolean }) => clsx("px-4 border-b-2 transition-all hover:bg-interactive-100 py-2", isActive ? "border-interactive-600" : "border-white hover:border-interactive-200")

  return (<div className="pg-ctr pg-ctr-py-lg w-full h-full flex flex-col gap-y-4 pt-10 pl-4" >
    <h1 className="px-10 text-h1">{firm.isSuccess ? firm.data?.name : firmId}</h1>
    <nav className="w-full -ml-4 px-10 flex text-h5 text-interactive-600">
      <NavLink to="" end className={linkStyle}>Advisors & Stats</NavLink>
      <NavLink to="edit" className={linkStyle}>Update</NavLink>
      <NavLink to="copy-portfolios" className={linkStyle}>Copy Portfolios</NavLink>
      <NavLink to="activity-settings" className={linkStyle}>Activity settings</NavLink>
      <NavLink to="archive" className={_ => clsx(linkStyle(_), "ml-auto text-red-600")}>Archive</NavLink>
    </nav>
    <div className="w-full h-full overflow-y-auto">
      <Outlet />
      {firm.isLoading && <Loading />}
      {firm.isError && <ErrorMessage id="manage-firm" message={String(firm.error)} />}
      {firm.isSuccess &&
        <Routes>
          <Route path="edit" element={<UpdateFirm firm={firm.data!} whenDone={() => firm.refetch()} />}/>
          <Route path="copy-portfolios" element={<CopyPortfolios firm={firm.data!} whenDone={() => firm.refetch()} />}/>
          <Route path="activity-settings" element={<ActivitySettings firm={firm.data!} whenDone={() => firm.refetch()} />}/>
          <Route path="archive" element={<ArchiveFirm firm={firm.data!} whenDone={() => { firm.refetch() }} />}/>
          <Route index element={<ViewFirm firm={firm.data!} />}/>
        </Routes>
      }
    </div>
  </div>
  )
}

export default ManageFirm