import { useMemo } from "react"
import { latest } from "../../../../lib/clients"
import { Client } from "../../../../models/Client"
import { Household } from "../../../../models/Household"
import { customDateFormat } from "../../../../lib/date"
import ScoreChart from "../components/ScoreChart/ScoreChart"
import useTaxScore from "../../../../hooks/useTaxScore"

const TaxTab = ({ client, household }: { client?: Client; household?: Household }) => {
  const clientOrHousehold = (client ?? household)!
  const clientGame = latest(clientOrHousehold, "tax")

  const latestHouseholdGames = useMemo(() => {
    return household?.members.map((member) => latest(member.client!, "tax")) ?? []
  }, [household?.members])

  const firstCompletedGame = household
    ? latestHouseholdGames.find((game) => game?.played) // Find the first completed game in household
    : clientGame

  const lastUpdatedDate = household ? firstCompletedGame?.played : clientGame?.played
  const completedMembers = household && household.members.filter((_, index) => latestHouseholdGames[index]?.played)

  const firstName = household ? completedMembers?.[0].client.firstName : client?.firstName
  const optimisationScore = useTaxScore(firstCompletedGame)

  return (
    <>
      <div className="tax-tab w-full flex flex-col items-start gap-10 py-10">
        <h1 className="sr-only">Retirement income</h1>
        <section className="flex justify-between items-center gap-2 w-full text-left px-16">
          <p>
            {firstName}'s profile completed on {customDateFormat(lastUpdatedDate!)}
          </p>
        </section>
        <section className="w-full flex flex-col gap-6 px-16">
          <div className="flex flex-col gap-1">
            <h2 className="text-emph uppercase">Tax optimization</h2>
            <p className="text-h3 font-semibold">
              {firstName}{" "}
              {optimisationScore?.rating === "high"
                ? "would benefit from a tax-efficient portfolio"
                : "is likely to be hesitant towards tax-efficient investing"}
            </p>
          </div>
          <div className="flex flex-col gap-2">
            <p>
              {firstName}
              {optimisationScore?.rating === "high"
                ? "'s willingness to deviate from the benchmark to minimize their tax burden suggests we should explore tax-optimized investments."
                : "'s focus on minimizing benchmark deviation suggests we should ensure any allocation to tax-optimized investments closely aligns to benchmark performance."}
            </p>
            <p>
              A tax-efficient portfolio employs tax-loss harvesting and asset allocation strategies to enhance after-tax returns while maintaining performance
              comparable to your current strategy.
            </p>
          </div>
        </section>
        <hr className="w-full border-surface-300" />
        <section className="w-full flex flex-col gap-6 px-16">
          <div className="flex justify-between">
            <div className="flex flex-col gap-1">
              <h2 className="text-emph uppercase">Tax efficiency score</h2>
              <p className="text-h3 font-semibold">
                {firstName}
                {optimisationScore?.rating === "high" ? "'s preferences align with tax-efficient investing" : "prefers to minimize benchmark deviation"}
              </p>
            </div>
            <div className="flex flex-col items-end">
              <span className="text-h2 font-semibold text-main-400">{optimisationScore?.score}</span>
              <span className="text-sm">Tax efficiency score</span>
            </div>
          </div>
          {optimisationScore && (
            <div className="risk-attitude-line-chart w-full self-center !h-80">
              <ScoreChart client={household ? completedMembers?.[0].client : client} score={optimisationScore.score} title={`${firstName}'s overall preference for tax optimization`} />
              <div className="flex justify-between items-center text-sec font-semi text-main-400">
                <span>Minimize benchmark deviation</span>
                <span>Minimize tax burden</span>
              </div>
            </div>
          )}
        </section>
      </div>
    </>
  )
}

export default TaxTab
