const Tooltip = ({ text }: { text: string | JSX.Element}) => {
  return (
    <div className="w-max p-2 shadow bg-white flex rounded-2 border border-neutral-100">
      <div className="absolute w-2 h-2 rotate-45 -left-1 bottom-1/2 top-3 bg-white" />
      <div className="text-red-600 text-sm">
        <p>{text}</p>
      </div>
    </div>
  )
}

export default Tooltip
