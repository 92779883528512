import { clsx } from "clsx"
import { useEffect, useState } from "react"
import RadioButton from "../../../../components/RadioButton/RadioButton"
import { useTheme } from "../../../../contexts/ThemeContext"
import { Client, GoalDetail } from "../../../../models/Client"
import { Household } from "../../../../models/Household"
import GoalCardMenu from "./GoalCardMenu"
import GoalPriorityLabel from "./GoalPriorityLabel"
import CustomGoalIcon from "./assets/CustomGoalIcon"
import CardTitle from "./components/CardTitle"

interface Props {
  alignVertical?: "bottom" | "top"
  client?: Client
  household?: Household
  goal: GoalDetail
  isSelected: boolean
  onChange: (goal: GoalDetail) => void
  onDelete: () => void
}

const GoalCard = ({ client, household, goal, isSelected = false, onChange, onDelete }: Props) => {
  const theme = useTheme()
  const clientOrHousehold = household ?? client
  const [goalDetails, setGoalDetails] = useState<GoalDetail[]>(clientOrHousehold?.goals?.goalDetails ?? [])

  useEffect(() => {
    setGoalDetails(clientOrHousehold?.goals?.goalDetails ?? [])
  }, [clientOrHousehold?.goals?.goalDetails, goalDetails.length])

  return (
    <>
      <div className="relative flex items-center">
        <label
          htmlFor={goal.type + goal.id}
          className={clsx("relative flex flex-col gap-3 text-left border border-highlight-200 p-3 w-[198px] cursor-pointer", {
            "bg-interactive-200 border-interactive-500": isSelected
          })}
        >
          <RadioButton
            name="selected-goal"
            id={goal.type + goal.id}
            className="hidden"
            checked={isSelected}
            onChange={() => onChange(goal)}
            aria-checked={isSelected}
          />
          <span id={goal.type + goal.id + "-label"} className="text-sec font-semibold">
            <CardTitle client={client} household={household} goalDetails={goalDetails} goal={goal} />
          </span>
          <div className="w-full flex justify-between items-center gap-2">
            {goal.type === "createOwnGoal" ? (
              <CustomGoalIcon backgroundColor={theme.goals.find((g) => g.type === goal.type)?.color?.[Number(goal.id)]} />
            ) : (
              <img src={theme.goals.find((g) => g.type === goal.type)?.icon} alt="" aria-hidden />
            )}
            {goal.priority && <GoalPriorityLabel priority={goal.priority} />}
          </div>
          <GoalCardMenu
            client={client}
            household={household}
            goal={goal}
            onDelete={onDelete}
            btnClassName="absolute top-0 right-0"
            dropdownClassName="-mt-14"
          />
        </label>
      </div>
    </>
  )
}

export default GoalCard
