import React, { useCallback, useEffect, useState } from 'react'
import { useMutation, useQuery } from "react-query"
import axiosInstance from "../../../../api/axiosInstance"
import { Firm } from "../../../../models/Firm"
import { AxiosError } from "axios"
import TextInput from "../../../../components/TextInput/TextInput"
import Loading from "../../../../components/ClientProfile/Loading/Loading"
import { AnimatePresence } from "framer-motion"
import Modal from "../../../../components/Modal/Modal"
import { useNavigate } from "react-router"
import { BillingCode } from "../../../../models/Billing"
import ErrorMessage from '../../../../components/Error/ErrorMessage'


type CodeDescriptor = {
  code: string,
  name: string,
  description?: string
}



const CreateBillingCode = () => {

  const [{ code, name, description }, setCode] = useState<Partial<CodeDescriptor>>({ code: "", name: "", description: "" })
  const [hasEditingStarted, setHasEditingStarted] = useState(false)
  const navigate = useNavigate()

  const existingCodes = useQuery(["billing/codes-with-stats"],
    () => axiosInstance.get<(BillingCode & { firms: Partial<Firm>[] })[]>(`${import.meta.env.VITE_APP_API_BASE || ""}/api/admin/billing/codes-with-stats`)
      .then(res => res.data),
    {
      staleTime: 300*1000,
      refetchOnWindowFocus: false
    }
  )
  
  const createCode = useMutation<any, AxiosError<{ message?: string } | string | undefined>, { code: string, name?: string, description?: string }>(
    (payload) => axiosInstance.post(`${import.meta.env.VITE_APP_API_BASE || ""}/api/admin/billing/codes`, payload)
      .then(res => res.data)
  )

  const isValid = code && /[a-z0-9]{2,}/.test(code)
  const isNameValid = name && /[A-Za-z0-9]/.test(name)
  const isDuplicate = code && existingCodes.data?.some(c => c._id?.toLowerCase() === code.toLowerCase())
  const isNameLikelyDuplicate = name && existingCodes.data?.some(c => c.name?.toLowerCase() === name.toLowerCase())

  useEffect(() => {
    setHasEditingStarted(hasEditingStarted || Boolean(name))
  }, [hasEditingStarted, name])

  const done = useCallback(() => {
    existingCodes.remove()
    navigate("../codes")
  }, [existingCodes, navigate])
  
  
  return (
    <div className="w-full h-full px-10 flex flex-col gap-y-4 mt-5">
      <h4 className="text-h4">Create a new billing code</h4>
      <form
        onSubmit={(e) => {
          if (isValid) {
            createCode.mutate({ code: code.replaceAll(/\s+/g, " ").trim(), name, description })
          }
          e.preventDefault(); e.stopPropagation()
        }}
        className="max-w-md flex flex-col gap-y-4 items-start"
      >
        <TextInput isDisabled={createCode.isSuccess} label="Billing code ID" name="code" value={code} onChange={(code) => setCode({ code: code.replaceAll(/ /g, "-").replaceAll(/[^a-zA-Z0-9-_]/g, "").toLowerCase(), name, description })} error={isDuplicate ? "Duplicate" : undefined} />
        <div className="w-full">
          <TextInput error={hasEditingStarted && !isNameValid ? "Please enter a valid name" : undefined } isDisabled={createCode.isSuccess} label="Billing code name" name="name" value={name} onChange={(name) => setCode({ code, name, description })} />
          {isNameLikelyDuplicate && <p className="text-alt-critical text-sec mt-1">This is likely a duplicate</p>}
        </div>
        <TextInput type="textarea" isDisabled={createCode.isSuccess} label="Description (optional)" name="description" value={description} onChange={(description) => setCode({ code, name, description })} />
        <button
          disabled={!isValid || !isNameValid || !!isDuplicate || createCode.isLoading || createCode.isSuccess}
          className="btn btn-primary btn-medium w-48" type="submit">
            {createCode.isLoading ? <Loading /> : <>Create</>}
        </button>
        <div role="alert">
          {createCode.error?.message && 
            <ErrorMessage id="create-billing-code" message={createCode.error?.message} />
          }
        </div>
        <AnimatePresence>
          {createCode.isSuccess && <Modal handleClose={done} handleConfirm={done}>
            <p>Billing code created.</p>
          </Modal>}
        </AnimatePresence>
      </form>
    </div>
  )
}

export default CreateBillingCode