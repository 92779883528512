import { clsx } from "clsx"
import { AnimatePresence } from "framer-motion"
import React, { useCallback, useEffect, useState } from "react"
import checkmark from "../../../../assets/icons/check-circle-navy.svg"
import Checkbox from "../../../../components/Checkbox/Checkbox"
import Modal from "../../../../components/Modal/Modal"
import StepInput from "../../../../components/StepInput/StepInput"
import { useTheme } from "../../../../contexts/ThemeContext"
import { tt } from "../../../../lib/translations"
import { GoalType } from "../../../../models/Client"
import plusIcon from "../../../advisor/assets/images/plus-circle.svg"

export type SelectedGoal = {
  goalType: GoalType
  quantity: number
}

type Props = {
  selectedGoals?: SelectedGoal[]
  onChange: (selectedGoals: SelectedGoal[]) => void
  handleConfirm?: (selectedGoals: SelectedGoal[]) => void
  handleClose?: () => void
  isAddInProgress?: boolean
}

const GoalsAdd: React.FC<Props> = ({ selectedGoals = [], onChange }) => {
  const theme = useTheme()
  const min = 1
  const max = 10
  const [isError, setIsError] = useState<boolean | undefined>(false)
  const [quantity, setQuantity] = useState<number | undefined>(1)
  const [currentGoal, setCurrentGoal] = useState<GoalType>()
  const [showQuantityModal, setShowQuantityModal] = useState<boolean>()
  const [shouldValidate, setShouldValidate] = useState<boolean>(false)

  const adjustQuantity = (delta: number) => {
    const newQuantity = quantity! + delta
    if (newQuantity >= min && newQuantity <= max) {
      setQuantity(newQuantity)
      setIsError(false)
    } else {
      setIsError(true)
    }
  }

  const handleDecrement = () => adjustQuantity(-1)
  const handleIncrement = () => adjustQuantity(1)

  const onCardClick = useCallback(
    (goalType: GoalType, isAdding?: boolean) => {
      if (goalType === "createOwnGoal" || goalType === "education") {
        const existingGoal = selectedGoals.find((goal) => goal.goalType === goalType)
        if (existingGoal && !isAdding) {
          onChange(selectedGoals.filter((goal) => goal.goalType !== goalType))
        } else {
          setCurrentGoal(goalType)
          setQuantity(existingGoal?.quantity || 1)
          setShowQuantityModal(true)
          onChange(existingGoal ? selectedGoals : [...selectedGoals, { goalType, quantity: 1 }])
        }
      } else {
        const existingGoal = selectedGoals.find((goal) => goal.goalType === goalType)
        if (existingGoal) {
          onChange(selectedGoals.filter((goal) => goal.goalType !== goalType))
        } else {
          onChange([...selectedGoals, { goalType, quantity: 1 }])
        }
      }
    },
    [onChange, selectedGoals]
  )

  const isValid = useCallback((amount?: number) => amount !== undefined && amount >= min && amount <= max, [max, min])
  const onQuantityConfirm = useCallback(() => {
    setShouldValidate(true)
    if (isValid(quantity) && currentGoal) {
      onChange(selectedGoals.map((goal) => (goal.goalType === currentGoal ? { ...goal, quantity: quantity! } : goal)))
      setShowQuantityModal(false)
    } else {
      setIsError(true)
    }
    setQuantity(1)
  }, [currentGoal, isValid, onChange, quantity, selectedGoals])

  useEffect(() => {
    if (shouldValidate) {
      setIsError(!isValid(quantity))
    }
  }, [shouldValidate, isValid, quantity])

  return (
    <>
      <div className="flex flex-col gap-10 items-center text-left">
        <div className="flex gap-5 flex-wrap">
          {theme.goals
            .filter((goal) => goal.type !== "createOwnGoal" || selectedGoals.some((g) => g.goalType === "createOwnGoal"))
            .map((goal) => {
              const selectedGoal = selectedGoals.find((g) => g.goalType === goal.type)
              const isChecked = Boolean(selectedGoal)
              return (
                <label
                  key={goal.type}
                  className={clsx(
                    "relative w-52 h-30 border border-interactive-200 p-4 py-6 flex flex-col items-center gap-1 cursor-pointer hover:bg-interactive-100 active:bg-interactive-200",
                    {
                      "border-interactive-500 bg-interactive-200": isChecked
                    }
                  )}
                >
                  <Checkbox className="sr-only" name={`goal-${goal.type}`} checked={isChecked} onChange={() => onCardClick(goal.type)} />
                  <img className="w-11" src={goal.icon} alt="" aria-hidden />
                  <div className="flex items-center gap-1">
                    <h3 className="text-sec font-semibold">{tt({ id: `profile-page-status-card-goals-goal-${goal.type}-title` })}</h3>
                    {(goal.type === "createOwnGoal" || goal.type === "education") && isChecked && (
                      <div className="w-3.5 h-3.5 rounded-full bg-highlight-600 flex items-center justify-center">
                        <span className="text-white text-center text-xxs leading-[10px] font-bold">{selectedGoal?.quantity}</span>
                      </div>
                    )}
                  </div>
                  {isChecked && <img className="absolute bottom-0 -mb-1.5" src={checkmark} alt="" aria-hidden />}
                </label>
              )
            })}
          <button
            className="w-52 h-30 border border-dotted border-interactive-200 flex items-center justify-center gap-2 px-4 py-6 cursor-pointer hover:bg-interactive-100 active:bg-interactive-200"
            onClick={() => {
              onCardClick("createOwnGoal", true)
            }}
          >
            <img className="w-5" src={plusIcon} alt="" aria-hidden />
            <span className="text-sec font-bold leading-4 text-interactive-600">Add own goal</span>
          </button>
        </div>
      </div>
      <AnimatePresence>
        {showQuantityModal && (
          <Modal
            className="w-modal"
            handleClose={() => {
              setQuantity(1)
              setShowQuantityModal(false)
            }}
          >
            <div className="w-full flex flex-col items-center gap-6">
              <h1 className="w-full text-h2 font-semibold">{tt({ id: `goal-add-quantity-modal-${currentGoal}` })}</h1>
              <StepInput
                id="goals-add"
                className="h-full"
                error={isError ? `Please select between ${min} and ${max} goals` : undefined}
                label={tt({ id: `goals-add-quantity-input-${currentGoal}`, message: "How many goals do you want to add?" })}
                min={min - 1}
                max={max}
                onIncrement={handleIncrement}
                onDecrement={handleDecrement}
                onChange={setQuantity}
                value={quantity}
              />
              <div className="flex gap-5">
                <button
                  className="btn btn-secondary btn-medium w-44"
                  onClick={() => {
                    setShowQuantityModal(false)
                    setQuantity(1)
                  }}
                >
                  Cancel
                </button>
                <button className="btn btn-primary btn-medium w-44" onClick={onQuantityConfirm}>
                  Add
                </button>
              </div>
            </div>
          </Modal>
        )}
      </AnimatePresence>
    </>
  )
}

export default GoalsAdd
