import Modal from "../Modal/Modal"
import warning from "../../pages/advisor/ImportClientsPage/assets/warning.svg"

const UnsavedChangesModal = ({ onClose, onCancel, onConfirm }: { onClose: () => void; onCancel: () => void; onConfirm: () => void }) => {
  return (
    <Modal className="mx-0" contentClassName="w-modal !px-10" handleClose={onClose}>
      <div className="reprofile-modal-wrapper relative flex flex-col gap-y-6">
        <img className="h-16" src={warning} alt="" aria-hidden />
        <h2 className="text-h2 text-center font-semibold text-main-600">You have unsaved changes</h2>
        <p className="text-p text-center text-main-600">This page contains unsaved changes that will be lost. Do you still wish to leave the page?</p>
        <div className="w-full flex justify-center gap-4">
          <button onClick={onCancel} className="btn btn-secondary btn-medium w-44">
            Cancel
          </button>
          <button onClick={onConfirm} className="btn btn-primary btn-medium w-44">
            Confirm
          </button>
        </div>
      </div>
    </Modal>
  )
}

export default UnsavedChangesModal
