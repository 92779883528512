import NumberInput from "../NumberInput/NumberInput"
import ErrorMessage from "../Error/ErrorMessage"
import minus from "./assets/minus.svg"
import plus from "./assets/plus.svg"

interface Props {
  className?: string
  error?: string
  id: string
  label?: string
  value?: number
  onIncrement: () => void
  onDecrement: () => void
  onChange: (value?: number) => void
  min?: number
  max?: number
}

const StepInput: React.FC<Props> = ({ className, error, id, label, value, onIncrement, onDecrement, onChange, min, max }) => {
  return (
    <div className="w-full h-full flex flex-col gap-y-1">
      <label className="text-sec font-semibold leading-4 mb-1" style={{ whiteSpace: "pre-line" }} htmlFor={id}>
        {label && <span className="text-input-label w-full flex-shrink-0 font-semibold">{label}</span>}
      </label>
      <div className="flex items-center gap-2 h-12">
        <button onClick={onDecrement} aria-label="Decrease quantity" className="btn btn-secondary p-3.5 h-full">
          <img className="w-5 h-5" src={minus} alt="" aria-hidden />
        </button>
        <NumberInput
          min={min}
          max={max}
          disableError={true}
          name={id}
          value={value}
          onChange={onChange}
          className="!w-28 h-full"
          inputClassName={className}
          wrapperClassName="!h-12"
          aria-label="Number input"
        />
        <button onClick={onIncrement} aria-label="Increase quantity" className="btn btn-secondary p-3.5 h-full">
          <img className="w-5 h-5" src={plus} alt="" aria-hidden />
        </button>
      </div>
      <div role="alert">
        {error &&
          <ErrorMessage id="step-input" message={error} />
        }
      </div>
    </div>
  )
}

export default StepInput