import { ErrorBoundary } from "react-error-boundary"
import { QueryClient, QueryClientProvider } from "react-query"
import { createBrowserRouter, createRoutesFromElements, Route, RouterProvider, Routes } from "react-router-dom"
import AppWithAuth from "./AppWithAuth"
import TrackerGA4 from "./components/Analytics/TrackerGA4"
import AppContextProvider from "./contexts/AppContext"
import MainLayout from "./layout/MainLayout"
import ClientStatusPage from "./pages/advisor/ClientStatusPage"
import ClientsListPage from "./pages/advisor/ClientsListPage"
import CreateClientPage from "./pages/advisor/CreateClientPage"
import HouseholdStatusPage from "./pages/advisor/HouseholdStatusPage"
import ClientStatusSideBar from "./pages/advisor/sidebars/client/ClientStatusSideBar"
import HouseholdStatusSideBar from "./pages/advisor/sidebars/household/HouseholdStatusSideBar"
import FirmAdminPage from "./pages/firm/FirmAdminPage"
import RMJourney from "./pages/rmjourney/RMJourney"
import AuthProvider from "./views/auth/AuthContext"

import { HelmetProvider } from "react-helmet-async"
import ErrorPage from "./components/Error/ErrorPage"
import ModalPresenter from "./components/ModalPresenter/ModalPresenter"
import ClientHouseholdCacheContextProvider from "./contexts/ClientHouseholdCacheContext"
import ThemeContextProvider from "./contexts/ThemeContext"
import TourControllerProvider from "./contexts/TourController"
import UserPoliciesContextProvider from "./contexts/UserPoliciesContext"
import errorImage from "./layout/images/plug-unplugged.svg"
import PortalNavBar from "./layout/navbars/PortalNavBar"
import SelectNavBar from "./layout/navbars/SelectNavBar"
import AdminRoutes from "./pages/admin/AdminRoutes"
import AdminSideBar from "./pages/admin/sidebar/AdminSidebar"
import AnalyticsPage from "./pages/advisor/AnalyticsPage"
import ArchivedListPage from "./pages/advisor/ArchivedListPage"
import ImportClientsPage from "./pages/advisor/ImportClientsPage/ImportClientsPage"
import PortfolioAnalyser from "./pages/advisor/PortfolioAnalyserPage"
import ReportRedirect from "./pages/advisor/ReportRedirect"
import ResultsPage from "./pages/advisor/Results/ResultsPage"
import InvestmentMeetingReport from "./pages/clients/reports/InvestmentMeetingReport"
import EconomicFingerprintPage from "./pages/efp/EconomicFingerprintPage"
import AssetClassesPage from "./pages/firm/AssetClassesPage"
import ModelPortfoliosPage from "./pages/firm/ModelPortfoliosPage"
import TeamMembersPage from "./pages/firm/TeamMembersPage"
import RMExplorer from "./pages/rmjourney/RMExplorer"
import RMJourneyNavbar from "./pages/rmjourney/RMJourneyNavbar"
import RMJourneySidebar from "./pages/rmjourney/RMJourneySidebar"
import RMJGoalProjectorDisclaimer from "./pages/rmjourney/footerDisclaimer/RMJGoalProjectorDisclaimer"
import SelectRoute from "./pages/select/SelectRoute"
import RedirectExternalClient from "./routes/RedirectExternalClient"
import CRMRoutes from "./pages/crm/CRMRoutes"

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: Infinity,
      refetchOnWindowFocus: false,
      retry: 3
    }
  }
})

function DisplayErrorDev(props: any) {
  return (
    <div className="w-full h-full bg-surface-100 flex flex-col items-center justify-center gap-11" role="alert">
      <img src={errorImage} alt="A plug that's been unplugged" />
      <div className="flex flex-col items-center justify-center gap-8 text-center">
        <h1 className="text-display font-semibold">Hey, what is going on?</h1>
        <p>{props.error?.toString()}</p>
        <button className="btn btn-primary btn-large" onClick={() => props.resetErrorBoundary()}>
          I have fixed it, let's try to re-render...
        </button>
      </div>
    </div>
  )
}

const ReportProviders = ({ children }: { children: React.ReactNode }) => (
  <AppContextProvider>
    <ThemeContextProvider fallbackToBuiltInTheme={false}>
      <ClientHouseholdCacheContextProvider>{children}</ClientHouseholdCacheContextProvider>
    </ThemeContextProvider>
  </AppContextProvider>
)

const CRMEmbedProviders = ({ children }: { children: React.ReactNode }) => (
  <AppContextProvider>
    <ThemeContextProvider fallbackToBuiltInTheme={false}>
      <ClientHouseholdCacheContextProvider>{children}</ClientHouseholdCacheContextProvider>
    </ThemeContextProvider>
  </AppContextProvider>
)


export default function App() {
  return <RouterProvider router={createBrowserRouter(
    createRoutesFromElements(
      <Route
        path="*"
        element={
          <HelmetProvider>
            <TrackerGA4>
              <ErrorBoundary FallbackComponent={process.env.NODE_ENV === "development" ? DisplayErrorDev : ErrorPage}>
                <QueryClientProvider client={queryClient}>
                  <AuthProvider>
                    <ThemeContextProvider fallbackToBuiltInTheme={true}>
                      <Routes>
                        <Route path="client/efp/:uuid" element={<EconomicFingerprintPage />} />
                        <Route
                          path="*"
                          element={
                            <AppWithAuth>
                              <Routes>
                                <Route path="reports/report" element={<ReportRedirect />} />
                                <Route path="reports/rm/pre-meeting" element={<ReportProviders><InvestmentMeetingReport /></ReportProviders>} />
                                <Route path="reports/rm/post-meeting" element={<ReportProviders><InvestmentMeetingReport postMeeting={true} /></ReportProviders>} />
                                <Route path="redirect/external/client/:provider/:externalId" element={<RedirectExternalClient />} />
                                <Route path="/embed/client/:clientId/*" element={<CRMEmbedProviders><CRMRoutes /></CRMEmbedProviders>} />
                                <Route
                                  path="*"
                                  element={
                                    <AppContextProvider>
                                      <ThemeContextProvider fallbackToBuiltInTheme={false}> {/* theme needs firm loaded */}
                                        <TourControllerProvider>
                                          <UserPoliciesContextProvider>
                                            <ClientHouseholdCacheContextProvider>
                                              <MainLayout
                                                footerDisclaimer={
                                                  <Routes>
                                                    <Route path="/clients/:clientId/rm/goal-explorer" element={<RMJGoalProjectorDisclaimer />} />
                                                    <Route path="/households/:householdId/rm/goal-explorer" element={<RMJGoalProjectorDisclaimer />} />
                                                    <Route path="*" element={<div></div>} />
                                                  </Routes>
                                                }
                                                sidebar={
                                                  <Routes>
                                                    <Route path="/clients/:clientId/rm/*" element={<RMJourneySidebar />} />
                                                    <Route path="/households/:householdId/rm/*" element={<RMJourneySidebar />} />
                                                    <Route path="/clients/:clientId/*" element={<ClientStatusSideBar />} />
                                                    <Route path="/households/:householdId/*" element={<HouseholdStatusSideBar />} />
                                                    <Route path="/admin/*" element={<AdminSideBar />} />
                                                    <Route path="*" element={<></>} />
                                                  </Routes>
                                                }
                                                navbar={
                                                  <Routes>
                                                    <Route path="/clients/:clientId/rm/overview" element={<></>} />
                                                    <Route path="/households/:householdId/rm/overview" element={<></>} />
                                                    <Route path="/clients/:clientId/rm/*" element={<RMJourneyNavbar />} />
                                                    <Route path="/households/:householdId/rm/*" element={<RMJourneyNavbar />} />
                                                    <Route path="/select" element={<SelectNavBar />} />
                                                    <Route path="/*" element={<PortalNavBar />} />
                                                  </Routes>
                                                }
                                              >
                                                <Routes>
                                                  <Route path="create-client" element={<CreateClientPage />} />
                                                  <Route path="import-clients" element={<ImportClientsPage />} />
                                                  <Route path="firm/team" element={<TeamMembersPage />} />
                                                  <Route path="preference-insights" element={<AnalyticsPage />} />
                                                  <Route path="archived" element={<ArchivedListPage />} />
                                                  <Route path="clients/:clientId/rm/*" element={<RMJourney />} />
                                                  <Route path="households/:householdId/rm/*" element={<RMJourney />} />
                                                  <Route path="clients/:clientId/explore/*" element={<RMExplorer />} />
                                                  <Route path="households/:householdId/explore/*" element={<RMExplorer />} />
                                                  <Route path="clients/:clientId" element={<ClientStatusPage />} />
                                                  <Route path="households/:householdId" element={<HouseholdStatusPage />} />
                                                  {import.meta.env.VITE_APP_THEME_JPMAM === "true" && (
                                                    <Route path="clients/:clientId/portfolio-analyser" element={<PortfolioAnalyser />} />
                                                  )}
                                                  <Route path="clients/:clientId/results/*" element={<ResultsPage />} />
                                                  <Route path="households/:householdId/results/*" element={<ResultsPage />} />
                                                  <Route path="firm/manage" element={<FirmAdminPage />} />
                                                  <Route path="firm/asset-classes" element={<AssetClassesPage />} />
                                                  <Route path="firm/model-portfolios" element={<ModelPortfoliosPage />} />
                                                  <Route path="admin/*" element={<AdminRoutes />} />
                                                  <Route path="select" element={<SelectRoute />} />
                                                  <Route path="/" element={<ModalPresenter><ClientsListPage /></ModalPresenter>} />
                                                </Routes>
                                              </MainLayout>
                                            </ClientHouseholdCacheContextProvider>
                                          </UserPoliciesContextProvider>
                                        </TourControllerProvider>
                                      </ThemeContextProvider>
                                    </AppContextProvider>
                                  }
                                />
                              </Routes>
                            </AppWithAuth>
                          }
                        />
                      </Routes>
                    </ThemeContextProvider>
                  </AuthProvider>
                </QueryClientProvider>
              </ErrorBoundary>
            </TrackerGA4>
          </HelmetProvider>
        }
      />
    )
  )} />
}
