import { Trans } from "@lingui/macro"
import clsx from "clsx"
import { useContext, useMemo } from "react"
import { useSearchParams } from "react-router-dom"
import PercentageBar from "../../../../components/PercentageBar/PercentageBar"
import { FirmContext } from "../../../../contexts/FirmContext"
import { latest } from "../../../../lib/clients"
import { tt } from "../../../../lib/translations"
import { ActionItemLabels } from "../../../../models/ActionItem"
import { Client } from "../../../../models/Client"
import { Household } from "../../../../models/Household"
import PortfolioDetail from "../../components/PortfolioDetail"
import insightIcon from "../assets/insight-icon.svg"
import { allAssetClasses, assetTypeWeights } from "../ComfortMatchPage"
import Container from "../components/Container"
import Footer from "../components/Footer"
import checkMark from "./assets/checkmark.svg"
import footerImage from "./assets/footer.svg"
import { portfolioModelSecondaryText, rtScores, sensitvityToLossText } from "./assets/profileTypes"
import { sortedGoalDetails } from "../InvestmentMeetingReport"

const RiskProfilePage = ({
  page,
  client,
  household,
  isPostMeeting = false
}: {
  page: number
  client: Client
  household: Household
  isPostMeeting?: boolean
}) => {
  const [par] = useSearchParams()
  const nextMeetingMonths = par.get("nextMeetingMonths")
  const clientOrHousehold = client ?? household
  const game = latest(clientOrHousehold, "risk")
  const portfolioMappings = game?.portfolioMappings
  const riskProfileMatch = [...(portfolioMappings || [])].sort((a, b) => b.riskComfort! - a.riskComfort!)
  const { firm } = useContext(FirmContext)

  const assetClasses = allAssetClasses(game?.assetClasses ?? []) || {}

  const assetWeights = assetTypeWeights(riskProfileMatch[0].portfolio.components, assetClasses).filter(({ assetClass }) => assetClass === "growth")

  const comfortMatchText = portfolioModelSecondaryText(riskProfileMatch[0].portfolio.name, assetWeights?.[0]?.total ?? 0)

  const currentPortfolio = useMemo(() => {
    if (clientOrHousehold.goals?.goalDetails &&  clientOrHousehold.goals?.goalDetails.length > 0) {
      const goal = sortedGoalDetails(clientOrHousehold.goals.goalDetails)[0]
      return firm?.modelPortfolios?.find((modelPortfolio) => modelPortfolio.id === goal.selectedPortfolio)  
    } else {
      return firm?.modelPortfolios?.find((modelPortfolio) => modelPortfolio.id === clientOrHousehold.currentPortfolio)
    }
  },
    [clientOrHousehold.currentPortfolio, clientOrHousehold.goals?.goalDetails, firm?.modelPortfolios]
  )
  const currentPortfolioScore = game?.portfolioMappings?.find((val) => val.portfolio.id === currentPortfolio?.id)

  const actionItems = useMemo(
    () =>
      [...(game?.actionItems ?? [])]?.filter(
        (item) =>
          item.value &&
          item.key !== "comfortable-with-investment-risk" &&
          item.key !== "increase-goal-achieving-likelihood" &&
          item.key !== "increase-goal-achieving-likelihood-of-investment-drawdown"
      ),
    [game?.actionItems]
  )

  return (
    <Container className="flex flex-col justify-between pt-10 relative">
      <div className="flex-auto mx-10">
        <h2 className="mb-1 text-main-600 text-sec tracking-wide uppercase">Session summary</h2>
        <h3 className="font-semibold text-main-600 text-h4">
          {isPostMeeting
            ? currentPortfolio?.id === riskProfileMatch[0].portfolio.id
              ? `You selected to continue with a ${currentPortfolio.name} risk level`
              : `We’ll consider a ${currentPortfolio?.name} risk level in our planning process`
            : client
            ? tt({
                id: "risk-profile-page-client-comfort-zone-text",
                message: `Your risk comfort zone centres around a ${riskProfileMatch[0].portfolio.name} risk level`,
                values: { portfolioName: riskProfileMatch[0].portfolio.name }
              })
            : tt({
                id: "risk-profile-page-household-comfort-zone-text",
                message: `Your household risk comfort zone centres around a ${riskProfileMatch[0].portfolio.name} risk level`,
                values: { portfolioName: riskProfileMatch[0].portfolio.name }
              })}
        </h3>
        {!isPostMeeting && (household || client) && riskProfileMatch && (
          <div className="risk-profile-summary bg-surface-100 w-full mt-6 mb-8 p-8 gap-x-4 flex justify-between items-center">
            <img src={insightIcon} alt="" className="w-16 h-16" aria-hidden />
            <div className="text-sec text-main-500 leading-tight">
              {comfortMatchText} When we meet we will review your preferences and determine together what risk level is right for you.
            </div>
          </div>
        )}
        {isPostMeeting && (
          <div className="bg-surface-100 w-full mt-6 mb-8 px-10 py-8 gap-x-4 flex flex-row">
            <div className="w-auto mr-24">{currentPortfolio && <PortfolioDetail portfolio={currentPortfolio} forReport={true} />}</div>
            <div className="w-56 pb-3">
              <h4 className="font-semibold text-main-600 text-sec">Alignment score</h4>
              <PercentageBar percentage={currentPortfolioScore?.riskComfort ?? 0} label={`Risk Comfort ${currentPortfolioScore?.riskComfort ?? 0}%`} />
            </div>
          </div>
        )}
        <div className="font-semibold">
          <h2 className="mb-1 text-main-600 text-sec tracking-wide uppercase">Your Risk Profile</h2>
          <h3 className="font-semibold text-main-600 text-h4">
            {client ? "What your results tell us about you" : "What your results tell us about each of you"}
          </h3>
          {household ? (
            <>
              <div className="flex gap-x-4 mt-8">
                {household.members.map(({ client }, i) => {
                  const colorIndex = i % household.members.length
                  const game = latest(client, "risk")
                  return (
                    <div key={client._id} className="h-full w-86">
                      <div className="flex items-center gap-2 h-10">
                        <div
                          className={clsx("w-6 h-6 flex justify-center items-center text-white rounded-full", {
                            "bg-avatar-0-500": colorIndex === 0,
                            "bg-avatar-1-500": colorIndex === 1
                          })}
                        >
                          {client.firstName.charAt(0)}
                        </div>
                        <div className="font-normal text-sm text-main-500">{client.firstName}</div>
                      </div>
                      {game?.risk.results?.R && (
                        <div className="text-sec text-main-600">
                          <h4 className="font-bold">{rtScores(game.risk.results.R.riskTolerance).title}</h4>
                          <p className="mt-2 leading-tight">{rtScores(game.risk.results.R.riskTolerance).body}</p>
                        </div>
                      )}
                      {game?.risk.results?.R && (
                        <div className="text-sec mt-4 text-main-600">
                          <h4 className="font-bold">{sensitvityToLossText(game.risk.results.R.lossAversion).title}</h4>
                          <p className="mt-2 leading-tight">{sensitvityToLossText(game.risk.results.R.lossAversion).body}</p>
                        </div>
                      )}
                    </div>
                  )
                })}
              </div>
              <div className="text-sec mt-4 text-main-600">
                <h4 className="font-bold mb-2">Considerations</h4>
                <p className="mb-6">You may need to adjust your risk level as we consider your goals and constraints in the planning process.</p>
              </div>
            </>
          ) : (
            game?.risk.results?.R && (
              <div className="text-sec mt-8 text-main-600">
                <h4 className="font-bold mb-2">{rtScores(game.risk.results.R.riskTolerance).title}</h4>
                <p className="mb-6">{rtScores(game.risk.results.R.riskTolerance).body}</p>

                <h4 className="font-bold mb-2">{sensitvityToLossText(game.risk.results.R.lossAversion).title}</h4>
                <p className="mb-6">{sensitvityToLossText(game.risk.results.R.lossAversion).body}</p>

                <h4 className="font-bold mb-2">Considerations</h4>
                <p className="mb-6">You may need to adjust your risk level as we consider your goals and constraints in the planning process.</p>
              </div>
            )
          )}
        </div>
      </div>

      <div className="h-full flex items-end">
        {isPostMeeting ? (
          <div className="w-full h-[354px] bg-surface-100 text-main-600 flex flex-col">
            <div className="flex flex-col w-full px-10 mt-15">
              <h2 className="mb-1 text-main-600 text-sec tracking-wide uppercase">Next Steps</h2>
              <h3 className="font-semibold text-main-600 text-h4">
                <Trans id="reports-risk-profile-page-actions-header">Your adviser will progress the following actions to support your experience</Trans>
              </h3>
            </div>
            <div className="flex px-10 mt-8">
              <div className="flex flex-row w-full text-sec gap-x-4 text-main-500">
                <ul className="mb-2 -mx-1">
                  {nextMeetingMonths && (
                    <li className="flex items-center justify-between text-main-500 hover:bg-interactive-100 p-1" key="next-meeting">
                      <div className="flex">
                        <img alt="tick" className="mr-1 w-5 h-5" src={checkMark} />
                        Meet again in {nextMeetingMonths} months
                      </div>
                    </li>
                  )}
                  {actionItems.slice(0, 3).map((item, i) => (
                    <li className="flex items-center justify-between text-main-500 text-sec font-normal hover:bg-interactive-100 p-1" key={`${item.key}-${i}`}>
                      <div className="flex items-center">
                        <img alt="tick" className="mr-1 w-5 h-5" src={checkMark} />
                        {item.key === "freetext" ? item.value : ActionItemLabels[item.key]}
                      </div>
                    </li>
                  ))}
                </ul>
                <ul className="mb-2 -mx-1">
                  {actionItems.slice(4).map((item, i) => (
                    <li className="flex items-center justify-between text-main-500 text-sec font-normal hover:bg-interactive-100 p-1" key={`${item.key}-${i}`}>
                      <div className="flex items-center">
                        <img alt="tick" className="mr-1 w-5 h-5" src={checkMark} />
                        {item.key === "freetext" ? item.value : ActionItemLabels[item.key]}
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div className="mx-9 h-full flex items-end">
              <Footer pageNum={page} client={client} household={household} firm={firm} className="relative" />
            </div>
          </div>
        ) : (
          <div className="w-full h-[354px]" style={{ backgroundImage: `url(${footerImage})` }}>
            <div className="mx-9 h-full flex items-end">
              <Footer
                pageNum={page}
                client={client}
                household={household}
                firm={firm}
                className="relative"
                textClassName="text-surface-100"
                borderClassName="border-t-surface-100"
              />
            </div>
          </div>
        )}
      </div>
    </Container>
  )
}

export default RiskProfilePage
