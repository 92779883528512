import axios, { AxiosError } from "axios"
import { clsx } from "clsx"
import { useCallback, useContext, useEffect, useState } from "react"
import { useMutation } from "react-query"
import AlertIcon from "../../assets/icons/AlertIcon"
import { useValidPassword } from "../../hooks/useAuthHooks"
import { Password } from "./AuthComponents"
import { AuthContext, SessionInfo } from "./AuthContext"
import { useNavigate } from "react-router"
import ErrorMessage from "../../components/Error/ErrorMessage"

type Props = {
  onError: (error: Error) => void,
  token: string
}

type PasswordErrors = {
  password?: string
  passwordConfirm?: string
}

export default function EnterNewPassword({ token }: Props) {
  const [shouldValidate, setShouldValidate] = useState(false)
  const [errors, setErrors] = useState<PasswordErrors>()
  const navigate = useNavigate()


  // const history = useHistory()
  const { signedIn } = useContext(AuthContext)
  const { password, setPassword, passwordIsValid } = useValidPassword('')
  const [error, setError] = useState('')


  const {
    password: passwordConfirm,
    setPassword: setPasswordConfirm,
    passwordIsValid: passwordConfirmIsValid,
  } = useValidPassword('')

  // const isInvalid = !passwordIsValid
  //   || password.length === 0
  //   || !passwordConfirmIsValid
  //   || passwordConfirm.length === 0
  //   || password !== passwordConfirm

  const {
    error: resetPasswordError, mutate: resetPassword, data: resetPasswordResponse
  } = useMutation<SessionInfo, AxiosError, { token: string, password: string }>(
      (payload) => axios.post(`${import.meta.env.VITE_APP_API_BASE}/api/auth/reset-password`, payload)
        .then(res => res.data)
  )
  
  useEffect(() => {
    if (resetPasswordError) {
      setError((resetPasswordError.response?.data as any)?.message ?? resetPasswordError.response?.data ?? resetPasswordError.response?.statusText)
    }
  }, [resetPasswordError])
  
  useEffect(() => {
    if (resetPasswordResponse) {
      signedIn(resetPasswordResponse)
      // history.push("/")
    }
  }, [resetPasswordResponse, signedIn])

  const doIt = useCallback(
    () => {
      if (token && password) {
        resetPassword({ token, password })
      }
    },
    [token, password, resetPassword],
  )
  




  const validate = useCallback(() => {
    const errs: PasswordErrors = {}
    errs.password = passwordIsValid
      ? undefined
      : "Password must be at least eight characters long, and contain at least one uppercase letter, one number, and one special character"
    errs.passwordConfirm = passwordConfirm && password !== passwordConfirm ? "Password confirmation doesn't match" : undefined
    setErrors(errs)
    return Object.values(errs).every((value) => value === undefined)
  }, [password, passwordConfirm, passwordIsValid])

  useEffect(() => {
    if (shouldValidate) {
      validate()
    }
  }, [password, passwordConfirm, passwordIsValid, shouldValidate, validate])

  const onResetPasswordClicked = async () => {
    setShouldValidate(true)
    const isValid = validate()
    if (isValid) {
      doIt()
      // try {
      //   await authContext.forgotPassword(props.username, props.code, password)
      //   navigate("/")
      // } catch (err: any) {
      //   if (err.name === "CodeMismatchException") {
      //     props.onError(err)
      //     return
      //   }
      // }
    }
  }

  const updatePassword = (
    <div className="flex flex-col justify-center items-center mx-auto my-auto">
      <div className="modal-container">
        <h1 className="modal-title text-left">Change password</h1>
        <Password error={errors?.password && "outline-red-500" } label="New password" passwordIsValid={passwordIsValid} setPassword={setPassword} />
        <div className={clsx("flex items-start gap-2 text-main-500 text-sm pt-1", { "text-red-600": errors?.password })}>
          {errors?.password && <AlertIcon className="h-3.5 w-auto"/>}
          <p className="w-full">Password must be at least eight characters long, and contain at least one uppercase letter, one number, and one special character.</p>
        </div>
        <div className="pt-4">
          <Password error={errors?.password && "outline-red-500" } id="password2" label="Confirm new password" passwordIsValid={passwordConfirmIsValid} setPassword={setPasswordConfirm} />
        </div>
        <div role="alert">
          {errors?.passwordConfirm && (
            <ErrorMessage id="enter-new-password" message={errors.passwordConfirm} />
          )}
        </div>
        <div className="flex gap-4 w-full max-w-[340px] m-auto pt-10">
          <button className="btn btn-secondary btn-medium text-sec flex-1" onClick={() => navigate(-1)}>
            Cancel
          </button>
          <button className="btn btn-primary btn-medium text-sec flex-1" onClick={onResetPasswordClicked}>
            Save changes
          </button>
        </div>
      </div>
    </div>
  )

  return updatePassword
}
