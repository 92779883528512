import { Trans } from "@lingui/macro"
import { useEffect, useRef, useState } from "react"
import { useTheme } from "../../../contexts/ThemeContext"
import { latest } from "../../../lib/clients"
import { Client } from "../../../models/Client"
import AvatarBadges from "../../../components/AvatarBadges/AvatarBadges"
import clsx from "clsx"

const RMJLossSensitivitySpectrum = ({ client }: { client: Client }) => {
  const theme = useTheme()
  const [graphWidth, setGraphWidth] = useState(0)
  const graphRef = useRef<HTMLDivElement>(null)
  const offset = (16 / graphWidth) * 100
  const game = latest(client, "risk")
  const score = game?.risk.results?.R.lossAversion ?? 50

  const calculateLinePosition = (lossAversion: number): number => {
    if (lossAversion <= 19) {
      return 100 - (lossAversion / 19) * 32
    } else if (lossAversion <= 49) {
      return 100 - (((lossAversion - 20) / 29) * 33 + 32)
    } else {
      return 100 - (((lossAversion - 50) / 50) * 33 + 66)
    }
  }

  const displacement = ((graphWidth * (calculateLinePosition(score) / 100)) / graphWidth) * 100

  useEffect(() => {
    const localRef = graphRef.current
    if (localRef) {
      const divResizeObserver = new ResizeObserver((e) => {
        setGraphWidth(e[0].contentRect.width)
      })
      divResizeObserver.observe(localRef)
      return () => {
        divResizeObserver.unobserve(localRef)
      }
    }
  }, [])

  const { dataPointIcon } = theme.reports?.riskChartsPage?.lineChart ?? {}
  return (
    <div className="w-full relative mt-18">
      <div ref={graphRef} className="risk-sensitivity-chart-line h-1 bg-surface-activity w-full" />
      <div className="flex flex-col items-center relative">
        <div className="absolute top-0 h-12 flex flex-col items-center -translate-y-full w-max" style={{ left: `${displacement - offset}%` }}>
          {dataPointIcon ? (
            <img className="w-8 h-8" src={dataPointIcon} alt="" aria-hidden />
          ) : <AvatarBadges clients={[client]} size="small" />}
          <hr className={clsx("h-full border-0 border-l", { "bg-highlight-600": dataPointIcon, "bg-avatar-0-500": !dataPointIcon })} />
          <div className={clsx("w-[5px] h-[5px] absolute bottom-0 rounded-full", { "bg-highlight-600": dataPointIcon, "bg-avatar-0-500": !dataPointIcon } )} />
        </div>
      </div>
      <div className="risk-sensitivity-chart-labels text-main-400 mt-1 flex justify-between text-sm">
        <p className="text-left w-18">
          <Trans id="risk-sensitivity-chart-high-label">Unsettled</Trans>
        </p>
        <p className="text-center w-32">
          <Trans id="risk-sensitivity-chart-medium-label">Somewhat unsettled</Trans>
        </p>
        <p className="text-right w-18">
          <Trans id="risk-sensitivity-chart-low-label">Composed</Trans>
        </p>
      </div>
    </div>
  )
}

export default RMJLossSensitivitySpectrum
