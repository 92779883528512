import { useMemo } from "react"
import { Game } from "../models/Client"

const useTaxScore = (game?: Game) => {
  return useMemo(() => {
    if (game?.gameType === "tax" && (game?.tax?.decisions?.T?.length ?? 0) > 0) {
      const averageChoiceX = Math.round(
        (game.tax.decisions?.T?.reduce((sum, item) => sum + item.choiceX!, 0) ?? 0) / (game.tax.decisions?.T?.length || 1)
      )
      const score = averageChoiceX
      const rating = score >= 20 ? "high" : "low"
      return { score, rating }
    }
    return undefined
  }, [game?.gameType, game?.tax.decisions?.T])
}

export default useTaxScore
