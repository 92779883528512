import { Trans } from "@lingui/macro"
import { formatISO } from "date-fns"
import { AnimatePresence } from "framer-motion"
import { useCallback, useContext, useMemo, useState } from "react"
import { Link } from "react-router-dom"
import { reprofile, update } from "../../../api/clients"
import { createGame } from "../../../api/createGame"
import { sendInvite } from "../../../api/sendInvite"
import linkIcon from "../../../assets/icons/link.svg"
import refreshIcon from "../../../assets/icons/refresh.svg"
import { AppContext } from "../../../contexts/AppContext"
import { ClientHouseholdCacheContext } from "../../../contexts/ClientHouseholdCacheContext"
import { FirmContext } from "../../../contexts/FirmContext"
import { useTheme } from "../../../contexts/ThemeContext"
import { TourControllerContext } from "../../../contexts/TourController"
import useTrackViewEvent from "../../../hooks/useTrackViewEvent"
import { areDecisionsIncoherent, latest, gameUrlByType } from "../../../lib/clients"
import { customDateFormat, formatDateLong } from "../../../lib/date"
import { Client, Game, GameType } from "../../../models/Client"
import { Household } from "../../../models/Household"
import Popover from "../../../pages/advisor/components/Popover/Popover"
import UnArchiveConfirmationModal from "../../../pages/advisor/components/UnArchiveConfirmationModal/UnArchiveConfirmationModal"
import AddClientEmailModal from "../../../pages/clients/components/AddClientEmailModal"
import ResultsAvailableMenu from "../../../pages/clients/components/Profile/components/ResultsAvailableMenu/ResultsAvailableMenu"
import { AuthContext } from "../../../views/auth/AuthContext"
import AlertPopover, { AlertPopoverOptions } from "../../AlertPopover/AlertPopover"
import DatePicker from "../../DatePicker/DatePicker"
import Modal from "../../Modal/Modal"
import Loading from "../Loading/Loading"
import ClientStatusAlert, { StatusAlert } from "./ClientStatusAlert"

const ClientGameStatusRow = ({
  client,
  game,
  gameType,
  household,
  index,
  setShowReprofileModal
}: {
  client: Client
  game?: Game
  gameType: GameType
  household?: Household
  index?: number
  setShowReprofileModal: ({ client, game }: { client: Client; game: Game }) => void
}) => {
  const { archivedAt, email, firstName, nextActivityDue, status } = client
  const { sessionInfo } = useContext(AuthContext)
  const { replace } = useContext(ClientHouseholdCacheContext)
  const theme = useTheme()
  const { tourSteps, step, progress, completeStep, isLastStep } = useContext(TourControllerContext)
  const { firm } = useContext(FirmContext)
  const [isTransitioning, setIsTransitioning] = useState<boolean>(false)
  const [showUnArchiveModal, setShowUnArchiveModal] = useState(false)
  const [showAddClientEmailModal, setShowAddClientEmailModal] = useState(false)
  const [infoAlert, setInfoAlert] = useState<StatusAlert | undefined>(undefined)
  const [, setErrorMessage] = useState<string>("")
  const nextActivityDueDate = useMemo(() => (nextActivityDue ? new Date(nextActivityDue) : undefined), [nextActivityDue])
  const trackViewEvent = useTrackViewEvent()
  const clientIndex = index ?? 0
  const { userProfile } = useContext(AppContext)

  const sendEmail = useCallback(
    (isReminder: boolean) => {
      sendInvite(sessionInfo!, client._id!)
        .then((res) => {
          replace(res)
          setInfoAlert({
            message: "Email has been sent",
            onClose: () => setInfoAlert(undefined),
            type: "success"
          })
        })
        .catch((error) => {
          console.error(`Error sending ${isReminder ? "reminder" : "invite"}:`, error)
          setInfoAlert(undefined)
          setErrorMessage(`Error sending ${isReminder ? "reminder" : "invite"}`)
        })
    },
    [client._id, replace, sessionInfo]
  )

  const onSendInvite = useCallback(
    (email: string) => {
      trackViewEvent({ action: "click", category: "client_invitation", label: "email_invite" })

      if (email) {
        setInfoAlert({
          message: "Sending...",
          timeout: false,
          type: "activity"
        })
        createGame(sessionInfo!, client._id!, gameType)
          .then(() => {
            sendEmail(false)
          })
          .catch((error) => {
            console.error("Error creating game:", error)
            setInfoAlert(undefined)
            setErrorMessage("Error creating game")
          })
      } else {
        setShowAddClientEmailModal(true)
      }
    },
    [trackViewEvent, sessionInfo, client._id, sendEmail, gameType]
  )

  const onSendReminder = useCallback(
    (email: string) => {
      trackViewEvent({ action: "click", category: "client_invitation", label: "email_reminder" })
      if (email) {
        setInfoAlert({
          message: "Sending...",
          timeout: false,
          type: "activity"
        })
        sendEmail(true)
      } else {
        setShowAddClientEmailModal(true)
      }
    },
    [trackViewEvent, sendEmail]
  )

  const onEditNextActivityDueDate = useCallback(
    (nextActivityDue: string) => {
      setIsTransitioning(true)
      update(sessionInfo!, client._id!, client, { nextActivityDue })
        .then((updatedClient) => {
          replace(updatedClient)
          setIsTransitioning(false)
          setInfoAlert({
            location: "nextActivityDue",
            message: "Date has been successfully updated",
            onClose: () => setInfoAlert(undefined),
            type: "success"
          })
        })
        .catch((error) => {
          setErrorMessage(error.message)
          setIsTransitioning(false)
        })
    },
    [sessionInfo, replace, client]
  )

  const onCopyInviteLink = useCallback(() => {
    trackViewEvent({ action: "click", category: "client_invitation", label: "copy_invite" })
    if (navigator.clipboard) {
      // reminder rather than new game so just copy the link
      if (game && !game.played) {
        navigator.clipboard.writeText(gameUrlByType(client, game, firm!))
        setInfoAlert({
          message: "Copying...",
          timeout: false,
          type: "activity"
        })
        sendInvite(sessionInfo!, client._id!, { skipEmail: true })
          .then(async (res) => {
            replace(res)
            setInfoAlert({
              message: "Link copied to clipboard",
              onClose: () => setInfoAlert(undefined),
              type: "success"
            })
            const game = latest(res, gameType)
            return await new Blob([gameUrlByType(res, game!, firm!)], { type: "text/plain" })
          })
          .catch((error) => {
            console.error("error creating game:", error)
            setInfoAlert(undefined)
            setErrorMessage("Error creating game")
            return ""
          })
      } else {
        setInfoAlert({
          message: "Copying...",
          timeout: false,
          type: "activity"
        })
        // Safari and Chrome
        if (typeof ClipboardItem && navigator.clipboard.write) {
          const text = new ClipboardItem({
            "text/plain": createGame(sessionInfo!, client._id!, gameType)
              .then(async (res) => {
                replace(res)
                setInfoAlert({
                  message: "Link copied to clipboard",
                  onClose: () => setInfoAlert(undefined),
                  type: "success"
                })
                const game = latest(res, gameType)
                return await new Blob([gameUrlByType(res, game!, firm!)], { type: "text/plain" })
              })
              .catch((error) => {
                console.error("error creating game:", error)
                setInfoAlert(undefined)
                setErrorMessage("Error creating game")
                return ""
              })
          })
          navigator.clipboard.write([text])
        } else {
          // Firefox doesn't support ClipboardItem
          createGame(sessionInfo!, client._id!, gameType)
            .then((res) => {
              replace(res)
              const game = latest(res, gameType)
              navigator.clipboard.writeText(gameUrlByType(res, game!, firm!))
              setInfoAlert({
                message: "Link copied to clipboard",
                onClose: () => setInfoAlert(undefined),
                type: "success"
              })
            })
            .catch((error) => {
              console.error("error creating game:", error)
              setInfoAlert(undefined)
              setErrorMessage("Error creating game")
            })
        }
      }
    }
  }, [trackViewEvent, game, client, firm, sessionInfo, replace, gameType])

  const onReprofile = () => {
    if (areDecisionsIncoherent(game)) {
      reprofile(sessionInfo!, client._id, game!.experimentGroupId!)
        .then((updatedClient) => {
          replace(updatedClient)
        })
        .catch((error) => {
          console.error("error updating client details", error)
          setErrorMessage("Error re-profiling")
        })
    } else {
      setShowReprofileModal({ client, game: game! })
    }
  }

  const onSaveEmailModal = (updatedClient: Client) => {
    if (!game || !game.sent) {
      onSendInvite(updatedClient.email)
    } else {
      onSendReminder(updatedClient.email)
    }
  }

  const alertPopoverOptions: AlertPopoverOptions | undefined = useMemo(() => {
    if (!infoAlert) {
      return
    }
    return {
      content: <ClientStatusAlert type={infoAlert.type} message={infoAlert.message} />,
      onClose: infoAlert.onClose,
      timeoutMS: infoAlert.timeout === false ? null : 5000,
      location: infoAlert.location
    }
  }, [infoAlert])

  return (
    <>
      <div className="flex flex-col lg:flex-row items-start lg:items-center justify-between gap-2 py-3">
        <div className="flex gap-3 items-center">
          <div className="text-p text-main-500 font-normal text-left">
            <div className="flex gap-2 items-center">
              {household && <h2 className="sr-only">Household actions</h2>}
              {!game && (
                <div className="flex items-center gap-2">
                  {theme.pages.profile.sections.clientStatus.statusImg && <img src={theme.pages.profile.sections.clientStatus.statusImg} alt="" aria-hidden />}
                  <p className="new-client-text">
                    <Trans id="client-status-new-client">
                      Invite {firstName} to complete the {gameType} activity
                    </Trans>
                  </p>
                </div>
              )}
              {game && !game.played && game.status !== "UP_FOR_REVIEW" && (
                <div className="flex items-center gap-2">
                  {theme.pages.profile.sections.clientStatus.statusImg && <img src={theme.pages.profile.sections.clientStatus.statusImg} alt="" aria-hidden />}
                  <p className="activity-pending-text">
                    <Trans id="activity-pending-text">
                      {firstName} was invited on {customDateFormat(game.sent ?? String(game.created))}
                    </Trans>
                  </p>
                </div>
              )}
              {game &&
                game.played &&
                game[gameType].results &&
                !game.summaryDate &&
                !game.markedAsReviewedAt &&
                game.status !== "UP_FOR_REVIEW" &&
                (household || !theme.pages.profile.sections.clientStatus.showButtons.presentResults ? (
                  <p className="new-results-text">
                    <Trans id="new-results-text">
                      {firstName} completed the {gameType} activity{game.played ? ` on ${customDateFormat(game.played)}` : ""}
                    </Trans>
                  </p>
                ) : (
                  <div className="flex flex-col">
                    <div className="flex gap-x-1 items-center">
                      <p>{firstName}'s results are ready to present</p>
                      <ResultsAvailableMenu client={client} game={game} />
                    </div>
                  </div>
                ))}
              {(game?.summaryDate || game?.markedAsReviewedAt) && gameType === "risk" && game.status !== "UP_FOR_REVIEW" ? (
                <div className="flex flex-col">
                  <span>{firstName} is up to date</span>
                  {nextActivityDue && nextActivityDueDate && (
                    <div className="flex text-sm">
                      <span>Re-profile due on</span>
                      <DatePicker
                        id="nextActivityDue"
                        yearsInPast={0}
                        isInline={true}
                        customInput={<span className="text-sm text-link-600 hover:text-link-500 underline ml-1">{formatDateLong(nextActivityDue)}</span>}
                        onChange={(value: Date | null) => onEditNextActivityDueDate(formatISO(value!.setHours(0, 0, 0, 0)))}
                        value={nextActivityDueDate}
                        popperPlacement="bottom-start"
                      />
                      <AlertPopover options={alertPopoverOptions} show={!!infoAlert && infoAlert.location === "nextActivityDue"}>
                        {isTransitioning && (
                          <div className="h-4 mx-2">
                            <Loading />
                          </div>
                        )}
                      </AlertPopover>
                    </div>
                  )}
                </div>
              ) : game?.summaryDate && gameType === "esg" ? (
                <p className="new-results-text flex items-center">
                  {`Activity completed${game?.played ? ` on ${customDateFormat(game.played)}` : ""}`}
                  <Popover
                    active={theme.tours.gameComplete.show && step.gameComplete === "Re-profile" && clientIndex === 0}
                    content={
                      <>
                        <p className="font-semibold text-main-500 text-sec">Re-profile your client if you wish to override their previous results.</p>
                        <div className="flex flex-row justify-between items-center mt-5">
                          <p className="tour-steps-count text-main-400 text-sm">
                            {progress.gameComplete + 1} of {tourSteps.gameComplete.length}
                          </p>
                          <button
                            className="tour-btn btn btn-secondary btn-xs w-21 text-interactive-600"
                            onClick={() => completeStep({ gameComplete: "Re-profile" })}
                          >
                            {isLastStep("gameComplete") ? "Got it" : "Next"}
                          </button>
                        </div>
                      </>
                    }
                    position="left"
                    onClose={() => completeStep({ gameComplete: "Re-profile" })}
                  >
                    <button className="btn btn-text btn-text-md flex items-center justify-center align-middle gap-2" onClick={onReprofile}>
                      <img src={refreshIcon} alt="" aria-hidden />
                      Re-profile
                    </button>
                  </Popover>
                </p>
              ) : null}
              {game?.status === "UP_FOR_REVIEW" && (
                <p className="up-for-review-text">
                  {nextActivityDue ? (
                    `${firstName}'s re-profile due on ${customDateFormat(nextActivityDue)}`
                  ) : (
                    <Trans id="client-status-up-for-review">Send invitation to re-profile {firstName}</Trans>
                  )}
                </p>
              )}
              {status === "Archived" && <p className="archived-text">{`${firstName}’s account was archived on ${customDateFormat(archivedAt)}`}</p>}
            </div>
          </div>
        </div>
        <div className="flex gap-5 items-center">
          <AlertPopover options={alertPopoverOptions} show={!!infoAlert && infoAlert.location !== "nextActivityDue"}>
            {!game && (
              <>
                <Popover
                  active={theme.tours.newClient.show && step.newClient === "Send invite" && clientIndex === 0}
                  position="left"
                  content={
                    <>
                      <p className="font-semibold text-main-500 text-sec text-left">
                        {household ? (
                          <Trans id="client-status-row-household-tour-send-invite">Invite each household member to complete the risk activity.</Trans>
                        ) : (
                          <Trans id="client-status-row-tour-send-invite">Invite your client to complete the activity.</Trans>
                        )}
                      </p>
                      <div className="flex flex-row justify-between items-center mt-5">
                        <p className="tour-steps-count text-main-400 text-sm">
                          {tourSteps.newClient.length > 1 && (
                            <span>
                              {progress.newClient + 1} of {tourSteps.newClient.length}
                            </span>
                          )}
                        </p>
                        <button
                          className="tour-btn btn btn-small border border-interactive-600 text-interactive-600"
                          onClick={() => completeStep({ newClient: "Send invite" })}
                        >
                          {isLastStep("newClient") ? "Got it" : "Next"}
                        </button>
                      </div>
                    </>
                  }
                  onClose={() => completeStep({ newClient: "Send invite" })}
                >
                  <button className="btn btn-primary btn-medium w-44" onClick={() => onSendInvite(email)}>
                    Send invite
                  </button>
                </Popover>
                <button className="copy-link-btn btn btn-text btn-text-md flex items-center justify-center align-middle gap-2" onClick={onCopyInviteLink}>
                  <img className="copy-link-btn__img" src={linkIcon} alt="" aria-hidden />
                  Copy link
                </button>
              </>
            )}
            {game && !game.played && game.status !== "UP_FOR_REVIEW" && (
              <>
                <button className="btn btn-medium w-44 btn-primary" onClick={() => onSendReminder(email)}>
                  Send reminder
                </button>
                <button className="btn btn-text btn-text-md flex items-center justify-center align-middle gap-2" onClick={onCopyInviteLink}>
                  <img src={linkIcon} alt="" aria-hidden />
                  Copy link
                </button>
              </>
            )}
            {game &&
              game.played &&
              game[gameType].results &&
              !game.summaryDate &&
              !game.markedAsReviewedAt &&
              game.status !== "UP_FOR_REVIEW" &&
              (household ? (
                <Popover
                  active={theme.tours.gameComplete.show && step.gameComplete === "Re-profile" && clientIndex === 0}
                  content={
                    <>
                      <p className="font-semibold text-main-500 text-sec">
                        Re-profile either member of the household if you wish to override their previous results.
                      </p>
                      <div className="flex flex-row justify-between items-center mt-5">
                        <p className="tour-steps-count text-main-400 text-sm">
                          {progress.gameComplete + 1} of {tourSteps.gameComplete.length}
                        </p>
                        <button
                          className="tour-btn btn btn-secondary btn-xs w-21 text-interactive-600"
                          onClick={() => completeStep({ gameComplete: "Re-profile" })}
                        >
                          {isLastStep("gameComplete") ? "Got it" : "Next"}
                        </button>
                      </div>
                    </>
                  }
                  position="left"
                  onClose={() => completeStep({ gameComplete: "Re-profile" })}
                >
                  <button className="btn btn-text btn-text-md flex items-center justify-center align-middle gap-2" onClick={onReprofile}>
                    <img src={refreshIcon} alt="" aria-hidden />
                    Re-profile
                  </button>
                </Popover>
              ) : (
                <>
                  {!userProfile?.isAssociate && theme.pages.profile.sections.clientStatus.showButtons.presentResults && (
                    <Popover
                      active={theme.tours.gameComplete.show && step.gameComplete === "Present results" && clientIndex === 0}
                      content={
                        <>
                          <p className="font-semibold text-main-500 text-sec">Present your client’s results to them when you next meet.</p>
                          <div className="flex flex-row justify-between items-center mt-5">
                            <p className="tour-steps-count text-main-400 text-sm">
                              {progress.gameComplete + 1} of {tourSteps.gameComplete.length}
                            </p>
                            <button
                              className="tour-btn btn btn-secondary btn-xs w-21 text-interactive-600"
                              onClick={() => completeStep({ gameComplete: "Present results" })}
                            >
                              {isLastStep("gameComplete") ? "Got it" : "Next"}
                            </button>
                          </div>
                        </>
                      }
                      position="bottom"
                      onClose={() => completeStep({ gameComplete: "Present results" })}
                    >
                      <Link
                        className="btn btn-medium btn-primary !w-44 text-center animate-highlight-ping"
                        to={`/clients/${client._id}/rm/overview`}
                        target="_blank"
                        onClick={(e) => {
                          e.preventDefault()
                          e.stopPropagation()
                          trackViewEvent({ action: "click", category: "present_results", label: client._id })
                          window.open(`/clients/${client._id}/rm/overview`)
                        }}
                      >
                        Present results
                      </Link>
                    </Popover>
                  )}
                  {theme.pages.profile.sections.clientStatus.showButtons.presentResults && (
                    <Popover
                      active={theme.tours.gameComplete.show && step.gameComplete === "Preview report" && clientIndex === 0}
                      content={
                        <>
                          <p className="font-semibold text-main-500 text-sec">Preview your client’s risk profile before you meet.</p>
                          <div className="flex flex-row justify-between items-center mt-5">
                            <p className="tour-steps-count text-main-400 text-sm">
                              {progress.gameComplete + 1} of {tourSteps.gameComplete.length}
                            </p>
                            <button
                              className="tour-btn btn btn-secondary btn-xs w-21 text-interactive-600"
                              onClick={() => completeStep({ gameComplete: "Preview report" })}
                            >
                              {isLastStep("gameComplete") ? "Got it" : "Next"}
                            </button>
                          </div>
                        </>
                      }
                      position="bottom"
                      onClose={() => completeStep({ gameComplete: "Preview report" })}
                    >
                      <Link
                        className="btn btn-secondary btn-medium w-44 text-center"
                        to={game?.gameReportUrl}
                        target="_blank"
                        rel="noreferrer"
                        onClick={() => trackViewEvent({ action: "click", category: "check_results", label: client._id })}
                      >
                        Preview insights
                      </Link>
                    </Popover>
                  )}
                  {gameType === "esg" && (
                    <Popover
                      active={theme.tours.gameComplete.show && step.gameComplete === "Re-profile" && clientIndex === 0}
                      content={
                        <>
                          <p className="font-semibold text-main-500 text-sec">Re-profile your client if you wish to override their previous results.</p>
                          <div className="flex flex-row justify-between items-center mt-5">
                            <p className="tour-steps-count text-main-400 text-sm">
                              {progress.gameComplete + 1} of {tourSteps.gameComplete.length}
                            </p>
                            <button
                              className="tour-btn btn btn-secondary btn-xs w-21 text-interactive-600"
                              onClick={() => completeStep({ gameComplete: "Re-profile" })}
                            >
                              {isLastStep("gameComplete") ? "Got it" : "Next"}
                            </button>
                          </div>
                        </>
                      }
                      position="left"
                      onClose={() => completeStep({ gameComplete: "Re-profile" })}
                    >
                      <button className="btn btn-text btn-text-md flex items-center justify-center align-middle gap-2" onClick={onReprofile}>
                        <img src={refreshIcon} alt="" aria-hidden />
                        Re-profile
                      </button>
                    </Popover>
                  )}
                </>
              ))}
            {(game?.summaryDate || game?.markedAsReviewedAt) && gameType !== "esg" && game.status !== "UP_FOR_REVIEW" && (
              <button className="btn btn-secondary btn-medium w-44" onClick={onReprofile}>
                Re-profile
              </button>
            )}
            {game?.status === "UP_FOR_REVIEW" && (
              <>
                <button className="btn btn-primary btn-medium w-44" onClick={() => onSendInvite(email)}>
                  Send invite
                </button>
                <button className="btn btn-text btn-text-md flex items-center justify-center align-middle gap-2" onClick={onCopyInviteLink}>
                  <img src={linkIcon} alt="" aria-hidden />
                  Copy link
                </button>
              </>
            )}
            {status === "Archived" && (
              <button className="btn btn-primary btn-medium w-44" onClick={() => setShowUnArchiveModal(true)}>
                Un-archive
              </button>
            )}
          </AlertPopover>
        </div>
      </div>

      <AnimatePresence>
        {showUnArchiveModal && <UnArchiveConfirmationModal clientOrHousehold={client} onClose={() => setShowUnArchiveModal(false)} />}
        {showAddClientEmailModal && (
          <Modal className="modal-medium" handleClose={() => setShowAddClientEmailModal(false)}>
            <AddClientEmailModal onSave={onSaveEmailModal} onClose={() => setShowAddClientEmailModal(false)} client={client} game={game!} />
          </Modal>
        )}
      </AnimatePresence>
    </>
  )
}

export default ClientGameStatusRow
