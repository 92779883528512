import heroImage from "./assets/hero.svg"
import errorIcon from "../../assets/images/error.svg"
import successIcon from "../../assets/images/success.svg"
import Modal from "../../../../components/Modal/Modal"
import Loading from "../../../../components/ClientProfile/Loading/Loading"
import { Client } from "../../../../models/Client"
import { Household } from "../../../../models/Household"
import { useContext, useState } from "react"
import { ClientHouseholdCacheContext } from "../../../../contexts/ClientHouseholdCacheContext"

interface Props {
  clientOrHousehold: Client | Household
  onClose: () => void
  refetch?: () => void
}

const UnArchiveConfirmationModal = ({ clientOrHousehold, onClose, refetch }: Props) => {
  const { unArchiveClient, unArchiveHousehold } = useContext(ClientHouseholdCacheContext)
  const [status, setStatus] = useState<"pending" | "saving" | "complete" | "unArchiveError" | "refetchError">("pending")
  const isHousehold = clientOrHousehold.hasOwnProperty("members")

  const onConfirm = () => {
    setStatus("saving")

    const request = isHousehold ? unArchiveHousehold : unArchiveClient

    request(clientOrHousehold._id)
      .then(() => {
        setStatus("complete")

        const closePromise = new Promise<void>((resolve) => {
          setTimeout(resolve, 1500)
        })
        const refetchPromise = refetch ? refetch() : null

        return Promise.all([closePromise, refetchPromise])
          .then(() => {
            onClose()
          })
          .catch(() => {
            setStatus("refetchError")
          })
      })
      .catch(() => {
        setStatus("unArchiveError")
      })
  }

  return (
    <Modal className="max-w-xl">
      <div className="relative flex flex-col gap-6">
        <img alt="" className="h-24" src={heroImage} />
        {(status === "unArchiveError" || status === "refetchError") && (
          <div className="border p-2 text-main-500 text-sm flex flex-row gap-x-2 border-interactive-200 shadow items-center">
            <img src={errorIcon} alt="" />
            {status === "unArchiveError" && `There was an error un-archiving the ${isHousehold ? "household" : "client"}. Please try again.`}
            {status === "refetchError" && `There was an error loading clients. Please try again.`}
          </div>
        )}
        <h2 className="text-h3 text-center font-semibold text-main-600">
          {isHousehold ? "Are you sure you want to un-archive this household?" : "Are you sure you want to un-archive this client?"}
        </h2>
        <div className="w-full flex justify-center gap-4">
          <button className="btn btn-secondary btn-medium" onClick={onClose}>
            Cancel
          </button>
          <button className="btn btn-primary btn-medium" onClick={onConfirm}>
            Confirm
          </button>
        </div>
        {status === "saving" && (
          <div className="absolute full-flex-content-center bg-white">
            <Loading />
          </div>
        )}
        {status === "complete" && (
          <div className="absolute full-flex-content-center bg-white">
            <img alt="" className="h-24 mb-6" src={successIcon} />
            <h3 className="text-h2 text-center font-semibold text-main-600 mb-6">{isHousehold ? "Household un-archived" : "Client un-archived"}</h3>
          </div>
        )}
      </div>
    </Modal>
  )
}

export default UnArchiveConfirmationModal
