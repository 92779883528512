import React, { useMemo } from "react"
import { Client, GoalDetail } from "../../../../../models/Client"
import { tt } from "../../../../../lib/translations"
import { Household } from "../../../../../models/Household"

interface CardTitleProps {
  client?: Client
  household?: Household
  goalDetails: GoalDetail[]
  goal: GoalDetail
}

export const getGoalTitle = ({ household, goalDetails, goal }: CardTitleProps) => {
  if (goal.type === "createOwnGoal") {
    const ownGoalsCount = goalDetails.filter((g) => g.type === "createOwnGoal").length
    return goal.name && goal.name.trim() !== "" ? goal.name : ownGoalsCount > 1 ? `Own goal ${goal.id || ownGoalsCount + 1}` : "Own goal"
  } else if (goal.type === "education") {
    const { name, value } = goal.recipient ?? {}
    if (value && value === "me") {
      return "My education"
    } else if (value && value !== "member1" && value !== "member2") {
      return `${name}'s education`
    } else if (household && (value === "member1" || value === "member2")) {
      return `${household.members[value === "member1" ? 0 : 1].client.firstName}'s education`
    }
    const educationGoalsCount = goalDetails.filter((g) => g.type === "education").length
    const newGoals = goalDetails.filter((g) => g.type === "education" && !g.recipient?.value).length
    return newGoals > 1 ? `Education ${goal.id ?? (educationGoalsCount + 1)}` : tt({ id: "profile-page-status-card-goals-goal-education-title" })
  }
  return undefined
}

const CardTitle: React.FC<CardTitleProps> = ({ client, household, goalDetails, goal }) => {
  const title = useMemo(() => {
    const title = getGoalTitle({ client, household, goalDetails, goal })
    return title || tt({ id: `profile-page-status-card-goals-goal-${goal.type}-title` })
  }, [client, goal, goalDetails, household])
  return <span>{title}</span>
}

export default CardTitle
