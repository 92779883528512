import { Firm } from "../../../../models/Firm"
import { formatCurrencyLong, formatCurrencyShort } from "../../../../lib/currency"

export type GoalExplorerStatuses = "init" | "pending" | "updating" | "calculating" | "success" | "error"

export const validateInvestmentAmount = (investmentAmount?: number) => {
  const investmentAmountConfig = { min: 0, max: 100000000 }

  if (investmentAmount !== null && investmentAmount !== undefined) {
    if (investmentAmount < investmentAmountConfig.min || investmentAmount > investmentAmountConfig.max) {
      return `Please add a value between ${formatCurrencyLong(investmentAmountConfig.min)} and ${formatCurrencyShort(investmentAmountConfig.max)}`
    }
  } else {
    return "Investment amount is required"
  }
}

export const validateAnnualContribution = (annualContribution?: number, _firm?: Firm) => {
  if (annualContribution !== null && annualContribution !== undefined) {
    if (annualContribution < 0 || annualContribution > 1000000000000) {
      return "Value must be less than $1T"
    }
  } else {
    return "Contribution amount is required"
  }
}

export const isNullOrUndefined = (value: any) => {
  return value === null || value === undefined
}
