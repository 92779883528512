import { motion } from "framer-motion"
import { useTheme } from "../../../../contexts/ThemeContext"

export const DoughnutChart = ({
  data,
  colors,
  centerLabel,
  className,
  forReport = false,
  strokeWidth = 6
}: {
  data?: number[]
  colors: string[]
  centerLabel?: string | JSX.Element
  className?: string
  forReport?: boolean
  strokeWidth?: number
}) => {
  const theme = useTheme()
  const baseStrokeDashoffset = 25
  return (
    <div className={className}>
      <div className="relative">
        <svg className="overflow-visible" width="100%" height="100%" viewBox="0 0 40 40">
          <motion.circle cx="20" cy="20" r="17.5" strokeDasharray="0" strokeWidth={3} fill="transparent" stroke="#EAE8E4" />
          {data && data.length > 0 ? (
            data.map((percentage, index) => {
              const cumulativeOffset = data.slice(0, index).reduce((a, b) => a + b, 0)
              const strokeDashoffset = 100 - cumulativeOffset + baseStrokeDashoffset
              return (
                <motion.circle
                  key={index}
                  cx="20"
                  cy="20"
                  r={16}
                  initial={{ strokeDasharray: "0 100" }}
                  animate={{
                    strokeDasharray: `${percentage} ${100 - percentage}`,
                    strokeDashoffset: strokeDashoffset
                  }}
                  transition={{ duration: forReport ? 0 : 0.5, ease: "easeIn" }}
                  strokeWidth={theme.charts?.portfolioDetail?.dougnutChartStrokeWidth ?? strokeWidth}
                  fill="transparent"
                  stroke={colors[index]}
                />
              )
            })
          ) : (
            <motion.circle
              cx="20"
              cy="20"
              r={16}
              initial={{ strokeDasharray: "0 100" }}
              animate={{
                strokeDasharray: "100 0",
                strokeDashoffset: baseStrokeDashoffset
              }}
              transition={{ duration: forReport ? 0 : 0.5, ease: "easeIn" }}
              strokeWidth={theme.charts?.portfolioDetail?.dougnutChartStrokeWidth ?? strokeWidth}
              fill="transparent"
              className="stroke-surface-activity"
            />
          )}
        </svg>
        {centerLabel && (
          <div className="absolute top-1/2 left-1/2 text-center -translate-x-1/2 -translate-y-1/2">
            <div>{centerLabel}</div>
          </div>
        )}
      </div>
    </div>
  )
}
