import { createContext, PropsWithChildren, useCallback, useContext, useEffect, useMemo, useState } from "react"
import useWebSocket, { ReadyState } from "react-use-websocket"
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { JsonObject } from "react-use-websocket/dist/lib/types"
import { archive, ClientUpdateRequest, unArchive, update } from "../api/clients"
import { archiveHousehold, HouseholdUpdateRequest, unArchiveHousehold, updateHousehold as updateHouseholdAPI } from "../api/households"
import { Client } from "../models/Client"
import { Household } from "../models/Household"
import { AuthContext, AuthStatus } from "../views/auth/AuthContext"
import { AppContext } from "./AppContext"

interface ClientUpdate {
  updated: Client
  timestamp: Date
  changed?: { [key: string]: any }
}

interface HouseholdUpdate {
  updated: Household
  timestamp: Date
  changed?: { [key: string]: any }
}


type ClientHouseholdsById = {[key:string]: Client|Household}
export interface ClientHouseholdCacheContextType {
  cache: ClientHouseholdsById,
  replace: (target: Client | Household) => void,
  updateClient: (clientId: string, fields: ClientUpdateRequest) => Promise<Client | null>,
  archiveClient: (clientId: string) => Promise<Client | null>,
  unArchiveClient: (clientId: string) => Promise<Client | null>,
  isClientUpdating: boolean,
  clientUpdateError?: any
  updateHousehold: (householdId: string, memberUpdates: ClientUpdateRequest[], fields: HouseholdUpdateRequest) => Promise<Household | null>,
  archiveHousehold: (householdId: string) => Promise<Household | null>,
  unArchiveHousehold: (householdId: string) => Promise<Household | null>,
  isHouseholdUpdating: boolean,
  householdUpdateError?: any,
  lastUpdate?: Client | Household,
  replaceAll: (target: (Client | Household)[]) => void,
  clear: () => void
}

export const defaults:ClientHouseholdCacheContextType = {
  cache: {},
  updateClient: () => Promise.resolve(null),
  archiveClient: () => Promise.resolve(null),
  unArchiveClient: () => Promise.resolve(null),
  isClientUpdating: false,
  updateHousehold: () => Promise.resolve(null),
  archiveHousehold: () => Promise.resolve(null),
  unArchiveHousehold: () => Promise.resolve(null),
  isHouseholdUpdating: false,
  replace: () => {},
  replaceAll: () => {},
  clear: () => {}
}

export const ClientHouseholdCacheContext = createContext(defaults)


const CONNECTION_STATUS = {
  [ReadyState.CONNECTING]: "Connecting",
  [ReadyState.OPEN]: "Open",
  [ReadyState.CLOSING]: "Closing",
  [ReadyState.CLOSED]: "Closed",
  [ReadyState.UNINSTANTIATED]: "Uninstantiated"
}


const ClientHouseholdCacheContextProvider:React.FC<PropsWithChildren<{}>> = ({ children }) => {
  
  const { authStatus, sessionInfo } = useContext(AuthContext)
  const { isSuccess } = useContext(AppContext)
  const [ cache, setCache ] = useState<ClientHouseholdsById>({})
  const [ isClientUpdating, setIsClientUpdating ] = useState(false)
  const [ clientUpdateError, setClientUpdateError ] = useState<any>()
  const [ isHouseholdUpdating, setIsHouseholdUpdating ] = useState(false)
  const [ householdUpdateError, setHouseholdUpdateError ] = useState<any>()


  const replace = useCallback((target: Client | Household) => {
    setCache(prev => ({...prev, [target._id]: target}))
  }, [setCache])


  const replaceAll = useCallback((targets: (Client | Household)[]) => {
    setCache(prev => ({...prev, ...targets.reduce((acc, target) => ({
      ...acc,
      ...target?._id ? { [target._id]: target } : {},
    }), {})}))
  }, [setCache])


  const { lastJsonMessage, readyState } = useWebSocket(
    isSuccess && authStatus === AuthStatus.SignedIn && sessionInfo?.accessToken ? import.meta.env.VITE_APP_SOCKET_URL ?? `ws://localhost:3302?accessToken=${sessionInfo?.accessToken}` : null,
    {
      shouldReconnect: () => Boolean(isSuccess as boolean && authStatus === AuthStatus.SignedIn && sessionInfo?.accessToken),
      queryParams: { ...sessionInfo?.accessToken ? { accessToken: sessionInfo?.accessToken } : {} },
      retryOnError: false
    }
  )

  const connectionStatus = useMemo(() => CONNECTION_STATUS[readyState], [readyState])
  
  useEffect(() => {
    console.log("websocket:", connectionStatus)
  }, [connectionStatus])

  const lastClientUpdate = useMemo(() => {
    const obj = lastJsonMessage as JsonObject
    if (obj?.event === "clientUpdate") {
      return {
        updated: (obj!.payload as any)!.client as Client,
        timestamp: new Date(obj.timestamp as string),
        changed: (obj!.payload as any)!.changed
      } as ClientUpdate
    } else if (obj?.event === "clientCreated") {
      return {
        updated: (obj!.payload as any)!.client as Client,
        timestamp: new Date(obj.timestamp as string),
      } as ClientUpdate
    }
  }, [lastJsonMessage])

  const lastHouseholdUpdate = useMemo(() => {
    const obj = lastJsonMessage as JsonObject
    if (obj?.event === "householdUpdate") {
      return {
        updated: (obj!.payload as any)!.household as Household,
        timestamp: new Date(obj.timestamp as string),
        changed: (obj!.payload as any)!.changed
      } as HouseholdUpdate
    }
  }, [lastJsonMessage])
  
  useEffect(() => {
    if(lastHouseholdUpdate?.updated) {
      replace(lastHouseholdUpdate.updated)
    }
  }, [lastHouseholdUpdate, replace])

  useEffect(() => {
    if(lastClientUpdate?.updated) {
      const target = lastClientUpdate.updated
      setCache(prev => Object.values(prev)
        .map(ch => ch.hasOwnProperty("members")
          ? { ...(ch as Household), members: (ch as Household).members.map(m => ({ ...m, client: m.client._id === target._id ? target : m.client }))}
          : ch._id === target._id ? target : ch
        ).reduce((acc, ch) => ({ ...acc, [ch._id]: ch }), {} as ClientHouseholdsById)
      )
    }
  }, [lastClientUpdate, setCache])

  
  const clear = useCallback(() => setCache({}), [setCache])

  return (
    <ClientHouseholdCacheContext.Provider value={{
      cache,
      updateClient: (clientId, fields) => {
        setIsClientUpdating(true)
        return update(sessionInfo!, clientId, cache[clientId] as Client, fields)
          .then((updated) => {
            replace(updated)
            return updated
          })
          .catch(err => {
            setClientUpdateError(err)
            throw err
          })
          .finally(() => setIsClientUpdating(false))
      },
      archiveClient: (clientId) => {
        setIsClientUpdating(true)
        setClientUpdateError(undefined)
        return archive(sessionInfo!, clientId)
          .then((updated) => {
            replace(updated)
            return updated
          })
          .catch(err => {
            setClientUpdateError(err)
            throw err
          })
          .finally(() => setIsClientUpdating(false))
      },
      unArchiveClient: (clientId) => {
        setIsClientUpdating(true)
        setClientUpdateError(undefined)
        return unArchive(sessionInfo!, clientId)
          .then((updated) => {
            replace(updated)
            return updated
          })
          .catch(err => {
            setClientUpdateError(err)
            throw err
          })
          .finally(() => setIsClientUpdating(false))
      },
      isClientUpdating,
      clientUpdateError,
      updateHousehold: (householdId:string, memberUpdates:ClientUpdateRequest[], fields:HouseholdUpdateRequest) => {
        setIsHouseholdUpdating(true)
        return updateHouseholdAPI(sessionInfo!, cache[householdId] as Household, memberUpdates, fields)
          .then((updated) => {
            replace(updated)
            return updated
          })
          .catch(err => {
            setHouseholdUpdateError(err)
            throw err
          })
          .finally(() => setIsHouseholdUpdating(false))
      },
      archiveHousehold: (householdId) => {
        setIsClientUpdating(true)
        setClientUpdateError(undefined)
        return archiveHousehold(sessionInfo!, householdId)
          .then((updated) => {
            replace(updated)
            return updated
          })
          .catch(err => {
            setHouseholdUpdateError(err)
            throw err
          })
          .finally(() => setIsHouseholdUpdating(false))
      },
      unArchiveHousehold: (householdId) => {
        setIsClientUpdating(true)
        setClientUpdateError(undefined)
        return unArchiveHousehold(sessionInfo!, householdId)
          .then((updated) => {
            replace(updated)
            return updated
          })
          .catch(err => {
            setHouseholdUpdateError(err)
            throw err
          })
          .finally(() => setIsHouseholdUpdating(false))
      },
      isHouseholdUpdating,
      householdUpdateError,
      replace,
      replaceAll,
      lastUpdate: (lastClientUpdate ?? lastHouseholdUpdate)?.updated,
      clear
    }}>
      {children}
    </ClientHouseholdCacheContext.Provider>
  )
}

export default ClientHouseholdCacheContextProvider
