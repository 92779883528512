import clsx from "clsx"
import { PropsWithChildren } from "react"

const Section: React.FC<PropsWithChildren<{ className?: string; showSeparator?: boolean }>> = ({ children, className, showSeparator }) => {
  return (
    <>
      <section className={clsx("results-tab-section py-10 px-12", className)}>{children}</section>
      {showSeparator && <hr className="border-surface-300" />}
    </>
  )
}

export default Section
