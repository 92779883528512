import clsx from "clsx"
import { tt } from "../../../../lib/translations"

const PRIORITIES = {
  essential: "text-positive-600 bg-positive-300",
  important: "text-data-yellow-600 bg-data-yellow-300",
  aspirational: "text-highlight-600 bg-highlight-200"
}

const GoalPriorityLabel = ({ priority }: { priority: "essential" | "important" | "aspirational" }) => {
  return <span className={clsx("text-xs font-bold uppercase py-1 px-1.5", PRIORITIES[priority])}>{tt({ id: `goals-priority-${priority}` })}</span>
}

export default GoalPriorityLabel
