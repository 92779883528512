import { Theme } from "../../../../../../config/theme"
import { tt } from "../../../../../../lib/translations"
import { Client } from "../../../../../../models/Client"
import { LegendData } from "../../../../../rmjourney/components/LineChart/LineChart"
import { ATTITUDE_TO_RISK_SCORES } from "../../../../../rmjourney/riskAttitude/assets/attitudeToRiskData"
import { createClientDataPoints } from "../../../../../rmjourney/utils/charts"

export const scoreAxisValues = (theme: Theme, score?: number, client?: Client) => {
  if (client) {
    const scores = [score ?? 0]
    const scoreXYaxisValue = scores!.map((s) => ATTITUDE_TO_RISK_SCORES.find((_, i) => i >= s)!) // uses attitude to risk curve
    const xAxis = {
      labels: [
        { id: "1", text: "0" },
        { id: "2", text: "10" },
        { id: "3", text: "20" },
        { id: "4", text: "30" },
        { id: "5", text: "40" },
        { id: "6", text: "50" },
        { id: "7", text: "60" },
        { id: "8", text: "70" },
        { id: "9", text: "80" },
        { id: "10", text: "90" },
        { id: "11", text: "100" }
      ],
      title: tt({ id: "score-chart-x-axis-title", message: "" })
    }
    const yAxis = {
      labels: [
        { id: "1", text: "0%" },
        { id: "2", text: "5%" },
        { id: "3", text: "10%" },
        { id: "4", text: "15%" },
        { id: "5", text: "20%" },
        { id: "6", text: "25%" }
      ],
      title: tt({ id: "score-chart-y-axis-title", message: "" })
    }
    const highlightDataPoints = createClientDataPoints({ theme: theme, clients: [client], values: scoreXYaxisValue })
    const highlightAreas = [
      {
        endXPercent: 24,
        id: "low",
        label: "Low",
        startXPercent: 0
      },
      {
        endXPercent: 59,
        id: "mid",
        label: "Mid",
        startXPercent: 24
      },
      {
        endXPercent: 100,
        id: "high",
        label: "High",
        startXPercent: 59
      }
    ]

    const legend: LegendData[] | undefined = [
      {
        color: highlightDataPoints[0].color,
        id: `data-point-${client?.firstName}`,
        text: client?.firstName
      }
    ]

    return {
      attitudeToRiskScore: ATTITUDE_TO_RISK_SCORES,
      scoreXYaxisValue,
      highlightAreas,
      highlightDataPoints,
      legend: legend ?? undefined,
      xAxis,
      yAxis
    }
  }
}
