import { AxiosError } from "axios"
import { useMemo, useState } from "react"
import { useMutation, useQuery } from "react-query"
import axiosInstance from "../../../../api/axiosInstance"
import Loading from "../../../../components/ClientProfile/Loading/Loading"
import SelectSearch from "../../../../components/Select/SelectSearch"
import TextInput from "../../../../components/TextInput/TextInput"
import { BillingCode } from "../../../../models/Billing"
import { Firm } from "../../../../models/Firm"

type FirmUpdate = Partial<Omit<Firm, "billingCode"> & { billingCode?: string | null}>

const UpdateFirm = ({ firm, whenDone = () => {} }: { firm: Firm, whenDone?: () => any }) => {

  const [firm2, setFirm2] = useState<FirmUpdate>({})

  const billingOptions = useQuery(["billing/codes"], () => axiosInstance.get<BillingCode[]>(`${import.meta.env.VITE_APP_API_BASE || ""}/api/admin/billing/codes`)
    .then(res => res.data), {
      staleTime: 0
    })

  const patchFirm = useMutation<unknown, AxiosError<{ message?: string } | string | undefined>, FirmUpdate>(
    (payload) => axiosInstance.patch(`${import.meta.env.VITE_APP_API_BASE || ""}/api/admin/firm/${firm._id}`, payload)
      .then(res => res.data),
      {
        onSuccess: () => {
          setFirm2({})
          whenDone()
        }
      }
  )

  const hasChanges = useMemo(() => firm && Object.keys(firm2).some(k => firm2[k as keyof {}] !== undefined && firm[k as keyof {}] !== firm2[k as keyof {}]), [firm, firm2])

  return (
    <div className="max-w-md pl-10 flex flex-col gap-y-6">
      <TextInput value={firm2?.name ?? firm.name} label={<label>Firm Name</label>} name="firm-name" onChange={(name) => setFirm2({ ...firm2, name })} />

      <SelectSearch
        className="w-full place-self-end"
        label="Select billing model (optional)"
        data={billingOptions.data}
        selected={billingOptions.data?.find(opt => opt._id === (firm2.billingCode !== undefined ? firm2.billingCode : firm.billingCode))?._id}
        match={(o, s) => o?._id.toLowerCase().indexOf(s) >= 0}
        onSelect={(billingModel) => setFirm2({ ...firm2, billingCode: billingModel?._id ?? null })}
        isSelected={(opt) => opt._id === (firm2.billingCode !== undefined ? firm2.billingCode : firm.billingCode)}
        // deselect // we don't allow deselect at the moment
        autoFocusSearch
        renderLabel={(code, sel) => {
          const selected = sel ? billingOptions.data?.find(opt => opt._id === sel) : undefined
          return code ? 
            <div className="grid gap-x-2 grid-cols-[6rem,1fr] -mx-4 px-4">
              <div className="text-sec h-full flex items-center justify-stretch">{code._id}</div>
              <div className="text-p h-full flex items-center justify-stretch">{code.name}</div>
            </div>
            : selected
              ? <div className="flex gap-x-2 items-baseline">
                  <div className="text-sm text-neutral-500">{selected._id}</div>
                  <div className="">{selected.name}</div>
                </div>
              : <></>
          }}
      />

      <button
        className="btn btn-primary btn-medium w-48"
        disabled={!patchFirm.isLoading && !hasChanges}
        // disabled={(billingModelName === firm?.billingModel && isShowBillingInfo === firm?.billingModel?.billingInfo) || billingModelName === ""}
        onClick={() => patchFirm.mutate(firm2)}
      >
        {patchFirm.isLoading ? <Loading type="dots"  /> : <>Update</>}
      </button>
    </div>
  )
}

export default UpdateFirm
