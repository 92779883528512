import { useMemo } from "react"
import { latest } from "../../../../lib/clients"
import { customDateFormat } from "../../../../lib/date"
import { Client } from "../../../../models/Client"
import { Household } from "../../../../models/Household"
import useRetirementRiskMindset from "../../../../hooks/useRetirementRiskMindset"

import retirementGraphTmp from "./assets/retirement-graph.svg"
import certaintyPiechart from "./assets/recommendation-certainty.svg"
import growthPiechart from "./assets/recommendation-growth.svg"

import ScoreChart from "../components/ScoreChart/ScoreChart"

const RetirementIncomeTab = ({ client, household }: { client?: Client; household?: Household }) => {
  const clientOrHousehold = (client ?? household)!
  const clientGame = latest(clientOrHousehold, "retirement")

  const latestHouseholdGames = useMemo(() => {
    return household?.members.map((member) => latest(member.client!, "retirement")) ?? []
  }, [household?.members])

  const firstCompletedGame = household
    ? latestHouseholdGames.find((game) => game?.played) // Find the first completed game in household
    : undefined

  const game = household ? firstCompletedGame : clientGame

  const lastUpdatedDate = household ? firstCompletedGame?.played : clientGame?.played
  const completedMembers = household && household.members.filter((_, index) => latestHouseholdGames[index]?.played)

  const retirementRiskMindset = useRetirementRiskMindset(game) // high is growth seeker, low is certainty seeker
  const firstName = household ? completedMembers?.[0].client.firstName : client?.firstName

  return (
    <>
      <div className="retirement-tab w-full flex flex-col items-start gap-10 py-10">
        <h1 className="sr-only">Retirement income</h1>
        <section className="flex justify-between items-center gap-2 w-full text-left px-16">
          <p>
            {firstName}'s profile completed on {customDateFormat(lastUpdatedDate!)}
          </p>
        </section>
        <section className="w-full flex flex-col gap-6 px-16">
          <div className="flex flex-col gap-1">
            <h2 className="text-emph uppercase">Retirement persona</h2>
            <p className="text-h3 font-semibold">
              {firstName} {retirementRiskMindset?.rating === "low" ? "is a certainty seeker" : "is a growth seeker"}
            </p>
          </div>
          <p>
            {firstName}{" "}
            {retirementRiskMindset?.rating === "low"
              ? "should consider allocating some of their portfolio to create a stream of regular income so they can spend confidently through their retirement."
              : "is comfortable with taking more risk with their retirement income in order for it to grow."}
          </p>
        </section>
        <hr className="w-full border-surface-300" />
        <section className="w-full flex flex-col gap-6 px-16">
          <div className="w-full flex items-center justify-between gap-6">
            <div className="flex flex-col gap-1">
              <h2 className="text-emph uppercase">Certainty score</h2>
              <p className="text-h3 font-semibold">
                {firstName} {retirementRiskMindset?.rating === "low" ? "prefers a more certain income" : "prefers to maximise their income"}
              </p>
            </div>
            <div className="flex flex-col items-end">
              <span className="text-h2 font-semibold text-main-400">{retirementRiskMindset?.score}</span>
              <span className="text-sm">Certainty score</span>
            </div>
          </div>
          {retirementRiskMindset?.score && (
            <div className="risk-attitude-line-chart w-full self-center !h-80">
              <ScoreChart client={household ? completedMembers?.[0].client : client} score={retirementRiskMindset?.score} title={`${firstName}'s overall preference for retirement income`} />
              <div className="flex justify-between items-center text-sec font-semi text-main-400">
                <span>More opportunity</span>
                <span>More certainty</span>
              </div>
            </div>
          )}
        </section>
        <hr className="w-full border-surface-300" />
        <section className="flex flex-col gap-6 items-start px-16">
          <h2 className="text-h3 font-semibold">Guaranteed income recommendation</h2>
          <p>
            We recommend allocating ${retirementRiskMindset?.rating === "low" ? "300,000" : "150,000"} of {firstName}'s portfolio to our guaranteed income
            product that will create a lifetime income stream of ${retirementRiskMindset?.rating === "low" ? "24,000" : "12,000"}/yr. This will leave{" "}
            {firstName} with ${retirementRiskMindset?.rating === "low" ? "560,000" : "710,000"} in their portfolio to focus on growth and income.
          </p>
          <img className="h-14" src={retirementRiskMindset?.rating === "low" ? certaintyPiechart : growthPiechart} alt="" aria-hidden />
        </section>
        <hr className="w-full border-surface-300" />
        <section className="w-full flex flex-col gap-6 items-center px-16">
          <h2 className="w-full text-h3 font-semibold text-left">Projected income in retirement</h2>
          <img src={retirementGraphTmp} alt="" aria-hidden />
        </section>
      </div>
    </>
  )
}

export default RetirementIncomeTab
