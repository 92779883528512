import { ReactNode, useState } from "react"
import { PortfolioReturn, RetirementDrawdownResults } from "../../../../../api/rm/goals"
import { Client, GoalDetail, InvestmentGoal } from "../../../../../models/Client"
import { Household } from "../../../../../models/Household"
import ChartDisplayToggle from "../ChartDisplayToggle/ChartDisplayToggle"
import PortfolioChart, { createPortfolioOptions } from "../PortfolioChart/PortfolioChart"
import chartToggle from "./assets/chart-toggle.svg"
import listToggle from "./assets/list-toggle.svg"
import errorIcon from "../../assets/error.svg"
import { calcComfortMatch } from "../../utils/comfortMatch"
import { calcGoalMatch } from "../../utils/goalMatch"
import RMJTextTooltip from "../RMJTextTooltip/RMJTextTooltip"
import infoIcon from "../../../assets/images/info-icon.svg"
import questionMarkCircle from "./assets/question-mark-circle.svg"
import Modal from "../../../../../components/Modal/Modal"
import PortfolioTable from "../PortfolioTable/PortfolioTable"
import { FEATURE_WEALTH_AND_INCOME_RANGE } from "../../../../../config/features"
import RetirementDrawdownChart from "../RetirementDrawdownChart/RetirementDrawdownChart"
import RetirementDrawdownLegends from "../RetirementDrawdownChart/RetirementDrawdownLegends"
import Loading from "../../../../../components/ClientProfile/Loading/Loading"
import clsx from "clsx"
import { useTheme } from "../../../../../contexts/ThemeContext"
import { latest } from "../../../../../lib/clients"
import GoalPriorityLabel from "../../../../advisor/Results/Goals/GoalPriorityLabel"

export const createOptions = ({
  goalType,
  clientOrHousehold,
  portfolios,
  isRange,
  disPortfolios,
  legacyPortfolios,
  goal
}: {
  goalType: InvestmentGoal
  clientOrHousehold?: Client | Household
  portfolios?: PortfolioReturn[]
  isRange?: boolean
  disPortfolios?: number[][]
  legacyPortfolios?: number[][]
  goal?: GoalDetail
}): Options | undefined => {
  if (portfolios) {
    const game = latest(clientOrHousehold!, "risk")
    const comfortMatch = calcComfortMatch({ portfolioMappings: game?.portfolioMappings, portfolios })
    const currentPortfolio = portfolios.find((portfolio) => portfolio.id === (goal?.selectedPortfolio || clientOrHousehold?.currentPortfolio))
    const goalMatch = calcGoalMatch({
      portfolios,
      goalType,
      otherSourcesIncome: goal
        ? goal.otherIncome?.value
        : (clientOrHousehold?.otherSourcesRetirementIncome ?? 0) + (clientOrHousehold?.pensionInRetirement ?? 0),
      retirementIncomeGoal: goal ? goal.targetAmount?.value : clientOrHousehold?.retirementIncomeGoal,
      wealthAccumulationGoal: goal ? goal.targetAmount?.value : clientOrHousehold?.wealthAccumulationGoal
    })
    const drawdownChartOptions = portfolios.map(({ expectedWealth, expectedWealthHigh, expectedWealthLow, name, riskComfort }) => ({
      expectedWealth,
      expectedWealthHigh,
      expectedWealthLow,
      name,
      riskComfort
    }))

    return {
      chartOptions: createPortfolioOptions({
        isRange,
        clientOrHousehold,
        comfortMatch,
        currentPortfolio,
        data: portfolios,
        goalMatch,
        goalType,
        goal
      }),
      comfortMatch,
      currentPortfolio,
      goalMatch,
      tableOptions: {
        portfolios
      },
      legacyPortfolios,
      disPortfolios,
      drawdownChartOptions
    }
  }
}

export type PortfolioOptionXAxisValue = {
  id: string
  name: string
  riskComfort: number
  goalAchievability: number
}

export interface PortfolioOptions {
  goalIndicator?: {
    goalType?: string
    label: string
    value: number
    y: number
  }
  series: {
    isComfortMatch: boolean
    isCurrentPortfolio: boolean
    isGoalMatch: boolean
    id: string
    value1: number
    value2?: number
  }[]
  xAxis: {
    values: PortfolioOptionXAxisValue[]
  }
  yAxis: {
    label: string
    values: {
      id: string
      label: string
      y: number
    }[]
  }
  wealthRange: {
    min: number
    max: number
  }[]
}

export interface Options {
  chartOptions: PortfolioOptions
  comfortMatch?: PortfolioReturn
  currentPortfolio?: PortfolioReturn
  goalMatch?: PortfolioReturn
  wealth?: PortfolioReturn[]
  tableOptions: {
    portfolios?: PortfolioReturn[]
  }
  isRange?: boolean
  legacyPortfolios?: number[][]
  disPortfolios?: number[][]
  drawdownChartOptions?: RetirementDrawdownResults[]
}

interface Props {
  client?: Client
  errorMessage?: string
  goalType: InvestmentGoal
  household?: Household
  message?: string
  options?: Options
  displayType: "chart" | "list"
  displayTypes?: (type: "chart" | "list") => void
  isRiskComfort?: boolean
  isRange?: boolean
  children?: ReactNode
  toggleState?: boolean
  isUpdating?: boolean
  goal?: GoalDetail
  outsideIM?: boolean
}

const SelectPortfolio = ({
  displayType,
  options,
  client,
  errorMessage,
  goalType,
  household,
  message,
  displayTypes,
  isRiskComfort,
  isRange,
  children,
  isUpdating,
  goal,
  outsideIM
}: Props) => {
  const theme = useTheme()
  const [showInfoTooltip, setShowInfoTooltip] = useState(false)
  const clientOrHousehold = client ?? household
  const [showCalculateInfoModal, setShowCalculateInfoModel] = useState(false)
  return (
    <div className={clsx("absolute w-full h-full flex flex-col pl-9 py-1 pr-1", { "pb-0": goalType === "retirementDrawdown", "pr-9 py-8": !outsideIM })}>
      {/* <div className="flex items-center gap-1">
          <h1 className="text-main-600 text-h4 font-semibold">
            {goalType === "wealthAccumulation"
              ? "Wealth Accumulation Projector"
              : goalType === "retirementDrawdown"
              ? "Retirement Income and Legacy Projector"
              : "Retirement Income Projector"}
          </h1>
          {FEATURE_WEALTH_AND_INCOME_RANGE && (
            <div className="w-5 h-5 relative" onMouseOver={() => setShowInfoTooltip(true)} onMouseOut={() => setShowInfoTooltip(false)}>
              <img className="cursor-pointer" src={infoIcon} alt="information icon" />
              {showInfoTooltip && (
                <div className="absolute -top-7 w-75 left-9/10 z-10">
                  <RMJTextTooltip>
                    <p className="text-sm leading-3">
                      {goalType === "retirementIncome"
                        ? "Our Retirement Income Projector illustrates how you might achieve your financial goals and stay comfortable with the level of investment risk"
                        : goalType === "retirementDrawdown"
                        ? "Our Retirement Income and Legacy Projector illustrates how you might achieve your financial goals and stay comfortable with the level of investment risk"
                        : "Our Wealth Accumulation Projector illustrates how you might achieve your financial goals while staying comfortable with the investment risk"}
                    </p>
                  </RMJTextTooltip>
                </div>
              )}
            </div>
          )}
        </div> */}
      {options && !errorMessage && (
        <div
          className={clsx("h-9 flex items-center mb-4", { "justify-between": goal?.priority && !outsideIM, "justify-end": !(goal?.priority && !outsideIM) })}
        >
          {goal?.priority && !outsideIM && <GoalPriorityLabel priority={goal.priority} />}
          <ChartDisplayToggle
            toggle1={<img src={chartToggle} alt="chart" />}
            toggle2={<img src={listToggle} alt="chart" />}
            onClick={(value) => {
              displayTypes!(value)
            }}
            valueDisplayType={displayType!}
          />
        </div>
      )}
      <div className={clsx("flex items-center", goalType === "retirementDrawdown" ? "justify-end" : "justify-between")}>
        {goalType !== "retirementDrawdown" && children}
        {options && displayType === "chart" && (
          <button className="btn btn-text btn-small btn-secondary text-xs flex gap-x-1 items-center" onClick={() => setShowCalculateInfoModel(true)}>
            <img src={questionMarkCircle} aria-hidden />
            How did we calculate this?
          </button>
        )}
      </div>
      {errorMessage && (
        <div className="border p-2 text-main-500 text-sm mb-6 flex flex-row gap-x-2 border-interactive-200 shadow items-center">
          <img src={errorIcon} alt="" />
          {errorMessage}
        </div>
      )}
      {message && !errorMessage && (
        <div className="w-full grow flex items-center justify-center">
          <p className="text-h5 text-main-500 mb-10">{message}</p>
        </div>
      )}
      {options && !errorMessage && (
        <>
          {displayType === "chart" ? (
            <>
              {goalType === "retirementDrawdown" ? (
                <div className="h-full w-full">
                  <div className="relative h-9/10 w-full">
                    {isUpdating ? <Loading /> : options.disPortfolios && <RetirementDrawdownChart client={client!} household={household!} options={options} />}
                  </div>
                  <RetirementDrawdownLegends clientOrHousehold={clientOrHousehold!} comfortMatch={options.comfortMatch!} />
                </div>
              ) : (
                <div className="flex flex-col grow mt-7 mb-6" style={{ minHeight: "400px" }}>
                  {isUpdating ? (
                    <Loading />
                  ) : (
                    <div className={clsx("grow", { "mb-6": !theme.charts?.comfortMatch?.highlightComfortZone || !isRiskComfort })}>
                      <PortfolioChart
                        options={options.chartOptions}
                        isRiskComfort={isRiskComfort && !outsideIM}
                        isRange={isRange!}
                        isUpdating={isUpdating}
                        clientOrHousehold={clientOrHousehold}
                        showComfortMatch={!goal?.selectedPortfolio || !outsideIM}
                        showGoalMatch={!goal?.selectedPortfolio || !outsideIM}
                      />
                    </div>
                  )}

                  <div className="flex flex-row gap-x-2 mt-2 justify-center items-center text-sm">
                    {goalType === "wealthAccumulation" && (
                      <>
                        <div className="bg-highlight-300 rounded-full w-2 h-2"></div>
                        <text>Total projected investment balance</text>
                      </>
                    )}

                    {goalType === "retirementIncome" && (
                      <>
                        <div className="bg-highlight-300 rounded-full w-2 h-2"></div>
                        <text>Total projected income</text>
                        {!!goal?.otherIncome?.value && (
                          <>
                            <div className="bg-highlight-400 rounded-full w-2 h-2"></div>
                            <text>Other income in retirement</text>
                          </>
                        )}
                      </>
                    )}
                  </div>
                </div>
              )}
            </>
          ) : (
            <div className="h-full flex flex-col justify-between">
              {isUpdating ? (
                <Loading />
              ) : (
                <div className="mb-2">
                  <PortfolioTable
                    options={options}
                    clientOrHousehold={clientOrHousehold}
                    goalType={goalType}
                    client={client}
                    household={household}
                    showComfortMatch={!goal?.selectedPortfolio || !outsideIM}
                    showGoalMatch={!goal?.selectedPortfolio || !outsideIM}
                    goal={goal}
                  />
                  <button className="btn btn-text btn-small btn-secondary text-xs flex gap-x-1 items-center" onClick={() => setShowCalculateInfoModel(true)}>
                    <img src={questionMarkCircle} aria-hidden />
                    How did we calculate this?
                  </button>
                </div>
              )}
            </div>
          )}
          {showCalculateInfoModal && (
            <Modal handleClose={() => setShowCalculateInfoModel(!showCalculateInfoModal)}>
              <div className="max-w-lg">
                <h2 className="text-h2 text-main-600">How did we calculate this?</h2>
                <p className="my-6">
                  {goalType === "wealthAccumulation"
                    ? "For each investment option we run 10,000 Monte Carlo simulations to see how much money you’re likely to have at your expected withdrawal date, given your investment amount, annual contributions, and time horizon."
                    : goalType === "retirementIncome"
                    ? "For each investment option we run 10,000 Monte Carlo simulations to see how much money you’re likely to have at retirement, given your investment amount, annual contributions, and timeframe. When you retire, we assume that you continue to stay invested in the same investment option and withdraw a constant amount each year (but adjusting upwards for inflation). For each simulation, we calculate the highest possible withdrawal rate such that you don’t run out of money before your planning horizon."
                    : "For each investment option we run 10,000 Monte Carlo simulations to see how your wealth is expected to change as you draw down on your investment during your retirement.  Market returns and volatility will influence each of our investment options - and therefore your balance - differently.  We assume that you’ll stay invested in the same investment option throughout your retirement, and each year you’ll withdraw your specified retirement income from your investment balance (offset by any income from other sources)."}
                </p>
                <hr className="border-surface-300 mb-6" />
                {goalType === "wealthAccumulation" ? (
                  <p className="mb-3">
                    <i>Goal achievability</i> is the percentage of Monte Carlo simulations where you exceed your wealth accumulation goal
                  </p>
                ) : goalType === "retirementIncome" ? (
                  <p className="mb-3">
                    <i>Goal achievability</i> is the percentage of Monte Carlo simulations where the withdrawal amount exceeds your retirement income goal.
                  </p>
                ) : (
                  !!clientOrHousehold?.estateGoal && (
                    <p className="mb-3">
                      <i>When legacy money may be required</i> is the year when there’s at least a 30% likelihood that you’ll need to begin using your 'legacy
                      money' to fund your retirement income withdrawals.
                    </p>
                  )
                )}
                {goalType === "wealthAccumulation" ? (
                  <p>
                    <i>Projected wealth</i> reflects the 30th percentile of Monte Carlo simulations, with the range reflecting the 15th-85th percentiles of
                    simulations.
                  </p>
                ) : goalType === "retirementIncome" ? (
                  <p>
                    <i>Projected income</i> reflects the withdrawal amount at the 30th percentile of Monte Carlo simulations, with the range reflecting the
                    15th-85th percentiles of simulations.
                  </p>
                ) : (
                  <p>
                    <i>{clientOrHousehold?.estateGoal ? "When legacy money may run out" : "When expected wealth may run out"}</i> is the year when there’s at
                    least a 30% likelihood you’ll have nothing left in your portfolio, with the range reflecting the 15%-85% likelihood range.
                  </p>
                )}
                <div className="flex justify-center mt-6">
                  <button className="btn btn-primary btn-medium w-44" onClick={() => setShowCalculateInfoModel(!showCalculateInfoModal)}>
                    Close
                  </button>
                </div>
              </div>
            </Modal>
          )}
        </>
      )}
    </div>
  )
}

export default SelectPortfolio
