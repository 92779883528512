import clsx from "clsx"
import { FocusEventHandler, ReactNode, useState } from "react"
import chevronDown from "./chevron-down.svg"
import ErrorMessage from "../Error/ErrorMessage"

export type Option = {
  id: string
  label: string
  value?: string
  disabled?: boolean
}

type Props = {
  className?: string
  inputClassName?: string
  error?: string | ReactNode | boolean
  id: string
  isDisabled?: boolean
  label?: string | ReactNode
  name?: string
  onBlurProp?: (evt: React.FocusEvent<Element>) => void
  onChange?: (evt: React.ChangeEvent<HTMLSelectElement>) => void
  options: Option[]
  value?: string | number | undefined
}

const Select = ({ className, inputClassName, error, id, isDisabled = false, label, name, onBlurProp, onChange, options, value }: Props) => {
  const [isFocused, setIsFocused] = useState(false)

  const onFocus = () => {
    setIsFocused(true)
  }

  const onBlur: FocusEventHandler = (evt) => {
    setIsFocused(false)
    if (onBlurProp) {
      onBlurProp(evt)
    }
  }

  return (
    <>
      <div className={clsx("select-input flex flex-col h-full", className)}>
        {label && (
          <label className="select-input-label block w-full font-semibold text-sec leading-4 text-grey-500 mb-1" htmlFor={id}>
            {label}
          </label>
        )}
        <select
          className={clsx(
            "appearance-none w-full h-full border border-main-400 outline-none pl-4 pr-8 py-2.5 flex items-center bg-no-repeat bg-right text-p bg-white overflow-hidden whitespace-nowrap text-ellipsis",
            {
              "border-red-500 shadow-err": !!error,
              "border-main-200 text-main-300": isDisabled,
              "border-main-400 text-main-500": !error && !isDisabled,
              "shadow-focuscolor border-focuscolor": isFocused && !error && !isDisabled
            },
            inputClassName
          )}
          disabled={isDisabled}
          id={id}
          name={name}
          onBlur={onBlur}
          onFocus={onFocus}
          onChange={onChange}
          style={{ backgroundImage: `url(${chevronDown})`, backgroundPosition: "right 2% center" }}
          value={value}
        >
          {options.map((option) => (
            <option key={option.id} value={option.value} disabled={option.disabled}>
              {option.label}
            </option>
          ))}
        </select>
        <div role="alert">{error && typeof error !== "boolean" && <ErrorMessage id="select-input" message={error} />}</div>
      </div>
    </>
  )
}

export default Select
