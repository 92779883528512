import { useTheme } from "../../../../../contexts/ThemeContext"
import { comfortMatch } from "../../../../../lib/clients"
import { Client, Game } from "../../../../../models/Client"
import RMJPortfolioComfort from "../../../../rmjourney/portfolioComfort/RMJPortfolioComfort"
import PortfolioSpectrumGraph from "../PortfolioSpectrumGraph/PortfolioSpectrumGraph"

const RiskComfortZone = ({ client, game }: { client: Client; game: Game }) => {
  const theme = useTheme()

  return theme.charts?.comfortMatch?.showAsSpectrumGraph ? (
    <PortfolioSpectrumGraph client={client!} game={game} />
  ) : (
    <RMJPortfolioComfort translationContext="advisor" />
  )
}

export default RiskComfortZone

export const comfortZoneText = (game: Game) => {
  const portfolioModel = comfortMatch(game)
  const highestPortfolioModelName = portfolioModel?.portfolio.name
  
  const personasInComfortZone = game.portfolioMappings?.filter(({ riskComfort }) => riskComfort! >= 60)
  const spectrumGraphlabels = game.portfolioMappings?.map(({ portfolio }) => portfolio).sort((a, b) => a.sd! - b.sd!)
  const personasPosition: number[] = personasInComfortZone
  ?.map((data, i) => spectrumGraphlabels?.findIndex((portfolio) => portfolio.name === data.portfolio.name))
  .sort()
  
  if (personasPosition) {
    const [lowerBound, higherBound] = [personasPosition[0], personasPosition[personasPosition.length - 1]]
    const [lowestPortfolioModel, highestPortfolioModel] = [
      game.portfolioMappings.find(({ portfolio }) => portfolio.translationKey === spectrumGraphlabels?.[lowerBound!].translationKey),
      game.portfolioMappings.find(({ portfolio }) => portfolio.translationKey === spectrumGraphlabels?.[higherBound!].translationKey)
    ]
    return personasInComfortZone?.length === 1
      ? `comfort zone aligns with our ${highestPortfolioModelName} approach.`
      : personasInComfortZone?.length === 2
      ? `comfort zone aligns with our ${lowestPortfolioModel?.portfolio.name} and ${highestPortfolioModel?.portfolio.name} investment approaches.`
      : `comfort zone spans our ${lowestPortfolioModel?.portfolio.name} to ${highestPortfolioModel?.portfolio.name} investment approaches.`
  } else {
    return null
  }
}
