import clsx from "clsx"
import { useState } from "react"
import chevronDown from "../../../../advisor/assets/images/chevron-down.svg"
import { createDomMotionComponent } from "framer-motion"
import { customDateFormat } from "../../../../../lib/date"
import { Client, Game } from "../../../../../models/Client"

const motion = {
  div: createDomMotionComponent("div")
}

const GameComment = ({ client, game, showBottomBorder = false, comment }: { client: Client; game?: Game; showBottomBorder?: boolean; comment?: string }) => {
  const [expanded, setExpanded] = useState<boolean>(false)
  return (
    <li className={clsx("flex justify-between gap-x-3 pl-4 pr-3 py-4 w-full relative", { "border-b border-surface-300": showBottomBorder })}>
      <div className="flex flex-col w-full pr-10">
        <div className="flex text-sm text-main-400">
          <p className="flex gap-x-1">
            <span className="font-semibold">{client?.firstName}</span>
            <span>{game?.played && `· ${customDateFormat(game.played)}`}</span>
          </p>
        </div>
        <motion.div
          initial={false}
          animate={{ height: expanded ? "auto" : "full" }}
          transition={{ duration: 0.3, ease: "easeInOut" }}
          className="overflow-hidden"
        >
          <p
            className={clsx("text-left", {
              "line-clamp-1": !expanded,
              "whitespace-pre-wrap": expanded
            })}
          >
            {comment || "There is no information currently available"}
          </p>
        </motion.div>
      </div>
      <button
        className="w-8 h-8 flex justify-center items-center absolute top-4 right-3"
        onClick={() => setExpanded(!expanded)}
        aria-expanded={expanded}
        aria-label={expanded ? "Collapse comment" : "Expand comment"}
      >
        <img src={chevronDown} alt="" aria-hidden className={clsx("transition-transform", { "rotate-180": expanded })} />
      </button>
    </li>
  )
}

export default GameComment
