import { InvestmentGoal } from "../../models/Client"
import { ModelPortfolio } from "../../models/PortfolioModel"
import axiosInstance from "../axiosInstance"

export type RetirementDrawdownResults = {
  expectedWealthLow: number[]
  expectedWealth: number[]
  expectedWealthHigh: number[]
  name: string
  riskComfort: number
}

export type PortfolioReturn = ModelPortfolio & {
  riskComfort: number
  percentiles: { [key: string]: number }
  goalAchievability: number
} & RetirementDrawdownResults

export type GoalAchievability = {
  id: string
  goalType: InvestmentGoal
  results: PortfolioReturn[]
  legacyPortfolios: number[][]
  disPortfolios: number[][]
}

export const computeGoalAchievability = async ({
  goalType,
  advisorId,
  clientId,
  householdId,
  goalId
}: {
  goalType: InvestmentGoal
  advisorId: string
  clientId?: string
  householdId?: string
  goalId?: string
}) => {
  return axiosInstance
    .get<GoalAchievability>(
      `${import.meta.env.VITE_APP_API_BASE || ""}/api/invest/${advisorId}/${clientId ? "client" : "household"}/${
        clientId ?? householdId
      }/goals/${goalType}/outcome${goalId ? `?goalId=${goalId}` : ""}`
    )
    .then((res) => res.data)
}
