/* eslint-disable @typescript-eslint/no-non-null-asserted-optional-chain */
import clsx from "clsx"
import { AnimatePresence, motion } from "framer-motion"
import { ReactNode, useMemo, useRef, useState } from "react"
import { PortfolioReturn } from "../../../../../api/rm/goals"
import { FEATURE_WEALTH_AND_INCOME_RANGE } from "../../../../../config/features"
import { useTheme } from "../../../../../contexts/ThemeContext"
import { shortRound } from "../../../../../lib/numbers"
import { Client, GoalDetail, InvestmentGoal } from "../../../../../models/Client"
import { Household } from "../../../../../models/Household"
import currentPortfolioIcon from "../../../assets/images/current-portfolio.svg"
import goalMatchIcon from "../../../assets/images/goal-match.svg"
import infoIcon from "../../../assets/images/info-icon.svg"
import riskComfortIcon from "../../../assets/images/riskComfort.svg"
import rounding from "../../../rounding"
import RMJTextTooltip from "../RMJTextTooltip/RMJTextTooltip"
import { PortfolioOptionXAxisValue, PortfolioOptions } from "../SelectPortfolio/SelectPortfolio"
import { getStepLevel, getThresholdRule } from "./portfolioChartUtils"
import { latest } from "../../../../../lib/clients"
import { tt } from "../../../../../lib/translations"

const comfortMatchGradient = "linear-gradient(180deg, rgb(87, 162, 172), rgb(67, 121, 129))"
const goalMatchGradient = "linear-gradient(180deg, rgb(211, 147, 87), rgb(160, 97, 38))"
const comfortGoalGradient = "linear-gradient(180deg, rgb(87, 162, 172), rgb(212, 147, 88))"
const currentPortfolioGradient = "linear-gradient(180deg, rgb(165, 129, 195), rgb(105, 69, 137))"
const comfortCurrentGradient = "linear-gradient(180deg, rgb(87, 162, 172), rgb(134, 98, 165))"

const getBarBackground = (isComfortMatch: boolean, isCurrentPortfolio: boolean, isGoalMatch: boolean) => {
  if (isComfortMatch) {
    // show current portfolio instead of goal match if it exists
    if (isCurrentPortfolio) {
      return comfortCurrentGradient
    } else if (isGoalMatch) {
      return comfortGoalGradient
    } else {
      return comfortMatchGradient
    }
  } else if (isCurrentPortfolio) {
    return currentPortfolioGradient
  } else if (isGoalMatch) {
    return goalMatchGradient
  } else {
    return "#C2C8FE"
  }
}

export const createPortfolioOptions = ({
  isRange,
  clientOrHousehold,
  comfortMatch,
  currentPortfolio,
  data = [],
  goalMatch,
  goalType,
  goal
}: {
  isRange?: boolean
  clientOrHousehold?: Client | Household
  comfortMatch?: PortfolioReturn
  currentPortfolio?: PortfolioReturn
  household?: Household
  data?: PortfolioReturn[]
  goalMatch?: PortfolioReturn
  goalType: InvestmentGoal
  goal?: GoalDetail
}): PortfolioOptions => {
  const otherSourcesIncome = goal ? (goal.otherIncome?.value ?? 0) : (clientOrHousehold?.otherSourcesRetirementIncome ?? 0) + (clientOrHousehold?.pensionInRetirement ?? 0)
  const retirementIncomeGoal = goal ? goal.targetAmount?.value : clientOrHousehold?.retirementIncomeGoal
  const wealthAccumulationGoal = goal ? goal.targetAmount?.value : clientOrHousehold?.wealthAccumulationGoal
  const allIncomes = data.reduce<number[]>((accumulator, currentValue) => {
    const {
      percentiles: { 30: income }
    } = currentValue

    return [...accumulator, goalType === "retirementIncome" ? otherSourcesIncome + income : income] // add the base income not from investment
  }, [])

  // add in income goal in case it's higher than the max portfolio income
  if (goalType === "retirementIncome" && retirementIncomeGoal) {
    allIncomes.push(retirementIncomeGoal)
  }

  if (goalType === "wealthAccumulation" && wealthAccumulationGoal) {
    allIncomes.push(wealthAccumulationGoal)
  }
  const range = data.map(({ percentiles }) => (goalType === "wealthAccumulation" ? percentiles["85"] : percentiles["85"] + otherSourcesIncome))
  const maxRange = Math.max(...range)
  const maxIncomeRange = Math.max(...allIncomes)
  const maxIncome = isRange && maxRange > maxIncomeRange ? maxRange : maxIncomeRange

  const numYAxisLabels = 7
  const step = numYAxisLabels - 1
  const baseNumber = rounding(maxIncome)
  const scaleValue = Math.ceil(maxIncome / step / baseNumber) * baseNumber
  const both = currentPortfolio ? comfortMatch?.id === currentPortfolio.id : comfortMatch?.id === goalMatch?.id
  const comfortMatchValue = comfortMatch ? otherSourcesIncome + comfortMatch?.percentiles[30] : 0
  const currentPortfolioOrGoalMatchValue = currentPortfolio
    ? otherSourcesIncome + currentPortfolio.percentiles[30]
    : goalMatch
    ? otherSourcesIncome + goalMatch?.percentiles[30]
    : 0
  const thresholdRule = getThresholdRule(scaleValue, both ? step - 1 : step)
  // threshold by percent of the axis-y height
  const threshold = {
    one: thresholdRule([0.25]),
    two: thresholdRule([0.65, 1.5])
  }
  const level = getStepLevel(Math.max(comfortMatchValue, currentPortfolioOrGoalMatchValue), threshold[both ? "two" : "one"])

  const maxYValue = scaleValue * (step + level)
  const yAxis = Array(numYAxisLabels + level)
    .fill(0)
    .map((_, i) => {
      const amount = shortRound(scaleValue * i)

      return {
        id: `y-axis-${i}`,
        label: `$${amount.value}${amount.unit}`
      }
    })
    .reverse() // reverse as axis starts at highest value

  return {
    goalIndicator:
      goalType === "retirementIncome" && retirementIncomeGoal
        ? {
            goalType: goal?.type,
            label: "Retirement goal",
            value: retirementIncomeGoal,
            y: 100 - (retirementIncomeGoal / maxYValue) * 100
          }
        : wealthAccumulationGoal
        ? {
            goalType: goal?.type,
            label: "Wealth goal",
            value: wealthAccumulationGoal,
            y: 100 - (wealthAccumulationGoal / maxYValue) * 100
          }
        : undefined,
    series: data.map(({ id, percentiles: { 30: income } }) => ({
      isComfortMatch: goalType === "retirementDrawdown" ? false : id === comfortMatch?.id,
      isCurrentPortfolio: goalType === "retirementDrawdown" ? false : id === currentPortfolio?.id,
      // show current portfolio instead of goal match if it exists
      isGoalMatch: !goal?.selectedPortfolio && !clientOrHousehold?.currentPortfolio && id === goalMatch?.id,
      id,
      value1: goalType === "retirementDrawdown" ? 0 : (income / maxYValue) * 100,
      value2: goalType === "retirementIncome" ? (otherSourcesIncome / maxYValue) * 100 : goalType === "retirementDrawdown" ? 0 : undefined
    })),
    wealthRange: data.map((data) =>
      goalType === "retirementIncome"
        ? { min: ((data.percentiles["15"] + otherSourcesIncome) / maxYValue) * 100, max: ((data.percentiles["85"] + otherSourcesIncome) / maxYValue) * 100 }
        : { min: (data.percentiles["15"] / maxYValue) * 100, max: (data.percentiles["85"] / maxYValue) * 100 }
    ),
    xAxis: {
      values: data.map(({ goalAchievability, id, name, riskComfort }) => ({
        id,
        name,
        riskComfort,
        goalAchievability
      }))
    },
    yAxis: {
      label: goalType === "retirementIncome" ? "Projected retirement income (today's dollars)" : "Projected wealth",
      values: yAxis.map((item, i) => {
        return {
          ...item,
          y: (100 / (yAxis.length - 1)) * i
        }
      })
    }
  }
}

const WealthRangeLine = ({
  min,
  max,
  initialVal,
  className,
  isUpdating,
  forReport
}: {
  min: number
  max: number
  initialVal: number
  className: string
  isUpdating: boolean
  forReport?: boolean
}) => {
  return (
    <AnimatePresence initial={!forReport}>
      <motion.div
        className={`absolute z-30 border-t border-b border-solid w-2 h-20 ${className}`}
        initial={{ bottom: `${initialVal}%`, height: "0%", opacity: 0 }}
        animate={{
          bottom: `${forReport || isUpdating ? min : initialVal}%`,
          height: `${forReport || isUpdating ? max - min : 0}%`,
          opacity: forReport || isUpdating ? 1 : 0
        }}
        transition={{ duration: 0.3, delay: 0.5, ease: "easeIn" }}
      >
        <motion.hr className={`mx-auto border-l border-dashed h-full w-min ${className}`} />
      </motion.div>
    </AnimatePresence>
  )
}

const BarContent = ({
  background,
  children,
  referencePoint
}: {
  background: string
  children?: ReactNode
  referencePoint?: React.RefObject<HTMLDivElement>
}) => {
  return (
    <motion.div
      ref={referencePoint}
      className="absolute w-full h-full"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      style={{ background }}
      transition={{ duration: 0.3, delay: 0.25, ease: "easeIn" }}
    >
      <div className={clsx("absolute w-full -top-1 -translate-y-full flex flex-col items-center gap-1 pb-1 z-40 bg-white")}>{children}</div>
    </motion.div>
  )
}

const PortfolioChart = ({
  clientOrHousehold,
  options,
  isRiskComfort,
  isRange,
  isUpdating,
  forReport = false,
  showComfortMatch,
  showGoalMatch
}: {
  clientOrHousehold?: Client | Household
  options: PortfolioOptions
  isRiskComfort?: boolean
  isRange?: boolean
  isUpdating?: boolean
  forReport?: boolean
  showComfortMatch?: boolean
  showGoalMatch?: boolean
}) => {
  const theme = useTheme()
  const [showGoalMatchTooltip, setShowGoalMatchTooltip] = useState(false)
  const [showCurrentInvestmentTooltip, setShowCurrentInvestmentTooltip] = useState(false)
  const [showRangeLineOnAnimationComplete, setShowRangeLineOnAnimationComplete] = useState(false)
  const [showComfortZoneTooltip, setShowComfortZoneTooltip] = useState(false)
  const tooltipRef = useRef<HTMLDivElement>(null)
  const containerRef = useRef<HTMLDivElement>(null)
  const portfolioBarRef = useRef<HTMLDivElement>(null)
  const chartLabelRef = useRef<HTMLDivElement>(null)
  const tooltipPos = tooltipRef.current?.getBoundingClientRect()
  const offSetX = 10
  const offSetY = 80
  const game = latest(clientOrHousehold!, "risk")
  const portfolioXAxisValues = useMemo(
    () =>
      options.xAxis.values.reduce(
        (acc: { pre: PortfolioOptionXAxisValue[]; match: PortfolioOptionXAxisValue[]; post: PortfolioOptionXAxisValue[] }, value) => {
          const group = value.riskComfort >= 60 ? "match" : acc.match.length > 0 ? "post" : "pre"
          acc[group].push(value)
          return acc
        },
        { pre: [], match: [], post: [] }
      ),
    [options]
  )

  const isPreInvestmentMeeting = !forReport && clientOrHousehold?.status === "New results available"

  return (
    <div className="goal-projector-chart w-full h-full flex flex-col">
      <div className="flex grow">
        <div className="relative w-6 shrink-0">
          <div className="absolute top-1/2 -rotate-90 -translate-x-1/2 -translate-y-1/2 mx-3 text-sec whitespace-nowrap">{options.yAxis.label}</div>
        </div>
        <div className="relative w-14 h-full flex flex-col shrink-0">
          {options.yAxis.values.map(({ id, label, y }) => {
            return (
              <div className="absolute w-full flex -translate-y-1/2 pr-2" key={id} style={{ top: `${y}%` }}>
                <p className=" w-full text-right text-sm">{label}</p>
              </div>
            )
          })}
        </div>
        <div className="relative flex flex-col grow">
          <div className="relative w-full flex grow">
            <div className="absolute w-full h-full">
              {options.yAxis.values.map(({ id, y }) => {
                return <div className="absolute w-full border-t border-dashed border-surface-300" key={id} style={{ top: `${y}%` }} />
              })}
            </div>
            <div className="absolute w-full h-full flex" ref={containerRef}>
              {options.series?.map(({ id, value1, isComfortMatch: comfortMatched, isCurrentPortfolio, isGoalMatch: goalMatched, value2 }, i: number) => {
                const isGoalMatch = Boolean(showGoalMatch && !!goalMatched)
                const isComfortMatch = Boolean(showComfortMatch && !!comfortMatched)
                const background = getBarBackground(isComfortMatch, isCurrentPortfolio, isGoalMatch)
                const reportBackground = getBarBackground(false, isCurrentPortfolio, isGoalMatch)
                return (
                  <div className="h-full flex flex-col items-center justify-end" key={id} style={{ width: `${100 / (options.series.length - 1)}%` }}>
                    {isRange && (
                      <WealthRangeLine
                        forReport={forReport}
                        isUpdating={showRangeLineOnAnimationComplete && !isUpdating}
                        initialVal={!value2 ? value1 : value1 + value2!}
                        min={options.wealthRange[i].min}
                        max={options.wealthRange[i].max}
                        className={
                          isGoalMatch
                            ? "border-goal-600"
                            : isCurrentPortfolio
                            ? "border-avatar-0-500"
                            : isComfortMatch && isRiskComfort
                            ? "border-highlight-400"
                            : "border-highlight-500"
                        }
                      />
                    )}
                    <AnimatePresence initial={!forReport}>
                      <motion.div
                        ref={portfolioBarRef}
                        className={clsx("relative w-3/5 bg-highlight-300", {
                          "border-t-2 border-goal-600": isRange && isGoalMatch && !isCurrentPortfolio && !isComfortMatch,
                          "border-t-2 border-highlight-500":
                            (isRange && !isCurrentPortfolio && !isGoalMatch && !isComfortMatch) || (forReport && !isCurrentPortfolio),
                          "border-t-2 border-avatar-0-500": isRange && isCurrentPortfolio,
                          "border-t-2 border-highlight-400": isRange && isComfortMatch && !isCurrentPortfolio && !isGoalMatch
                        })}
                        key={`${id}-one`}
                        initial={{ height: "0%" }}
                        animate={{ height: `${value1}%` }}
                        transition={{ duration: 1, delay: 0.25, ease: "easeIn" }}
                        onAnimationStart={() => setShowRangeLineOnAnimationComplete(false)}
                        onAnimationComplete={() => setShowRangeLineOnAnimationComplete(true)}
                      >
                        {isComfortMatch && !isGoalMatch && !isCurrentPortfolio && isRiskComfort && (
                          <BarContent background={isPreInvestmentMeeting ? background : reportBackground}>
                            {isPreInvestmentMeeting && (
                              <>
                                <img alt="" aria-hidden src={riskComfortIcon} />
                                <p className="text-xs text-riskComfort-500 text-center font-semibold uppercase">
                                  Highest
                                  <br />
                                  Risk
                                  <br />
                                  comfort
                                </p>
                              </>
                            )}
                          </BarContent>
                        )}

                        {isGoalMatch && !isComfortMatch && (
                          <>
                            {showGoalMatchTooltip && (
                              <div
                                className="fixed w-75 cursor-default z-50"
                                style={{
                                  top: `${tooltipPos?.top! - offSetY}px`,
                                  left: `${tooltipPos?.left! + tooltipPos?.width! - (tooltipPos?.width! / 2 - offSetX)}px`
                                }}
                              >
                                <RMJTextTooltip>
                                  <p className="text-sm leading-3">
                                    Your Goal Match investment option has just enough expected return to achieve your financial goal, without taking too much
                                    risk
                                  </p>
                                </RMJTextTooltip>
                              </div>
                            )}
                            <BarContent background={isPreInvestmentMeeting ? background : reportBackground} referencePoint={tooltipRef}>
                              <div
                                className={clsx("flex flex-col items-center gap-y-0.5 pt-0.5", {
                                  "cursor-default": !FEATURE_WEALTH_AND_INCOME_RANGE,
                                  "cursor-pointer": FEATURE_WEALTH_AND_INCOME_RANGE
                                })}
                                onMouseOver={FEATURE_WEALTH_AND_INCOME_RANGE ? () => setShowGoalMatchTooltip(true) : () => {}}
                                onMouseOut={() => setShowGoalMatchTooltip(false)}
                              >
                                <img alt="" src={goalMatchIcon} />
                                <p className="text-xs text-goal-600 text-center font-semibold uppercase">
                                  Goal <br />
                                  match
                                </p>
                              </div>
                            </BarContent>
                          </>
                        )}
                        {isComfortMatch && isGoalMatch && (
                          <BarContent background={isPreInvestmentMeeting ? background : reportBackground}>
                            <p className="text-xs text-riskComfort-500 text-center font-semibold uppercase">
                              Risk
                              <br />
                              comfort <br />
                              + <br />
                              Goal <br />
                              match
                            </p>
                          </BarContent>
                        )}

                        {isCurrentPortfolio && !isComfortMatch && (
                          <>
                            {showCurrentInvestmentTooltip && (
                              <div
                                className="fixed w-75 cursor-default z-50"
                                style={{
                                  top: `${tooltipPos?.top! - offSetY}px`,
                                  left: `${tooltipPos?.left! + tooltipPos?.width! - (tooltipPos?.width! / 2 - offSetX)}px`
                                }}
                              >
                                <RMJTextTooltip>
                                  <p className="text-sm leading-3">
                                    Your current investment was selected with the aim of achieving your financial goals, without taking too much risk.
                                  </p>
                                </RMJTextTooltip>
                              </div>
                            )}
                            <BarContent background={isPreInvestmentMeeting ? background : reportBackground} referencePoint={tooltipRef}>
                              <div
                                className={clsx("flex flex-col items-center gap-y-0.5 pt-0.5", {
                                  "cursor-default": !FEATURE_WEALTH_AND_INCOME_RANGE,
                                  "cursor-pointer": FEATURE_WEALTH_AND_INCOME_RANGE
                                })}
                                onMouseOver={FEATURE_WEALTH_AND_INCOME_RANGE ? () => setShowCurrentInvestmentTooltip(true) : () => {}}
                                onMouseOut={() => setShowCurrentInvestmentTooltip(false)}
                              >
                                <img className="w-4 h-4" alt="" src={currentPortfolioIcon} aria-hidden />
                                <p className="text-xs text-avatar-0-500 text-center font-semibold uppercase">
                                  {forReport ? (
                                    <>
                                      Selected <br />
                                      investment
                                    </>
                                  ) : (
                                    <>
                                      Current <br />
                                      investment
                                    </>
                                  )}
                                </p>
                              </div>
                            </BarContent>
                          </>
                        )}
                        {isComfortMatch && isCurrentPortfolio && (
                          <BarContent background={isPreInvestmentMeeting ? background : reportBackground}>
                            <p className="text-xs text-riskComfort-500 text-center font-semibold uppercase">
                              {isPreInvestmentMeeting && (
                                <>
                                  Risk
                                  <br />
                                  comfort <br />
                                  + <br />
                                </>
                              )}
                              {forReport ? (
                                <span className="text-avatar-0-500">
                                  Selected <br />
                                  investment
                                </span>
                              ) : (
                                <span className={isPreInvestmentMeeting ? "text-riskComfort-500" : "text-avatar-0-500"}>
                                  Current <br />
                                  investment
                                </span>
                              )}
                            </p>
                          </BarContent>
                        )}
                      </motion.div>
                      {!!value2 && (
                        <motion.div
                          className="relative w-3/5 bg-highlight-400"
                          key={`${id}-two`}
                          initial={{ height: "0%" }}
                          animate={{ height: `${value2}%` }}
                          transition={{ duration: 1, delay: 0.25, ease: "easeIn" }}
                          onAnimationStart={() => setShowRangeLineOnAnimationComplete(false)}
                          onAnimationComplete={() => setShowRangeLineOnAnimationComplete(true)}
                        />
                      )}
                    </AnimatePresence>
                  </div>
                )
              })}
              {options.goalIndicator && (
                <motion.div
                  className="absolute w-full border-t border-main-600 z-40"
                  initial={{ top: forReport ? `${options.goalIndicator.y}%` : "100%" }}
                  animate={{ top: `${options.goalIndicator.y}%` }}
                  transition={{ duration: 1, delay: 0.25, ease: "easeIn" }}
                >
                  <p className="absolute -top-1 -translate-y-full text-xs font-semibold text-main-500">
                    {options.goalIndicator.goalType
                      ? tt({ id: `portfolio-chart-${options.goalIndicator.goalType}-target-line-label` })
                      : options.goalIndicator.label}
                  </p>
                </motion.div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col gap-2 h-auto">
        <div className="relative w-full flex">
          <div className="w-20 h-full shrink-0"></div>
          {options.xAxis.values.map(({ id, name }) => {
            return (
              <div ref={chartLabelRef} className="flex flex-col gap-2 pt-2" key={id} style={{ width: `${100 / (options.xAxis.values.length - 1)}%` }}>
                <p className="text-center text-sm font-semibold">{name}</p>
              </div>
            )
          })}
        </div>
        <div className={clsx("relative w-full flex", forReport && "mt-3")}>
          <div className="w-20 h-full shrink-0 text-sm">Achievability</div>
          {options.xAxis.values.map(({ id, goalAchievability }) => {
            return (
              <div className="flex flex-col gap-2" key={id} style={{ width: `${100 / (options.xAxis.values.length - 1)}%` }}>
                <p className="text-center text-sm">{goalAchievability}%</p>
              </div>
            )
          })}
        </div>
        {(!FEATURE_WEALTH_AND_INCOME_RANGE || isRiskComfort) && game?.risk?.results && (
          <div className="relative w-full flex">
            <div className="w-20 h-full shrink-0 text-sm">Risk comfort</div>
            {portfolioXAxisValues.pre.map(({ id, riskComfort }) => (
              <div className="flex flex-col gap-2" key={id} style={{ width: `${100 / (options.xAxis.values.length - 1)}%` }}>
                <p className="text-center text-sm">{riskComfort}%</p>
              </div>
            ))}
            <div
              className={clsx("flex flex-row relative justify-center", { "bg-interactive-100 -mt-1 pt-1": theme?.charts?.comfortMatch?.highlightComfortZone })}
              style={{ width: `${portfolioXAxisValues.match.length * (100 / (options.xAxis.values.length - 1))}%` }}
            >
              {portfolioXAxisValues.match.map(({ id, riskComfort }) => (
                <div className="flex flex-col gap-2 mb-7" key={id} style={{ width: `${100 / (portfolioXAxisValues.match.length - 1)}%` }}>
                  <p className="text-center text-sm">{riskComfort}%</p>
                </div>
              ))}
              {theme.charts?.comfortMatch?.highlightComfortZone && (
                <div
                  className={clsx(
                    "flex gap-x-1 items-center absolute justify-center left-0 right-0 top-7 mx-auto h-max text-highlight-700 text-xs font-semibold text-center leading-xs",
                    {
                      "max-w-[60px] top-1/4": portfolioXAxisValues.match.length === 1
                    }
                  )}
                >
                  Comfort zone
                  {!forReport && (
                    <div className="w-4 h-4">
                      <img
                        className="cursor-pointer"
                        src={infoIcon}
                        aria-hidden
                        onMouseOver={() => setShowComfortZoneTooltip(true)}
                        onMouseOut={() => setShowComfortZoneTooltip(false)}
                      />
                    </div>
                  )}
                </div>
              )}
              {showComfortZoneTooltip && (
                <div
                  className={clsx(
                    "absolute w-75 top-full z-10",
                    portfolioXAxisValues.match.length && portfolioXAxisValues.post.length <= 1 ? "right-[20%]" : "left-[20%]"
                  )}
                >
                  <RMJTextTooltip>
                    <p className="text-sm leading-3">The comfort zone highlights where you have a Risk Comfort score of 60% or above.</p>
                  </RMJTextTooltip>
                </div>
              )}
            </div>
            {portfolioXAxisValues.post.map(({ id, riskComfort }) => {
              return (
                <div className="flex flex-col gap-2" key={id} style={{ width: `${100 / (options.xAxis.values.length - 1)}%` }}>
                  <p className="text-center text-sm">{riskComfort}%</p>
                </div>
              )
            })}
          </div>
        )}
      </div>
    </div>
  )
}

export default PortfolioChart
