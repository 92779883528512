import { useMemo } from "react"
import { useParams } from "react-router"
import LineChart from "../components/LineChart/LineChart"
import peopleIcon from "../assets/images/icon-people.svg"
import useClient from "../../../hooks/useClient"
import useHousehold from "../../../hooks/useHousehold"
import Loading from "../../../components/ClientProfile/Loading/Loading"
import LoadingError from "../../../components/ClientProfile/LoadingError/LoadingError"
import { riskAttitudeAxisValues, riskAttitudeDetails } from "./riskAttitudeAttributes"
import PatternsModalLink from "../../clients/reports/RiskChartsPage/components/PatternsModalLink"
import { useTheme } from "../../../contexts/ThemeContext"
import { latest } from "../../../lib/clients"
import clsx from "clsx"

const RMJRiskAttitude = ({ translationContext = "client" }: { translationContext?: "advisor" | "client" }) => {
  const { clientId, householdId } = useParams<{ clientId: string; householdId: string }>()
  const { client, isClientLoading, clientLoadError } = useClient(clientId)
  const { household, isHouseholdLoading, householdLoadError } = useHousehold(householdId)
  const theme = useTheme()
  const riskAttitudeMessages = riskAttitudeDetails(client, household, translationContext)

  const attitudeToRisk = useMemo(() => {
    return riskAttitudeAxisValues(theme, client, household)
  }, [client, household, theme])

  const showNote = theme.charts?.attitudeToRisk?.note?.show && riskAttitudeMessages?.note

  return (
    <div className="rmj-risk-attitude w-full h-full overflow-auto no-scrollbar px-4">
      <div className={clsx("w-full h-full flex flex-col", { "gap-y-10": !showNote && !theme.charts?.attitudeToRisk?.description?.show })}>
        <div className="flex justify-between items-center mb-6">
          <div>
            <p className="text-sec text-main-600 mb-1 uppercase">Attitude to risk</p>
            <div className="flex flex-col gap-y-6">
              {riskAttitudeMessages?.title && <h4 className="text-h3 font-semibold text-main-600">{riskAttitudeMessages?.title}</h4>}
              {theme.charts?.attitudeToRisk?.description?.show && riskAttitudeMessages?.description && <p>{riskAttitudeMessages?.description}</p>}
            </div>
          </div>
          <div className="score flex gap-8">
            {client ? (
              <h1 className="text-h2 text-right text-interactive-400 font-semibold flex flex-col">
                {latest(client, "risk")?.risk.results?.R.riskTolerance}
                <span className="text-sm text-interactive-400">Risk score</span>
              </h1>
            ) : (
              household?.members
                .filter((member) => member.client.games?.find((game) => game.gameType === "risk")?.played)
                .map(({ client }, i) => (
                  <h1 className="text-h2 text-right text-interactive-400 font-semibold flex flex-col items-end" key={client._id}>
                    <span className="flex items-center gap-2">
                      {attitudeToRisk?.legend && <div className="w-2 h-2 rounded-full" style={{ backgroundColor: attitudeToRisk.legend[i].color }} />}
                      {latest(client, "risk")?.risk.results?.R.riskTolerance}
                    </span>
                    <span className="text-sm text-interactive-400">Risk score</span>
                  </h1>
                ))
            )}
          </div>
        </div>
        {(isClientLoading || isHouseholdLoading) && (
          <div className="absolute top-0 bottom-0 left-0 right-0 flex justify-center items-center">
            <Loading />
          </div>
        )}
        <div className="rmj-risk-attitude-line-chart h-full w-full grow">
          {showNote && (
            <div className="risk-attitude-note flex gap-x-1 bg-surface-200 p-3 pr-5 items-center text-sec mb-12 w-full">
              <img className="h-4 w-4" alt="" src={theme.reports?.riskChartsPage?.attitudeToRisk?.note?.icon} aria-hidden />
              <p>{riskAttitudeMessages.note}</p>
            </div>
          )}
          {(client || household) && (
            <>
              <LineChart
                className="min-h-[360px]"
                xAxis={attitudeToRisk!.xAxis}
                yAxis={attitudeToRisk!.yAxis}
                gameType="risk"
                icon={theme.reports?.riskChartsPage?.lineChart?.showIcon ? peopleIcon : undefined}
                type="risk"
                legend={theme.reports?.riskChartsPage?.lineChart?.showLegend ? attitudeToRisk!.legend : []}
                series={[
                  {
                    id: "1",
                    title: client?.firstName,
                    values: attitudeToRisk!.attitudeToRiskScore,
                    color: theme.colors.scoreChartColor ?? "#7882D1"
                  }
                ]}
                highlightAreas={attitudeToRisk!.highlightAreas}
                highlightDataPoints={attitudeToRisk!.highlightDataPoints}
                title={riskAttitudeMessages?.graphTitle}
                showScoreAsDataPoint={theme.charts?.attitudeToRisk?.showScoreAsDataPoint}
              />
              <PatternsModalLink type="risk" />
            </>
          )}
          {clientLoadError && <LoadingError message={"Could not load client"} />}
          {householdLoadError && <LoadingError message={"Could not load household"} />}
        </div>
      </div>
    </div>
  )
}

export default RMJRiskAttitude
