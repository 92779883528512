import { useContext, useMemo, useState } from "react"
import { FirmContext } from "../../../contexts/FirmContext"
import Loading from "../../../components/ClientProfile/Loading/Loading"
import ErrorMessage from "../../../components/Error/ErrorMessage"
import { Client, GoalDetail, PortfolioMapping } from "../../../models/Client"
import { Household } from "../../../models/Household"
import Select, { Option } from "../../../components/Select/Select"
import CardTitle from "../../advisor/Results/Goals/components/CardTitle"

const EditPortfolioModal = ({
  client,
  household,
  currentPortfolio,
  goals,
  portfolioMappings,
  onUpdate,
  updateState,
  onClose
}: {
  client?: Client
  household?: Household
  currentPortfolio?: string
  goals?: GoalDetail[]
  portfolioMappings?: PortfolioMapping[]
  onUpdate: (portfolio: string | GoalDetail[] | null) => void
  updateState: "updating" | "success" | "error" | undefined
  onClose: () => void
}) => {
  const { firm } = useContext(FirmContext)
  const [portfolio, setPortfolio] = useState<string | null>(currentPortfolio ?? null)
  const [goalPortfolios, setGoalPortfolios] = useState<GoalDetail[]>(
    () =>
      goals?.map((goal) => ({
        type: goal.type,
        id: goal.id,
        selectedPortfolio: goal.selectedPortfolio ?? null
      })) || []
  )

  const name = useMemo(
    () => (household ? household.members.map((member) => member.client.firstName).join(" and ") : client?.firstName),
    [client?.firstName, household]
  )

  const options: Option[] = useMemo(() => {
    const portfolios =
      firm?.modelPortfolios
        ?.map((modelPortfolio) => {
          const portfolioMapping = portfolioMappings?.find((mapping) => mapping.portfolio.id === modelPortfolio.id)
          return {
            id: modelPortfolio.id,
            label: portfolioMapping?.riskComfort !== undefined ? `${modelPortfolio.name} - ${portfolioMapping.riskComfort}% Risk comfort` : modelPortfolio.name,
            value: modelPortfolio.id,
            r: modelPortfolio?.r || 0
          }
        })
        .sort((a, b) => b.r - a.r) || []
    return [
      ...portfolios,
      {
        id: "no-portfolio",
        label: "No selected investment",
        value: ""
      }
    ]
  }, [firm?.modelPortfolios, portfolioMappings])

  return (
    <div className="w-full flex flex-col overflow-y-scroll no-scrollbar" style={{ maxHeight: "calc(100vh - 100px)" }}>
      <div className="w-full flex flex-col gap-6">
        <h2 className="text-h2 font-semibold">
          Please select an investment risk level for{" "}
          {goals?.length === 1 ? (
            "this goal"
          ) : (
            <>
              your {household ? "clients" : "client"}
              {goals ? (household ? "' goals" : "'s goals") : ""}
            </>
          )}
        </h2>
        {goals ? ( // if goals present
          goals.map((goal, i) => (
            <div key={i}>
              <Select
                label={<CardTitle goalDetails={goals} goal={goal} />}
                id={`portfolio-${goal.type}`}
                options={options}
                value={goalPortfolios.find((g) => g.type === goal.type && g.id === goal.id)?.selectedPortfolio ?? ""}
                onChange={({ target: { value } }) => {
                  setGoalPortfolios((prev) =>
                    prev.map((g) => (g.type === goal.type && g.id === goal.id ? { ...g, selectedPortfolio: value === "" ? null : value } : g))
                  )
                }}
              />
            </div>
          ))
        ) : (
          <Select // no goals present, single portfolio
            label={`${name}'s portfolio`}
            id="current-portfolio"
            options={options}
            value={portfolio ?? ""}
            onChange={({ target: { value } }) => {
              setPortfolio(value === "" ? null : value)
            }}
          />
        )}
        {updateState === "error" && <ErrorMessage id="edit-portfolio" message="Error updating the portfolio" />}
        <div className="flex gap-4 w-full m-auto text-center justify-center">
          <button className="btn btn-secondary btn-medium w-44" onClick={onClose}>
            Cancel
          </button>
          <button
            className="btn btn-primary btn-medium w-44"
            disabled={updateState === "updating"}
            onClick={() => onUpdate(goals ? goalPortfolios : portfolio)}
          >
            {updateState === "updating" ? <Loading type="dots" /> : "Update"}
          </button>
        </div>
      </div>
    </div>
  )
}

export default EditPortfolioModal
