import { Client } from "../../../models/Client"
import Container from "./components/Container"
import { Household } from "../../../models/Household"
import Footer from "./components/Footer"
import { useCallback, useContext, useMemo } from "react"
import { FirmContext } from "../../../contexts/FirmContext"
import Target from "./assets/goals.svg"
import PortfolioChart from "../../rmjourney/goalExplorer/components/PortfolioChart/PortfolioChart"
import { Options } from "../../rmjourney/goalExplorer/components/SelectPortfolio/SelectPortfolio"
import { formatCurrencyLong } from "../../../lib/currency"
import RetirementDrawdownChart from "../../rmjourney/goalExplorer/components/RetirementDrawdownChart/RetirementDrawdownChart"
import RetirementDrawdownLegends from "../../rmjourney/goalExplorer/components/RetirementDrawdownChart/RetirementDrawdownLegends"
import clsx from "clsx"
import { sortedGoalDetails } from "./InvestmentMeetingReport"
import { getGoalTitle } from "../../advisor/Results/Goals/components/CardTitle"
import { tt } from "../../../lib/translations"
import { format, intervalToDuration } from "date-fns"

interface Props {
  page: number
  client: Client
  household: Household
  options: Options
}

const ProjectorChartPage = ({ page, client, household, options }: Props) => {
  const { firm } = useContext(FirmContext)
  const clientOrHousehold = client || household
  const currentPortfolio = useMemo(() => {
    if (clientOrHousehold.goals?.goalDetails && clientOrHousehold.goals?.goalDetails.length > 0) {
      const goal = sortedGoalDetails(clientOrHousehold.goals.goalDetails)[0]
      return firm?.modelPortfolios?.find((modelPortfolio) => modelPortfolio.id === goal.selectedPortfolio)
    } else {
      return firm?.modelPortfolios?.find((modelPortfolio) => modelPortfolio.id === clientOrHousehold.currentPortfolio)
    }
  }, [clientOrHousehold.currentPortfolio, clientOrHousehold.goals?.goalDetails, firm?.modelPortfolios])

  const goal =
    clientOrHousehold?.goals?.goalDetails && clientOrHousehold.goals.goalDetails.length > 0
      ? sortedGoalDetails(clientOrHousehold.goals.goalDetails)[0]
      : undefined
  const goalType = goal?.type === "retirementIncome" && clientOrHousehold?.alreadyRetired ? "retirementDrawdown" : goal?.type

  const title = useMemo(() => {
    if (goal && clientOrHousehold) {
      const title = getGoalTitle({ client, household, goalDetails: clientOrHousehold.goals!.goalDetails!, goal })
      return title ? title : tt({ id: `profile-page-status-card-goals-goal-${goal.type}-title` })
    }
  }, [client, clientOrHousehold, goal, household])

  const clientRetirementAge = useCallback((client: Client) => {
    return client.retirementDate ? intervalToDuration({ start: new Date(client.dob), end: new Date(client.retirementDate) }).years : client.retirementAge
  }, [])

  return (
    <Container className="flex flex-col justify-between pt-10 relative">
      <div className="mx-10">
        <h2 className="mb-2 text-main-600 text-h4 font-semibold pb-5 tracking-wide">
          {goalType === "retirementIncome"
            ? "Retirement income projector"
            : goalType === "retirementDrawdown"
            ? "Retirement income and legacy projector"
            : `${title} projector`}
        </h2>
        <div className="bg-surface-100 grow flex w-full px-6 py-6 items-center">
          <div className="flex-col pr-7 text-center border-r border-surface-400">
            <img className="mx-auto h-16 w-16" alt="target" src={Target} />
            <h3 className="text-h2 pt-3 font-semibold">
              {`${formatCurrencyLong(goal?.targetAmount?.value)?.trim()}${goal?.type === "retirementIncome" ? tt({ id: `postfix-option-${goal?.targetAmount?.frequency ?? "annually"}` }) : ""}`}
              <span className="block pt-2 font-semibold text-p">
                {goalType === "retirementIncome" ? "Retirement income target" : goalType === "retirementDrawdown" ? "Retirement drawdown target" : `${title} target`}
              </span>
            </h3>
          </div>
          <div className={clsx("flex flex-col flex-wrap w-86 gap-x-8 text-sm ml-6", goalType === "wealthAccumulation" ? "max-h-32" : "max-h-44")}>
            <div>
              <h4>Investment amount</h4>
              <p className="font-bold">{formatCurrencyLong(goal?.investmentAmount ?? clientOrHousehold.investmentAmount)}</p>
            </div>

            {goalType !== "retirementDrawdown" && (
              <div>
                <h4 className="pt-2">Investment contribution</h4>
                <p className="font-bold">
                  {`${formatCurrencyLong(goal?.contributions?.value)?.trim()}${tt({ id: `postfix-option-${goal?.contributions?.frequency ?? "annually"}` })}`}
                </p>
              </div>
            )}

            {(goalType === "retirementIncome" || goalType === "retirementDrawdown") && (
              <>
                {clientOrHousehold.pensionInRetirement && (
                  <div className="pb-2">
                    <h4 className="pt-2">Pension in retirement</h4>
                    <p className="font-bold">{formatCurrencyLong(clientOrHousehold.pensionInRetirement, "p/y")}</p>
                  </div>
                )}
                {!!goal?.otherIncome?.value && <div>
                  <h4 className="pt-2">Other income in retirement</h4>
                  <p className="font-bold">
                    {`${formatCurrencyLong(goal?.otherIncome?.value)?.trim()}${tt({ id: `postfix-option-${goal?.otherIncome?.frequency ?? "annually"}` })}`}
                  </p>
                </div>}
              </>
            )}

            {goalType !== "retirementIncome" && goalType !== "retirementDrawdown" && (
              <div className="flex flex-col pt-2">
                <h4>Expected withdrawl year</h4>
                <p className="font-bold">
                  {goal?.targetDate?.value ? format(new Date(goal.targetDate.value), "yyyy") : clientOrHousehold.wealthAccumulationYear}
                </p>
              </div>
            )}

            {goalType === "retirementDrawdown" && (clientOrHousehold.estateGoal || goal?.estateGoal) && (
              <div className="py-2">
                <h4>Legacy goal</h4>
                <p className="font-bold">{formatCurrencyLong(goal?.estateGoal ?? clientOrHousehold.estateGoal)}</p>
              </div>
            )}
            {goalType === "retirementIncome" && (
              <div className="pt-2">
                <h4>Retirement age</h4>
                {client && (
                  <div className="flex">
                    <p className="font-bold pr-1">{clientRetirementAge(client)} yrs</p>
                    <p>(retire in {clientRetirementAge(client)! - (new Date().getFullYear() - new Date(client.dob).getFullYear())} yrs)</p>
                  </div>
                )}
                {household && (
                  <>
                    {household.members.map((member) => (
                      <div key={member.client?._id ?? member.id} className="flex">
                        <p className="font-bold pr-1">
                          {member.client.firstName}, {clientRetirementAge(member.client)} yrs
                        </p>
                        <p>(retire in {clientRetirementAge(member.client)! - (new Date().getFullYear() - new Date(member.client.dob).getFullYear())} yrs)</p>
                      </div>
                    ))}
                  </>
                )}
                <div className="pb-2">
                  <h4 className="pt-2">Planning horizon</h4>
                  <p className="font-bold">{goal?.planningHorizon || clientOrHousehold?.planningHorizon || 100} yrs</p>
                </div>
              </div>
            )}
            <div className={clsx({ "pt-2": !(clientOrHousehold.estateGoal || goal?.estateGoal) })}>
              <h4>Selected investment</h4>
              <p className="font-bold">{currentPortfolio?.name}</p>
            </div>
          </div>
        </div>
        <div className="mt-18">
          <p className="text-sec text-main-500 pb-2">
            Our{" "}
            {goalType === "retirementIncome" ? "Retirement Income" : goalType === "retirementDrawdown" ? "Retirement Income and Legacy" : title}{" "}
            Projector illustrates how you might achieve your financial goal and stay comfortable with the level of investment risk.
          </p>
        </div>
        <div className="relative w-full h-full flex flex-col pt-5">
          {goalType === "retirementDrawdown" ? (
            <div className="h-[500px] w-full flex flex-col">
              <div className="relative h-full w-full">
                <RetirementDrawdownChart client={client!} household={household!} options={options} forReport={true} />
              </div>
              <div className="mb-5">
                <RetirementDrawdownLegends forReport={true} clientOrHousehold={clientOrHousehold!} comfortMatch={options.comfortMatch!} />
              </div>
            </div>
          ) : (
            <div className="flex flex-col mt-4 grow mb-6 max-h-[450px]">
              {options && (
                <div className="mb-4 grow">
                  <PortfolioChart
                    options={options.chartOptions}
                    isRiskComfort={true}
                    isRange={true}
                    forReport={true}
                    showComfortMatch={true}
                    showGoalMatch={true}
                  />
                </div>
              )}
              <div className="flex flex-row gap-x-2 justify-center items-center text-sm">
                <div className="border-x w-2.5 h-2 border-highlight-500">
                  <hr className="mx-auto rotate-90 border-l border-dashed h-full w-max border-highlight-500" />
                </div>
                <p>Wealth range</p>
                <div className="bg-interactive-200 rounded-full w-2 h-2" />
                <p>{goalType === "wealthAccumulation" ? "Total projected investment balance" : "Total projected income"}</p>
                {goalType === "retirementIncome" && goal?.otherIncome?.value && (
                  <>
                    <div className="bg-interactive-300 rounded-full w-2 h-2"></div>
                    <p>Other income in retirement</p>
                  </>
                )}
              </div>
            </div>
          )}

          <p className="text-xxs text-main-500 pb-10 v-sm:pb-0">
            The{" "}
            {goalType === "retirementIncome" ? "Retirement Income" : goalType === "retirementDrawdown" ? "Retirement Income and Legacy" : title}{" "}
            Projector is designed for illustrative purposes only, and does not represent a full financial plan.
          </p>
        </div>
      </div>
      <div className="relative mx-10">
        <Footer pageNum={page} client={client} household={household} firm={firm} />
      </div>
    </Container>
  )
}

export default ProjectorChartPage
